import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Subsubcategory from './Subsubcategory.jsx';

const MobileSubcategory = ({value}) => {
const [showfullmenu,setshowfullmenu] = useState(false)
const nvg = useNavigate();
const urlParts = value.url.split('/');
const categoryid = urlParts[urlParts.length - 2];



const transfer2 = (value,title) =>{
  let urlParts = value.split('/');
let id = urlParts[urlParts.length - 2];
// nvg("/category",{ state: { id: id,pagename:title } });
nvg(`/category/${id}/${title}/none`);
// window.location.reload();
}


const transfer = (id)=>{
  // nvg("/category",{ state: { id: id,pagename:value.breadcrumbs} });
  nvg(`/category/${id}/${value.breadcrumbs}/none`);
  // window.location.reload();
}
    const [categories, setCategories] = useState([]);
    useEffect(()=>{
        async function fetchcategory(){
        const response = await axios.get(value.children);
         setCategories(response.data)
           }
    
        fetchcategory();
    },[])
  return (
    <li className={!categories[0]?.url ? "" : "mega"}>
        {!categories[0]?.url ? <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  onClick={()=>{transfer(categoryid)}}
  type='button'
 >
   {" "}
   {/* <img
     src={value.image}
     style={{ width: "25px" }}
     alt={404}
     className="hideonlaptop"
   />{" "} */}
   &nbsp;{" "}
   <span className="largefont"> {value.name} </span>


 </button> : <button
                             type="button"
                            className="btn dark-menu-item desgin1 justshow"
                            style={{padding:"6px 18px 0px 18px"}}
                          onClick={()=>{setshowfullmenu(!showfullmenu)}}
                            // href="javascript:void(0)"
                          >
                            {/* <img
                              src={value.image}
                              style={{ width: "25px" }}
                              alt={404}
                              className="hideonlaptop"
                            />{" "} */}
                            &nbsp;{" "}
                            <span className="largefont"> {value.name}</span>
                          </button>}

                          {showfullmenu == true ? !categories[0]?.url ? "" : <ul className="mega-menu full-mega-menu " style={{display:"block"}}>
                            <div >
                              <div className="container">
                                <div className="row" >
                                    {categories.map((item, index) => (
                                        <div className="col mega-box" >
                                    <div className="link-section">
                                      <div className="menu-title" >
                                        <h5 onClick={()=>{transfer2(item.url,item.breadcrumbs)}} style={{fontWeight:'400'}}>{item.name} </h5>
                                      </div>
                                      <Subsubcategory subvalue={item.children} />
                                    </div>
                                  </div>
                                    ))}
                                  
                                </div>
                              </div>
                            </div>
                          </ul> :''}
 
</li>
  )
}

export default MobileSubcategory