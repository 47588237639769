import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { gettoken, getvoucher } from "../Localstorage/Store";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { checkoutvalidation } from "../Validation/Checkout";
import { useNavigate } from "react-router-dom";
import { getCode, getName } from "country-list";
import countryList from "country-list";
import Loader from "../components/Loader";

const Checkout = () => {
  const [data, setData] = useState([]);
  const [cartinfo, setcartinfo] = useState({});
  const [userloader, setuserloader] = useState(false);
  const [hideorshowform, sethideorshowform] = useState(true);
  const [loading, setloading] = useState(false);
  const [addloader, setaddloader] = useState(true);
  const [showadd, setshowadd] = useState(false);
  const tokenvalue = gettoken();
  const nvg = useNavigate();
  const [cityna,setcityna] = useState("")
  const [total_shipping_charge_basketview, settotal_shipping_charge_basketview] = useState(0);
  console.log("dddddddddvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv",total_shipping_charge_basketview);
  const [firstname_super, setfirstname_super] = useState("");
  const [lastname_super, setlastname_super] = useState("");
  const [phonenumber_super, setphonenumber_super] = useState("");
  const [emailid_super, setemailid_super] = useState("");
  const [address1_super, setaddress1_super] = useState("");
  const [address2_super, setaddress2_super] = useState("");
  const [country2_super, setcountry2_super] = useState("");
  const [country2url_super, setcountry2url_super] = useState("");
  const [state2_super, setstate2_super] = useState("");
  const [city2_super, setcity2_super] = useState("");
  const [pincode2_super, setpincode2_super] = useState("");
  const [shppingstatus, setshppingstatus] = useState("");
  const localvouhcer = getvoucher();
  const [currenteditaddressid, setcurrenteditaddressid] = useState(0);
  const [formmodea, setformmodea] = useState("create");
console.log("daa",firstname_super,lastname_super,phonenumber_super,emailid_super,country2url_super,country2_super,state2_super)
  const checkoutform = async (values) => {
    if(shipping_price == 0){
      setshppingstatus("No Shipping Available for this Pincode");
    }else{
      setshppingstatus("");
    const modifiedUrl = cartinfo.lines.replace("/lines/", "/");
          // total: parseFloat(cartinfo.balance_payable),
          const data = tokenvalue.msg == "Voucher login" ? {
            basket: modifiedUrl,
            guest_email: emailid_super,
                payment_types:"COD",
            shipping_method_code: "no-shipping-required",
            code : localvouhcer,
            shipping_address: {
              country: country2url_super,
              first_name: firstname_super,
              last_name: lastname_super,
              line1: address1_super,
              line2: address2_super,
              line3: "",
              city: "",
              notes: "",
              phone_number: `+91${phonenumber_super}`,
              postcode: pincode2_super,
              state: state2_super,
              line4: city2_super,
              email: emailid_super,
            },
          } : {
            basket: modifiedUrl,
            guest_email: emailid_super,
                payment_types:"COD",
            shipping_method_code: "no-shipping-required",
            shipping_address: {
              country: country2url_super,
              first_name: firstname_super,
              last_name: lastname_super,
              line1: address1_super,
              line2: address2_super,
              line3: "",
              city: "",
              notes: "",
              phone_number: `+91${phonenumber_super}`,
              postcode: pincode2_super,
              state: state2_super,
              line4: city2_super,
              email: emailid_super,
            },
          };
    // const data = {
    //   basket: modifiedUrl,
    //   guest_email: emailid_super,
    //       payment_types:"COD",
    //   shipping_method_code: "no-shipping-required",
    //   shipping_address: {
    //     country: country2url_super,
    //     first_name: firstname_super,
    //     last_name: lastname_super,
    //     line1: address1_super,
    //     line2: address2_super,
    //     line3: "",
    //     city: "",
    //     notes: "",
    //     phone_number: `+91${phonenumber_super}`,
    //     postcode: pincode2_super,
    //     state: state2_super,
    //     line4: city2_super,
    //     email: emailid_super,
    //   },
    // };
    nvg("/pay", {
      state: {
        checkoutdata: data,
        total:Number(parseFloat(cartinfo.balance_payable + (total_shipping_charge_basketview == null ? shipping_price : total_shipping_charge_basketview))).toFixed(2),
        shipping_price:total_shipping_charge_basketview == null ? shipping_price : total_shipping_charge_basketview
      },
    });
  }
  };

  const setnewaddress = async (type, data, setFieldValue) => {
      setfirstname_super(data.first_name);
      setlastname_super(data.last_name);
      setphonenumber_super(data.phone_number.substring(3));
      setaddress1_super(data.line1);
      setaddress2_super(data.line2);
      setcity2_super(data.line4);
      setemailid_super(data.email);
      setstate2_super(data.state);
      setpincode2_super(data.postcode);
      getcountryurlsuper(data.country);
      shippingcharges(data.postcode)
  };
  const [shiploading,setshiploading] = useState(false)
  const [shipping_price, setshipping_price] = useState(0);
  const shippingcharges = async (pin) => {
    try {
      setshiploading(true)
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      const formdata = new FormData();
      formdata.append("pin_code",pin);
      let url = `${process.env.REACT_APP_API_URL}ship/get_price/`;
      const response = await axios.post(url, formdata,config);
console.log("reosponse of shipping charges",response)
setshipping_price(response.data.total_rate)
setshiploading(false)
    } catch (error) {
      setshipping_price(0);
      setshiploading(false)
    }
  };

  const createaddress = async (values,setFieldError) =>{
    console.log("values of fromd ",values,formmodea)
    if(formmodea == "create"){
   try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      const formdata = new FormData();
                formdata.append("first_name", values.first_name);
                formdata.append("last_name", values.last_name);
                formdata.append("phone_number", `+91${values.mobile}`);
                formdata.append("line1", values.address1);
                formdata.append("line2", values.address2);
                formdata.append("country", getCode(values.country));
                formdata.append("state", values.state);
                formdata.append("email", values.email);
                formdata.append("line4", values.city);
                formdata.append("postcode", values.pincode);
                if(tokenvalue.msg == "Voucher login"){
                  formdata.append("user", tokenvalue.user_id);
                }else{
                  formdata.append("user", tokenvalue.id);
                }
      // setloading(true)
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
      const response = await axios.post(url,formdata,config);
      if(response.data?.id){
        console.log("reespnse ravi",response.data)
        getaddress("new")
        shippingcharges(values.pincode)
        sethideorshowform(true)
        setnewaddress('add',response.data)
      }else{
        
      }
    } catch (error) {
      console.log("firstnnnnnnnnn,,,",error.response)
      setFieldError('mobile',error.response.data.phone_number[0])
    }
    }else{


      try {
       const formdata = new FormData();
       formdata.append("first_name", values.first_name);
       formdata.append("last_name", values.last_name);
       formdata.append("phone_number", `+91${values.mobile}`);
       formdata.append("line1", values.address1);
       formdata.append("line2", values.address2);
       formdata.append("country", getCode(values.country));
       formdata.append("state", values.state);
       formdata.append("email", values.email);
       formdata.append("line4", values.city);
       formdata.append("postcode", values.pincode);
       if(tokenvalue.msg == "Voucher login"){
         formdata.append("user", tokenvalue.user_id);
       }else{
         formdata.append("user", tokenvalue.id);
       }
       const config = {
         headers: {
           Authorization: `Bearer ${tokenvalue.access}`,
         },
       };
     
       let url = `${process.env.REACT_APP_API_URL}homepageapi/address/${currenteditaddressid}/update/`;
       const response = await axios.put(url, formdata, config);
       console.log("show me response of response id",response);
       if(response.data?.id){
        console.log("reespnse ravi",response.data)
        getaddress("new")
        shippingcharges(values.pincode)
        sethideorshowform(true)
        setnewaddress('add',response.data)
      }else{
        
      }
      } catch (error) {
        console.log("firstnnnnnnnnn,,,",error.response)
        setFieldError('mobile',error.response.data.phone_number[0])
      }
    }
 
  
  
   }

  const getcountryurlsuper = (value) => {
    countriesdata.map((item4) =>
      item4.printable_name == getName(value)
        ? setcountry2url_super(item4.url)
        : ""
    );
    countriesdata.map((item4) =>
      item4.printable_name == getName(value)
        ? setcountry2_super(item4.printable_name)
        : ""
    );
  };

  const fetchstateandcity = async (value, setFieldError, setFieldValue) => {
    if (value.length == 6) {
      try {
        let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
        const response = await axios.get(url);
        // setFieldValue("city", response.data.city);
        if(response.data.city == "NA"){
          setFieldValue('city','');
          setcityna("NA");
        setFieldError('city',"Please Enter Your City")
        }else{
          setFieldValue('city',response.data.city);
          setcityna("");
        }
        setFieldValue("state", response.data.state);
        const countrydata = countriesdata.filter(
          (item2) => item2.printable_name == response.data.country.trim()
        );
        console.log(";;;;;;", countrydata);
        setFieldValue("country", countrydata[0].printable_name);
        setFieldValue("countryurl", countrydata[0].url);
      } catch (error) {
        setFieldError("pincode", error.response.data.message);
      }
    } else {
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("country", "");
      setFieldValue("countryurl", "");
    }
  };
  const [countriesdata, setcountriesdata] = useState([]);
  useEffect(() => {
    async function fetchcategory() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setloading(true);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/BasketView`;
      const response = await axios.get(url, config);
      setData(response.data.product_deatils);
      settotal_shipping_charge_basketview(response.data.total_shiping_charge);
      setcartinfo(response.data);
      setloading(false);
      console.log("first full basket view ",response.data.product_deatils.length)
      if(response.data.product_deatils.length == 0){
nvg('/');
      }
    }

    async function getcountries() {
      let url = `${process.env.REACT_APP_API_URL}api/countries/`;
      try {
        const response = await axios.get(url);

        setcountriesdata(response.data);
      } catch (error) {}
    }

    getcountries();
    fetchcategory();
  }, []);

  const getcountryurl = (value, setFieldValue) => {
    countriesdata.map((item4) =>
      item4.printable_name == getName(value)
        ? setFieldValue("countryurl", item4.url)
        : ""
    );
    countriesdata.map((item4) =>
      item4.printable_name == getName(value)
        ? setFieldValue("country", item4.printable_name)
        : ""
    );
  };
  const getcountryurl2 = (value) => {
    const res = countriesdata.find(
      (item3) => item3.price_excl_tax == getName(value)
    );
    return res.url;
  };

  const [addressdata, setaddressdata] = useState([]);
  const [addressdataindex, setaddressdataindex] = useState(0);
  console.log("addressdata isf pf dd",addressdataindex);


  const editformopen = async (data,setFieldValue,mod) =>{
    if(mod == "create"){
    setformmodea("create");
    sethideorshowform(false);
    setFieldValue('first_name','');
    setFieldValue('last_name','');
    setFieldValue('mobile','');
    setFieldValue('address1','');
    setFieldValue('address2','');
      setFieldValue('city','');
    setFieldValue('email','');
    setFieldValue('state','');
    setFieldValue('pincode','');
    setFieldValue("country",'');
    setFieldValue("countryurl", '');
    }else{

    console.log("erorr of erors",data);
    sethideorshowform(false);
    setformmodea("update");
    setFieldValue('first_name',data.first_name);
    setFieldValue('last_name',data.last_name);
    setFieldValue('mobile',data.phone_number.substring(3));
    setFieldValue('address1',data.line1);
    setFieldValue('address2',data.line2);
    setcurrenteditaddressid(data.id)
    if(data.line4 !== "NA"){
      setFieldValue('city',data.line4);
    }else{
      setFieldValue('city','');

    } 
    setFieldValue('email',data.email);
    setFieldValue('state',data.state);
    setFieldValue('pincode',data.postcode);
    getcountryurlsuper(data.country);
    const countrydata = countriesdata.filter(
      (item2) => item2.printable_name == data.country_printable_name
    );
    setFieldValue("country", countrydata[0].printable_name);
    setFieldValue("countryurl", countrydata[0].url);
    // shippingcharges(data.postcode)
    }
  }
   async function getaddress(actionid) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      setaddloader(true);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/list`;
      try {
        const response = await axios.get(url, config);
        let response2 = await axios.get(
          `${process.env.REACT_APP_API_URL}api/countries/`
        );
        setaddressdata(response.data);
        if(actionid !== "new"){
          if(response.data[0]?.postcode){
            shippingcharges(response.data[0].postcode)
          }
        }
        //  setaddressdataindex(response.data[response.data.length - 1])
        //  const item = response.data[response.data.length - 1];
        setaddressdataindex(response.data[0]);
        const item = response.data[0];
        //  response.data.map((item,index)=>{
        // if(index == 0){
          if(actionid == "default"){
            if (item) {
              if (item.first_name) {
                setfirstname_super(item.first_name);
              }
              if (item.last_name) {
                setlastname_super(item.last_name);
              }
              if (item.phone_number) {
                setphonenumber_super(item.phone_number.substring(3));
              }
              if (item.line1) {
                setaddress1_super(item.line1);
              }
              if (item.line2) {
                setaddress2_super(item.line2);
              }
              if (item.email) {
                setemailid_super(item.email);
              }
              if (item.state) {
                setstate2_super(item.state);
              }
              if (item.line4) {
                setcity2_super(item.line4);
              }
              if (item.postcode) {
                setpincode2_super(item.postcode);
              }
              if (item.country) {
                console.log("yyyyyuuuuuuu", response2, item.country);
                response2.data.map((item4) =>
                  item4.printable_name == getName(item.country)
                    ? setcountry2url_super(item4.url)
                    : ""
                );
                response2.data.map((item4) =>
                  item4.printable_name == getName(item.country)
                    ? setcountry2_super(item4.printable_name)
                    : ""
                );
              }
            }else{
        sethideorshowform(false)
            }
          }
        // }
        //  })
        setaddloader(false);
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }
  useEffect(() => {
   
    getaddress("default");
  }, []);

  return <> <Header /> {shiploading == true ? <> <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}><h2
  style={{
    textAlign: "center",
    paddingTop: "15px",
    fontWeight: "600",
    fontSize: "20px",
  }}
>
  <Loader />
</h2></div></> : addloader == false ? (
    userloader == false ? (
      <>

        {/* breadcrumb start */}
        <div className="breadcrumb-main margincart">
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    {/* <ul>
                <li><a href="javascript:void(0)">home</a></li>
                <li>></li>
                <li><a href="javascript:void(0)">checkout</a></li>
              </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb End */}

        {/* section start */}
        <section className="section-big-py-space b-g-light">
          <div className="custom-container">
            <div className="checkout-page contact-page">
              <div className="checkout-form">
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    address1: '',
                    address2: '',
                    country: '',
                    countryurl: '',
                    state: '',
                    city: '',
                    pincode: '',
                  }}
                  validationSchema={checkoutvalidation}
                  onSubmit={(values,{setFieldError}) => {
                    createaddress(values,setFieldError);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleSubmit,
                    touched,
                    setFieldValue,
                    setFieldError,
                  }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <div className="row d-flex justify-content-center align-items-start">
                        <div className="col-lg-7 col-sm-12 col-xs-12">
                          {/* <div className="checkout-title">
                  <h3>Billing Details</h3></div> */}

                          <div className="theme-form">

                          <div class="row details py-2 justify-content-start">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-0" style={{position:'relative'}}>
                                {/* {addressdata.map((item, index) => (index == addressdataindex ? */}
                                {addressdataindex ? (
                                  <input
                                    type="checkbox"
                                    name="shipping-option"
                                    hidden
                                    style={{position:'relative',top:'2px',right:'2px'}}
                                    onClick={() => {
                                      // setshowadd(!showadd);
                                      editformopen({},setFieldValue,"create");
                                      sethideorshowform(false);
                                    }}
                                    id="account-option"
                                  />
                                ) : (
                                  ""
                                )}
                                {/* : 'dddd'))}&nbsp; */}
                                <label
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn m-0"
                                  htmlFor="account-option"
                                >
                                  {/* SHIP TO A DIFFERENT ADDRESS? */}
                                  <span style={{fontSize:'17px'}}>+</span> Add a new address
                                </label>
                              </div>
                           </div>
                            <div className="row check-out " style={{display:hideorshowform == false ? "flex" : "none"}}>
                              <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label className="acounttitle m-0">
                                  First Name <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                                <Field
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  style={{ fontSize: "12px" }}
                                  placeholder="Enter Your First Name"
                                  value={values?.first_name}
                                />
                                <div className="error">
                                  {errors.first_name && touched.first_name ? (
                                    <p style={{ color: "red" }}>
                                      {errors.first_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label className="acounttitle m-0">
                                  Last Name
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                                <Field
                                  name="last_name"
                                  type="text"
                                  className="form-control"
                                  style={{ fontSize: "12px" }}
                                  placeholder="Enter Your Last Name"
                                  value={values?.last_name}
                                />
                                <div className="error">
                                  {errors.last_name && touched.last_name ? (
                                    <p style={{ color: "red" }}>
                                      {errors.last_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label className="acounttitle field-label m-0">
                                  Phone
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                                <Field
                                  name="mobile"
                                  type="number"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Enter Your Mobile. No"
                                  value={values?.mobile}
                                />
                                <div className="error">
                                  {errors.mobile && touched.mobile ? (
                                    <p style={{ color: "red" }}>
                                      {errors.mobile}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                <label className="acounttitle field-label m-0">
                                  Email Address
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder='Email'/> */}
                                <Field
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  style={{ fontSize: "12px" }}
                                  placeholder="Enter Your Email Address"
                                  value={values?.email}
                                />
                                <div className="error">
                                  {errors.email && touched.email ? (
                                    <p style={{ color: "red" }}>
                                      {errors.email}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <label className="acounttitle field-label m-0">
                                  Address
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                <Field
                                  name="address1"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="House number and street name"
                                  value={values.address1}
                                />
                                <div className="error">
                                  {errors.address1 && touched.address1 ? (
                                    <p style={{ color: "red" }}>
                                      {errors.address1}
                                    </p>
                                  ) : null}
                                </div>
                                {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                                <Field
                                  name="address2"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control mt-2"
                                  placeholder="Apartment, suite, unit etc. (optional)"
                                  value={values.address2}
                                />
                                <div className="error">
                                  {errors.address2 && touched.address2 ? (
                                    <p style={{ color: "red" }}>
                                      {errors.address2}
                                    </p>
                                  ) : null}
                                </div>
                                {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                              </div>

                              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                <label className=" acounttitle field-label m-0">
                                  Postal Code
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                                <Field
                                  name="pincode"
                                  type="number"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Enter Your Pincode"
                                  value={values.pincode}
                                  minLength={6}
                                  maxLength={6}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "pincode",
                                      e.target.value.replace(/[^0-9\ ]/gi, "")
                                    );
                                    fetchstateandcity(
                                      e.target.value.replace(/[^0-9\ ]/gi, ""),
                                      setFieldError,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <div className="error">
                                  {errors.pincode && touched.pincode ? (
                                    <p style={{ color: "red" }}>
                                      {errors.pincode}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                <label className="acounttitle field-label m-0">
                                  Country
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                <Field
                                  name="country"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Country"
                                  readOnly
                                  value={values.country}
                                />
                                <Field
                                  name="countryurl"
                                  type="hidden"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Country"
                                  readOnly
                                  value={values.countryurl}
                                />
                                <div className="error">
                                  {errors.country && touched.country ? (
                                    <p style={{ color: "red" }}>
                                      {errors.country}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                <label className="acounttitle field-label m-0">
                                  State
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder="State" /> */}
                                <Field
                                  name="state"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Enter Your State"
                                  readOnly
                                  value={values.state}
                                />
                                {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}
                                <div className="error">
                                  {errors.state && touched.state ? (
                                    <p style={{ color: "red" }}>
                                      {errors.state}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {values.state !== '' && values.state !== null && cityna == 'NA' ? <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                <label className="acounttitle field-label m-0">
                                  City
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder="City" /> */}
                                <Field
                                  name="city"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Enter Your City"
                                  value={values.city}
                                />
                                {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                                <div className="error">
                                  {errors.city && touched.city ? (
                                    <p style={{ color: "red" }}>
                                      {errors.city}
                                    </p>
                                  ) : null}
                                </div>
                              </div> : <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                <label className="acounttitle field-label m-0">
                                  City
                                  <span style={{color:'red',fontSize:'13px'}}> *</span>
                                </label>
                                {/* <input type="text" name="field-name" placeholder="City" /> */}
                                <Field
                                  name="city"
                                  type="text"
                                  style={{ fontSize: "12px" }}
                                  className="form-control"
                                  placeholder="Enter Your City"
                                  readOnly
                                  value={values.city}
                                />
                                {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                                <div className="error">
                                  {errors.city && touched.city ? (
                                    <p style={{ color: "red" }}>
                                      {errors.city}
                                    </p>
                                  ) : null}
                                </div>
                              </div>}
                              
                              <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                               
                              <div className="payment-box">
                              <div className="text-left" style={{display:'flex',justifyContent:'end',gap:'4px'}}>
                               {firstname_super == "" || firstname_super == null ? "" : <button
                                  type="button"
                                  onClick={()=>{sethideorshowform(true)}}
                                  style={{ fontSize: "12px",background:'#bdbfc0',color:'#000' }}
                                  className="btn-normal btn"
                                >
                                  Cancel
                                </button>} 
                                <button
                                  type="submit"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Save
                                </button>
                              </div>
                               </div>
                               
                              </div>
                            </div>
                           
                            <div class="row details py-2 justify-content-start" style={{alignItems:'start'}}>
                              {addressdata.map((item, index) =>
                                item.id == addressdataindex.id ? (
                                  <div
                                    class="col-lg-6"
                                    style={{
                                      marginBottom: "9px",
                                      // display: showadd ? "block" : "none",
                                    }}
                                  >
                                    <div class="card">
                                      <div class="card-body">
                                        <h5
                                          class="card-title acounttitle"
                                          style={{
                                            textTransform: "capitalize",
                                            position:'relative'
                                          }}
                                        >
                                          {item.first_name}&nbsp;
                                          {item.last_name}&nbsp;(Default)
                                          <span className="hello" style={{position:'absolute',top:'-1px',right:"0px"}}>
                              {/* <i class="fa-solid fa-ellipsis-vertical"></i> */}
                              <div class="dropdown">
<button class="dot-dot secondary dropdown-toggle" style={{border:'none',background:'white'}} type="three dots" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
<i class="fa-solid fa-ellipsis-vertical"></i>
</button>
<ul class="dropdown-menu dropdownMenu lateststyle  ">
<li><button type="button" style={{fontSize:'12px'}}   onClick={()=>{editformopen(item,setFieldValue)}} class="dropdown-item"><i class="fa fa-pen-to-square "></i>&nbsp;Edit</button></li>
{/* <li><button type="button" style={{fontSize:'12px'}}  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1400000"
               class="dropdown-item" onClick={()=>{setdelid(item.id)}}><i class="fa fa-trash-can "></i>&nbsp;Delete</button></li> */}
</ul>
</div>
                            </span>
                                        </h5>

                                        <div class="form-check" style={{position:'relative'}}>
                                          { address1_super == item.line1 &&
                                          address2_super == item.line2 &&
                                          country2_super ==
                                            countriesdata.find(
                                              (item3) =>
                                                item3.printable_name ==
                                                getName(item.country)
                                            )?.printable_name &&
                                          pincode2_super == item.postcode ? (
                                            <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}

                                              onChange={() => {
                                                setnewaddress(
                                                  "default",
                                                  addressdata,
                                                  setFieldValue
                                                );
                                              }}
                                              checked
                                            />
                                          ) : (
                                            <input
                                              class=""
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={() => {
                                                setnewaddress(
                                                  "new",
                                                  item,
                                                  setFieldValue
                                                );
                                              }}
                                            />
                                          )}

                                          <label
                                            class="form-check-label mb-0"
                                            for="flexRadioDefault1"
                                          >
                                            <h5 className="number acounttitle" style={{paddingBottom:'4px'}}>
                                              Mobile:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.phone_number}
                                              </span>
                                            </h5>
                                            <h5 className="number acounttitle">
                                              Email ID:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.email}
                                              </span>
                                            </h5>
                                          </label>
                                        </div>
                                        <p className="small-text" style={{height:'40px'}}>
                                          <span style={{ textWrap: "nowrap" }}>
                                            {item.line1}
                                          </span>
                                          &nbsp;
                                          {item.line2}
                                          &nbsp;
                                          {countryList.getName(item.country)}
                                          &nbsp;
                                          {item.state}
                                          &nbsp;
                                          {item.line4}-{item.postcode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                              {addressdata.map((item, index) =>
                                item.id !== addressdataindex.id ? (
                                  <div
                                    class="col-lg-6"
                                    style={{
                                      marginBottom: "9px",
                                      // display: showadd ? "block" : "none",
                                    }}
                                  >
                                    <div class="card">
                                      <div class="card-body">
                                        <h5
                                          class="card-title acounttitle"
                                          style={{
                                            textTransform: "capitalize",
                                            position:'relative'
                                          }}
                                        >
                                          {item.first_name}&nbsp;
                                          {item.last_name}
                                          <span className="hello" style={{position:'absolute',top:'-1px',right:"0px"}}>
                              {/* <i class="fa-solid fa-ellipsis-vertical"></i> */}
                              <div class="dropdown">
<button class="dot-dot secondary dropdown-toggle"  style={{border:'none',background:'white'}} type="three dots" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
<i class="fa-solid fa-ellipsis-vertical"></i>
</button>
<ul class="dropdown-menu dropdownMenu lateststyle  ">
<li><button type="button" style={{fontSize:'12px'}} onClick={()=>{editformopen(item,setFieldValue)}}   class="dropdown-item"><i class="fa fa-pen-to-square "></i>&nbsp;Edit</button></li>
{/* <li><button type="button" style={{fontSize:'12px'}}  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1400000"
               class="dropdown-item" onClick={()=>{setdelid(item.id)}}><i class="fa fa-trash-can "></i>&nbsp;Delete</button></li> */}
</ul>
</div>
                            </span>
                                        </h5>

                                        <div class="form-check" style={{position:'relative'}}>
                                          {firstname_super ==
                                            item.first_name &&
                                          lastname_super == item.last_name &&
                                          address1_super == item.line1 &&
                                          address2_super == item.line2 &&
                                          country2_super ==
                                            countryList.getName(item.country) &&
                                          pincode2_super == item.postcode ? (
                                            <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={() => {
                                                setnewaddress(
                                                  "default",
                                                  addressdata,
                                                  setFieldValue
                                                );
                                              }}
                                              checked
                                            />
                                          ) : (
                                            <input
                                              class=""
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={() => {
                                                setnewaddress(
                                                  "new",
                                                  item,
                                                  setFieldValue
                                                );
                                              }}
                                            />
                                          )}

                                          <label
                                            class="form-check-label mb-0"
                                            for="flexRadioDefault1"
                                          >
                                            <h5 className="number acounttitle" style={{paddingBottom:'4px'}} >
                                              Mobile:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.phone_number}
                                              </span>
                                            </h5>
                                            <h5 className="number acounttitle">
                                              Email ID:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.email}
                                              </span>
                                            </h5>
                                          </label>
                                        </div>
                                        <p className="small-text" style={{height:'40px'}}>
                                          <span style={{ textWrap: "nowrap" }}>
                                            {item.line1}
                                          </span>
                                          &nbsp;
                                          {item.line2}
                                          &nbsp;
                                          {countryList.getName(item.country)}
                                          &nbsp;
                                          {item.state}
                                          &nbsp;
                                          {item.line4}-{item.postcode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        </div>




                        <div className="col-xl-4 col-lg-5 col-sm-12 col-xs-12 makestk">
                          <div className="checkout-details theme-form ">
                            <div className="order-box">
                              <div className="title-box">
                                <div style={{ fontSize: "12px",fontWeight:'600',color:'#1d1c1c' }}>
                                  Product <span>Total</span>
                                </div>
                              </div>
                              <ul className="qty">
                                {data[0]?.product__title
                                  ? data.map((item, index) => (
                                      <li style={{ fontSize: "12px",fontWeight:'400' }}>
                                        {item.product__title.length > 12 ? `${item.product__title.substring(0, 12)}...` : item.product__title} × {item.quantity}{" "}
                                        <span style={{ fontSize: "12px",fontWeight:'500' }}>
                                          ₹{(item.quantity * item.price_excl_tax).toFixed(2)}
                                        </span>
                                      </li>
                                    ))
                                  : ""}
                              </ul>
                              <ul className="sub-total">
                                <li style={{ fontSize: "12px",fontWeight:'600',color:'#1d1c1c' }}>
                                  Subtotal{" "}
                                  <span
                                    style={{ fontSize: "12px",fontWeight:'500' }}
                                    className="count"
                                  >
                                    ₹{cartinfo.balance_payable}
                                  </span>
                                </li>
                                <li style={{ fontSize: "12px",fontWeight:'600',color:'#1d1c1c' }}>
                                  Shipping{" "}
                                  <span
                                    style={{ fontSize: "12px",fontWeight:'500' }}
                                    className="count"
                                  >
                                    ₹{total_shipping_charge_basketview == null ? shipping_price : total_shipping_charge_basketview}
                                  </span>
                                </li>
                              </ul>
                              <ul className="total">
                                <li style={{fontSize:'12px',fontWeight:'600',color:'#1d1c1c'}}>
                                  Total{" "}
                                  <span
                                    style={{ fontSize: "12px",fontWeight:'500',color:'' }}
                                    className="count"
                                  >
                                    
                                    {/* {console.log(" dddddd",cartinfo.balance_payable,"kkkkkkkkkkkkkkkk",shipping_price)} */}
                                    ₹{(Number(parseFloat(cartinfo.balance_payable) + (total_shipping_charge_basketview == null ? parseFloat(shipping_price) : parseFloat(total_shipping_charge_basketview))).toFixed(2))} 
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="payment-box">
                              <div className="text-right">
                               {firstname_super == "" || firstname_super == null ? <button
                                onClick={()=>{checkoutform()}}
                                disabled
                                  type="button"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Proceed to Payment
                                </button> : <button
                                onClick={()=>{checkoutform()}}
                                  type="button"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Proceed to Payment
                                </button>} 
                              </div>
                              <span style={{display:'block',marginTop:'12px',color:'red'}}>
                                {shppingstatus}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* <Footer /> */}
      </>
    ) : (
      <><div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}> <h2
      style={{
        textAlign: "center",
        paddingTop: "15px",
        fontWeight: "600",
        fontSize: "20px",
      }}
    >
      <Loader />
      
    </h2></div></>
    )
  ) : (
    <></>
  )}</>
};
export default Checkout;
