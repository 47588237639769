import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { cartApi } from './api/cartapi'
import token from './state/token'
import cart from './state/cart'
import wishlist from './state/wishlist'
import { wishlistApi } from './api/wishlistapi'
import { bannerApi } from './api/bannerapi'

export const store = configureStore({
  reducer: {
    token:token,
    cart:cart,
    wishlist:wishlist,
    [cartApi.reducerPath]: cartApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartApi.middleware).concat(wishlistApi.middleware).concat(bannerApi.middleware),
})

setupListeners(store.dispatch)