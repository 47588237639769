import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { gettoken } from "../Localstorage/Store";
import axios from "axios";
import Disable from "../components/Disable";
import Loginheader from "../components/Loginheader";


const Thankyoupagelogin = () => {
  const [loading,setloading] = useState(true)
  const [orderid,setorderid] = useState(0)
  const location = useLocation();
  const nvg = useNavigate();
const tokenvalue = gettoken();
console.log("location of laoction",location)
  useEffect(()=>{
    
    const checkoutform = async () => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${tokenvalue.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}my_api/checkout/`;
          const response = await axios.post(
            url,
            location.state.orderinfo,
            config
          );
         setloading(false)
        } catch (error) {}
      
      
    };
if(location.state.orderinfo){
  setorderid(location.state.orderid)
  setloading(false)
}
  },[location])

  useEffect(() => {
    function handleClick(event) {
      // Your function logic here
      console.log('Clicked anywhere on the page');
    }

    // Add event listener to the document body
    document.body.addEventListener('click', handleClick);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);
    return(
  <>
<div>
 
  {/*header start*/}
{/* <Header /> */}
{/* <Loginheader /> */}
{/* {tokenvalue.msg !== "Voucher login" ? <Header /> : <Loginheader />}  */}
  {/*header end*/}
{/* <Disable /> */}


<section className=" pt-5 ">
  
          <div className="col-lg-12 col-sm-12  mt-lg-5 mt-xs-4 mb-2">
            {/* <div className='container-fluid bigdiv'> */}
            <div className="container-fluid">
              <div className="row Thankpage">
              <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 pb-3 d-flex justify-content-center">
          <img
            // src={`./images/logo.png`}
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth"
            style={{ maxWidth:'340px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>
                <div className="col-lg-12 col-sm-12 col-md-12">
                <div className="TextTankyou">
               <div className="Imgverify"> <img src="./images/icon/Group1.png" alt="img-fluid" style={{width:'23%'}} className="boyimg"/></div>
                    <h2 className="Fontthank" style={{fontWeight:'600'}}> <a href="Thank you" style={{color:'#230BB3'}}>Thank you</a> for your order <img src="./images/icon/Group2.png" alt="404" className="Checkimg2"/></h2>
                </div>
                <div>
                    <p className="text-center" style={{color:'#8F9091'}} >Your order no <span onClick={()=>{nvg(`/track-order`)}} style={{color:'#230bb3',textDecoration:'underline',cursor:'pointer'}}>{location?.state?.orderid}</span>  been placed and is being processed. You will receive an email
                        <p className="text-center" style={{color:'#8F9091'}} >With order details.</p>
                    </p>
                </div>
                <div className="backTohome" style={{paddingTop:'20px'}}>
                <NavLink to="/" style={{lineHeight:'20px',color:'#8F9091',fontSize:'14px' }}>Back To Login Page</NavLink> 
                </div>
               


                </div>

                
              </div>
            </div>
          </div>
        </section>

  {/* footer start */}
  {/* <Footer /> */}
  {/* footer end */}

 
</div>




        </>
    )
}
export default Thankyoupagelogin;