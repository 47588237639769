import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Disable() {
  const navigate = useNavigate();

  function handleClick(event) {
    event.preventDefault();
    // Optionally, you can perform any other actions here before navigating
    // For example, log the click event or execute some other function
    // Then navigate programmatically using the navigate function
    navigate(event.target.href);
  }

  useEffect(() => {
    // Disable all links rendered by NavLink components
    const links = document.querySelectorAll('.nav-link'); // Adjust the selector as per your NavLink classNames
    links.forEach(link => {
      link.addEventListener('click', handleClick);
    });

    // Clean up the event listeners when the component unmounts
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleClick);
      });
    };
  }, [navigate]);

  return null; // This component doesn't render anything
}

export default Disable;
