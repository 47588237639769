import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Subsubcategory from './Subsubcategory.jsx';

const Subcategorynew = ({value,setwdith}) => {
const [showfullmenu,setshowfullmenu] = useState(true)
const [showfullmenuname,setshowfullmenuname] = useState("none")
const nvg = useNavigate();
// const urlParts = value.url.split('/');
const categoryid = value.category_id;


const transfer = (id,value)=>{
  nvg(`/category/${id}/${value}/none`);
  // nvg("/category",{ state: { id: id,pagename:value.breadcrumbs} });
  // window.location.reload();
}


const transfer2 = (value,title,maincategory) =>{
//   let urlParts = value.split('/');
// let id = urlParts[urlParts.length - 2];
// nvg("/category",{ state: { id: id,pagename:title } });
nvg(`/category/${value}/${maincategory} > ${title}/none`);
window.location.reload();
}
    const [categories, setCategories] = useState([]);
    // useEffect(()=>{
    //     async function fetchcategory(){
    //     const response = await axios.get(value.children);
    //      setCategories(response.data)
    //        }
    
    //     fetchcategory();
    // },[])
  return (
    <li className={setwdith == 0 ? "" : "mega"}>
        {setwdith == 0 ? <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
   onClick={()=>{transfer(value.category_id,value.category_name)}}
 >
   {" "}
   {/* <img
     src={value.image}
     style={{ width: "25px" }}
     alt={404}
     className="hideonlaptop"
   />{" "} */}
   &nbsp;{" "}
   <span className="largefont"> {value.category_name} </span>


 </button> : <button
                             type="button"
                            className="btn dark-menu-item desgin1 ulappear"
                            style={{padding:"6px 18px 0px 18px"}}
                          // onClick={()=>{setshowfullmenu(!showfullmenu);setshowfullmenuname(value.name)}}
                            // href="javascript:void(0)"
                          >
                            {/* <img
                              src={value.image}
                              style={{ width: "25px" }}
                              alt={404}
                              className="hideonlaptop"
                            />{" "} */}
                            &nbsp;{" "}
                            <span className="largefont desgin1"> {value.category_name}</span>
                          </button>}

                          { setwdith == 0 ? "" : <ul className={setwdith == 0 ? "mega-menu full-mega-menu resultappear newwith" : "mega-menu full-mega-menu resultappear newwith2"} >
                            <div >
                              <div className="container p-0" style={{width:'500px'}} onClick={()=>{console.log("ckg if subcategiry like title mobile main div container div")}}>
                                <div className="row" onClick={()=>{console.log("ckg if subcategiry like title mobile main div row div")}}>
                                    {value.child.map((item, index) => (
                                      
                                        <div className={value.child.length < 4 ? value.child.length < 3 ? value.child.length < 1 ? "col-12 mega-box" : "col-4 mega-box" : "col-4 mega-box" : "col-4 mega-box" } key={index}>
                                    <div className="link-section" >
                                      <div className="menu-title">
                                        <h5 className='subhover' onClick={()=>{transfer2(item.id,item.name,value.category_name)}} style={{cursor:'pointer',textWrap:'nowrap'}} >{item.name} </h5>
                                      </div>
                                      {/* <Subsubcategorynew subvalue={item.children} /> */}
                                    </div>
                                  </div>
                                    ))}
                                  
                                </div>
                              </div>
                            </div>
                          </ul> }
 
</li>
  )
}

export default Subcategorynew