import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loginheader from "../components/Loginheader";
import { gettoken } from "../Localstorage/Store";

const options = {
  items: 1,
  loop: true,
  autoplay: true,
  nav: false,
  responsiveClass: true,
  dots: false,
  responsive: {
    1200: {
      items: 1,
      // stagePadding: 50,
    },
    920: {
      items: 1,
    },
    700: {
      items: 1,
    },
    600: {
      items: 1,
    },
    504: {
      items: 1,
    },
    300: {
      items: 1,
    },
    310: {
      items: 1,
    },
  },
};
const About = () => {
  const tokenvalue = gettoken();
  
    return(
        <>
<div>
 
  {/*header start*/}
  {tokenvalue ? <Header /> : <Loginheader />} 
  {}
  {/*header end*/}
  {/* breadcrumb start */}
  <div className={tokenvalue ? "breadcrumb-main marginfromtop" : "breadcrumb-main marginfromtop23"}>
  {/* <div className="breadcrumb-main marginfromtop"> */}
    <div className="container m-0">
      <div className="row">
        <div className="col">
          <div className="breadcrumb-contain">
            <div>
              <ul>
                <li><a href="/">home</a></li>
                <li>{`>`}</li>
                <li><a href="javascript:void(0)">about</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section className="section-big-pt-space pb-2" style={{backgroundColor:"#ececec"}}>
          <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4 mb-5">
            <div className="container-fuild emp-profile">
              <div className="row profile2">
               

                <section className="tab-product-main-tab">
                  <div className="row mt-2">
                       <h3 className="privacy">ABOUT US</h3>
                       <p className="policy"> 
<b>1 Welcome to One Up Brands: </b> 
<br />
At One Up Brands, we're passionate about bringing convenience and quality to your doorstep. With a diverse range of products spanning from body care essentials to electrical gadgets and fashionable wearables, we strive to be your one-stop destination for all your needs. Our mission is simple: to provide you with a seamless shopping experience and deliver products that enhance your lifestyle. <br /> <br />

<b>2 Our Story: </b> <br />

Founded with a vision to simplify the way people shop online, [Your Ecommerce Store] began its journey with a commitment to offering an extensive selection of products at competitive prices. We understand the challenges of modern-day living and aim to make shopping easier, faster, and more enjoyable for our customers. From the comfort of your home, you can explore our curated collection of top-quality items and find exactly what you're looking for. <br />
<br />


<b>3 Our Vision:</b>
<br />
Looking ahead, we envision One Up Brands becoming the go-to destination for shoppers worldwide. We're committed to expanding our product range, embracing new technologies, and staying ahead of the curve in an ever-evolving market. Our goal is to become synonymous with convenience, reliability, and innovation, and to continue serving our customers with excellence for years to come.
<br />
<br />
<b>4 Join Us on Our Journey:</b>
<br />
Thank you for choosing One Up Brands as your trusted shopping partner. We invite you to explore our diverse selection of products and discover the endless possibilities that await you. Whether you're in need of personal care essentials, cutting-edge gadgets, or stylish apparel, we've got you covered. Join us on our journey as we strive to make shopping simpler, smarter, and more satisfying for you.


<br />
 .</p>

                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
 
</div>




        </>
    )
}
export default About;