import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getshipping,
  gettoken,
  getvoucher,
  shippinfidstore,
  shippingidremove,
} from "../Localstorage/Store";
import { FaEdit } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
// import {  } from "react-icons/fa";
import {
  useDeleteCartMutation,
  useGetCartProductQuery,
} from "../store/api/cartapi";
import { getCode } from "country-list";
import { addItem } from "../store/state/cart";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Field, Form, Formik } from "formik";
import { checkoutvalidation } from "../Validation/Checkout";
import { checkoutvalidation2 } from "../Validation/Checkout2";
import { checkoutvalidation3 } from "../Validation/Checkout3";
import { Button } from "bootstrap";

const Cart = () => {
  const [inprocess, setinprocess] = useState(true);
  const [showtax, setshowtax] = useState(false);
  const [loading, setloading] = useState(false);
  const [shipaddress, setshipaddress] = useState(false);
  const [shippingaddressopen, setshippingaddressopen] = useState(false);
  const [qtymsg, setqtymsg] = useState("");
  const [vouchervalue, setvouchervalue] = useState("");
  const [creditvalue, setcreditvalue] = useState("");
  const [voucherdesc, setvoucherdesc] = useState([]);
  const [
    total_shipping_charge_basketview,
    settotal_shipping_charge_basketview,
  ] = useState(0);
  const [offerSum, setOfferSum] = useState(0);
  // console.log("totll voucher value", offerSum);
  const [totalitemvalue, settotalitemvalue] = useState(0);
  const [discountval, setdiscountval] = useState(0);
  const [delitem, setdelitem] = useState(0);
  const [totalmrp, settotalmrp] = useState(0);
  const [shipping_price, setshipping_price] = useState(0);
  const [totalcredit, settotalcredit] = useState(0);
  const [offeramount_total, setofferamount_total] = useState(0);
  const [offeramount_totalwithqty, setofferamount_totalwithqty] = useState(0);
  const [qty_total, setqty_total] = useState(0);
  const [taxable_total, settaxable_total] = useState(0);
  const [gst_total, setgst_total] = useState(0);
  const [totalvocuerprice, settotalvocuerprice] = useState(0);
  const [data, setData] = useState([]);

  const [voucherlist, setvoucherlist] = useState([]);
  const [voucherlist2, setvoucherlist2] = useState([]);
  const [voucherlist2filter, setvoucherlist2filter] = useState([]);
  const [cartinfo, setcartinfo] = useState({});
  const [shippingerror, setshippingerror] = useState(null);
  const [firstname_super, setfirstname_super] = useState("");
  const [lastname_super, setlastname_super] = useState("");
  const [phonenumber_super, setphonenumber_super] = useState("");
  const [emailid_super, setemailid_super] = useState("");
  const [address_type, setaddress_type] = useState("");
  const [address1_super, setaddress1_super] = useState("");
  const [address2_super, setaddress2_super] = useState("");
  const [country2_super, setcountry2_super] = useState("");
  const [country2url_super, setcountry2url_super] = useState("");
  const [state2_super, setstate2_super] = useState("");
  const [city2_super, setcity2_super] = useState("");
  const [pincode2_super, setpincode2_super] = useState("");
  const tokenvalue = gettoken();
  const shippingid = getshipping();
  // console.log("this is shipppi g  id is here ",shippingid)
  const localvouhcer = getvoucher();
  const nvg = useNavigate();
  const location = useLocation();
  const gobalvariable = useSelector((state) => state);
  const dispatch = useDispatch();
  const [countriesdata, setcountriesdata] = useState([]);
  const [cityna, setcityna] = useState("");
  const [addressstatus, setaddressstatus] = useState(false);
  const openalert = useRef(null);
  const closeref = useRef(null);
  const editformopen = useRef(null);
  const editformopen2 = useRef(null);
  const closeaddressbtn = useRef(null);
  const editfromclose = useRef(null);


  const funvoucherdesc = (item) =>{
    const validJsonString = item.replace(/'/g, '"');
    console.log("Dddddddssss",JSON.parse(validJsonString))
    setvoucherdesc(JSON.parse(validJsonString))
  }
  const alertb = () => {
    openalert.current.click();
    setTimeout(() => {
      setqtymsg("");
      closeref.current.click();
    }, 3000);
  };
  const [shiploading, setshiploading] = useState(false);
  const shippingcharges = async (pin) => {
    try {
      setshiploading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      const formdata = new FormData();
      formdata.append("pin_code", pin);
      let url = `${process.env.REACT_APP_API_URL}ship/get_price/`;
      const response = await axios.post(url, formdata, config);
      // console.log("reosponse of shipping charges",response)

      setshipping_price(response.data.total_rate);
      setshippingerror(null);
      setshiploading(false);
    } catch (error) {
      // console.log("error resp of charge api", error.response.data);
      setshippingerror(
        "Oops! We're unable to calculate shipping costs for your order at the moment."
      );
      setshipping_price(0);
      setshiploading(false);
    }
  };

  const calculateOfferSum = (data) => {
    const sum = data.reduce((acc, item) => acc + item.offer, 0);
    setOfferSum(sum);
  };

  const setshippadd = (id) => {
    shippinfidstore(id);
    const selectadd = addressdata.filter((item) => item.id == id);
    // console.log("select address is and full data",selectadd,"dkdkdkdkd",addressdata,"dkdkdkdkdkdkdkd",address)
    shippingcharges(selectadd[0].postcode);
    handleEdit5();
  };

  const togglefun = () => {
    if (shipaddress == true) {
      shippingcharges(pincode2_super);
      setshipaddress(!shipaddress);

      handleEdit5();
    } else {
      if (shippingid) {
        // console.log("yes it has");
        shippingidremove();
        // handleEdit5()
        setTimeout(() => {
          setshipaddress(!shipaddress);
          // handleEdit5()
        }, 300);
      } else {
        // console.log("no it has")
        setshipaddress(!shipaddress);
      }
    }
  };
  const fetchstateandcity = async (value, setFieldError, setFieldValue) => {
    if (value.length == 6) {
      try {
        let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
        const response = await axios.get(url);
        // setFieldValue("city", response.data.city);
        if (response.data.city == "NA") {
          setFieldValue("city", "");
          setcityna("NA");
          setFieldError("city", "Please Enter Your City");
        } else {
          setFieldValue("city", response.data.city);
          setcityna("");
        }
        setFieldValue("state", response.data.state);
        const countrydata = countriesdata.filter(
          (item2) => item2.printable_name == response.data.country.trim()
        );
        // console.log(";;;;;;", countrydata);
        setFieldValue("country", countrydata[0].printable_name);
        setFieldValue("countryurl", countrydata[0].url);
      } catch (error) {
        setFieldError("pincode", error.response.data.message);
      }
    } else {
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("country", "");
      setFieldValue("countryurl", "");
    }
  };
  const fetchstateandcity2 = async (value, setFieldError, setFieldValue) => {
    if (value.length == 6) {
      try {
        let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
        const response = await axios.get(url);
        // setFieldValue("city", response.data.city);
        if (response.data.city == "NA") {
          setFieldValue("city2", "");
          setcityna("NA");
          setFieldError("city2", "Please Enter Your City");
        } else {
          setFieldValue("city2", response.data.city);
          setcityna("");
        }
        setFieldValue("state2", response.data.state);
        const countrydata = countriesdata.filter(
          (item2) => item2.printable_name == response.data.country.trim()
        );
        // console.log(";;;;;;", countrydata);
        setFieldValue("country2", countrydata[0].printable_name);
        setFieldValue("countryurl2", countrydata[0].url);
      } catch (error) {
        setFieldError("pincode2", error.response.data.message);
      }
    } else {
      setFieldValue("city2", "");
      setFieldValue("state2", "");
      setFieldValue("country2", "");
      setFieldValue("countryurl2", "");
    }
  };
  const fetchstateandcity4 = async (value, setFieldError, setFieldValue) => {
    if (value.length == 6) {
      try {
        let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
        const response = await axios.get(url);
        // setFieldValue("city", response.data.city);
        if (response.data.city == "NA") {
          setFieldValue("city3", "");
          setcityna("NA");
          setFieldError("city3", "Please Enter Your City");
        } else {
          setFieldValue("city3", response.data.city);
          setcityna("");
        }
        setFieldValue("state3", response.data.state);
        const countrydata = countriesdata.filter(
          (item2) => item2.printable_name == response.data.country.trim()
        );
        // console.log(";;;;;;", countrydata);
        setFieldValue("country3", countrydata[0].printable_name);
        setFieldValue("countryurl3", countrydata[0].url);
      } catch (error) {
        setFieldError("pincode3", error.response.data.message);
      }
    } else {
      setFieldValue("city2", "");
      setFieldValue("state2", "");
      setFieldValue("country2", "");
      setFieldValue("countryurl2", "");
    }
  };

  const removevoucher = async (codename) => {
    try {
      const formdata = new FormData();
      formdata.append("vouchercode", codename);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}cartapi/remove-voucher/`;
      const response = await axios.post(url, formdata, config);
      cartrefetch();
    } catch (error) {
      // console.log("errordddddddd", error.response.data);
    }
  };

  const buttonRef = useRef(null);

  const seteditformvalue = (setFieldValue) => {
    setFieldValue("first_name2", firstname_super);
    setFieldValue("last_name2", lastname_super);
    setFieldValue("mobile2", phonenumber_super.substring(3));
    setFieldValue("address12", address1_super);
    setFieldValue("address_type", address_type);
    setFieldValue("address22", address2_super);
    // setcurrenteditaddressid(data.id)
    setFieldValue("city2", city2_super);
    setFieldValue("email2", emailid_super);
    setFieldValue("state2", state2_super);
    setFieldValue("pincode2", pincode2_super);
    // console.log("this isi country issue",countriesdata,"this again country",country2_super)
    const countrydata = countriesdata.filter(
      (item2) => item2.printable_name == country2_super
    );
    setFieldValue("country2", countrydata[0].printable_name);
    setFieldValue("countryurl2", countrydata[0].url);
    setTimeout(() => {
      handleEdit2();
    }, 600);
    // console.log("this issss error",pincode2_super,city2_super,state2_super,)
  };

  const handleClick = () => {
    const buttonNode = buttonRef.current;
    // console.log("this is add utt",)
    if (buttonNode) {
      buttonNode.click();
    }
  };
  const handleEdit = () => {
    const buttonNode = editformopen.current;
    // console.log("this is add utt 0000",)
    // seteditformvalue()
    if (buttonNode) {
      buttonNode.click();
    }
  };
  const handleEdit2 = () => {
    const buttonNode = editformopen2.current;
    // console.log("this is add utt 0000",)
    // seteditformvalue()
    if (buttonNode) {
      buttonNode.click();
    }
  };
  const handleEdit3 = () => {
    const buttonNode = closeaddressbtn.current;
    if (buttonNode) {
      buttonNode.click();
    }
  };
  const handleEdit5 = () => {
    const buttonNode = editfromclose.current;
    if (buttonNode) {
      buttonNode.click();
    }
  };

  const editddress = async (values, setFieldError) => {
    try {
      // console.log("this iss ssssss",values)
      const formdata = new FormData();
      formdata.append("first_name", values.first_name2);
      formdata.append("address_type", values.address_type);
      formdata.append("last_name", values.last_name2);
      formdata.append("phone_number", `+91${values.mobile2}`);
      formdata.append("line1", values.address12);
      formdata.append("line2", values.address22);
      formdata.append("country", getCode(values.country2));
      formdata.append("state", values.state2);
      formdata.append("email", values.email2);
      formdata.append("line4", values.city2);
      formdata.append("postcode", values.pincode2);
      if (tokenvalue.msg == "Voucher login") {
        formdata.append("user", tokenvalue.user_id);
      } else {
        formdata.append("user", tokenvalue.id);
      }
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };

      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/${slectaddressid}/update/`;
      const response = await axios.put(url, formdata, config);
      // console.log("show me response of response id",response);
      if (response.data?.id) {
        //  console.log("reespnse ravi",response.data)
        //  getaddress("new")
        getaddress(slectaddressid);
        shippingcharges(values.pincode2);
        setslectaddressid(response.data.id);

        handleEdit5();

        //  sethideorshowform(true)
        //  setnewaddress('add',response.data)
      } else {
      }
    } catch (error) {
      //  console.log("firstnnnnnnnnn,,,",error.response)
      setFieldError("mobile2", error.response.data.phone_number[0]);
    }
  };

  const createaddress2 = async (values, setFieldError) => {
    console.log("this is shipping address");
  };

  const createaddress = async (values, setFieldError, shipping = "0") => {
    // console.log("check this createaddress api ")
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      const formdata = new FormData();
      formdata.append("first_name", values.first_name);
      formdata.append("last_name", values.last_name);
      formdata.append("phone_number", `+91${values.mobile}`);
      formdata.append("line1", values.address1);
      formdata.append("line2", values.address2);
      formdata.append("country", getCode(values.country));
      formdata.append("state", values.state);
      formdata.append("email", values.email);
      formdata.append("line4", values.city);
      formdata.append("address_type", values.address_type);
      formdata.append("postcode", values.pincode);
      if (tokenvalue.msg == "Voucher login") {
        formdata.append("user", tokenvalue.user_id);
      } else {
        formdata.append("user", tokenvalue.id);
      }
      // setloading(true)
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
      const response = await axios.post(url, formdata, config);
      if (response.data?.id) {
        // console.log("reespnse ravi vffdddrredd",response.data)
        if (shipping == "0") {
          getaddress(response.data.id);
          setaddressstatus(true);
          shippingcharges(response.data.postcode);
          // setslectaddressid()
          handleEdit3();
          setTimeout(() => {
            setaddressstatus(false);
            handleClick();
          }, 3000);
        } else {
          getaddress();
          shippingcharges(response.data.postcode);
          shippinfidstore(response.data.id);
          //  shippinfidstore(response.data.id)
          // setshipaddress(false)
          setshippingaddressopen(false);
          handleEdit5();
        }
      } else {
      }
    } catch (error) {
      setFieldError("mobile", error.response.data.phone_number[0]);
    }
  };

  // console.log("local value of voucher",localvouhcer)
  const [vouchervalid, setvouchervalid] = useState(false);
  const vouchercheck = async (currentdata) => {
    if (tokenvalue.msg == "Voucher login") {
      // console.log("current data og cart", currentdata);
      let checkvoucher = currentdata.filter(
        (item) => item.code == localvouhcer
      );
      // console.log("dddddjdjdjdjdkcmccmcmmckdkdkd", checkvoucher, localvouhcer);
      if (checkvoucher[0] && checkvoucher[0].code) {
        if (checkvoucher[0].offer > 0) {
          setvouchervalid(true);
        } else {
          setvouchervalid(false);
          // removevoucher(localvouhcer)
        }
      } else {
        try {
          const formdata = new FormData();
          formdata.append("vouchercode", localvouhcer);
          const config = {
            headers: {
              Authorization: `Bearer ${tokenvalue.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}cartapi/assign-voucher/`;
          const response = await axios.post(url, formdata, config);
          if (!response.data.code) {
            setvouchererror(response.data);
          } else {
            setvouchererror("");
          }
          setvouchervalue("");
          // fetchcategory()
          cartrefetch();
        } catch (error) {
          // console.log("errordddddddd", error.response.data);
          if (error.response.data.non_field_errors) {
            setvouchererror(error.response.data.non_field_errors[0]);
          }
          if (error.response.data) {
            setvouchererror(error.response.data);
          }
        }
      }
    } else {
      setvouchervalid(true);
    }
  };

  const [slectaddressid, setslectaddressid] = useState(0);
  // console.log("this is address is op",slectaddressid)
  const selectaddress = async (address) => {
    if (shipaddress == false) {
      const selectadd = addressdata.filter((item) => item.id == address);
      setslectaddressid(address);
      // console.log("select address is and full data",selectadd,"dkdkdkdkd",addressdata,"dkdkdkdkdkdkdkd",address)
      shippingcharges(selectadd[0].postcode);
    } else {
      setslectaddressid(address);
    }
  };
  const editselectaddress = async () => {
    const selectadd = addressdata.filter((item) => item.id == slectaddressid);
    setfirstname_super(selectadd[0].first_name);
    setlastname_super(selectadd[0].last_name);
    setphonenumber_super(selectadd[0].phone_number);
    setemailid_super(selectadd[0].email);
    setaddress1_super(selectadd[0].line1);
    setaddress_type(selectadd[0].address_type);
    setaddress2_super(selectadd[0].line2);
    setcountry2_super(selectadd[0].country_printable_name);
    // setcountry2url_super(selectadd[0].)
    setstate2_super(selectadd[0].state);
    setcity2_super(selectadd[0].line4);
    setpincode2_super(selectadd[0].postcode);
    setTimeout(() => {
      handleEdit();
    }, 600);
    // console.log("eidt",selectadd,"dkdkdkdkd",addressdata,"dkdkdkdkdkdkdkd")
  };

  const finalpayfun = (billing, ship, shipadd = null) => {
    const modifiedUrl = cartinfo.lines.replace("/lines/", "/");
    const billingcountry = countriesdata.filter(
      (item2) => item2.printable_name == billing.country_printable_name.trim()
    );
    const billingcountry2 = countriesdata.filter(
      (item2) => item2.printable_name == shipadd?.country_printable_name.trim()
    );

    const data =
      tokenvalue.msg == "Voucher login"
        ? {
            basket: modifiedUrl,
            guest_email: billing.email,
            payment_types: "COD",
            shipping_method_code: "no-shipping-required",
            code: localvouhcer,
            shipping_address: {
              country:
                shipadd == null
                  ? billingcountry[0].url
                  : billingcountry2[0].url,
              first_name:
                shipadd == null ? billing.first_name : shipadd.first_name,
              last_name:
                shipadd == null ? billing.last_name : shipadd.last_name,
              line1: shipadd == null ? billing.line1 : shipadd.line1,
              line2: shipadd == null ? billing.line2 : shipadd.line2,
              line3: "",
              line4: shipadd == null ? billing.line4 : shipadd.line4,
              notes: "",
              phone_number:
                shipadd == null ? billing.phone_number : shipadd.phone_number,
              postcode: shipadd == null ? billing.postcode : shipadd.postcode,
              state: shipadd == null ? billing.state : shipadd.state,
              email: shipadd == null ? billing.email : shipadd.email,
            },
            billing_address: {
              country: billingcountry[0].url,
              first_name: billing.first_name,
              last_name: billing.last_name,
              line1: billing.line1,
              line2: billing.line2,
              line3: "",
              line4: billing.line4,
              notes: "",
              phone_number: billing.phone_number,
              postcode: billing.postcode,
              state: billing.state,
              email: billing.email,
            },
          }
        : {
            basket: modifiedUrl,
            guest_email: billing.email,
            payment_types: "COD",
            shipping_method_code: "no-shipping-required",
            shipping_address: {
              country:
                shipadd == null
                  ? billingcountry[0].url
                  : billingcountry2[0].url,
              first_name:
                shipadd == null ? billing.first_name : shipadd.first_name,
              last_name:
                shipadd == null ? billing.last_name : shipadd.last_name,
              line1: shipadd == null ? billing.line1 : shipadd.line1,
              line2: shipadd == null ? billing.line2 : shipadd.line2,
              line3: "",
              line4: shipadd == null ? billing.line4 : shipadd.line4,
              notes: "",
              phone_number:
                shipadd == null ? billing.phone_number : shipadd.phone_number,
              postcode: shipadd == null ? billing.postcode : shipadd.postcode,
              state: shipadd == null ? billing.state : shipadd.state,
              email: shipadd == null ? billing.email : shipadd.email,
            },
            billing_address: {
              country: billingcountry[0].url,
              first_name: billing.first_name,
              last_name: billing.last_name,
              line1: billing.line1,
              line2: billing.line2,
              line3: "",
              line4: billing.line4,
              notes: "",
              phone_number: billing.phone_number,
              postcode: billing.postcode,
              state: billing.state,
              email: billing.email,
            },
          };

    const realprice = voucherlist2[0]
      ? voucherlist2[0]?.shipping_incl == true &&
        (totalitemvalue == 0
          ? 0
          : total_shipping_charge_basketview == null
          ? shipping_price > 0
            ? shipping_price
            : shipping_price
          : total_shipping_charge_basketview > 0
          ? total_shipping_charge_basketview
          : total_shipping_charge_basketview) +
          cartinfo.basic_amount_radeemable +
          (voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" ||
          voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive"
            ? 0
            : 0) >
          offerSum
        ? (totalitemvalue == 0
            ? 0
            : total_shipping_charge_basketview == null
            ? shipping_price > 0
              ? shipping_price
              : shipping_price
            : total_shipping_charge_basketview > 0
            ? total_shipping_charge_basketview
            : total_shipping_charge_basketview) +
          cartinfo.basic_amount_radeemable +
          (voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" ||
          voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive"
            ? 0
            : 0) -
          offerSum + (voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" ||
            voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive"
              ? cartinfo.total_tax : 0)
        : cartinfo.balance_payable
        ? Number(
            parseFloat(cartinfo.balance_payable) +
              (voucherlist2[0]?.shipping_incl
                ? 0
                : total_shipping_charge_basketview == null
                ? parseFloat(shipping_price)
                : parseFloat(total_shipping_charge_basketview))
          ).toFixed(0)
        : voucherlist2[0]?.shipping_incl
        ? 0
        : total_shipping_charge_basketview == null
        ? parseFloat(shipping_price).toFixed(0)
        : parseFloat(total_shipping_charge_basketview).toFixed(0)
      : cartinfo.balance_payable
      ? Number(
          parseFloat(cartinfo.balance_payable) +
            (voucherlist2[0]?.shipping_incl
              ? 0
              : total_shipping_charge_basketview == null
              ? parseFloat(shipping_price)
              : parseFloat(total_shipping_charge_basketview))
        ).toFixed(0)
      : voucherlist2[0]?.shipping_incl
      ? 0
      : total_shipping_charge_basketview == null
      ? parseFloat(shipping_price).toFixed(0)
      : parseFloat(total_shipping_charge_basketview).toFixed(0);

    nvg("/pay", {
      state: {
        checkoutdata: data,
        subtotal: offeramount_totalwithqty,
        total: voucherlist2[0]?.voucher_type ==
        "Gift Voucher - Exclusive3" ? (totalitemvalue == 0
          ? 0
          : total_shipping_charge_basketview == null
          ? shipping_price > 0
            ? shipping_price
            : shipping_price
          : total_shipping_charge_basketview > 0
          ? total_shipping_charge_basketview
          : total_shipping_charge_basketview) +
          (voucherlist2[0]
            ? voucherlist2[0]?.voucher_type ==
                "Gift Voucher - Inclusive" ||
              voucherlist2[0]?.voucher_type ==
                "Shopping Voucher - Inclusive"
              ? 0
              : cartinfo.total_tax
            : 0) +
          (voucherlist2[0]
            ? voucherlist2[0]?.voucher_type ==
                "Gift Voucher - Inclusive" ||
              voucherlist2[0]?.voucher_type ==
                "Shopping Voucher - Inclusive"
              ? totalitemvalue == 0
                ? 0
                : parseInt(totalmrp)
              : cartinfo.basic_amount_radeemable
            : totalitemvalue == 0
            ? 0
            : parseInt(totalmrp)) : parseInt(realprice),
        // total:Number(parseFloat(cartinfo.balance_payable + (voucherlist2[0]?.shipping_incl ? 0 : ship))).toFixed(0),
        shipping_price: ship,
        shippingstatus: voucherlist2[0]?.shipping_incl == true ? true : false,
      },
    });
    console.log(
      "full order details",
      data,
      "billing add",
      billing,
      "ship amout",
      ship,
      "shiping address",
      shipadd,
      realprice
    );
  };
  const gopaypage = () => {
    const shippingamount =
      totalitemvalue == 0
        ? 0
        : total_shipping_charge_basketview == null
        ? shipping_price > 0
          ? shipping_price
          : shipping_price
        : total_shipping_charge_basketview > 0
        ? total_shipping_charge_basketview
        : total_shipping_charge_basketview;
    const billingpay = addressdata.filter((item) => item.id == slectaddressid);
    if (shipaddress) {
      if (shippingid) {
        const shippingpay = addressdata.filter((item) => item.id == shippingid);
        console.log(
          "ttyyyuuii",
          shippingpay,
          "ggggggg",
          addressdata,
          "hhhhhhhhhhhhhhh",
          billingpay
        );
        finalpayfun(billingpay[0], shippingamount, shippingpay[0]);
      } else {
        console.log(
          "ttyyyuuii",
          "ggggggg",
          addressdata,
          "hhhhhhhhhhhhhhh",
          billingpay
        );

        finalpayfun(billingpay[0], shippingamount);
      }
      // console.log("this is shipping id",shippingid)
    } else {
      console.log(
        "ttyyyuuii",
        "ggggggg",
        addressdata,
        "hhhhhhhhhhhhhhh",
        billingpay
      );
      finalpayfun(billingpay[0], shippingamount);
      // console.log("this is shipping no id",shippingid)
    }

    // console.log("kdkdkdkddkdkmcmcmcmcmcclcx.. this is selected id",slectaddressid,"shipping charges is here",shipping,"full data is here",data)
  };

  const [vouchererror, setvouchererror] = useState("");
  const applyvoucher = async (vouchercode) => {
    if (!vouchercode || vouchercode === "") return;
    try {
      const formdata = new FormData();
      formdata.append("vouchercode", vouchercode);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}cartapi/assign-voucher/`;
      const response = await axios.post(url, formdata, config);
      if (!response.data.code) {
        setvouchererror(response.data);
      } else {
        setvouchererror("");
      }
      setvouchervalue("");
      // fetchcategory()
      cartrefetch();
    } catch (error) {
      // console.log("errordddddddd", error.response.data);
      if (error.response.data.non_field_errors) {
        setvouchererror(error.response.data.non_field_errors[0]);
      }
      if (error.response.data) {
        setvouchererror(error.response.data);
      }
    }
  };
  const applycredit = async (crditcode) => {
    if (!crditcode || crditcode === "") return;
    try {
      const formdata = new FormData();
      formdata.append("amount", crditcode);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}credit/remove-amount/`;
      const response = await axios.post(url, formdata, config);
      // if(!response.data.code){
      //   setvouchererror(response.data)
      // }else{
      //   setvouchererror('')
      // }
      // setvouchervalue('')
      // console.log("this is voucher response", response);
      // fetchcategory()
      cartrefetch();
    } catch (error) {
      // console.log("errordddddddd", error.response.data);
      if (error.response.data.non_field_errors) {
        // setvouchererror(error.response.data.non_field_errors[0])
      }
      if (error.response.data) {
        // setvouchererror(error.response.data)
      }
    }
  };

  const incrementcart = async (qty, id) => {
    // const formdata = new FormData();
    // formdata.append("quantity", qty + 1);
    // formdata.append("product_id", id);
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${tokenvalue.access}`,
    //   },
    // };
    // setloading(false);
    // let url = `${process.env.REACT_APP_API_URL}homepageapi/UpdateQuantityOfProduct`;
    // const response = await axios.post(url, formdata, config);
    // cartrefetch()
    // handleBlur(qty + 1,id,qty)
    prevalue = await cartqty(qty + 1, id);
    if (prevalue == 12000) {
      console.log("cart qty check test", prevalue);
      cartqty(qty, id);
      // e.target.value = oldvalue;
      // cartrefetch()
      // console.log("first attempte is 1.1",prevalue);
      cartrefetch();
      alertb();
      setinprocess(true);
    }
  };
  const decrementcart = async (qty, id) => {
    if (qty > 1) {
      const formdata = new FormData();
      formdata.append("quantity", qty - 1);
      formdata.append("product_id", id);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      setloading(false);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/UpdateQuantityOfProduct`;
      const response = await axios.post(url, formdata, config);
      cartrefetch();
    }
  };
  let prevalue = 0;
  const enterfun = (e, product, quantity) => {
    if (e.key === "Enter") {
      handleBlur(e, product, quantity);
    }
  };
  const handleBlur = async (e, item, oldvalue) => {
    if (e.target.value === "" || parseInt(e.target.value) == 0) {
      e.target.value = oldvalue;
      prevalue = await cartqty(oldvalue, item);
      console.log("2 2 try for qty check", prevalue);
      cartrefetch();
      setinprocess(true);
    } else {
      prevalue = await cartqty(e.target.value, item);
      if (prevalue == 12000) {
        console.log(
          "2 try for qty check",
          prevalue,
          oldvalue,
          "ccccc",
          e.target.value
        );
        cartqty(oldvalue, item);
        // e.target.value = oldvalue;
        // cartrefetch()
        // console.log("first attempte is 1.1",prevalue);
        cartrefetch();
        alertb();
        console.log(
          "2 try for qty check again",
          prevalue,
          oldvalue,
          "ccccc",
          e.target.value
        );
        setinprocess(true);
      } else {
        //   console.log("first attempte is 2",prevalue,);
        // console.log("first attempte is 2.1",prevalue);

        prevalue = await cartqty(e.target.value, item);
        e.target.value = prevalue;
        cartrefetch();
        setinprocess(true);
      }
    }
    // let newQuantity;
    // if (e.target.value === '' || parseInt(e.target.value) === 0) {
    //   newQuantity = 1;
    // } else {
    //   newQuantity = await cartqty(parseInt(e.target.value), item.product);

    // }
  };
  const cartqty = async (qty, id) => {
    try {
      if (qty > 0) {
        const formdata = new FormData();
        formdata.append("quantity", qty);
        formdata.append("product_id", id);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
        setloading(false);
        let url = `${process.env.REACT_APP_API_URL}homepageapi/UpdateQuantityOfProduct`;
        const response = await axios.post(url, formdata, config);
        // console.log("reeer  djdjdjjd latest",response)
        if (response.data.reason) {
          console.log("this is error massage of cart qty api", response);
          setqtymsg(response.data.reason);
          return 12000;
          // console.log("reeer  djdjdjjd latest  nnnnnnnnnnnnnnnnnnnnnnnnn        repaassessd",response)
        }
        cartrefetch();
        return qty;
        // cartrefetch()
      } else {
        cartrefetch();
        // return 3;
      }
    } catch (error) {
      console.log("ddddddddd", error.response);
    }
  };

  const [deletecart] = useDeleteCartMutation();
  const Removeproduct = async (id) => {
    try {
      const cartid = cartinfo.id;
      const response = await deletecart({ id, cartid });
      if (response.data.obj == "data deleted") {
        dispatch(addItem(gobalvariable.cart - 1));
        cartrefetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [addressdata, setaddressdata] = useState([]);
  // console.log("this is address list", addressdata);
  // const shippingcharges = async (qty, id) => {
  //   if (qty > 1) {
  //     const formdata = new FormData();
  //     formdata.append("quantity", qty - 1);
  //     formdata.append("product_id", id);
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${tokenvalue.access}`,
  //       },
  //     };
  //     setloading(false);
  //     let url = `${process.env.REACT_APP_API_URL}homepageapi/UpdateQuantityOfProduct`;
  //     const response = await axios.post(url, formdata, config);
  //     cartrefetch()
  //   }
  // };

  const {
    data: cartdata,
    isLoading: cartloading,
    refetch: cartrefetch,
  } = useGetCartProductQuery();
  async function getaddress(slectaddressid = 0) {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        "Content-Type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/list`;
    try {
      const response = await axios.get(url, config);
      setaddressdata(response.data);
      if (response.data) {
        if (slectaddressid == 0) {
          shippingcharges(response.data[0].postcode);
          setslectaddressid(response.data[0].id);
        } else {
          setslectaddressid(slectaddressid);
        }
        // setaddressdataindex(response.data[0]);
        // setaddressdataindex(response.data[response.data.length - 1])
      }
    } catch (error) {
      // setloading(false)
      // console.log(error)
    }
  }

  async function getcountries() {
    let url = `${process.env.REACT_APP_API_URL}api/countries/`;
    try {
      const response = await axios.get(url);

      setcountriesdata(response.data);
    } catch (error) {}
  }

  const [outofstock, setOutOfStock] = useState(false);
  const [isshoppingvoucher, setisshoppingvoucher] = useState([]);
  // console.log("sjsjsj shopping voucer",isshoppingvoucher);
  useEffect(() => {
    if (cartdata) {
      // shippingcharges('')
      getcountries();
      getaddress();
      settotal_shipping_charge_basketview(cartdata.total_shiping_charge);
      const updatedProducts = cartdata.product_deatils.map((product) => ({
        ...product,
        calculatedPercentOfMRP:
          product.discount_type == "percentage"
            ? (product.discount / 100) * product.mrp * product.quantity
            : product.discount * product.quantity,
      }));
      const totalPrice = updatedProducts.reduce(
        (acc, product) => acc + product.calculatedPercentOfMRP,
        0
      );
      const totalmrp = updatedProducts.reduce(
        // (acc, product) => acc + product.mrp * product.quantity,
        (acc, product) => acc + product.price_excl_tax * product.quantity,
        0
      );
      const totalitem = updatedProducts.reduce(
        (acc, product) => acc + 1 * product.quantity,
        0
      );

      // console.log("updateing product is here",updatedProducts);
      const isAnyOutOfStock = updatedProducts.some((item) => item.stock == 0);
      // console.log("stock status is here",isAnyOutOfStock)
      setOutOfStock(isAnyOutOfStock);
      settotalitemvalue(totalitem);
      setdiscountval(totalPrice);
      settotalmrp(totalmrp);
      setvoucherlist(cartdata.voucher_list);
      calculateOfferSum(cartdata.voucher_list);
      const totalvouceramount = cartdata.voucher_list.reduce(
        (acc, product) => acc + 1 * product.offer,
        0
      );
      // console.log(
      //   "is prpice voucer",
      //   cartdata.voucher_list,
      //   "ddjccncnc mcmcmcmccmcmcmccmccmcmcmcmcm",
      //   totalvouceramount
      // );
      settotalvocuerprice(totalvouceramount);
      setvoucherlist2(cartdata.voucher_list);
      const updatedData = cartdata.voucher_list.filter(
        (item) => item.code !== localvouhcer
      );
      setvoucherlist2filter(updatedData);
      let shoppingvoucher = cartdata.voucher_list.filter(
        (item) =>
          item.voucher_type == "Shopping Voucher - Exclusive" ||
          item.voucher_type == "Gift Voucher - Exclusive"
      );
      setisshoppingvoucher(shoppingvoucher);
      const offerpricewithqty = updatedProducts.reduce(
        (total, item) => total + item.price_excl_tax * item.quantity,
        0
      );
      const gsttotal = updatedProducts.reduce(
        (total, item) => total + item.gst_tax_amount * item.quantity,
        0
      );
      const taxabletotal = updatedProducts.reduce(
        (total, item) => total + item.base_price * item.quantity,
        0
      );
      const qtytotal = updatedProducts.reduce(
        (total, item) => total + item.quantity,
        0
      );
      // const setofferamount_totalwithqty = updatedProducts.reduce((total, item) => total + item.price_incl_tax, 0);
      setgst_total(gsttotal);
      settaxable_total(taxabletotal);
      setqty_total(qtytotal);
      setofferamount_totalwithqty(offerpricewithqty);
      setData(updatedProducts);
      setcartinfo(cartdata);
      // console.log("this is cart data here ddd", cartdata);
      if (totalitem > 0) {
        vouchercheck(cartdata.voucher_list);
      }
      setloading(false);
    }
    const fetchcredit = async (pin) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
            "Content-Type": "application/json",
          },
        };
        let url = `${process.env.REACT_APP_API_URL}credit/total_amount/`;
        const response = await axios.get(url, config);
        // console.log("this is credit reponse", response);
        settotalcredit(response.data.amount);
      } catch (error) {
        console.log("error resp of credit api", error.response.data);
      }
    };

    if (tokenvalue.msg !== "Voucher login") {
      fetchcredit();
    }
  }, [cartdata]);
  // console.log("this is si real slaatffff",slectaddressid)








  let remainamount = ((totalitemvalue == 0
    ? 0
    : total_shipping_charge_basketview == null
    ? shipping_price > 0
      ? shipping_price
      : shipping_price
    : total_shipping_charge_basketview > 0
    ? total_shipping_charge_basketview
    : total_shipping_charge_basketview) +
  cartinfo.basic_amount_radeemable +
  (voucherlist2[0]?.voucher_type ==
    "Shopping Voucher - Exclusive" ||
  voucherlist2[0]?.voucher_type ==
    "Gift Voucher - Exclusive"
    ? 0
    : cartinfo.total_tax) -
  offerSum);

  return (
    <>
      <Header />

      {shiploading == true ? (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <h2
              style={{
                textAlign: "center",
                paddingTop: "15px",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              <Loader />
            </h2>
          </div>
        </>
      ) : cartloading == true ? (
        <></>
      ) : (
        <>
          {/* breadcrumb start */}
          <div className="breadcrumb-main margincart">
            <div className="container m-0">
              <div className="row">
                <div className="col">
                  <div className="breadcrumb-contain">
                    <div>
                      {/* <ul>
                <li><a href="javascript:void(0)">home</a></li>
                <li>></li>
                <li><a href="javascript:void(0)">checkout</a></li>
              </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* breadcrumb End */}

          {/*section start*/}
          <section className="section-big-py-space b-g-light">
            <div className="container-fluid d-flex justify-content-center">
              <div className="row justify-content-center definewidth align-items-start">
                <div className="col-lg-8" style={{ paddingBottom: "4px" }}>
                  {/* <div className="table-responsive" style={{ borderRadius: "6px" }}>
                <h3 style={{ padding: '5px 9px', fontWeight: 700, fontSize: "18px" }}>Shopping Cart</h3> */}

                  <div className=" hayeveryone" style={{ display: "none" }}>
                    <div
                      // class="row details  cartdesgintwo"
                      class="row details"
                      style={{ display: "none" }}
                    >
                      {loading == true
                        ? ""
                        : data[0]?.product__title
                        ? data.map((item, index) => (
                            <div
                              class="col-lg-5 py-2"
                              style={{ width: "470px" }}
                            >
                              <div class="card">
                                <div
                                  class="card-body"
                                  style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{ padding: "10px" }}
                                    onClick={() => {
                                      nvg("/productdetails", {
                                        state: {
                                          id: item.product,
                                          pname: item.product__title,
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}media/${item.image[0].original}`}
                                      width="120px"
                                      style={{ cursor: "pointer" }}
                                      Height="170px"
                                      alt="cart"
                                      className=" "
                                    />
                                  </div>
                                  <div style={{ paddingTop: "40px" }}>
                                    <h6 className="">
                                      <span
                                        onClick={() => {
                                          nvg("/productdetails", {
                                            state: {
                                              id: item.product,
                                              pname: item.product__title,
                                            },
                                          });
                                        }}
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                          fontWeight: "600",
                                          cursor: "pointer",
                                          padding: "0px 3px",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.product__title}
                                      </span>
                                    </h6>
                                    <h6
                                      className="td-color"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "18px",
                                        fontSize: "14px",
                                        paddingTop: "7px",
                                      }}
                                    >
                                      &nbsp;{`₹${item.price_excl_tax}`}
                                      <span
                                        style={{
                                          color: "#c1c2c5",
                                          textDecoration: "line-through",
                                          fontSize: "11px",
                                        }}
                                      >{` ₹${item.mrp}`}</span>
                                    </h6>
                                    <div
                                      className="qty-box"
                                      style={{ padding: "" }}
                                    >
                                      <div
                                        className="qty-box"
                                        style={{ padding: "10px 0px" }}
                                      >
                                        {/* <div className="input-group qtywidth">
                                      <button
                                        style={{
                                          border: "none",
                                          outline: "none",
                                          backgroundColor: "white",
                                          color: "#230BB3",
                                          fontSize: "17px",
                                          fontWeight: 700,
                                        }}
                                        onClick={() => {
                                          decrementcart(
                                            item.quantity,
                                            item.product
                                          );
                                        }}
                                      >
                                        -
                                      </button>
                                      <input
                                        type="number"
                                        name="quantity"
                                        style={{ width: "40px",padding:'6px' }}
                                        className="form-control qty input-number"
                                        readOnly
                                        defaultValue={item.quantity}
                                      />
                                      <button
                                        style={{
                                          border: "none",
                                          outline: "none",
                                          backgroundColor: "white",
                                          color: "#230BB3",
                                          fontSize: "17px",
                                          fontWeight: 700,
                                        }}
                                        onClick={() => {
                                          incrementcart(
                                            item.quantity,
                                            item.product
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </div> */}

                                        <div className="input-group qtywidth">
                                          <button
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              backgroundColor: "white",
                                              color: "#230BB3",
                                              fontSize: "17px",
                                              fontWeight: 700,
                                            }}
                                            onClick={() => {
                                              decrementcart(
                                                item.quantity,
                                                item.product
                                              );
                                            }}
                                          >
                                            -
                                          </button>

                                          <input
                                            type="number"
                                            name="quantity"
                                            style={{
                                              width: "37px",
                                              padding: "0.375rem 0.45rem",
                                            }}
                                            className="form-control qty input-number"
                                            defaultValue={item.quantity}
                                            // readOnly
                                            value={
                                              inprocess
                                                ? item.quantity
                                                : undefined
                                            }
                                            onChange={() => {
                                              setinprocess(false);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(
                                                e,
                                                item.product,
                                                item.quantity
                                              );
                                            }}
                                            // value={item.quantity}
                                            // onBlur={(e)=>{cartqty(e.target.value,item.product)}}
                                            // onBlur={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                            //   e.target.value = 1;cartqty(1,item.product)
                                            // }else{e.target.value = cartqty(e.target.value,item.product);}}}
                                            // onChange={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                            //   e.target.value = 1;
                                            // }else{cartqty(e.target.value,item.product);}}}
                                          />
                                          <button
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              backgroundColor: "white",
                                              color: "#230BB3",
                                              fontSize: "17px",
                                              fontWeight: 700,
                                            }}
                                            onClick={() => {
                                              incrementcart(
                                                item.quantity,
                                                item.product
                                              );
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <h6
                                      className="td-color"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "18px",
                                        fontSize: "14px",
                                        paddingTop: "7px",
                                      }}
                                    >
                                      &nbsp;
                                      {`₹${(
                                        item.price_excl_tax * item.quantity
                                      ).toFixed(0)}`}
                                      <span style={{ color: "#52bb56" }}>
                                        &nbsp;&nbsp;
                                        {/* {item.voucher?.[0] ? "Applied" : ""} */}
                                      </span>
                                    </h6>
                                    {/* <h6 className="td-color" style={{fontWeight: 400, lineHeight: "63px",fontSize:"14px", color: '#230BB3'}}>₹539</h6> */}
                                    {/* <div className="firstrow px-2  d-flex justify-content-between">
                            <span
                              style={{
                                fontWeight: 500,
                                color: "black",
                                fontSize: "12px",
                                marginTop: "7px",
                              }}
                            >
                              ₹539
                            </span>
                            <span
                              className="fiftyoff"
                              style={{
                                fontWeight: 600,
                                marginTop: "9px",
                                fontSize: 10,
                                color: "#8F9091",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              ₹1039{" "}
                            </span>
                            <span
                              style={{
                                fontSize: 10,
                                color: "#8F9091",
                                cursor: "pointer",
                                marginTop: "9px",
                              }}
                              onClick={() => {
                                setshowtax(!showtax);
                              }}
                            >
                              (50%) <br />{" "}
                              <span
                                style={{
                                  fontSize: 10,
                                  color: "#8F9091",
                                  display: showtax == true ? "block" : "none",
                                }}
                                id="span2"
                              ></span>
                            </span>
                          </div> */}
                                    {/* <span>
                            <p style={{ color: "#230cb3" }} className="save">
                              You save ₹500
                            </p>
                          </span> */}
                                  </div>
                                  {/* <hr  style={{color:'black', width:'200px'}}/> */}

                                  {/* <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  <h5 className="number">
                                    Mobile:{" "}
                                    <span className="number2">
                                      +99-3839483943
                                    </span>
                                  </h5>
                                </label>
                              </div> */}
                                  {/* <p className="small-text">
                                85-B, UAE Road, DubaiSaudi Arabia, 201001
                              </p> */}
                                </div>
                                <hr
                                  style={{
                                    color: "black",
                                    width: "100%",
                                    margin: "0px 0px 10px 0px",
                                  }}
                                />
                                {/* <button
                                 onClick={() => {
                                  setdelitem(item.product);
                                }}
                                                  type="button"
                                                  className="icon"
                              style={{
                                color: "#777777",
                                padding: "0px 3px",
                                lineHeight: "63px",
                                position:'relative',
                                top:'8px',
                                border:'none',
backgroundColor:'#fff'
                              }}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModal13400"
                                                  // class="dropdown-item"
                                                  // onClick={() => {
                                                  //   setdelid(item.id);
                                                  // }}
                                                ></button> */}
                                <a
                                  href="#"
                                  style={{ color: "#230cb3" }}
                                  className="remove"
                                  // onClick={() => {
                                  //   Removeproduct(item.product);
                                  // }}
                                  onClick={() => {
                                    setdelitem(item.product);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal13400"
                                >
                                  Remove
                                </a>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>

                  <div
                    // className="table-responsive bigcart scroll-container"
                    className="table-responsive scroll-container"
                    style={{
                      borderRadius: "6px",
                      padding: "0px 5px",
                      height: "fit-content",
                      maxHeight: "65vh",
                      background: "white",
                    }}
                  >
                    <h3
                      style={{
                        padding: "8px 9px 1px",
                        fontWeight: 700,
                        fontSize: "22px",
                      }}
                    >
                      Shopping Cart
                    </h3>
                    {tokenvalue.msg == "Voucher login" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Image
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "12%",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Product Name
                            </th>
                            {/* <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "14px",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            MRP
                          </th> */}
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "16%",
                                textWrap: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              {/* Unit Price */}
                              Offer Price
                            </th>
                         
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "18%",
                                textWrap: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              {/* Basic Amount */}
                              Total Amount
                            </th>

                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "18%",
                                textWrap: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              {/* Tax Type */}
                              Taxable Value
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "18%",
                                textWrap: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              GST Rate
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "18%",
                                textWrap: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              GST Amount
                            </th>

                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "13px",
                                width: "8%",
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data[0]?.product__title ? (
                            data.map((item, index) => (
                              <>
                                <tr>
                                  <td
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.product,
                                            pname: item.product__title,
                                          },
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_API_URL}media/${item.image[0].original}`}
                                        width="80px"
                                        height="80px"
                                        alt="cart"
                                        className=" "
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className="pnwidth"
                                    style={{ position: "relative", top: "0px" }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "0px",
                                      }}
                                    >
                                      <h6>
                                        <p
                                          style={{
                                            color: "black",
                                            fontSize: "13px",
                                            // lineHeight: "22px",
                                            lineHeight:
                                              item.stock == 0 ? "40px" : "63px",
                                            textAlign: "center",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                            cursor: "pointer",
                                            overflow: "hidden",
                                            position: "relative",
                                            top: "8px",
                                          }}
                                          onClick={() => {
                                            nvg("/productdetails", {
                                              state: {
                                                id: item.product,
                                                pname: item.product__title,
                                              },
                                            });
                                          }}
                                        >
                                          {/* {item.product__title.length > 10 ? item.product__title.substring(0,11) + '...' : item.product__title} */}

                                          {item.product__title}
                                        </p>
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "13px",
                                            // lineHeight: "22px",
                                            // lineHeight: "63px",
                                            textAlign: "center",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            position: "relative",
                                            top: "0px",
                                          }}
                                        >
                                          {" "}
                                          {item.stock == 0
                                            ? "(Out of Stock)"
                                            : ""}
                                        </p>
                                      </h6>
                                    </div>
                                  </td>
                                  {/* <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color"
                                  style={{
                                    // position:'relative',
                                    // top:'12px',
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  ₹{item.mrp}
                                </h6>
                              </td> */}
                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    <h6
                                      className="td-color"
                                      style={{
                                        // position:'relative',
                                        // top:'8px',
                                        fontWeight: 400,
                                        lineHeight: "63px",
                                        fontSize: "13px",
                                        textAlign: "center",
                                        textAlign: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "8px",
                                      }}
                                    >
                                      {/* ₹{item.base_price} old */}₹
                                      {item.price_excl_tax}
                                    </h6>
                                  </td>
                                                              <td
                                    style={{
                                      position: "relative",
                                      padding: "12px 0px",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      className="qty-box"
                                      style={{
                                        padding: "10px 0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        position: "",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "0px",
                                      }}
                                    >
                                      <div
                                        className="input-group qtywidth"
                                        style={{
                                          border: "0px",
                                          flexWrap: "nowrap",
                                        }}
                                      >
                                        <button
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "white",
                                            color: "#230BB3",
                                            fontSize: "17px",
                                            fontWeight: 700,
                                          }}
                                          onClick={() => {
                                            decrementcart(
                                              item.quantity,
                                              item.product
                                            );
                                          }}
                                        >
                                          -
                                        </button>

                                        <input
                                          type="number"
                                          name="quantity"
                                          style={{
                                            width: "37px",
                                            padding: "0.375rem 0.45rem",
                                            border: "1px solid #dddddd",
                                          }}
                                          className="form-control qty input-number"
                                          defaultValue={item.quantity}
                                          // readOnly
                                          value={
                                            inprocess
                                              ? item.quantity
                                              : undefined
                                          }
                                          onChange={() => {
                                            setinprocess(false);
                                          }}
                                          onKeyDown={(e) =>
                                            enterfun(
                                              e,
                                              item.product,
                                              item.quantity
                                            )
                                          }
                                          onBlur={(e) => {
                                            handleBlur(
                                              e,
                                              item.product,
                                              item.quantity
                                            );
                                          }}
                                          // value={item.quantity}
                                          // onBlur={(e)=>{cartqty(e.target.value,item.product)}}
                                          // onBlur={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                          //   e.target.value = 1;cartqty(1,item.product)
                                          // }else{e.target.value = cartqty(e.target.value,item.product);}}}
                                          // onChange={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                          //   e.target.value = 1;
                                          // }else{cartqty(e.target.value,item.product);}}}
                                        />
                                        <button
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "white",
                                            color: "#230BB3",
                                            fontSize: "17px",
                                            fontWeight: 700,
                                          }}
                                          onClick={() => {
                                            incrementcart(
                                              item.quantity,
                                              item.product
                                            );
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    <h6
                                      className="td-color px-lg-0 px-md-2"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "63px",
                                        fontSize: "13px",
                                        // color: "#230BB3",
                                        textAlign: "center",
                                        textAlign: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "8px",
                                      }}
                                    >
                                      ₹
                                      {/* {(
                                   parseInt(item.base_price) * parseInt(item.quantity)
                                  ).toFixed(0)}{" "} old */}
                                      {(
                                        parseInt(item.price_excl_tax) *
                                        parseInt(item.quantity)
                                      ).toFixed(0)}{" "}
                                    </h6>
                                  </td>

                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    <h6
                                      className="td-color px-lg-0 px-md-2"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "63px",
                                        fontSize: "13px",
                                        // color: "#230BB3",
                                        textAlign: "center",
                                        textAlign: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "8px",
                                      }}
                                    >

                            ₹{Number.isInteger((item.base_price *
                                        item.quantity
                                      )) ? (
                                        item.base_price *
                                        item.quantity
                                      ) : (
                                        item.base_price *
                                        item.quantity
                                      ).toFixed(2)}
                                      {/* IGST */}
                                      
                                      {/* ₹{(
                                        item.base_price *
                                        item.quantity
                                      ).toFixed(0)} */}
                                      {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                    </h6>
                                  </td>
                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    <h6
                                      className="td-color px-lg-0 px-md-2"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "63px",
                                        fontSize: "13px",
                                        // color: "#230BB3",
                                        textAlign: "center",
                                        textAlign: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "8px",
                                      }}
                                    >
                                      {item.gst_rate}%
                                      {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                    </h6>
                                  </td>
                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    <h6
                                      className="td-color px-lg-0 px-md-2"
                                      style={{
                                        fontWeight: 400,
                                        lineHeight: "63px",
                                        fontSize: "13px",
                                        // color: "#230BB3",
                                        textAlign: "center",
                                        textAlign: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "8px",
                                      }}
                                    >
                                       ₹{Number.isInteger(item.gst_tax_amount * item.quantity) ? (item.gst_tax_amount * item.quantity) : (item.gst_tax_amount * item.quantity).toFixed(2)}
                                      {/* ₹{(item.gst_tax_amount * item.quantity).toFixed(0)} */}
                                      {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                    </h6>
                                  </td>
                                  {/* <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  ₹{parseInt((item.price_incl_tax * item.quantity).toFixed(0))}
                                </h6>
                              </td> */}
                                  <td
                                    style={{
                                      position: "relative",
                                      padding: "0px",
                                    }}
                                  >
                                    {/* <a href="javascript:void(0)" class="icon" style="color:#777777;padding: 0px 3px; line-height: 63px;"> <img src="/images/edit.png" alt="404"> </a> */}
                                    <div
                                      style={{
                                        position: "",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "start",
                                        top: "0px",
                                      }}
                                    >
                                      {/* <a
                              href="javascript:void(0)"
                              onClick={() => {
                                Removeproduct(item.product);
                              }}
                              className="icon"
                              style={{
                                color: "#777777",
                                padding: "0px 3px",
                                lineHeight: "63px",
                                position:'relative',
                                top:'8px'
                              }}
                            > */}
                                      <button
                                        onClick={() => {
                                          setdelitem(item.product);
                                        }}
                                        type="button"
                                        className="icon"
                                        style={{
                                          color: "#777777",
                                          padding: "0px 3px",
                                          lineHeight: "63px",
                                          position: "relative",
                                          top: "8px",
                                          border: "none",
                                          backgroundColor: "#fff",
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal13400"
                                        // class="dropdown-item"
                                        // onClick={() => {
                                        //   setdelid(item.id);
                                        // }}
                                      >
                                        {/* <i class="fa fa-trash-can "></i> */}
                                        {/* &nbsp;Delete */}
                                        <img
                                          src="/images/delete.png"
                                          style={{ width: "14px" }}
                                          alt={404}
                                        />
                                      </button>
                                      {/* </a> */}
                                    </div>
                                  </td>
                                </tr>
                                {/* {console.log("this is it",data.length,"hhhhh",index)} */}
                                <tr
                                  style={{
                                    display:
                                      data.length - 1 == index ? "" : "none",
                                  }}
                                >
                                  <td
                                    colSpan={ 3}
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    TOTAL
                                  </td>
                                  {/* <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}}></td> */}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {qty_total}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ₹{offeramount_totalwithqty.toFixed(0)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ₹{(taxable_total).toFixed(0)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                   ₹{(gst_total).toFixed(0)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  ></td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="9">
                                <h6
                                  className="td-color"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "34px",
                                    fontSize: "14px",
                                    color: "#230BB3",
                                    textAlign: "center",
                                  }}
                                >
                                  Your Cart is Empty!
                                </h6>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      nvg("/home");
                                    }}
                                    style={{
                                      backgroundColor: "#230bb3",
                                      color: "#fff",
                                      borderRadius: "0px",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Shop Now
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Image
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Product Name
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              MRP
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Price
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </th>
                            <th
                              className="family"
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                width: "8%",
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data[0]?.product__title ? (
                            data.map((item, index) => (
                              <tr>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      nvg("/productdetails", {
                                        state: {
                                          id: item.product,
                                          pname: item.product__title,
                                        },
                                      });
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}media/${item.image[0].original}`}
                                      width="80px"
                                      height="80px"
                                      alt="cart"
                                      className=" "
                                    />
                                  </div>
                                </td>
                                <td
                                  className="pnwidth"
                                  style={{ position: "relative", top: "0px" }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "0px",
                                    }}
                                  >
                                    <h6>
                                      <p
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          // lineHeight: "22px",
                                          lineHeight:
                                            item.stock == 0 ? "40px" : "63px",
                                          textAlign: "center",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "1",
                                          WebkitBoxOrient: "vertical",
                                          cursor: "pointer",
                                          overflow: "hidden",
                                          position: "relative",
                                          top: "8px",
                                        }}
                                        onClick={() => {
                                          nvg("/productdetails", {
                                            state: {
                                              id: item.product,
                                              pname: item.product__title,
                                            },
                                          });
                                        }}
                                      >
                                        {/* {item.product__title.length > 10 ? item.product__title.substring(0,11) + '...' : item.product__title} */}

                                        {item.product__title}
                                      </p>
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          // lineHeight: "22px",
                                          // lineHeight: "63px",
                                          textAlign: "center",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "1",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          position: "relative",
                                          top: "0px",
                                        }}
                                      >
                                        {" "}
                                        {item.stock == 0
                                          ? "(Out of Stock)"
                                          : ""}
                                      </p>
                                    </h6>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    position: "relative",
                                    padding: "0px",
                                  }}
                                >
                                  <h6
                                    className="td-color"
                                    style={{
                                      // position:'relative',
                                      // top:'12px',
                                      fontWeight: 400,
                                      lineHeight: "63px",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "8px",
                                    }}
                                  >
                                    ₹{item.mrp}
                                  </h6>
                                </td>
                                <td
                                  style={{
                                    position: "relative",
                                    padding: "0px",
                                  }}
                                >
                                  <h6
                                    className="td-color"
                                    style={{
                                      // position:'relative',
                                      // top:'8px',
                                      fontWeight: 400,
                                      lineHeight: "63px",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      textAlign: "center",
                                      position: "relative",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "8px",
                                    }}
                                  >
                                    ₹{item.price_incl_tax}
                                  </h6>
                                </td>
                                <td
                                  style={{
                                    position: "relative",
                                    padding: "12px",
                                  }}
                                >
                                  {" "}
                                  <div
                                    className="qty-box"
                                    style={{
                                      padding: "10px 0px",
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      position: "",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "0px",
                                    }}
                                  >
                                    <div
                                      className="input-group qtywidth"
                                      style={{ border: "0px" }}
                                    >
                                      <button
                                        style={{
                                          border: "none",
                                          outline: "none",
                                          backgroundColor: "white",
                                          color: "#230BB3",
                                          fontSize: "17px",
                                          fontWeight: 700,
                                        }}
                                        onClick={() => {
                                          decrementcart(
                                            item.quantity,
                                            item.product
                                          );
                                        }}
                                      >
                                        -
                                      </button>

                                      <input
                                        type="number"
                                        name="quantity"
                                        style={{
                                          width: "37px",
                                          padding: "0.375rem 0.45rem",
                                          border: "1px solid #dddddd",
                                        }}
                                        className="form-control qty input-number"
                                        defaultValue={item.quantity}
                                        // readOnly
                                        value={
                                          inprocess ? item.quantity : undefined
                                        }
                                        onChange={() => {
                                          setinprocess(false);
                                        }}
                                        onKeyDown={(e) =>
                                          enterfun(
                                            e,
                                            item.product,
                                            item.quantity
                                          )
                                        }
                                        onBlur={(e) => {
                                          handleBlur(
                                            e,
                                            item.product,
                                            item.quantity
                                          );
                                        }}
                                        // value={item.quantity}
                                        // onBlur={(e)=>{cartqty(e.target.value,item.product)}}
                                        // onBlur={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                        //   e.target.value = 1;cartqty(1,item.product)
                                        // }else{e.target.value = cartqty(e.target.value,item.product);}}}
                                        // onChange={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                        //   e.target.value = 1;
                                        // }else{cartqty(e.target.value,item.product);}}}
                                      />
                                      <button
                                        style={{
                                          border: "none",
                                          outline: "none",
                                          backgroundColor: "white",
                                          color: "#230BB3",
                                          fontSize: "17px",
                                          fontWeight: 700,
                                        }}
                                        onClick={() => {
                                          incrementcart(
                                            item.quantity,
                                            item.product
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    position: "relative",
                                    padding: "0px",
                                  }}
                                >
                                  <h6
                                    className="td-color px-lg-0 px-md-2"
                                    style={{
                                      fontWeight: 400,
                                      lineHeight: "63px",
                                      fontSize: "14px",
                                      color: "#230BB3",
                                      textAlign: "center",
                                      textAlign: "center",
                                      position: "relative",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "8px",
                                    }}
                                  >
                                    ₹
                                    {(
                                      item.price_incl_tax * item.quantity
                                    ).toFixed(0)}{" "}
                                    <span style={{ color: "#52bb56" }}>
                                      &nbsp;&nbsp;
                                      {item.voucher?.[0] ? "Applied" : ""}
                                    </span>
                                  </h6>
                                </td>
                                <td
                                  style={{
                                    position: "relative",
                                    padding: "0px",
                                  }}
                                >
                                  {/* <a href="javascript:void(0)" class="icon" style="color:#777777;padding: 0px 3px; line-height: 63px;"> <img src="/images/edit.png" alt="404"> </a> */}
                                  <div
                                    style={{
                                      position: "",
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      top: "0px",
                                    }}
                                  >
                                    {/* <a
                              href="javascript:void(0)"
                              onClick={() => {
                                Removeproduct(item.product);
                              }}
                              className="icon"
                              style={{
                                color: "#777777",
                                padding: "0px 3px",
                                lineHeight: "63px",
                                position:'relative',
                                top:'8px'
                              }}
                            > */}
                                    <button
                                      onClick={() => {
                                        setdelitem(item.product);
                                      }}
                                      type="button"
                                      className="icon"
                                      style={{
                                        color: "#777777",
                                        padding: "0px 3px",
                                        lineHeight: "63px",
                                        position: "relative",
                                        top: "8px",
                                        border: "none",
                                        backgroundColor: "#fff",
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal13400"
                                      // class="dropdown-item"
                                      // onClick={() => {
                                      //   setdelid(item.id);
                                      // }}
                                    >
                                      {/* <i class="fa fa-trash-can "></i> */}
                                      {/* &nbsp;Delete */}
                                      <img
                                        src="/images/delete.png"
                                        style={{ width: "14px" }}
                                        alt={404}
                                      />
                                    </button>
                                    {/* </a> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <h6
                                  className="td-color"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "34px",
                                    fontSize: "14px",
                                    color: "#230BB3",
                                    textAlign: "center",
                                  }}
                                >
                                  Your Cart is Empty!
                                </h6>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      nvg("/home");
                                    }}
                                    style={{
                                      backgroundColor: "#230bb3",
                                      color: "#fff",
                                      borderRadius: "0px",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Shop Now
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}

                    {/* <h5
                  style={{
                    padding: "9px 9px",
                    fontWeight: 400,
                    fontSize: 14,
                    cursor: "pointer",
                    display:'inline-block'
                  }}
                  onClick={() => {
                    nvg("/home");
                  }}
                >
                  <img src="/images/Arrow 1.png" alt /> Back to Shop
                </h5> */}
                  </div>

                  {voucherlist2[0] ? (
                    voucherlist2[0]?.offer_type != "Coupon" ? (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "0px 6px",
                          marginBottom: "8px",
                        }}
                      >
                        <table className="table bg-white mt-3">
                          <tbody>
                            {/* {data[0]?.product__title ? ( */}
                            <tr className="" style={{ borderColor: "#333" }}>
                              <td
                                style={{
                                  fontWeight: "700",
                                  fontSize: "22px",
                                  display: "flex",
                                  gap: "19px",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <span>
                                  {/* {voucherlist2.length}  */}
                                  Redeem Voucher(s)
                                </span>
                                <div style={{ display: "flex", gap: 4 }}>
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color: "#230BB3",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      value={vouchervalue}
                                      style={{ borderRadius: "0px" }}
                                      className="form-control applypay"
                                      placeholder="Enter Your Voucher Code"
                                      onChange={(e) => {
                                        setvouchervalue(e.target.value);
                                      }}
                                    />
                                  </span>
                                  <button
                                    className="btn"
                                    style={{
                                      fontWeight: 500,
                                      // backgroundColor: "#2b2a29",
                                      backgroundColor: "#230BB3",
                                      color: "white",
                                      borderRadius: "0px",
                                      fontSize: 12,
                                      height: "fit-content",
                                      padding: "7px 8px",
                                    }}
                                    onClick={() => {
                                      applyvoucher(vouchervalue);
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                                {/* <span style={{ color: "red",fontSize:'12px',fontWeight:'500' }}>{vouchererror}</span> */}
                                <span
                                  className="carttotalhere"
                                  style={{
                                    color: "",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Voucher(s) Applied:{" "}
                                  <span
                                    style={{
                                      border: "1px solid #ced4da",
                                      padding: "2px 5px",
                                    }}
                                  >
                                    {voucherlist2.length}
                                  </span>{" "}
                                  : Value:{" "}
                                  <span
                                    style={{
                                      border: "1px solid #ced4da",
                                      padding: "2px 5px",
                                    }}
                                  >
                                    ₹{offerSum}
                                  </span>
                                </span>
                                {/* <span className="carttotalhere" style={{ color: "",fontSize:'12px',fontWeight:'500' }}>Voucher Total: ₹{offerSum}</span> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div
                          className="newscrollerbar"
                          style={{ width: "100%" }}
                        >
                          <table
                            className="table-responsive bg-white mt-3"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              {/* {data[0]?.product__title ? ( */}
                              <>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    S.NO
                                  </th>
                                  <th
                                    style={{
                                      textWrap: "nowrap",
                                      fontSize: "12px",
                                      width: "18%",
                                    }}
                                  >
                                    Voucher Code
                                  </th>
                                  <th
                                    style={{
                                      textWrap: "nowrap",
                                      fontSize: "12px",
                                      width: "19%",
                                    }}
                                    className="px-lg-0 px-2"
                                  >
                                    Voucher Type
                                  </th>
                                  {/* <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Basic Amount</th> */}
                                  <th
                                    style={{
                                      textWrap: "nowrap",
                                      fontSize: "12px",
                                      width: "18%",
                                    }}
                                    className="px-lg-0 px-2"
                                  >
                                    GST
                                  </th>
                                  <th
                                    style={{
                                      textWrap: "nowrap",
                                      fontSize: "12px",
                                      width: "18%",
                                    }}
                                    className="px-lg-0 px-2"
                                  >
                                    Shipping
                                  </th>
                                  <th
                                    style={{
                                      textWrap: "nowrap",
                                      fontSize: "12px",
                                    }}
                                    className="px-lg-0 px-2"
                                  >
                                    Value
                                  </th>
                                  <th></th>
                                </tr>

                                {/* { data.map((item, index) => ( */}
                                {/* {console.log("voucherlis dat", voucherlist2)} */}
                                {
                                  // voucherlist2[0]?.offer ?
                                  voucherlist2.map((item, index) => (
                                    <tr
                                      style={{
                                        display:
                                          tokenvalue.msg == "Voucher login"
                                            ? item.code == localvouhcer
                                              ? ""
                                              : "none"
                                            : "none",
                                      }}
                                    >
                                      <td>
                                        <span
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {1}
                                        </span>
                                      </td>
                                      <td>
                                        <div>
                                          <div
                                            className="firstrow  justify-content-between mt-1 py-1 align-items-center"
                                            style={{
                                              display:
                                                tokenvalue.msg ==
                                                "Voucher login"
                                                  ? item.code == localvouhcer
                                                    ? "flex"
                                                    : "none"
                                                  : "none",
                                            }}
                                          >
                                            <div
                                              className="firstcontianer d-flex align-items-center"
                                              style={{ gap: 4 }}
                                            >
                                              <div className="containerimg">
                                                <img
                                                  src="/images/carticon.png"
                                                  style={{ width: "15px" }}
                                                  alt={404}
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  gap: "4px",
                                                  alignItems: "baseline",
                                                  minWidth: "192px",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {item.code}
                                                  {/* applied &nbsp;{" "} */}
                                                  {/* {item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive" ? "excl. GST"
                                                  : "incl. GST"} */}
                                                  {/* &nbsp; */}
                                                  <span
                                                    onClick={() => {
                                                      funvoucherdesc(
                                                        item.description
                                                      );
                                                    }}
                                                    className="icon"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#voucherdesc"
                                                  >
                                                    {" "}
                                                    <BsInfoCircle />
                                                  </span>
                                                  {/* <span style={{color:'red',cursor:'pointer',display:index == 0 ? 'none' : 'inline-block'}} onClick={()=>{removevoucher(item.code)}}> */}
                                                  {/* x
                          </span> */}
                                                </p>
                                              </div>
                                            </div>
                                            {/* <div
                                            className="remove"
                                            style={{
                                              flexDirection: "column",
                                              paddingRight: "0px",
                                              padding: "0px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "#230BB3",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                            >
                                              ₹{item.offer}
                                            </span>
                                          </div> */}
                                          </div>
                                        </div>
                                      </td>
                                      <td style={{ fontSize: "12px" }}>
                                        {item.clubable
                                          ? "Clubbable"
                                          : "Nonclubbable"}
                                      </td>

                                      <td style={{ fontSize: "12px" }}>
                                        {item.voucher_type ==
                                          "Gift Voucher - Exclusive" ||
                                        item.voucher_type ==
                                          "Shopping Voucher - Exclusive"
                                          ? "Payable"
                                          : "Included"}
                                      </td>
                                      <td style={{ fontSize: "12px" }}>
                                        {" "}
                                        {item.shipping_incl == false
                                          ? "Payable"
                                          : item.voucher_type ==
                                          "Gift Voucher - Exclusive" || item.voucher_type ==
                                          "Gift Voucher - Inclusive" ? "Payable" : "Included"}{" "}
                                      </td>
                                      <td style={{ fontSize: "12px" }}>
                                        {" "}
                                        ₹{item.offer}
                                      </td>
                                      <td>
                                        {/* <a href="javascript:void(0)" class="icon" style="color:#777777;padding: 0px 3px; line-height: 63px;"> <img src="/images/edit.png" alt="404"> </a> */}
                                        <div></div>
                                      </td>
                                    </tr>
                                  ))
                                  // : ""
                                }

                                {voucherlist2filter[0]?.code
                                  ? voucherlist2filter.map((item, index) => (
                                      <tr
                                        style={{
                                          display:
                                            tokenvalue.msg == "Voucher login"
                                              ? item.code == localvouhcer
                                                ? "none"
                                                : ""
                                              : "",
                                        }}
                                      >
                                        <td>
                                          <span
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {index + 2}
                                          </span>
                                        </td>
                                        <td>
                                          <div>
                                            <div
                                              className="firstrow  py-1 justify-content-between  align-items-center"
                                              style={{
                                                display:
                                                  tokenvalue.msg ==
                                                  "Voucher login"
                                                    ? item.code == localvouhcer
                                                      ? "none"
                                                      : "flex"
                                                    : "flex",
                                              }}
                                            >
                                              <div
                                                className="firstcontianer d-flex align-items-start"
                                                style={{ gap: 4 }}
                                              >
                                                <div className="containerimg">
                                                  <img
                                                    src="/images/carticon.png"
                                                    style={{ width: "15px" }}
                                                    alt={404}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    gap: "4px",
                                                    alignItems: "baseline",
                                                    minWidth: "192px",
                                                  }}
                                                >
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      position: "relative",
                                                      top: "3px",
                                                    }}
                                                  >
                                                    {item.code}
                                                    {/* applied &nbsp;{" "} */}
                                                    {/* {item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive" ? "excl. GST"
                                                  : "incl. GST"} */}

                                                    <span
                                                      onClick={() => {
                                                        funvoucherdesc(
                                                          item.description
                                                        );
                                                      }}
                                                      className="icon"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#voucherdesc"
                                                    >
                                                      {" "}
                                                      <BsInfoCircle />
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                              {/* <div
                                            className="remove"
                                            style={{
                                              flexDirection: "column",
                                              paddingRight: "0px",
                                              padding: "0px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "#230BB3",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                            >
                                              ₹{item.offer}
                                            </span>
                                        
                                          </div> */}
                                            </div>{" "}
                                          </div>
                                        </td>
                                        {/* <td style={{fontSize:'12px'}}>
                                      Clubbable                                    </td>
                                    <td style={{fontSize:'12px'}}>Payable</td>
                                    <td style={{fontSize:'12px'}}>Payable</td> */}
                                        <td style={{ fontSize: "12px" }}>
                                          {item.clubable
                                            ? "Clubbable"
                                            : "Nonclubbable"}
                                        </td>

                                        <td style={{ fontSize: "12px" }}>
                                          {item.voucher_type ==
                                            "Gift Voucher - Exclusive" ||
                                          item.voucher_type ==
                                            "Shopping Voucher - Exclusive"
                                            ? "Payable"
                                            : "Included"}
                                        </td>
                                        <td style={{ fontSize: "12px" }}>
                                          {" "}
                                          {/* {item.shipping_incl == false
                                            ? "Payable"
                                            : "Included"}{" "} */}
                                            {item.shipping_incl == false
                                          ? "Payable"
                                          : item.voucher_type ==
                                          "Gift Voucher - Exclusive" || item.voucher_type ==
                                          "Gift Voucher - Inclusive" ? "Payable" : "Included"}
                                        </td>
                                        <td style={{ fontSize: "12px" }}>
                                          {" "}
                                          ₹{item.offer}
                                        </td>
                                        <td>
                                          <span
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                              display: "inline-block",
                                              position: "relative",
                                              left: "5px",
                                            }}
                                            onClick={() => {
                                              removevoucher(item.code);
                                            }}
                                          >
                                            <img
                                              src="/images/delete.png"
                                              style={{ width: "14px" }}
                                              alt={404}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    ))
                                  : ""}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "0px 6px",
                          marginBottom: "8px",
                        }}
                      >
                        <table className="table bg-white mt-3">
                          <tbody>
                            {data[0]?.product__title ? (
                              <tr className="" style={{ borderColor: "#333" }}>
                                <td
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    display: "flex",
                                    gap: "19px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <span>Redeem Coupon</span>
                                  {/* <div style={{ display: "flex", gap: 4 }}>
                        <span style={{ fontWeight: 700, color: "#230BB3" }}>
                          <input
                            type="text"
                            value={vouchervalue}
                            style={{ borderRadius: "0px" }}
                            className="form-control applypay"
                            placeholder="Enter Your Voucher Code"
                            onChange={(e) => {
                              setvouchervalue(e.target.value);
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            fontWeight: 500,
                            backgroundColor: "#230BB3",
                            color: "white",
                            borderRadius: "0px",
                            fontSize: 12,
                            height: "fit-content",
                            padding: "7px 8px",
                          }}
                          onClick={() => {
                            applyvoucher(vouchervalue);
                          }}
                        >
                          Apply
                        </button>
                      </div> */}
                                  {/* <span className="carttotalhere" style={{ color: "",fontSize:'12px',fontWeight:'500' }}>Coupon(s) Applied: <span style={{border: "1px solid #ced4da",padding: "2px 5px"}}>{voucherlist2.length}</span> : Value: <span style={{border: "1px solid #ced4da",padding: "2px 5px"}}>₹{offerSum}</span></span> */}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>

                        <div
                          className="newscrollerbar"
                          style={{ width: "100%" }}
                        >
                          <table
                            className="table-responsive bg-white mt-3"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              {data[0]?.product__title ? (
                                <>
                                  <tr>
                                    <th
                                      style={{
                                        textAlign: "center",
                                        fontSize: "12px",
                                        width: "8%",
                                      }}
                                    >
                                      S.NO
                                    </th>
                                    <th
                                      style={{
                                        textWrap: "nowrap",
                                        fontSize: "12px",
                                        width: "18%",
                                      }}
                                    >
                                      Coupon Code
                                    </th>
                                    <th
                                      style={{
                                        textWrap: "nowrap",
                                        fontSize: "12px",
                                      }}
                                      className="px-lg-0 px-2"
                                    >
                                      Description
                                    </th>
                                    {/* <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Voucher Type</th>
                                <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">GST</th>
                                <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Shipping</th> */}
                                    <th
                                      style={{
                                        textWrap: "nowrap",
                                        fontSize: "12px",
                                        width: "7%",
                                      }}
                                      className="px-lg-0 px-2"
                                    >
                                      Value
                                    </th>
                                    <th></th>
                                  </tr>

                                  {voucherlist2[0]?.offer
                                    ? voucherlist2.map((item, index) => (
                                        <tr
                                          style={{
                                            display:
                                              tokenvalue.msg == "Voucher login"
                                                ? item.code == localvouhcer
                                                  ? ""
                                                  : "none"
                                                : "none",
                                          }}
                                        >
                                          <td>
                                            <span
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {1}
                                            </span>
                                          </td>
                                          <td>
                                            <div>
                                              <div
                                                className="firstrow  justify-content-between mt-1 py-1 align-items-center"
                                                style={{
                                                  display:
                                                    tokenvalue.msg ==
                                                    "Voucher login"
                                                      ? item.code ==
                                                        localvouhcer
                                                        ? "flex"
                                                        : "none"
                                                      : "none",
                                                }}
                                              >
                                                <div
                                                  className="firstcontianer d-flex align-items-center"
                                                  style={{ gap: 4 }}
                                                >
                                                  <div className="containerimg">
                                                    <img
                                                      src="/images/carticon.png"
                                                      style={{ width: "15px" }}
                                                      alt={404}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      gap: "4px",
                                                      alignItems: "baseline",
                                                      minWidth: "192px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0"
                                                      style={{
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {item.code} 
                                                      {/* applied &nbsp;{" "} */}
                                                      {/* {item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive" ? "excl. GST"
                                                  : "incl. GST"}
                                                <span
                                                  onClick={()=>{setvoucherdesc(item.description)}}
                                                  className="icon"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#voucherdesc"
                                               
                                                > <BsInfoCircle /></span> */}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {item.description}
                                          </td>

                                          {/* <td style={{fontSize:'12px'}}>{item.voucher_type == "Gift Voucher - Exclusive" || item.voucher_type == "Shopping Voucher - Exclusive" ? "Payable" : "Included"}</td>
                                    <td style={{fontSize:'12px'}}>  {item.shipping_charge == false ? "Payable" : "Included"}       </td> */}
                                          <td style={{ fontSize: "12px" }}>
                                            {" "}
                                            ₹{parseInt(item.offer)}
                                          </td>
                                          <td>
                                            <div></div>
                                          </td>
                                        </tr>
                                      ))
                                    : ""}

                                  {voucherlist2filter[0]?.code
                                    ? voucherlist2filter.map((item, index) => (
                                        <tr
                                          style={{
                                            display:
                                              tokenvalue.msg == "Voucher login"
                                                ? item.code == localvouhcer
                                                  ? "none"
                                                  : ""
                                                : "",
                                          }}
                                        >
                                          <td>
                                            <span
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {index + 2}
                                            </span>
                                          </td>
                                          <td>
                                            <div>
                                              <div
                                                className="firstrow  py-1 justify-content-between  align-items-center"
                                                style={{
                                                  display:
                                                    tokenvalue.msg ==
                                                    "Voucher login"
                                                      ? item.code ==
                                                        localvouhcer
                                                        ? "none"
                                                        : "flex"
                                                      : "flex",
                                                }}
                                              >
                                                <div
                                                  className="firstcontianer d-flex align-items-start"
                                                  style={{ gap: 4 }}
                                                >
                                                  <div className="containerimg">
                                                    <img
                                                      src="/images/carticon.png"
                                                      style={{ width: "15px" }}
                                                      alt={404}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      gap: "4px",
                                                      alignItems: "baseline",
                                                      minWidth: "192px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0"
                                                      style={{
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {item.code} applied &nbsp;{" "}
                                                      {item.voucher_type ==
                                                        "Shopping Voucher - Exclusive" ||
                                                      item.voucher_type ==
                                                        "Gift Voucher - Exclusive"
                                                        ? "excl. GST"
                                                        : "incl. GST"}
                                                      <span
                                                        onClick={() => {
                                                          funvoucherdesc(
                                                            item.description
                                                          );
                                                        }}
                                                        className="icon"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#voucherdesc"
                                                      >
                                                        {" "}
                                                        <BsInfoCircle />
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                                {/* <div
                                            className="remove"
                                            style={{
                                              flexDirection: "column",
                                              paddingRight: "0px",
                                              padding: "0px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "#230BB3",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                            >
                                              ₹{item.offer}
                                            </span>
                                        
                                          </div> */}
                                              </div>{" "}
                                            </div>
                                          </td>
                                          {/* <td style={{fontSize:'12px'}}>
                                      Clubbable                                    </td>
                                    <td style={{fontSize:'12px'}}>Payable</td>
                                    <td style={{fontSize:'12px'}}>Payable</td> */}
                                          <td style={{ fontSize: "12px" }}>
                                            {item.clubable
                                              ? "Clubbable"
                                              : "Nonclubbable"}
                                          </td>

                                          <td style={{ fontSize: "12px" }}>
                                            {item.voucher_type ==
                                              "Gift Voucher - Exclusive" ||
                                            item.voucher_type ==
                                              "Shopping Voucher - Exclusive"
                                              ? "Payable"
                                              : "Included"}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {" "}
                                            {item.shipping_charge == false
                                              ? "Payable"
                                              : "Included"}{" "}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {" "}
                                            ₹{item.offer}
                                          </td>
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                                display: "inline-block",
                                                position: "relative",
                                                left: "5px",
                                              }}
                                              onClick={() => {
                                                removevoucher(item.code);
                                              }}
                                            >
                                              <img
                                                src="/images/delete.png"
                                                style={{ width: "14px" }}
                                                alt={404}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      ))
                                    : ""}
                                </>
                              ) : (
                                ""
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className=" col-lg-4 mt-lg-0 mt-md-0 mt-sm-3 mt-xs-3 makestk">
                  <div
                    className="py-0"
                    style={{
                      position: "relative",
                      top: "-5px",
                      background: "#ffff",
                      borderRadius: 6,
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      className="firstrow px-4 d-flex justify-content-between"
                      style={{ padding: "5px 0px", paddingLeft: "20px" }}
                    >
                      <h4
                        style={{
                          fontWeight: 700,
                          fontSize: "22px",
                        }}
                      >
                        Confirm Delivery Address
                      </h4>
                    </div>
                    {/* <div className="firstrow px-4 pt-1 d-flex justify-content-between">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "12px",
                          paddingLeft: "0px",
                          alignSelf: "center",
                        }}
                      >
                        Select Address
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                          width:'70%'
                        }}
                      >
                        <select
                          name="country"
                          style={{
                            fontSize: "12px",
                            outline: "none",
                            borderRadius: "0px",
                          }}
                          className="form-select"
                          onChange={(e)=>selectaddress(e.target.value)}
                        >
                          {addressdata.map((item, index) => (
                            slectaddressid == item.id ? <option value={item.id}>
                            {`${item.line1} ${item.line2} ${item.country_printable_name} ${item.state} ${item.line4} ${item.postcode}`}
                          </option> : ''             
                          ))}
                          {addressdata.map((item, index) => (
                            slectaddressid != item.id ? <option value={item.id}>
                            {`${item.line1} ${item.line2} ${item.country_printable_name} ${item.state} ${item.line4} ${item.postcode}`}
                          </option> : ''             
                          ))}
                        </select>
                      </span>
                    </div> */}
                    <div
                      className="firstrow px-4 pt-1 d-flex justify-content-between"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="customscroll">
                        {/* {addressdata.map((item, index) => (
                            slectaddressid == item.id ?(
                                    <div
                                      class="col-lg-12"
                                      style={{
                                        marginBottom: "9px",
                                      }}
                                    >
                                      <div class="card">
                                        <div class="card-body">
                                          <h5
                                            class="card-title acounttitle"
                                            style={{
                                              textTransform: "capitalize",
                                              position:'relative'
                                            }}
                                          >
                                            {item.first_name}&nbsp;
                                            {item.last_name} <span style={{fontSize:'10px',color:'#8d9091'}}>({item.address_type})</span>
                                            <span className="hello" style={{position:'absolute',top:'-1px',right:"0px"}}>
                              </span>
                                          </h5>

                                          <div class="form-check" style={{position:'relative'}}>
                                             {shippingid == item.id ? <input
                                                type="radio"
                                                name="flexRadioDefault"
                                                style={{position:'absolute',top:'9px',left:'5px'}}
                                                onChange={()=>{setshippadd(item.id)}}
                                                checked
                                              /> : <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={()=>{setshippadd(item.id)}}
                                              
                                            />} 
                                            

                                            <label
                                              class="form-check-label mb-0"
                                              for="flexRadioDefault1"
                                            >
                                              <h5 className="number acounttitle" style={{paddingBottom:'4px'}} >
                                                Mobile:{" "}
                                                <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                  {item.phone_number}
                                                </span>
                                              </h5>
                                              <h5 className="number acounttitle">
                                                Email ID:{" "}
                                                <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                  {item.email}
                                                </span>
                                              </h5>
                                            </label>
                                          </div>
                                          <p className="small-text" style={{height:'40px'}}>
                                            <span style={{ textWrap: "nowrap" }}>
                                              {item.line1}
                                            </span>
                                            &nbsp;
                                            {item.line2}
                                            &nbsp;
                                            {item.country_printable_name}
                                            &nbsp;
                                            {item.state}
                                            &nbsp;
                                            {item.line4}-{item.postcode}
                                          </p>
                                        </div>
                                      </div>
                                    </div>)
                                  : ''             
                                ))} */}

                        {addressdata.map((item, index) =>
                          slectaddressid == item.id ? (
                            <>
                              {item.id != shippingid ? (
                                <div
                                  class="col-lg-12"
                                  style={{
                                    marginBottom: "9px",
                                  }}
                                >
                                  <div class="card pt-1">
                                    <div class="card-body p-0">
                                      <div
                                        class="form-check"
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        {/* <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'7px'}}
                                              onChange={()=>{setshippadd(item.id)}}
                                              checked
                                            />  */}
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "9px",
                                            left: "7px",
                                            width: "13px",
                                            height: "13px",
                                            border: "1px solid",
                                            borderRadius: "50%",
                                            background: "#230bb3",
                                          }}
                                          onChange={() => {
                                            setshippadd(item.id);
                                          }}
                                        ></div>
                                        <h5
                                          class="card-title "
                                          style={{
                                            textTransform: "capitalize",
                                            position: "relative",
                                            textAlign: "start",
                                            paddingLeft: "9px",
                                            margin: "0px",
                                            fontSize: "13px",
                                            position: "relative",
                                            top: "4px",
                                          }}
                                        >
                                          {item.first_name}&nbsp;
                                          {item.last_name}{" "}
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              color: "#8d9091",
                                            }}
                                          >
                                            ({item.address_type})
                                          </span>
                                          {/* <span className="hello" style={{position:'absolute',top:'-1px',right:"0px"}}>

                            </span> */}
                                        </h5>
                                      </div>
                                      <p
                                        className="small-text"
                                        style={{
                                          textAlign: "start",
                                          paddingBottom: "6px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        <span style={{ textWrap: "wrap" }}>
                                          {item.line1}
                                        </span>
                                        &nbsp;
                                        {item.line2}
                                        &nbsp;
                                        {item.country_printable_name}
                                        &nbsp;
                                        {item.state}
                                        &nbsp;
                                        {item.line4}-{item.postcode}
                                      </p>
                                      <div
                                        class="form-check"
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        {/* {item.id == shippingid ? <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={()=>{setshippadd(item.id)}}
                                              checked
                                            /> : <input
                                            type="radio"
                                            name="flexRadioDefault"
                                            style={{position:'absolute',top:'9px',left:'5px'}}
                                            onChange={()=>{setshippadd(item.id)}}
                                          />}  */}

                                        <label
                                          class="form-check-label mb-0"
                                          for="flexRadioDefault1"
                                          style={{ textAlign: "start" }}
                                        >
                                          <h5
                                            className="number"
                                            style={{
                                              paddingBottom: "4px",
                                              textAlign: "start",
                                              fontSize: "13px",
                                            }}
                                          >
                                            Mobile:{" "}
                                            <span
                                              className="number2"
                                              style={{
                                                textTransform: "none",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {item.phone_number}
                                            </span>
                                          </h5>
                                          {/* <h5 className="number acounttitle">
                                              Email ID:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.email}
                                              </span>
                                            </h5> */}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )
                        )}
                        {addressdata.map((item, index) =>
                          slectaddressid != item.id ? (
                            <>
                              {item.id != shippingid ? (
                                <div
                                  class="col-lg-12"
                                  style={{
                                    marginBottom: "9px",
                                  }}
                                >
                                  <div class="card pt-1">
                                    <div class="card-body p-0">
                                      <div
                                        class="form-check"
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="flexRadioDefault"
                                          style={{
                                            position: "absolute",
                                            top: "9px",
                                            left: "7px",
                                          }}
                                          // onChange={()=>{setshippadd(item.id)}}
                                          onClick={(e) =>
                                            selectaddress(item.id)
                                          }
                                        />
                                        <h5
                                          class="card-title "
                                          style={{
                                            textTransform: "capitalize",
                                            position: "relative",
                                            textAlign: "start",
                                            paddingLeft: "9px",
                                            margin: "0px",
                                            fontSize: "13px",
                                            position: "relative",
                                            top: "4px",
                                          }}
                                        >
                                          {item.first_name}&nbsp;
                                          {item.last_name}{" "}
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              color: "#8d9091",
                                            }}
                                          >
                                            ({item.address_type})
                                          </span>
                                          {/* <span className="hello" style={{position:'absolute',top:'-1px',right:"0px"}}>

                            </span> */}
                                        </h5>
                                      </div>
                                      <p
                                        className="small-text"
                                        style={{
                                          textAlign: "start",
                                          paddingBottom: "6px",
                                          fontSize: "13px",
                                        }}
                                      >
                                        <span style={{ textWrap: "balance" }}>
                                          {item.line1}
                                        </span>
                                        &nbsp;
                                        {item.line2}
                                        &nbsp;
                                        {item.country_printable_name}
                                        &nbsp;
                                        {item.state}
                                        &nbsp;
                                        {item.line4}-{item.postcode}
                                      </p>
                                      <div
                                        class="form-check"
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        {/* {item.id == shippingid ? <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{position:'absolute',top:'9px',left:'5px'}}
                                              onChange={()=>{setshippadd(item.id)}}
                                              checked
                                            /> : <input
                                            type="radio"
                                            name="flexRadioDefault"
                                            style={{position:'absolute',top:'9px',left:'5px'}}
                                            onChange={()=>{setshippadd(item.id)}}
                                          />}  */}

                                        <label
                                          class="form-check-label mb-0"
                                          for="flexRadioDefault1"
                                          style={{ textAlign: "start" }}
                                        >
                                          <h5
                                            className="number"
                                            style={{
                                              paddingBottom: "4px",
                                              textAlign: "start",
                                              fontSize: "13px",
                                            }}
                                          >
                                            Mobile:{" "}
                                            <span
                                              className="number2"
                                              style={{
                                                textTransform: "none",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {item.phone_number}
                                            </span>
                                          </h5>
                                          {/* <h5 className="number acounttitle">
                                              Email ID:{" "}
                                              <span className="number2" style={{textTransform:'none',fontSize:'13px'}}>
                                                {item.email}
                                              </span>
                                            </h5> */}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </div>
                    <div className="firstrow px-4 pt-1 d-flex justify-content-between">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "12px",
                          paddingLeft: "0px",
                          alignSelf: "center",
                        }}
                      >
                        {/* Select Address */}
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                          // width:'50%'
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#fff",
                            padding: "3px 3px",
                            color: "#230BB3",
                            width: "fit-content",
                            fontWeight: 600,
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaladdressone2"
                          // onClick={()=>{handleClick()}}
                        >
                          +Add
                        </button>
                        <button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#fff",
                            padding: "3px 3px",
                            color: "#230BB3",
                            width: "fit-content",
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            editselectaddress();
                          }}
                        >
                          <FaEdit
                            style={{
                              fontSize: "10px",
                              position: "relative",
                              top: "-1px",
                              left: "2px",
                            }}
                          />{" "}
                          Edit
                        </button>
                      </span>
                    </div>
                    {/* <div
                      className="firstrow px-4 d-flex justify-content-between"
                      style={{ padding: "5px 0px", paddingLeft: "20px" }}
                    >
                      {data[0]?.product__title && outofstock == false ? (
                        inprocess == true &&
                        vouchervalid == true &&
                        shippingerror == null ? (
                          cartinfo.basic_amount_radeemable == 0 ||
                          cartinfo.basic_amount_radeemable >
                            totalvocuerprice ? (
                            <div onClick={()=>{gopaypage()}} style={{ width: "100%" }}>
                              <button
                                style={{
                                  border: "none",
                                  outline: "none",
                                  backgroundColor: "#230BB3",
                                  padding: "7px 0px",
                                  color: "white",
                                  width: "100%",
                                  fontWeight: 600,
                                }}
                              >
                                CHECKOUT
                              </button>
                            </div>
                          ) : (
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                backgroundColor: "#230BB3",
                                padding: "7px 0px",
                                color: "white",
                                width: "100%",
                                fontWeight: 600,
                              }}
                              data-bs-toggle="modal"
                              data-bs-target={
                                totalvocuerprice -
                                  cartinfo.basic_amount_radeemable >
                                0
                                  ? "#exampleModa39098"
                                  : ""
                              }
                            >
                              CHECKOUT
                            </button>
                          )
                        ) : (
                          <button
                            style={{
                              border: "none",
                              outline: "none",
                              backgroundColor: "#230BB3",
                              padding: "7px 0px",
                              color: "white",
                              width: "100%",
                              fontWeight: 600,
                            }}
                          >
                            CHECKOUT
                          </button>
                        )
                      ) : (
                        <button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#230BB3",
                            padding: "7px 0px",
                            color: "white",
                            width: "100%",
                            fontWeight: 600,
                          }}
                        >
                          CHECKOUT
                        </button>
                      )}
                    </div> */}
                  </div>

                  <div
                    className="py-2 px-2"
                    style={{ background: "#ffff", borderRadius: 6 }}
                  >
                    <h4
                      style={{
                        padding: "0px 14px",
                        fontWeight: 700,
                        fontSize: "22px",
                      }}
                    >
                      Payment Summary
                    </h4>
                    <div
                      className="firstrow px-3 d-flex justify-content-between"
                      style={{ padding: "5px 0px" }}
                    >
                      <span
                        className="family"
                        style={{ fontWeight: 600, fontSize: "12px" }}
                      >
                        {voucherlist2[0]
                          ? `Basic Amount (${qty_total} Item(s))`
                          : `Cart Value ${qty_total} Item(s)`}
                        {/* {data.length} Items */}
                      </span>
                      <span
                        className="family"
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                        }}
                      >
                        {/* {console.log("jsyut checking for ",totalmrp)} */}₹
                        {console.log(
                          "check value item",
                          totalmrp,
                          "2",
                          totalitemvalue,
                          cartinfo.basic_amount_radeemable,
                          totalmrp
                        )}
                        {/* {voucherlist2[0] ? voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Shopping Voucher - Inclusive" ? totalitemvalue == 0 ? 0 : totalmrp : cartinfo.basic_amount_radeemable : totalitemvalue == 0 ? 0 : totalmrp} */}
                        {voucherlist2[0]
                          ? voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Inclusive" ||
                            voucherlist2[0]?.voucher_type ==
                              "Shopping Voucher - Inclusive"
                            ? (taxable_total).toFixed(0)
                            : (taxable_total).toFixed(0)
                          : totalitemvalue == 0
                          ? 0
                          : totalmrp}
                        {/* ₹{cartinfo.total_incl_tax_excl_discounts} */}
                      </span>
                    </div>
                    {/* <div
                      className="firstrow px-3 d-flex justify-content-between"
                      style={{ padding: "5px 0px" }}
                    >
                      <span
                        className="family"
                        style={{ fontWeight: 600, fontSize: "12px" }}
                      >
                        Discount
                      </span>
                      <span
                        className="family"
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                        }}
                      >
                        {discountval == 0 ? `₹0` : `-₹${discountval}`}
                      </span>
                    </div> */}
                    {voucherlist2[0] ? (
                      // cartinfo.balance_payable > 0 ? (
                      <div
                        className="firstrow px-3 d-flex justify-content-between"
                        style={{ padding: "5px 0px" }}
                      >
                        <span
                          className="family"
                          style={{ fontWeight: 600, fontSize: "12px" }}
                        >
                          GST Amount
                          {/* (Payable) */}
                        </span>
                        <span
                          className="family"
                          style={{
                            fontWeight: 500,
                            color: "#230BB3",
                            fontSize: "12px",
                          }}
                        >
                          {cartinfo.total_tax > 0
                            ? `${isshoppingvoucher[0]?.code ? "+" : "+"}₹${
                                cartinfo.total_tax
                              }`
                            : `₹0`}
                        </span>
                      </div>
                    ) : (
                      // ) : (
                      //   ""
                      // )
                      ""
                    )}

                    <div
                      className="firstrow px-3 d-flex justify-content-between"
                      style={{ padding: "5px 0px" }}
                    >
                      <span
                        className="family"
                        style={{ fontWeight: 600, fontSize: "12px" }}
                      >
                        Shipping Charges
                        {/* <span style={{fontSize: "9px",color: '#230bb3'}}> (Shipping Charge Applicable)</span> */}
                        {/* <br /> */}
                      </span>
                      <span
                        className="family"
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                        }}
                      >
                        {totalitemvalue == 0
                          ? `₹0`
                          : total_shipping_charge_basketview == null
                          ? shipping_price > 0
                            ? `+₹${shipping_price}`
                            : `₹${shipping_price}`
                          : total_shipping_charge_basketview > 0
                          ? `+₹${total_shipping_charge_basketview}`
                          : `₹${total_shipping_charge_basketview}`}
                      </span>
                    </div>
                    <div
                      className="firstrow px-3 "
                      style={{
                        padding: voucherlist2?.[0]?.voucher_type ==
                        "Gift Voucher - Exclusive test" || voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Inclusive test"
                        ? "0px" : "5px 0px" ,
                        display: voucherlist2[0] ? "flex" : "none",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "100%" }}></div>
                      <div className="justify-content-between" style={{display: voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Exclusive test" || voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Inclusive test"
                          ? "none" : 'flex' }}>
                        <span
                          className="family"
                          style={{ fontWeight: 600, fontSize: "12px" }}
                        >
                          Total Amount
                       
                        </span>
                        <span
                          className="family"
                          style={{
                            fontWeight: 500,
                            color: "#230BB3",
                            fontSize: "12px",
                          }}
                        >
                          ₹
                          {(totalitemvalue == 0
                            ? 0
                            : total_shipping_charge_basketview == null
                            ? shipping_price > 0
                              ? shipping_price
                              : shipping_price
                            : total_shipping_charge_basketview > 0
                            ? total_shipping_charge_basketview
                            : total_shipping_charge_basketview) +
                            (voucherlist2[0]
                              ? voucherlist2[0]?.voucher_type ==
                                  "Gift Voucher - Inclusive" ||
                                voucherlist2[0]?.voucher_type ==
                                  "Shopping Voucher - Inclusive"
                                ? 0
                                : cartinfo.total_tax
                              : 0) +
                            (voucherlist2[0]
                              ? voucherlist2[0]?.voucher_type ==
                                  "Gift Voucher - Inclusive" ||
                                voucherlist2[0]?.voucher_type ==
                                  "Shopping Voucher - Inclusive"
                                ? totalitemvalue == 0
                                  ? 0
                                  : parseFloat(totalmrp)
                                : parseFloat(cartinfo.basic_amount_radeemable)
                              : totalitemvalue == 0
                              ? 0
                              : parseFloat(totalmrp))}
                        </span>
                      </div>
                    </div>





                    
                    {data.length > 0 ? (
                      voucherlist2[0]?.offer ? ( voucherlist2[0]?.voucher_type ==
                        "Gift Voucher - Exclusive off" || voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Inclusive off"
                        ? ""  : <div
                          className="firstrow px-3 d-flex justify-content-between"
                          style={{ padding: "5px 0px" }}
                        >
                          <span
                            className="family"
                            style={{ fontWeight: 600, fontSize: "12px" }}
                          >
                            {voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Inclusive" ||
                            voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Exclusive"
                              ? "Voucher Discount"
                              : "Voucher Amount"}
                          </span>
                          <span
                            className="family"
                            style={{
                              fontWeight: 500,
                              color: "#230BB3",
                              fontSize: "12px",
                            }}
                          >
                            {/* -₹{cartinfo.offer_tootal_amount} */}
                            {`-₹${parseInt(offerSum)}`}
                          </span>
                        </div>) : (
                        ""
                      )
                    ) : (
                      ""
                    )}




                    <div className="firstrow px-3 pt-1 d-flex justify-content-between">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "12px",
                          paddingLeft: "0px",
                        }}
                      >
                        {/* Payable{" "} */}

                        {voucherlist2[0]?.voucher_type ==
                          "Gift Voucher - Inclusive" ||
                        voucherlist2[0]?.voucher_type ==
                          "Gift Voucher - Exclusive"
                          ? "Amount Payable"
                          : "Balance Payable"}

                        {/* <span
                          style={{
                            fontSize: "10px",
                            color: "#8F9091",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setshowtax(!showtax);
                          }}
                        >
                          (incl. taxes){" "}
                          <span style={{ fontSize: "13px" }}>
                            {showtax == true ? "-" : "+"}
                          </span>
                          <span
                            style={{
                              fontSize: 10,
                              color: "#8F9091",
                              textAlign: "end",
                              display: showtax == true ? "inline" : "none",
                            }}
                            id="span2"
                          > : ₹
                            {cartinfo.total_tax ? cartinfo.total_tax : 0}
                          </span>
                        </span> */}
                      </span>
                   <span
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                          display:voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Exclusive34"
                          ? "none" : ''
                        }}
                      >
                        ₹
                        
                        {/* {console.log("this is true here", totalitemvalue)} */}
    
                        {voucherlist2[0]
                          ? voucherlist2[0]?.shipping_incl == true &&
                            (totalitemvalue == 0
                              ? 0
                              : total_shipping_charge_basketview == null
                              ? shipping_price > 0
                                ? shipping_price
                                : shipping_price
                              : total_shipping_charge_basketview > 0
                              ? total_shipping_charge_basketview
                              : total_shipping_charge_basketview) +
                              cartinfo.basic_amount_radeemable +
                              (voucherlist2[0]?.voucher_type ==
                                "Shopping Voucher - Exclusive" ||
                              voucherlist2[0]?.voucher_type ==
                                "Gift Voucher - Exclusive"
                                ? 0
                                : 0) >
                              offerSum
                            ? ""
                            : cartinfo.balance_payable
                            ? Number(
                                parseFloat(cartinfo.balance_payable) +
                                  (voucherlist2[0]?.shipping_incl
                                    ? 0
                                    : total_shipping_charge_basketview == null
                                    ? parseFloat(shipping_price)
                                    : parseFloat(
                                        total_shipping_charge_basketview
                                      ))
                              ).toFixed(0)
                            : voucherlist2[0]?.shipping_incl
                            ? 0
                            : total_shipping_charge_basketview == null
                            ? parseFloat(shipping_price).toFixed(0)
                            : parseFloat(
                                total_shipping_charge_basketview
                              ).toFixed(0)
                          : cartinfo.balance_payable
                          ? Number(
                              parseFloat(cartinfo.balance_payable) +
                                (voucherlist2[0]?.shipping_incl
                                  ? 0
                                  : total_shipping_charge_basketview == null
                                  ? parseFloat(shipping_price)
                                  : parseFloat(
                                      total_shipping_charge_basketview
                                    ))
                            ).toFixed(0)
                          : voucherlist2[0]?.shipping_incl
                          ? 0
                          : total_shipping_charge_basketview == null
                          ? parseFloat(shipping_price).toFixed(0)
                          : parseFloat(
                              total_shipping_charge_basketview
                            ).toFixed(0)}

                            
                            
                        {voucherlist2[0]
                          ? voucherlist2[0]?.shipping_incl == true &&
                            (totalitemvalue == 0
                              ? 0
                              : total_shipping_charge_basketview == null
                              ? shipping_price > 0
                                ? shipping_price
                                : shipping_price
                              : total_shipping_charge_basketview > 0
                              ? total_shipping_charge_basketview
                              : total_shipping_charge_basketview) +
                              cartinfo.basic_amount_radeemable +
                              (voucherlist2[0]?.voucher_type ==
                                "Shopping Voucher - Exclusive" ||
                              voucherlist2[0]?.voucher_type ==
                                "Gift Voucher - Exclusive"
                                ? 0
                                : 0) >
                              offerSum
                            ? (totalitemvalue == 0
                                ? 0
                                : total_shipping_charge_basketview == null
                                ? shipping_price > 0
                                  ? shipping_price
                                  : shipping_price
                                : total_shipping_charge_basketview > 0
                                ? total_shipping_charge_basketview
                                : total_shipping_charge_basketview) +
                              cartinfo.basic_amount_radeemable +
                              (voucherlist2[0]?.voucher_type ==
                                "Shopping Voucher - Exclusive" ||
                              voucherlist2[0]?.voucher_type ==
                                "Gift Voucher - Exclusive"
                                ? 0
                                : 0) -
                              offerSum + (voucherlist2[0]?.voucher_type ==
                                "Shopping Voucher - Exclusive" ||
                              voucherlist2[0]?.voucher_type ==
                                "Gift Voucher - Exclusive"
                                ? cartinfo.total_tax
                                : 0)
                            : ""
                          : ""}

<br/>
</span>

<span
                        style={{
                          fontWeight: 500,
                          color: "#230BB3",
                          fontSize: "12px",
                          display:voucherlist2?.[0]?.voucher_type ==
                          "Gift Voucher - Exclusive34"
                          ? "" : 'none'
                        }}
                      >


₹{(totalitemvalue == 0
                            ? 0
                            : total_shipping_charge_basketview == null
                            ? shipping_price > 0
                              ? shipping_price
                              : shipping_price
                            : total_shipping_charge_basketview > 0
                            ? total_shipping_charge_basketview
                            : total_shipping_charge_basketview) +
                            (voucherlist2[0]
                              ? voucherlist2[0]?.voucher_type ==
                                  "Gift Voucher - Inclusive" ||
                                voucherlist2[0]?.voucher_type ==
                                  "Shopping Voucher - Inclusive"
                                ? 0
                                : cartinfo.total_tax
                              : 0) +
                            (voucherlist2[0]
                              ? voucherlist2[0]?.voucher_type ==
                                  "Gift Voucher - Inclusive" ||
                                voucherlist2[0]?.voucher_type ==
                                  "Shopping Voucher - Inclusive"
                                ? totalitemvalue == 0
                                  ? 0
                                  : parseInt(totalmrp)
                                : cartinfo.basic_amount_radeemable
                              : totalitemvalue == 0
                              ? 0
                              : parseInt(totalmrp))}


                      </span>




                    </div>

                 
                    <div
                      className="firstrow px-3 d-flex justify-content-between"
                      style={{ padding: "5px 0px", paddingLeft: "20px" }}
                    >
                      {data[0]?.product__title && outofstock == false ? (
                        inprocess == true &&
                        vouchervalid == true &&
                        shippingerror == null ? (
                          // cartinfo.basic_amount_radeemable == 0 ||
                          cartinfo.basic_amount_radeemable +
                            (voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Exclusive3" || voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Inclusive3" ? 0 : voucherlist2[0]?.shipping_incl == false
                              ? 0
                              : totalitemvalue == 0
                              ? 0
                              : total_shipping_charge_basketview == null
                              ? shipping_price > 0
                                ? shipping_price
                                : shipping_price
                              : total_shipping_charge_basketview > 0
                              ? total_shipping_charge_basketview
                              : total_shipping_charge_basketview) +
                            (voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Exclusive3" || voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Inclusive3" ? 0 : voucherlist2[0]?.voucher_type ==
                              "Shopping Voucher - Exclusive" ||
                            voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Exclusive"
                              ? cartinfo.total_tax
                              : 0) >
                            (voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Exclusive2" || voucherlist2[0]?.voucher_type ==
                              "Gift Voucher - Inclusive2" ? voucherlist2?.[0]?.offer_type  != "Coupon" ? 0 : totalvocuerprice : totalvocuerprice) ? (
                            <div
                              onClick={() => {
                                gopaypage();
                              }}
                              style={{ width: "100%" }}
                            >
                              <button
                                style={{
                                  border: "none",
                                  outline: "none",
                                  backgroundColor: "#230BB3",
                                  padding: "7px 0px",
                                  color: "white",
                                  width: "100%",
                                  fontWeight: 600,
                                }}
                              >
                                CHECKOUT
                              </button>
                            </div>
                          ) : (
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                backgroundColor: "#230BB3",
                                padding: "7px 0px",
                                color: "white",
                                width: "100%",
                                fontWeight: 600,
                              }}
                              data-bs-toggle="modal"
                              data-bs-target={
                                totalvocuerprice -
                                  cartinfo.basic_amount_radeemable >
                                0
                                  ? "#exampleModa39098"
                                  : ""
                              }
                            >
                              CHECKOUT
                            </button>
                          )
                        ) : (
                          <button
                            style={{
                              border: "none",
                              outline: "none",
                              backgroundColor: "#230BB3",
                              padding: "7px 0px",
                              color: "white",
                              width: "100%",
                              fontWeight: 600,
                            }}
                          >
                            CHECKOUT
                          </button>
                        )
                      ) : (
                        <button
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "#230BB3",
                            padding: "7px 0px",
                            color: "white",
                            width: "100%",
                            fontWeight: 600,
                          }}
                        >
                          CHECKOUT
                        </button>
                      )}
                    </div>

                    <div
                      className="firstrow px-3 d-flex justify-content-between"
                      style={{ padding: "5px 0px", paddingLeft: "20px" }}
                    >
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "500",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {vouchererror == "This coupon has already been used" ? "This Voucher has already been used" : vouchererror}
                      </span>
                    </div>
                  </div>

                  <div
                    className="py-2"
                    style={{
                      background: "#ffff",
                      borderRadius: 6,
                      marginTop: 10,
                      display:
                        tokenvalue.msg == "Voucher login" ? "none" : "none",
                    }}
                  >
                    <div
                      className="firstrow px-4 d-flex justify-content-between"
                      style={{ padding: "5px 0px", paddingLeft: "20px" }}
                    >
                      <h4
                        style={{
                          // padding: "4px 9px",
                          fontWeight: 700,
                          fontSize: "18px",
                        }}
                      >
                        {tokenvalue.msg == "Voucher login"
                          ? "Add Voucher"
                          : "Apply Credit"}
                      </h4>
                    </div>
                    <div
                      className="firstrow px-4 justify-content-between"
                      style={{
                        padding: "5px 0px",
                        display:
                          tokenvalue.msg == "Voucher login" ? "none" : "flex",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: 12,
                          alignSelf: "center",
                        }}
                      >
                        Apply Credits
                      </span>
                      <div style={{ display: "flex", gap: 4 }}>
                        <span style={{ fontWeight: 700, color: "#230BB3" }}>
                          <input
                            type="text"
                            value={creditvalue}
                            className="form-control applypay"
                            style={{ borderRadius: "0px" }}
                            placeholder="Enter your credit"
                            onChange={(e) => {
                              setcreditvalue(e.target.value);
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            fontWeight: 500,
                            // backgroundColor: "#2b2a29",
                            backgroundColor: "#230BB3",
                            color: "white",
                            fontSize: 12,
                            height: "fit-content",
                            padding: "7px 8px",
                            alignSelf: "center",
                            borderRadius: "0px",
                          }}
                          onClick={() => {
                            applycredit(creditvalue);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          tokenvalue.msg == "Voucher login" ? "none" : "flex",
                      }}
                      className="firstrow px-4 pb-2 justify-content-between"
                    >
                      <span style={{ fontWeight: 600, fontSize: "17px" }} />
                      <span style={{ fontWeight: 500, fontSize: 11 }}>
                        Available Balance - ₹{totalcredit}
                      </span>
                    </div>
                    <div className="firstrow px-2 pb-2 d-flex justify-content-between">
                      {/* {console.log("ddjdjdjcmcmcmc", shippingerror)} */}
                      <p
                        style={{
                          color: "red",
                          display: data[0]?.product__title ? "block" : "none",
                        }}
                      >
                        {shippingerror == null ? "" : shippingerror}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            className="modal fade"
            id="voucherdesc"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                <div
                  class="modal-header"
                  style={{ border: "0px", padding: "0px", height: "30px" }}
                >
                  {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Add Address</h1> */}
                  <button
                    type="button"
                    class="btn-close"
                    style={{ position: "relative", top: "8px", left: "-17px" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
                {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                <div className="modal-header mod-line d-none pt-0"></div>
                <div className="modal-body">
                  <div className="row gy-3 mt-1">
                    {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                    <h4
                      style={{
                        marginTop: "0px",
                        color: "rgb(35, 11, 179)",
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "800",
                      }}
                    >
                      Voucher Info
                    </h4>
                    <p
                      className="ccedit-p "
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      {/* {voucherdesc} */}
                   {/* {voucherdesc == '' ? '' : <div dangerouslySetInnerHTML={{ __html: voucherdesc }} /> }   */}
                   <ul style={{listStyleType:"disc",fontSize:'14px',padding:'0px 10px'}}>
                      {voucherdesc ? voucherdesc.map((item,index)=>(
                        <li style={{padding:'4px 0px',display:'list-item'}}>{item}</li>
                      )) : ''}
                    </ul>
{/* <ul style={{listStyleType: "disc",
    fontSize: '14px', padding: '0px 10px'}}>
  <li style={{padding: "4px 0px"}}>This voucher can be redeemed on www.oneupbrands.com.</li>
  <li style={{padding: "4px 0px"}}>This voucher cannot be redeemed for cash.</li>
  <li style={{padding: "4px 0px"}}>This voucher cannot be redeemed in parts and has to be used in a single order; Any unutilised value will lapse.</li>
  <li style={{padding: "4px 0px"}}>This voucher can be used to login for a maximum of 3 times. If a purchase is not made within these attempts, the voucher becomes inactive.</li>
  <li style={{padding: "4px 0px"}}>One Up Trade is not responsible for unauthorised use of lost/misplaced vouchers</li>
  <li style={{padding: "4px 0px"}}>This voucher is Valid Upto 11:59:59 PM of [DATE]</li>
  <li style={{padding: "4px 0px"}}>This voucher is clubbable, i.e, more than one (clubbable)voucher can be applied on order value of up to Rs. [Clubbable Upto].</li>
  <li style={{padding: "4px 0px"}}>This voucher is not inclusive of taxes; GST is payable separately.</li>
  <li style={{padding: "4px 0px"}}>Shipping charges are payable separately.</li> */}
{/* </ul> */}
                    </p>
                  </div>
                </div>
                {/* <div
                  className="modal-footer mod-line m-auto"
                  style={{ border: "none" }}
                >
                  <button
                    type="button"
                    className="btn closebtn "
                    style={{
                      paddingRight: "20px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#230BB3",
                    }}
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn closebtn profile-edit-btn justhoverwh"
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                    // onClick={() => {
                    //   Removeproduct(delitem);
                    // }}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Remove
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div
            style={{
              overflow: "hidden",
              width: "5%",
              position: "relative",
            }}
          >
            <button
              ref={openalert}
              type="button"
              style={{
                fontSize: "12px",
                position: "absolute",
                left: "400px",
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal140000340"
              class="dropdown-item"
            >
              {/* sss */}
            </button>
          </div>
          {/* <button onClick={()=>{alertb()}}>auto</button> */}
          <div
            className="modal fade"
            id="exampleModal140000340"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <div className="modal-content centercontentofalert">
                {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
                {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                <div className="modal-header mod-line d-none"></div>
                <div className="modal-body" style={{ padding: "0px" }}>
                  <div className="row">
                    {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}

                    <p
                      className="ccedit-p "
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        backgroundColor: "#230bb3",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      {qtymsg}
                    </p>
                  </div>
                </div>
                <div
                  className="modal-footer mod-line m-auto"
                  style={{
                    border: "none",
                    position: "absolute",
                    left: "389px",
                  }}
                >
                  {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                  <button
                    type="button"
                    className="btn closebtn "
                    style={{
                      paddingRight: "20px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#230BB3",
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeref}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal13400"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
                {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                <div className="modal-header mod-line d-none"></div>
                <div className="modal-body">
                  <div className="row gy-3 mt-2">
                    {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                    <h4
                      style={{
                        color: "rgb(35, 11, 179)",
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "800",
                      }}
                    >
                      Cart Alert
                    </h4>
                    <p
                      className="ccedit-p "
                      style={{ textAlign: "center", fontSize: "13px" }}
                    >
                      Are you sure you want to remove this Item ?
                    </p>
                  </div>
                </div>
                <div
                  className="modal-footer mod-line m-auto"
                  style={{ border: "none" }}
                >
                  {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                  <button
                    type="button"
                    className="btn closebtn "
                    style={{
                      paddingRight: "20px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#230BB3",
                    }}
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn closebtn profile-edit-btn justhoverwh"
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      Removeproduct(delitem);
                    }}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*section end*/}

          {/* <Footer /> */}

          <div
            className="modal fade"
            id="exampleModa39098"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered ">
              <div className="modal-content">
                {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
                {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                <div className="modal-header mod-line d-none"></div>
                <div className="modal-body">
                  <div className="row gy-3 mt-2">
                    {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                    <h4
                      style={{
                        color: "rgb(35, 11, 179)",
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "800",
                      }}
                    >
                      Voucher Alert
                    </h4>
                    {/* {console.log("first",totalvocuerprice - cartinfo.basic_amount_radeemable)} */}
                    <p
                      className="ccedit-p "
                      style={{ textAlign: "center", fontSize: "13px" }}
                    >
                      {/* {console.log("checkout is here",totalvocuerprice,'dddddd',cartinfo.basic_amount_radeemable ,"ggg",(voucherlist2[0]?.shipping_incl == false ? 0 : totalitemvalue == 0
                          ? 0 : total_shipping_charge_basketview == null
                          ? shipping_price > 0 ? shipping_price : shipping_price
                          : total_shipping_charge_basketview > 0 ? total_shipping_charge_basketview : total_shipping_charge_basketview),(voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? 0 : cartinfo.total_tax))} */}
                      {voucherlist2[0]?.voucher_type ==
                        "Gift Voucher - Inclusive" ||
                      voucherlist2[0]?.voucher_type ==
                        "Gift Voucher - Exclusive"
                        ? "Cart Amount Must be Higher Than Voucher Value"
                        : voucherlist2[0]?.voucher_type ==
                            "Shopping Voucher - Inclusive" &&
                          voucherlist2[0]?.shipping_incl == true
                        ? "Cart Amount Must be Higher Than Voucher Value"
                        : `Are you sure you want to Checkout you still have ₹
                    
                    ${(
                      totalvocuerprice -
                      (cartinfo.basic_amount_radeemable +
                        (voucherlist2[0]?.shipping_incl == false
                          ? 0
                          : totalitemvalue == 0
                          ? 0
                          : total_shipping_charge_basketview == null
                          ? shipping_price > 0
                            ? shipping_price
                            : shipping_price
                          : total_shipping_charge_basketview > 0
                          ? total_shipping_charge_basketview
                          : total_shipping_charge_basketview))
                    )
                      // totalvocuerprice - {cartinfo.basic_amount_radeemable}
                      .toFixed(0)}
                      in your voucher ?`}
                      {/* + (voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? 0 : cartinfo.total_tax) */}
                    </p>
                  </div>
                </div>
                <div
                  className="modal-footer mod-line m-auto"
                  style={{ border: "none" }}
                >
                  {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                  <button
                    type="button"
                    className="btn closebtn "
                    style={{
                      paddingRight: "20px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#230BB3",
                    }}
                    data-bs-dismiss="modal"
                    // aria-label="Close"
                  >
                    {voucherlist2[0]?.voucher_type ==
                      "Gift Voucher - Inclusive" ||
                    voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive"
                      ? "Close"
                      : voucherlist2[0]?.voucher_type ==
                          "Shopping Voucher - Inclusive" &&
                        voucherlist2[0]?.shipping_charge == true
                      ? "Close"
                      : "Cancel"}
                  </button>
                  {voucherlist2[0]?.voucher_type !==
                    "Gift Voucher - Inclusive" &&
                  voucherlist2[0]?.voucher_type !==
                    "Gift Voucher - Exclusive" ? (
                    voucherlist2[0]?.voucher_type ==
                      "Shopping Voucher - Inclusive" &&
                    voucherlist2[0]?.shipping_incl == true ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="btn closebtn profile-edit-btn justhoverwh"
                        data-bs-dismiss="modal"
                        // aria-label="Close"
                        // onClick={() => {
                        //   nvg("/checkout");
                        // }}
                        onClick={() => {
                          gopaypage();
                        }}
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Checkout
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModaladdressone2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ maxWidth: "770px" }}>
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Add Address
                  </h1>
                  <button
                    type="button"
                    ref={closeaddressbtn}
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      mobile: "",
                      address1: "",
                      address2: "",
                      country: "",
                      countryurl: "",
                      state: "",
                      city: "",
                      pincode: "",
                      address_type: "HOME",
                    }}
                    validationSchema={checkoutvalidation}
                    onSubmit={(values, { setFieldError }) => {
                      createaddress(values, setFieldError);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                    }) => (
                      <Form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="row check-out ">
                          <div className="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                            <label className=" acounttitle field-label m-0">
                              Address Type
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <div className="d-flex" style={{ gap: "10px" }}>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="HOME"
                                    onChange={handleChange}
                                    checked={values.address_type == "HOME"}
                                  />
                                  HOME
                                </label>
                              </div>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="OFFICE"
                                    onChange={handleChange}
                                    checked={values.address_type == "OFFICE"}
                                  />
                                  OFFICE
                                </label>
                              </div>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="OTHER"
                                    onChange={handleChange}
                                    checked={values.address_type == "OTHER"}
                                  />
                                  OTHER
                                </label>
                              </div>
                            </div>
                            <div className="error">
                              {errors.address_type && touched.address_type ? (
                                <p style={{ color: "red" }}>
                                  {errors.address_type}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              First Name{" "}
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='First Name' /> */}

                            <Field
                              type="text"
                              name="first_name"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your First Name"
                              value={values?.first_name}
                            />
                            <div className="error">
                              {errors.first_name && touched.first_name ? (
                                <p style={{ color: "red" }}>
                                  {errors.first_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              Last Name
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                            <Field
                              name="last_name"
                              type="text"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Last Name"
                              value={values?.last_name}
                            />
                            <div className="error">
                              {errors.last_name && touched.last_name ? (
                                <p style={{ color: "red" }}>
                                  {errors.last_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Phone
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                            <Field
                              name="mobile"
                              type="tel"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your Mobile. No"
                              value={values?.mobile}
                              onChange={(e) => {
                                setFieldValue(
                                  "mobile",
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />
                            <div className="error">
                              {errors.mobile && touched.mobile ? (
                                <p style={{ color: "red" }}>{errors.mobile}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Email Address
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Email'/> */}
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Email Address"
                              value={values?.email}
                            />
                            <div className="error">
                              {errors.email && touched.email ? (
                                <p style={{ color: "red" }}>{errors.email}</p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Address 1
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="address1"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="House number and street name"
                              value={values.address1}
                            />
                            <div className="error">
                              {errors.address1 && touched.address1 ? (
                                <p style={{ color: "red" }}>
                                  {errors.address1}
                                </p>
                              ) : null}
                            </div>
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Address 2
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="address2"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control mt-2"
                              placeholder="Apartment, suite, unit etc."
                              value={values.address2}
                            />
                            <div className="error">
                              {errors.address2 && touched.address2 ? (
                                <p style={{ color: "red" }}>
                                  {errors.address2}
                                </p>
                              ) : null}
                            </div>
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>

                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12">
                            <label className=" acounttitle field-label m-0">
                              Postal Code
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                            <Field
                              name="pincode"
                              type="tel"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your Pincode"
                              value={values.pincode}
                              minLength={6}
                              maxLength={6}
                              onChange={(e) => {
                                setFieldValue(
                                  "pincode",
                                  e.target.value.replace(/[^0-9\ ]/gi, "")
                                );
                                fetchstateandcity(
                                  e.target.value.replace(/[^0-9\ ]/gi, ""),
                                  setFieldError,
                                  setFieldValue
                                );
                              }}
                            />
                            <div className="error">
                              {errors.pincode && touched.pincode ? (
                                <p style={{ color: "red" }}>{errors.pincode}</p>
                              ) : null}
                            </div>
                          </div>

                          {values.state !== "" &&
                          values.state !== null &&
                          cityna == "NA" ? (
                            <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                              <label className="acounttitle field-label m-0">
                                City
                                <span
                                  style={{ color: "red", fontSize: "13px" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </label>
                              {/* <input type="text" name="field-name" placeholder="City" /> */}
                              <Field
                                name="city"
                                type="text"
                                style={{ fontSize: "12px" }}
                                className="form-control"
                                placeholder="Enter Your City"
                                value={values.city}
                              />
                              {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                              <div className="error">
                                {errors.city && touched.city ? (
                                  <p style={{ color: "red" }}>{errors.city}</p>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                              <label className="acounttitle field-label m-0">
                                City
                                <span
                                  style={{ color: "red", fontSize: "13px" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </label>
                              {/* <input type="text" name="field-name" placeholder="City" /> */}
                              <Field
                                name="city"
                                type="text"
                                style={{ fontSize: "12px" }}
                                className="form-control"
                                placeholder="Enter Your City"
                                readOnly
                                value={values.city}
                              />
                              {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                              <div className="error">
                                {errors.city && touched.city ? (
                                  <p style={{ color: "red" }}>{errors.city}</p>
                                ) : null}
                              </div>
                            </div>
                          )}

                          {/* {console.log("reeffghgttffddddcc vvvvvv nnnnnnnnnn
                    
                    nn",errors,"llkkkkkkkkjjjhhhhgggfffffdsssss",values)} */}
                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                            <label className="acounttitle field-label m-0">
                              State
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder="State" /> */}
                            <Field
                              name="state"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your State"
                              readOnly
                              value={values.state}
                            />
                            {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}
                            <div className="error">
                              {errors.state && touched.state ? (
                                <p style={{ color: "red" }}>{errors.state}</p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                            <label className="acounttitle field-label m-0">
                              Country
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="country"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Country"
                              readOnly
                              value={values.country}
                            />
                            <Field
                              name="countryurl"
                              type="hidden"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Country"
                              readOnly
                              value={values.countryurl}
                            />
                            <div className="error">
                              {errors.country && touched.country ? (
                                <p style={{ color: "red" }}>{errors.country}</p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div className="payment-box">
                              <div
                                className="text-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "4px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    fontSize: "12px",
                                    background: "#bdbfc0",
                                    color: "#000",
                                  }}
                                  className="btn-normal btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  ref={buttonRef}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div
                              class="alert alert-success"
                              role="alert"
                              style={{
                                display:
                                  addressstatus == true ? "block" : "none",
                              }}
                            >
                              Address Created Successfully
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="exampleModaladdressone3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ maxWidth: "785px" }}>
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Edit Address
                  </h1>
                  <button
                    type="button"
                    ref={editfromclose}
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  class="modal-body"
                  style={{ maxHeight: "90vh", overflowX: "hidden" }}
                >
                  <Formik
                    initialValues={{
                      first_name2: "",
                      last_name2: "",
                      email2: "",
                      mobile2: "",
                      address12: "",
                      address22: "",
                      country2: "",
                      countryurl2: "",
                      state2: "",
                      city2: "",
                      pincode2: "",
                      address_type: "",
                    }}
                    validationSchema={checkoutvalidation2}
                    onSubmit={(values, { setFieldError }) => {
                      // console.log("this is eirt form")
                      editddress(values, setFieldError);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                    }) => (
                      <Form
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        //  onSubmit={handleSubmit}
                      >
                        <button
                          type="button"
                          // data-bs-toggle="modal"
                          //             data-bs-target="#exampleModaladdressone3"
                          ref={editformopen}
                          onClick={() => {
                            seteditformvalue(setFieldValue);
                          }}
                          style={{ position: "absolute", left: "5000px" }}
                        ></button>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaladdressone3"
                          ref={editformopen2}
                          style={{ position: "absolute", left: "5000px" }}
                        ></button>
                        {/* {console.log("this is error/ from error edit msg",errors,"va;ies",values)} */}
                        <div className="row check-out ">
                          <div className="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                            <label
                              className=" field-label mb-2"
                              style={{ fontSize: "19px", fontWeight: "600" }}
                            >
                              Billing Address
                              {/* <span style={{color:'red',fontSize:'13px'}}> *</span> */}
                            </label>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                            <label className=" acounttitle field-label m-0">
                              Address Type
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                            {/* <Field
                        name="address_type"
                        as="select"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your Address Type"
                        value={values.address_type}
                       >
                      <option value="HOME" label="HOME" />
                      <option value="OFFICE" label="OFFICE" />
                      <option value="OTHER" label="OTHER" />
                    </Field> */}

                            <div className="d-flex" style={{ gap: "10px" }}>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="HOME"
                                    onChange={handleChange}
                                    checked={values.address_type == "HOME"}
                                  />
                                  HOME
                                </label>
                              </div>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="OFFICE"
                                    onChange={handleChange}
                                    checked={values.address_type == "OFFICE"}
                                  />
                                  OFFICE
                                </label>
                              </div>
                              <div>
                                <label
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="address_type"
                                    value="OTHER"
                                    onChange={handleChange}
                                    checked={values.address_type == "OTHER"}
                                  />
                                  OTHER
                                </label>
                              </div>
                            </div>
                            <div className="error">
                              {errors.address_type && touched.address_type ? (
                                <p style={{ color: "red" }}>
                                  {errors.address_type}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              First Name{" "}
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='First Name' /> */}

                            <Field
                              type="text"
                              name="first_name2"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your First Name"
                              value={values?.first_name2}
                            />
                            <div className="error">
                              {errors.first_name2 && touched.first_name2 ? (
                                <p style={{ color: "red" }}>
                                  {errors.first_name2}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              Last Name
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                            <Field
                              name="last_name2"
                              type="text"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Last Name"
                              value={values?.last_name2}
                            />
                            <div className="error">
                              {errors.last_name2 && touched.last_name2 ? (
                                <p style={{ color: "red" }}>
                                  {errors.last_name2}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Phone
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                            <Field
                              name="mobile2"
                              type="tel"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your Mobile. No"
                              value={values?.mobile2}
                              onChange={(e) => {
                                setFieldValue(
                                  "mobile2",
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />
                            <div className="error">
                              {errors.mobile2 && touched.mobile2 ? (
                                <p style={{ color: "red" }}>{errors.mobile2}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Email Address
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Email'/> */}
                            <Field
                              name="email2"
                              type="email"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Email Address"
                              value={values?.email2}
                            />
                            <div className="error">
                              {errors.email2 && touched.email2 ? (
                                <p style={{ color: "red" }}>{errors.email2}</p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Address 1
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="address12"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="House number and street name"
                              value={values.address12}
                            />
                            <div className="error">
                              {errors.address12 && touched.address12 ? (
                                <p style={{ color: "red" }}>
                                  {errors.address12}
                                </p>
                              ) : null}
                            </div>
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Address 2
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="address22"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control mt-2"
                              placeholder="Apartment, suite, unit etc."
                              value={values.address22}
                            />
                            <div className="error">
                              {errors.address22 && touched.address22 ? (
                                <p style={{ color: "red" }}>
                                  {errors.address22}
                                </p>
                              ) : null}
                            </div>
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>

                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12">
                            <label className=" acounttitle field-label m-0">
                              Postal Code
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                            <Field
                              name="pincode2"
                              type="tel"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your Pincode"
                              value={values.pincode2}
                              minLength={6}
                              maxLength={6}
                              onChange={(e) => {
                                setFieldValue(
                                  "pincode2",
                                  e.target.value.replace(/[^0-9\ ]/gi, "")
                                );
                                fetchstateandcity2(
                                  e.target.value.replace(/[^0-9\ ]/gi, ""),
                                  setFieldError,
                                  setFieldValue
                                );
                              }}
                            />
                            <div className="error">
                              {errors.pincode2 && touched.pincode2 ? (
                                <p style={{ color: "red" }}>
                                  {errors.pincode2}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          {values.state2 !== "" &&
                          values.state2 !== null &&
                          cityna == "NA" ? (
                            <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                              <label className="acounttitle field-label m-0">
                                City
                                <span
                                  style={{ color: "red", fontSize: "13px" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </label>
                              {/* <input type="text" name="field-name" placeholder="City" /> */}
                              <Field
                                name="city2"
                                type="text"
                                style={{ fontSize: "12px" }}
                                className="form-control"
                                placeholder="Enter Your City"
                                value={values.city2}
                              />
                              {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                              <div className="error">
                                {errors.city2 && touched.city2 ? (
                                  <p style={{ color: "red" }}>{errors.city2}</p>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                              <label className="acounttitle field-label m-0">
                                City
                                <span
                                  style={{ color: "red", fontSize: "13px" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </label>
                              {/* <input type="text" name="field-name" placeholder="City" /> */}
                              <Field
                                name="city2"
                                type="text"
                                style={{ fontSize: "12px" }}
                                className="form-control"
                                placeholder="Enter Your City"
                                readOnly
                                value={values.city2}
                              />
                              {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                              <div className="error">
                                {errors.city2 && touched.city2 ? (
                                  <p style={{ color: "red" }}>{errors.city2}</p>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {/* {console.log("reeffghgttffddddcc vvvvvv nnnnnnnnnn
                      
                      nn",errors,"llkkkkkkkkjjjhhhhgggfffffdsssss",values)} */}
                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                            <label className="acounttitle field-label m-0">
                              State
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder="State" /> */}
                            <Field
                              name="state2"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your State"
                              readOnly
                              value={values.state2}
                            />
                            {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}
                            <div className="error">
                              {errors.state2 && touched.state2 ? (
                                <p style={{ color: "red" }}>{errors.state2}</p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                            <label className="acounttitle field-label m-0">
                              Country
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <Field
                              name="country2"
                              type="text"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Country"
                              readOnly
                              value={values.country2}
                            />
                            <Field
                              name="countryurl2"
                              type="hidden"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Country"
                              readOnly
                              value={values.countryurl2}
                            />
                            <div className="error">
                              {errors.country2 && touched.country2 ? (
                                <p style={{ color: "red" }}>
                                  {errors.country2}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div className="payment-box">
                              <div
                                className="text-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "4px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    fontSize: "12px",
                                    background: "#bdbfc0",
                                    color: "#000",
                                  }}
                                  className="btn-normal btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  ref={buttonRef}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div
                              class="alert alert-success"
                              role="alert"
                              style={{
                                display:
                                  addressstatus == true ? "block" : "none",
                              }}
                            >
                              Address Created Successfully
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht mb-0 d-flex">
                            {/* {console.log("this is sjippind response",shipaddress)} */}
                            {/* <span style={{height:"18px",width: "18px",background: "#230bb3",display: "flex",justifyContent: "center",alignItems:"center"}}><FaCheck style={{fontSize:'10px',color:'white'}} /></span> */}
                            <span
                              style={{
                                height: "18px",
                                width: "18px",
                                background:
                                  shipaddress == false ? "#e9ecef" : "#230bb3",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "4px",
                                borderRadius: "2px",
                              }}
                              onClick={() => {
                                togglefun();
                              }}
                            >
                              <FaCheck
                                style={{
                                  fontSize: "10px",
                                  color: shipaddress == false ? "#000" : "#fff",
                                }}
                              />
                            </span>
                            {/* <input type="checkbox" name="" id="" onChange={()=>{setshipaddress(!shipaddress)}} style={{position:'relative',top:'2px'}} /> */}
                            <span
                              onClick={() => {
                                togglefun();
                              }}
                            >
                              {" "}
                              SHIP TO A DIFFERENT ADDRESS?
                            </span>
                          </div>

                          {shipaddress == false ? (
                            ""
                          ) : (
                            <div className="form-group col-md-12 col-sm-12 col-xs-12 cht"></div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>

                  {shipaddress == false ? (
                    ""
                  ) : (
                    <div
                      className="form-group col-md-12 col-sm-12 col-xs-12 cht"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "25px",
                        marginBottom: "6px",
                      }}
                    >
                      {shippingaddressopen == false ? (
                        <>
                          <div className="col-12">
                            <h6
                              style={{
                                color: "#230bb3",
                                position: "relative",
                                top: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setshippingaddressopen(!shippingaddressopen);
                              }}
                            >
                              {" "}
                              + Add Shipping Address
                            </h6>
                          </div>
                          {addressdata.map((item, index) =>
                            item.id == shippingid &&
                            item.id != slectaddressid ? (
                              <div
                                class="col-lg-5"
                                style={{
                                  marginBottom: "9px",
                                }}
                              >
                                <div class="card">
                                  <div class="card-body">
                                    <h5
                                      class="card-title acounttitle"
                                      style={{
                                        textTransform: "capitalize",
                                        position: "relative",
                                      }}
                                    >
                                      {item.first_name}&nbsp;
                                      {item.last_name}{" "}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8d9091",
                                        }}
                                      >
                                        ({item.address_type})
                                      </span>
                                      <span
                                        className="hello"
                                        style={{
                                          position: "absolute",
                                          top: "-1px",
                                          right: "0px",
                                        }}
                                      >
                                        {/* <div class="dropdown">
  <button class="dot-dot secondary dropdown-toggle"  style={{border:'none',background:'white'}} type="three dots" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
  <i class="fa-solid fa-ellipsis-vertical"></i>
  </button>
  <ul class="dropdown-menu dropdownMenu lateststyle  ">
  <li><button type="button" style={{fontSize:'12px'}} 
     class="dropdown-item"><i class="fa fa-pen-to-square "></i>&nbsp;Edit</button></li>
  </ul>
  </div> */}
                                      </span>
                                    </h5>

                                    <div
                                      class="form-check"
                                      style={{ position: "relative" }}
                                    >
                                      {shippingid == item.id ? (
                                        <input
                                          type="radio"
                                          name="flexRadioDefault"
                                          style={{
                                            position: "absolute",
                                            top: "9px",
                                            left: "5px",
                                          }}
                                          onChange={() => {
                                            setshippadd(item.id);
                                          }}
                                          checked
                                        />
                                      ) : (
                                        <input
                                          type="radio"
                                          name="flexRadioDefault"
                                          style={{
                                            position: "absolute",
                                            top: "9px",
                                            left: "5px",
                                          }}
                                          onChange={() => {
                                            setshippadd(item.id);
                                          }}
                                        />
                                      )}

                                      <label
                                        class="form-check-label mb-0"
                                        for="flexRadioDefault1"
                                      >
                                        <h5
                                          className="number acounttitle"
                                          style={{ paddingBottom: "4px" }}
                                        >
                                          Mobile:{" "}
                                          <span
                                            className="number2"
                                            style={{
                                              textTransform: "none",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {item.phone_number}
                                          </span>
                                        </h5>
                                        <h5 className="number acounttitle">
                                          Email ID:{" "}
                                          <span
                                            className="number2"
                                            style={{
                                              textTransform: "none",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {item.email}
                                          </span>
                                        </h5>
                                      </label>
                                    </div>
                                    <p
                                      className="small-text"
                                      style={{ height: "40px" }}
                                    >
                                      <span style={{ textWrap: "nowrap" }}>
                                        {item.line1}
                                      </span>
                                      &nbsp;
                                      {item.line2}
                                      &nbsp;
                                      {item.country_printable_name}
                                      &nbsp;
                                      {item.state}
                                      &nbsp;
                                      {item.line4}-{item.postcode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}

                          {addressdata.map((item, index) =>
                            item.id != slectaddressid ? (
                              <>
                                {item.id != shippingid ? (
                                  <div
                                    class="col-lg-5"
                                    style={{
                                      marginBottom: "9px",
                                    }}
                                  >
                                    <div class="card">
                                      <div class="card-body">
                                        <h5
                                          class="card-title acounttitle"
                                          style={{
                                            textTransform: "capitalize",
                                            position: "relative",
                                          }}
                                        >
                                          {item.first_name}&nbsp;
                                          {item.last_name}{" "}
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              color: "#8d9091",
                                            }}
                                          >
                                            ({item.address_type})
                                          </span>
                                          <span
                                            className="hello"
                                            style={{
                                              position: "absolute",
                                              top: "-1px",
                                              right: "0px",
                                            }}
                                          >
                                            {/* <div class="dropdown">
<button class="dot-dot secondary dropdown-toggle"  style={{border:'none',background:'white'}} type="three dots" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
<i class="fa-solid fa-ellipsis-vertical"></i>
</button>
<ul class="dropdown-menu dropdownMenu lateststyle  ">
<li><button type="button" style={{fontSize:'12px'}} 
// onClick={()=>{editformopen(item,setFieldValue)}}
   class="dropdown-item"><i class="fa fa-pen-to-square "></i>&nbsp;Edit</button></li>
</ul>
</div> */}
                                          </span>
                                        </h5>

                                        <div
                                          class="form-check"
                                          style={{ position: "relative" }}
                                        >
                                          {item.id == shippingid ? (
                                            <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{
                                                position: "absolute",
                                                top: "9px",
                                                left: "5px",
                                              }}
                                              onChange={() => {
                                                setshippadd(item.id);
                                              }}
                                              checked
                                            />
                                          ) : (
                                            <input
                                              type="radio"
                                              name="flexRadioDefault"
                                              style={{
                                                position: "absolute",
                                                top: "9px",
                                                left: "5px",
                                              }}
                                              onChange={() => {
                                                setshippadd(item.id);
                                              }}
                                            />
                                          )}

                                          <label
                                            class="form-check-label mb-0"
                                            for="flexRadioDefault1"
                                          >
                                            <h5
                                              className="number acounttitle"
                                              style={{ paddingBottom: "4px" }}
                                            >
                                              Mobile:{" "}
                                              <span
                                                className="number2"
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {item.phone_number}
                                              </span>
                                            </h5>
                                            <h5 className="number acounttitle">
                                              Email ID:{" "}
                                              <span
                                                className="number2"
                                                style={{
                                                  textTransform: "none",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {item.email}
                                              </span>
                                            </h5>
                                          </label>
                                        </div>
                                        <p
                                          className="small-text"
                                          style={{ height: "40px" }}
                                        >
                                          <span style={{ textWrap: "nowrap" }}>
                                            {item.line1}
                                          </span>
                                          &nbsp;
                                          {item.line2}
                                          &nbsp;
                                          {item.country_printable_name}
                                          &nbsp;
                                          {item.state}
                                          &nbsp;
                                          {item.line4}-{item.postcode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )
                          )}
                        </>
                      ) : (
                        <Formik
                          initialValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                            mobile: "",
                            address1: "",
                            address2: "",
                            country: "",
                            countryurl: "",
                            state: "",
                            city: "",
                            pincode: "",
                            address_type: "HOME",
                          }}
                          validationSchema={checkoutvalidation}
                          onSubmit={(values, { setFieldError }) => {
                            createaddress(values, setFieldError, "shippingadd");
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleSubmit,
                            touched,
                            setFieldValue,
                            handleChange,
                            setFieldError,
                          }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit}>
                              <div className="row check-out ">
                                <div className="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                                  <label className=" acounttitle field-label m-0">
                                    Address Type
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                                  {/* <Field
                        name="address_type"
                        as="select"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your Address Type"
                        value={values.address_type}
                       >
                      <option value="HOME" label="HOME" />
                      <option value="OFFICE" label="OFFICE" />
                      <option value="OTHER" label="OTHER" />
                    </Field> */}

                                  <div
                                    className="d-flex"
                                    style={{ gap: "10px" }}
                                  >
                                    <div>
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "2px",
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="address_type"
                                          value="HOME"
                                          onChange={handleChange}
                                          checked={
                                            values.address_type == "HOME"
                                          }
                                        />
                                        HOME
                                      </label>
                                    </div>
                                    <div>
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "2px",
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="address_type"
                                          value="OFFICE"
                                          onChange={handleChange}
                                          checked={
                                            values.address_type == "OFFICE"
                                          }
                                        />
                                        OFFICE
                                      </label>
                                    </div>
                                    <div>
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "2px",
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          name="address_type"
                                          value="OTHER"
                                          onChange={handleChange}
                                          checked={
                                            values.address_type == "OTHER"
                                          }
                                        />
                                        OTHER
                                      </label>
                                    </div>
                                  </div>
                                  <div className="error">
                                    {errors.address_type &&
                                    touched.address_type ? (
                                      <p style={{ color: "red" }}>
                                        {errors.address_type}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                  <label className="acounttitle m-0">
                                    First Name{" "}
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder='First Name' /> */}

                                  <Field
                                    type="text"
                                    name="first_name"
                                    className="form-control"
                                    style={{ fontSize: "12px" }}
                                    placeholder="Enter Your First Name"
                                    value={values?.first_name}
                                  />
                                  <div className="error">
                                    {errors.first_name && touched.first_name ? (
                                      <p style={{ color: "red" }}>
                                        {errors.first_name}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                  <label className="acounttitle m-0">
                                    Last Name
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                                  <Field
                                    name="last_name"
                                    type="text"
                                    className="form-control"
                                    style={{ fontSize: "12px" }}
                                    placeholder="Enter Your Last Name"
                                    value={values?.last_name}
                                  />
                                  <div className="error">
                                    {errors.last_name && touched.last_name ? (
                                      <p style={{ color: "red" }}>
                                        {errors.last_name}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                  <label className="acounttitle field-label m-0">
                                    Phone
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                                  <Field
                                    name="mobile"
                                    type="tel"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="Enter Your Mobile. No"
                                    value={values?.mobile}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "mobile",
                                        e.target.value.replace(/\D/g, "")
                                      );
                                    }}
                                  />
                                  <div className="error">
                                    {errors.mobile && touched.mobile ? (
                                      <p style={{ color: "red" }}>
                                        {errors.mobile}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                  <label className="acounttitle field-label m-0">
                                    Email Address
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder='Email'/> */}
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    style={{ fontSize: "12px" }}
                                    placeholder="Enter Your Email Address"
                                    value={values?.email}
                                  />
                                  <div className="error">
                                    {errors.email && touched.email ? (
                                      <p style={{ color: "red" }}>
                                        {errors.email}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                  <label className="acounttitle field-label m-0">
                                    Address 1
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <Field
                                    name="address1"
                                    type="text"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="House number and street name"
                                    value={values.address1}
                                  />
                                  <div className="error">
                                    {errors.address1 && touched.address1 ? (
                                      <p style={{ color: "red" }}>
                                        {errors.address1}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                                  {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                                </div>
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                  <label className="acounttitle field-label m-0">
                                    Address 2
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <Field
                                    name="address2"
                                    type="text"
                                    style={{ fontSize: "12px" }}
                                    className="form-control mt-2"
                                    placeholder="Apartment, suite, unit etc."
                                    value={values.address2}
                                  />
                                  <div className="error">
                                    {errors.address2 && touched.address2 ? (
                                      <p style={{ color: "red" }}>
                                        {errors.address2}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                                  {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                                </div>

                                <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12">
                                  <label className=" acounttitle field-label m-0">
                                    Postal Code
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                                  <Field
                                    name="pincode"
                                    type="tel"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="Enter Your Pincode"
                                    value={values.pincode}
                                    minLength={6}
                                    maxLength={6}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "pincode",
                                        e.target.value.replace(/[^0-9\ ]/gi, "")
                                      );
                                      fetchstateandcity(
                                        e.target.value.replace(
                                          /[^0-9\ ]/gi,
                                          ""
                                        ),
                                        setFieldError,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                  <div className="error">
                                    {errors.pincode && touched.pincode ? (
                                      <p style={{ color: "red" }}>
                                        {errors.pincode}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                {values.state !== "" &&
                                values.state !== null &&
                                cityna == "NA" ? (
                                  <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                                    <label className="acounttitle field-label m-0">
                                      City
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </label>
                                    {/* <input type="text" name="field-name" placeholder="City" /> */}
                                    <Field
                                      name="city"
                                      type="text"
                                      style={{ fontSize: "12px" }}
                                      className="form-control"
                                      placeholder="Enter Your City"
                                      value={values.city}
                                    />
                                    {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                                    <div className="error">
                                      {errors.city && touched.city ? (
                                        <p style={{ color: "red" }}>
                                          {errors.city}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                                    <label className="acounttitle field-label m-0">
                                      City
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </label>
                                    {/* <input type="text" name="field-name" placeholder="City" /> */}
                                    <Field
                                      name="city"
                                      type="text"
                                      style={{ fontSize: "12px" }}
                                      className="form-control"
                                      placeholder="Enter Your City"
                                      readOnly
                                      value={values.city}
                                    />
                                    {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                                    <div className="error">
                                      {errors.city && touched.city ? (
                                        <p style={{ color: "red" }}>
                                          {errors.city}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                                {/* {console.log("reeffghgttffddddcc vvvvvv nnnnnnnnnn
                                  
                                  nn",errors,"llkkkkkkkkjjjhhhhgggfffffdsssss",values)} */}
                                <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                                  <label className="acounttitle field-label m-0">
                                    State
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  {/* <input type="text" name="field-name" placeholder="State" /> */}
                                  <Field
                                    name="state"
                                    type="text"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="Enter Your State"
                                    readOnly
                                    value={values.state}
                                  />
                                  {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}
                                  <div className="error">
                                    {errors.state && touched.state ? (
                                      <p style={{ color: "red" }}>
                                        {errors.state}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                                  <label className="acounttitle field-label m-0">
                                    Country
                                    <span
                                      style={{ color: "red", fontSize: "13px" }}
                                    >
                                      {" "}
                                      *
                                    </span>
                                  </label>
                                  <Field
                                    name="country"
                                    type="text"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="Country"
                                    readOnly
                                    value={values.country}
                                  />
                                  <Field
                                    name="countryurl"
                                    type="hidden"
                                    style={{ fontSize: "12px" }}
                                    className="form-control"
                                    placeholder="Country"
                                    readOnly
                                    value={values.countryurl}
                                  />
                                  <div className="error">
                                    {errors.country && touched.country ? (
                                      <p style={{ color: "red" }}>
                                        {errors.country}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                  <div className="payment-box">
                                    <div
                                      className="text-left"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        gap: "4px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          fontSize: "12px",
                                          background: "#bdbfc0",
                                          color: "#000",
                                        }}
                                        className="btn-normal btn"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        // ref={buttonRef}
                                        onClick={() => {
                                          setshippingaddressopen(
                                            !shippingaddressopen
                                          );
                                        }}
                                      >
                                        Back
                                      </button>
                                      <button
                                        type="submit"
                                        style={{ fontSize: "12px" }}
                                        className="btn-normal btn"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                                  <div
                                    class="alert alert-success"
                                    role="alert"
                                    style={{
                                      display:
                                        addressstatus == true
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    Address Created Successfully
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </div>
                  )}
                  {/* <Formik
                    initialValues={{
                      first_name3: '',
                      last_name3: '',
                      email3: '',
                      mobile3: '',
                      address13: '',
                      address23: '',
                      country3: '',
                      countryurl3: '',
                      state3: '',
                      city3: '',
                      pincode3: '',
                    }}
                    validationSchema={checkoutvalidation3}
                    onSubmit={(values,{setFieldError,setSubmitting}) => {
                      setSubmitting(false);
                      console.log("tttttgggg")
                      createaddress2(values,setFieldError);
                    }}
                  >
                    {(action) =>(
                      <Form autoComplete="off" onSubmit={(e) => {
                        e.preventDefault(); 
                        action.handleSubmit(e); 
                      }} 
                      >

                      <div className="row check-out ">
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <label className="acounttitle m-0">
                          First Name <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        {console.log("this is error issue full",action.values,"op error",action.errors,"op first error]")}
                        <Field
                          type="text"
                          name="first_name3"
                          className="form-control"
                          style={{ fontSize: "12px" }}
                          placeholder="Enter Your First Name"
                          value={action.values?.first_name3}
                        />
                        <div className="error">
                          {action.errors.first_name3 && action.touched.first_name3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.first_name3}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <label className="acounttitle m-0">
                          Last Name
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="last_name3"
                          type="text"
                          className="form-control"
                          style={{ fontSize: "12px" }}
                          placeholder="Enter Your Last Name"
                          value={action.values?.last_name3}
                        />
                        <div className="error">
                          {action.errors.last_name3 && action.touched.last_name3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.last_name3}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <label className="acounttitle field-label m-0">
                          Phone
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="mobile3"
                          type="number"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Enter Your Mobile. No"
                          value={action.values?.mobile3}
                        />
                        <div className="error">
                          {action.errors.mobile3 && action.touched.mobile3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.mobile3}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <label className="acounttitle field-label m-0">
                          Email Address
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="email3"
                          type="email"
                          className="form-control"
                          style={{ fontSize: "12px" }}
                          placeholder="Enter Your Email Address"
                          value={action.values?.email3}
                        />
                        <div className="error">
                          {action.errors.email3 && action.touched.email3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.email3}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <label className="acounttitle field-label m-0">
                          Address
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="address13"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="House number and street name"
                          value={action.values.address13}
                        />
                        <div className="error">
                          {action.errors.address13 && action.touched.address13 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.address13}
                            </p>
                          ) : null}
                        </div>

                        <Field
                          name="address23"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control mt-2"
                          placeholder="Apartment, suite, unit etc. (optional)"
                          value={action.values.address23}
                        />
                        <div className="error">
                          {action.errors.address23 && action.touched.address23 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.address23}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <label className=" acounttitle field-label m-0">
                          Postal Code
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="pincode3"
                          type="number"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Enter Your Pincode"
                          value={action.values.pincode3}
                          minLength={6}
                          maxLength={6}
                          onChange={(e) => {
                            action.setFieldValue(
                              "pincode3",
                              e.target.value.replace(/[^0-9\ ]/gi, "")
                            );
                            fetchstateandcity4(
                              e.target.value.replace(/[^0-9\ ]/gi, ""),
                              action.setFieldError,
                              action.setFieldValue
                            );
                          }}
                        />
                        <div className="error">
                          {action.errors.pincode3 && action.touched.pincode3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.pincode3}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                        <label className="acounttitle field-label m-0">
                          Country
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="country3"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Country"
                          readOnly
                          value={action.values.country3}
                        />
                        <Field
                          name="countryurl3"
                          type="hidden"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Country"
                          readOnly
                          value={action.values.countryurl3}
                        />
                        <div className="error">
                          {action.errors.country3 && action.touched.country3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.country3}
                            </p>
                          ) : null}
                        </div>
                      </div>
            
                      <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                        <label className="acounttitle field-label m-0">
                          State
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="state3"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Enter Your State"
                          readOnly
                          value={action.values.state3}
                        />
                        <div className="error">
                          {action.errors.state3 && action.touched.state3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.state3}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {action.values.state3 !== '' && action.values.state3 !== null && cityna == 'NA' ? <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                        <label className="acounttitle field-label m-0">
                          City
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="city3"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Enter Your City"
                          value={action.values.city3}
                        />
                        <div className="error">
                          {action.errors.city3 && action.touched.city3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.city3}
                            </p>
                          ) : null}
                        </div>
                      </div> : <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                        <label className="acounttitle field-label m-0">
                          City
                          <span style={{color:'red',fontSize:'13px'}}> *</span>
                        </label>
                        <Field
                          name="city3"
                          type="text"
                          style={{ fontSize: "12px" }}
                          className="form-control"
                          placeholder="Enter Your City"
                          readOnly
                          value={action.values.city3}
                        />
                        <div className="error">
                          {action.errors.city3 && action.touched.city3 ? (
                            <p style={{ color: "red" }}>
                              {action.errors.city3}
                            </p>
                          ) : null}
                        </div>
                      </div>}
                      
                      <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                      
                      <div className="payment-box">
                      <div className="text-left" style={{display:'flex',justifyContent:'end',gap:'4px'}}>
  <button
                          type="button"
                          style={{ fontSize: "12px",background:'#bdbfc0',color:'#000' }}
                          className="btn-normal btn"
                          data-bs-dismiss="modal" aria-label="Close"
                          ref={buttonRef}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          style={{ fontSize: "12px" }}
                          className="btn-normal btn"
                        >
                          Save
                        </button>
                      </div>
                      </div>

                      
                      </div>



                      <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                      
                      <div class="alert alert-success" role="alert" style={{display:addressstatus == true ? "block" : "none"}}>
    Address Created Successfully 
  </div>
                      
                      </div>


                    </div>
                    </Form>
                    )}
                    </Formik> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Cart;
