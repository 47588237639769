import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { gettoken, getvoucher } from '../../Localstorage/Store';
const localvouhcer = getvoucher();
export const bannerApi = createApi({
  reducerPath: 'bannerApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}`, prepareHeaders: (headers, { getState }) => {
    const tokenvalue = gettoken();
    if (tokenvalue) {
      headers.set('Authorization',`Bearer ${tokenvalue.access}`);
    }
    return headers;
  }, }),
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => ({
        url: `bannermanage/banners/`,
        method:'GET'
      })
    }),
    getNewArrival: builder.query({
      query: () => ({
        url: `bannermanage/latest-product/`,
        method:'GET'
      })
    }),
    getFeatureProduct: builder.query({
      query: () => ({
        url: localvouhcer ? `homepageapi/featured-products/?code_list=${localvouhcer}` : `homepageapi/featured-products/`,
        method:'GET'
      })
    }),
    getBestSeller: builder.query({
      query: () => ({
        url: `homepageapi/best-sellers/`,
        method:'GET'
      })
    }),
    getCategoryItem: builder.query({
      query: () => ({
        url: `bannermanage/categories-promotions/`,
        method:'GET'
      })
    }),
    getBrandItem: builder.query({
      query: () => ({
        url: `homepageapi/shop_by_brand/`,
        method:'GET'
      })
    }),
  }),
})

export const { useGetBannerQuery,useGetBrandItemQuery,useGetNewArrivalQuery,useGetBestSellerQuery,useGetCategoryItemQuery,useGetFeatureProductQuery } = bannerApi