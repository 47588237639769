import * as Yup from "yup";

export const Register6val = Yup.object({
  companytype: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    companytype2: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    industrytype: Yup.string()
    .label("Intustry Type")
    .required("Intustry Type is required"),
    weblink: Yup.string(),
  contactperson: Yup.string()
    .label("Primary Contact Person")
    .required("Primary Contact Person is required"),
    companyname: Yup.string()
    .label("Company Name")
    .required("Name of Company is required"),
    designation: Yup.string()
    .label("Designation")
    .required("Designation is required"),
    email: Yup.string()
    .label("Email Id")
    .email()
    .required("Email Id is required"),
    mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Please Enter Your Mobile Number"),
      mobileotp: Yup.string()
    .required("Please Enter Your Mobile OTP"),
      emailotp: Yup.string()
    .required("Please Enter Your Email OTP"),
    terms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions"),
    GSTIN: Yup.string()
    .label("GST No"),
    // .required("GST No is required"),
    // businessname: Yup.string()
    // .label("businessname"),
    address: Yup.string()
    .label("Address")
    .max(100)
    .required("Address is required"),
    address2: Yup.string()
    .label("Address 2")
    .max(60)
    .required("Address 2 is required"),
    postalcode: Yup.number()
    .label("Postal Code")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Postal Code is required"),
    city: Yup.string()
    .required("City is required"),
    state: Yup.string()
    .required(" State is required"),
    country: Yup.string()
    .required("Country is required"),
    pan: Yup.string()
    .required("Pan Card is required"),
    // .min(1000000000, "PAN No must be at least 10 digits")
    // .max(9999999999, "PAN No cannot be more than 10 digits"),
    // businesslegaltype: Yup.string()
    // .label("Business Legal Type")
    // .required(" Business Legal Type is required"),
    registrationdate: Yup.date()
    .label("Date of Establishment")
    .required("Registration Date is required"),
    alt_name: Yup.string()
    .label("Full Name"),
    // .required("Full Name is required"),
    alt_des: Yup.string()
    .label("Designation"),
    // .required("Designation is required"),
    alt_email: Yup.string()
    .label("Email ID")
    .email(),
    // .required("Email ID is required"),
    alt_mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits"),
    // .required("Please Enter Your Mobile Number"),
    reg_address: Yup.string()
    .label("Address")
    .max(140),
    // .required("Address is required"),
    reg_postalcode: Yup.number()
    .label("Postal Code")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits"),
    // .required("Postal Code is required"),
    reg_city: Yup.string(),
    // .required("City is required"),
    reg_state: Yup.string(),
    // .required(" State is required"),
    reg_country: Yup.string(),
    // .required("Country is required"),
    office_address: Yup.string()
    .label("Office Address")
    .max(140)
    .required("Address is required"),
    office_postalcode: Yup.number()
    .label("Postal Code")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Postal Code is required"),
    office_city: Yup.string()
    .required("City is required"),
    office_state: Yup.string()
    .required(" State is required"),
    office_country: Yup.string()
    .required("Country is required"),
});
