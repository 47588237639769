import * as Yup from "yup";

export const business = Yup.object({
  // first_name: Yup.string()
  //   .label("First Name")
  //   .min(2)
  //   .max(19)
  //   .required("Please Enter First Name"),
  // last_name: Yup.string()
  //   .label("Last Name")
  //   .min(2)
  //   .max(19)
  //   .required("Please Enter Last Name"),
  address1: Yup.string()
  .label("Address 1")
    .min(2)
    .max(60)
    .required("Please Enter Address1"),
  address2: Yup.string()
  .label("Address 2")
    .min(2)
    .max(60)
    .required("Please Enter Address2"),
    pancard: Yup.string()
    .label("Pancard No")
    .min(2)
    .required("Pancard No is required"),
    Companyname: Yup.string()
    .label("Company Name")
    .min(2)
    .required("Company Name is required"),
    GSTNumber: Yup.string()
    .label("GST No")
    .min(2)
    .required("GST No is required"),
  state: Yup.string()
    .label("State")
    .min(2)
    .required("State is required"),
  city: Yup.string()
    .label("City")
    .min(2)
    .required("City is required"),
  pincode: Yup.string()
    .label("PinCode")
    .min(6)
    .max(6)
    .required("Pincode is required"),
    email: Yup.string().email().label("Email")
    .required("Please Enter Email"),
    mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Mobile Number is required"),
    gender: Yup.string()
    .label("gender"),
    password: Yup.string()
    .label("Password")
    .min(5)
    .required("Password is required"),
    cpassword: Yup.string()
    .label("Confirm Password")
    .min(5)
    .required("Confirm Password is required"),
    birth_date: Yup.date()
    .label("Birth Date")
    // .max(new Date(), "Birth date cannot be in the future")
    .required("Please enter your birth date")
    .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), "You must be at least 18 years old"),
});
