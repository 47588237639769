import { useLocation } from "react-router-dom";
import { gettoken } from "../../Localstorage/Store";
import { address } from "../../Validation/address";
import { Field, Formik, Form } from "formik";
import axios from "axios";
import csc from 'country-state-city';
import Select from "react-select";
import { getCode } from 'country-list';
import { useEffect, useState } from "react";
const AddressformComp = ({addaddress,item,editmode,createfun,closefun}) => {
    const currentwdith = window.innerWidth;
    const location = useLocation();
    const tokenvalue = gettoken()
    console.log("first name if code ",getCode("United Kingdom"))
    const [loading,setloading] = useState(false)
    const [singleaddress,setsingleaddress] = useState({})
    // const [editmode,seteditmode] = useState(false)
    const [createaddressstatus,setcreateaddressstatus] = useState(false)
    const [createaddresserror,setcreateaddresserror] = useState(false)
    const [createaddressmsg,setcreateaddressmsg] = useState("")
    const [country,setcountry] = useState("")
    const [state,setstate] = useState("")
    const [city,setcity] = useState("")
    const countries = csc.getAllCountries();
    console.log("city",city,"state",state,"country of country",countries)

    const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country
    }));

    const [addressdata,setaddressdata] = useState([])

const [cityna,setcityna] = useState("")
const [statelist,setstatelist] = useState("")
const [citylist,setcitylist] = useState("")
const updatedStates = (countryId) =>
csc.getStatesOfCountry(countryId)
.map((state) => ({ label: state.name, value: state.id, ...state }));
const updatedCities = (stateId) =>

csc.getCitiesOfState(stateId)
.map((city) => ({ label: city.name, value: city.id, ...city }));


const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
  if(value.length == 6){
try {
  const config = {
    headers: {
      Authorization: `Bearer ${tokenvalue.access}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
  const response = await axios.get(url,config);
  console.log(" new response vvv",response)
  if(response.data.city == "NA"){
    setFieldValue('city','');
    setcityna("NA");
  setFieldError('city',"Please Enter Your City")
  }else{
    setFieldValue('city',response.data.city);
    setcityna("");
  }
  setFieldValue('state',response.data.state)
  const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
  console.log(";;;;;;",countrydata)
  setFieldValue('country',countrydata[0].printable_name);

} catch (error) {
  // console.log("error",error.response.data)
  // setFieldValue('city','')
  // setFieldValue('state','')
  setFieldError('pincode',error.response.data.message)
}
}else{
  setFieldValue('city','')
  setFieldValue('state','')
  setFieldValue('country','')
}
}
    
   const createaddress = async (value) =>{
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    
    setloading(true)
    // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
    const response = await axios.post(url,value,config);
    if(response.data?.id){
     setcreateaddressstatus(true);
     setcreateaddressmsg("Successfully Created");
     
     setTimeout(() => {
      setcreateaddressmsg(false);
      setcreateaddressmsg("");
      window.location.reload();
     }, 3000);
    }else{
      createaddresserror(true);
      
    }
  
   }
  
  //  edit address api start here 
   const editaddress = async (value) =>{
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    
    setloading(true)
    // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
    const response = await axios.post(url,value,config);
    if(response.data?.id){
     setcreateaddressstatus(true);
     setcreateaddressmsg("Successfully Created");
     
     setTimeout(() => {
      setcreateaddressmsg(false);
      setcreateaddressmsg("");
      window.location.reload();
     }, 3000);
    }else{
      createaddresserror(true);
      
    }
  
   }
  //  edit address api end here 
  
  


  const [countriesdata,setcountriesdata] = useState([])
  useEffect(()=>{ 
 async function getcountries() {
 let url = `${process.env.REACT_APP_API_URL}api/countries/`;
   try {
     const response = await axios.get(url);
     setcountriesdata(response.data)
   } catch (error) {
   }
 }
 async function getaddress() {
  const config = {
    headers: {
      Authorization: `Bearer ${tokenvalue.access}`,
      'Content-Type': 'application/json',
    },
  };
let url = `${process.env.REACT_APP_API_URL}homepageapi/address/list`;
  try {
    const response = await axios.get(url,config);
    setaddressdata(response.data)
    
  } catch (error) {
    // setloading(false)
    // console.log(error)
  }
}

 

 getcountries()
 getaddress()
   },[])
  return (
    <Formik
            initialValues={{
              first_name:item.first_name,
              last_name: '',
              mobile: '',
              address1: '',
              address2: '',
              email: '',
              country: country,
              state: state,
              city: city,
              pincode: '',
              address_type:'HOME'
            }}
            validationSchema={address}
            onSubmit={(values,{setFieldError}) => {
                const formdata = new FormData();
                console.log("values of vlue",values)
                formdata.append("first_name", values.first_name);
                formdata.append("last_name", values.last_name);
                formdata.append("phone_number", `+91${values.mobile}`);
                formdata.append("line1", values.address1);
                formdata.append("line2", values.address2);
                formdata.append("country", getCode(values.country));
                formdata.append("state", values.state);
                formdata.append("email", values.email);
                formdata.append("line4", values.city);
                formdata.append("postcode", values.pincode);
                formdata.append("address_type", values.address_type);
                if(tokenvalue.msg == "Voucher login"){
                  formdata.append("user", tokenvalue.user_id);
                }else{
                  formdata.append("user", tokenvalue.id);
                }
                if(!addressdata[0]){
                  formdata.append("is_default_for_billing", true);
                }
                console.log("values of vlue og formdata",formdata)
               
                createfun(formdata,setFieldError);
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              touched,
              handleChange,
              setFieldValue,
              setFieldError
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>














                {addaddress == true ? <div className="container-fluid addressbox">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                              <h6 className="mb-3 mt-3" style={{color:'#230BB3'}}>Add a new address</h6>
                              <h6 className="mb-3 mt-3" onClick={()=>{closefun()}} style={{ color: "#230BB3",cursor:'pointer' }}>
                      Close
                    </h6>
                    {console.log("all values",values)}
                            </div>
                            <div className="col-lg-12 ">
                              <div className="profile-head">
                                <div className="form-group mb-0">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">Address Type <span style={{color:'red'}}>*</span></label>
                                  <div className="d-flex" style={{gap:'10px'}}>
       <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="HOME"
            onChange={handleChange}
            checked={values.address_type == 'HOME'}
          />
         HOME
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="OFFICE"
            onChange={handleChange}
            checked={values.address_type == 'OFFICE'}
          />
          OFFICE
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="OTHER"
            onChange={handleChange}
            checked={values.address_type == 'OTHER'}
          />
          OTHER
        </label>
      </div></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">First Name <span style={{color:'red'}}>*</span></label>
                                  <Field
                          type="text"
                          style={{fontSize:'12px'}}
                          name="first_name"
                          className="form-control"
                          placeholder="First Name"
                          value={values.first_name}
                        />
                                <div className="error">
                                {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                                </div>
                                </div>
                              </div>
                            </div>
                            
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">Last Name<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="last_name"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Last Name"
                          value={values.last_name}
                        />
                        <div className="error">
                        {errors.last_name && touched.last_name ? (
                          <p style={{ color: "red" }}>
                            {errors.last_name}
                          </p>
                        ) : null}
                        </div>
                                </div>
                               
                              

                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Alternate Mobile No. (Optional)</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="number"
                                    readOnly1
                                    className="form-control"
                                    id="review"
                                    placeholder="Alternate Mobile No"
                                    required
                                  />
                                </div> */}
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">

                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">Mobile No.<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="mobile"
                          type="tel"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Mobile. No"
                          value={values.mobile}
                          onChange={(e) => {
                            setFieldValue("mobile", e.target.value.replace(/\D/g, ''));
                          }}
                        />
                        <div className="error">
                        {errors.mobile && touched.mobile ? (
                          <p style={{ color: "red" }}>
                            {errors.mobile}
                          </p>
                        ) : null}
                        </div>
                                </div>

                            
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">

                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">Email ID<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="email"
                          type="email"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Email ID"
                          value={values.email}
                        />
                        <div className="error">
                        {errors.email && touched.email ? (
                          <p style={{ color: "red" }}>
                            {errors.email}
                          </p>
                        ) : null}
                        </div>
                                </div>

                            
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                              <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">Address 1<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="address1"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Address 1"
                          value={values.address1}
                        />
                        <div className="error">
                        {errors.address1 &&
                        touched.address1 ? (
                          <p style={{ color: "red" }}>
                            {errors.address1}
                          </p>
                        ) : null}
                        </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                             
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">Address 2<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="address2"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Address 2"
                          value={values.address2}
                        />
                        <div className="error">
                        {errors.address2 &&
                        touched.address2 ? (
                          <p style={{ color: "red" }}>
                            {errors.address2}
                          </p>
                        ) : null}
                        </div>
                                </div>
                           
                              

                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Alternate Mobile No. (Optional)</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="number"
                                    readOnly1
                                    className="form-control"
                                    id="review"
                                    placeholder="Alternate Mobile No"
                                    required
                                  />
                                </div> */}
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                                
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="dob">Pincode<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="pincode"
                          type="tel"
                          minLength={6}
                          maxLength={6}
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Pincode"
                          value={values.pincode}
                          onChange={(e)=>{setFieldValue("pincode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                        />
                        <div className="error">
                        {errors.pincode &&
                        touched.pincode ? (
                          <p style={{ color: "red" }}>
                            {errors.pincode}
                          </p>
                        ) : null}
                        </div>
                                </div>
                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Gender</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    id="review"
                                    placeholder="Female"
                                    required
                                  />
                                </div> */}
{/* 
                                <br />
                                <br />
                                <br />
                                <div className="herobtn" style={{ marginBottom: '20px',marginTop:"0px" }}>
                                <span><a href="" style={{paddingRight:'20px',fontSize:'12px',fontWeight:'600',color:'#230BB3'}}>Cancel</a></span>
                                  <input
                                    style={{ outline: 'none', padding: "5px 9px",fontSize:'12px' }}
                                    type="Submit"
                                    className="profile-edit-btn"
                                    name="btnAddMore"
                                    value="Submit"
                                  />
                                </div> */}
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">

                              
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">Country<span style={{color:'red'}}>*</span></label>
                                  {/* <Field
                          name="country"
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          value={values.country}
                        /> */}






                           {/* <Field as="select" name="country" style={{fontSize:'12px'}} className="form-select">
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countriesdata.map((item, index) => (
                      <option  value={item.printable_name}>{item.printable_name}</option>
                      ))}
                    </Field>
 */}



                        <Field
                          name="country"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Country"
                          readOnly
                          value={values.country}
                        />








                         {/* <Select  onChange={(e)=>{setstatelist(e.id);setFieldValue("country",e.sortname)}} style={{fontSize:'11px'}} options={updatedCountries}  /> */}
                      {/* <option value="" disabled>
                        Select Country
                      </option>
                      {updatedCountries.map((item, index) => (
                      <option  value={item}>{item.label}</option>
                      ))}
                    </Select> */}
                        <div className="error">{errors.country &&
                        touched.country ? (
                          <p style={{ color: "red" }}>
                            {errors.country}
                          </p>
                        ) : null}</div>
                                </div>
                                
                      
                              </div>
                            </div>
                            <div className="col-lg-6 ">
                              <div className="profile-head">
                              <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">State<span style={{color:'red'}}>*</span></label>
                                  <Field
                          name="state"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="State"
                          readOnly
                          value={values.state}
                        />
                         {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}

                        <div className="error">
                        {errors.state &&
                        touched.state ? (
                          <p style={{ color: "red" }}>
                            {errors.state}
                          </p>
                        ) : null}
                        </div>
                                </div>
                              </div>
                            </div>






                            
                           {values.state !== '' && values.state !== null && cityna == 'NA' ? <div className="col-lg-6 ">
                              <div className="profile-head">
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">City<span style={{color:'red'}}>*</span></label>
                                  <Field
                          style={{fontSize:'12px'}}
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                        />
                         {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                        <div className="error">
                        {errors.city &&
                        touched.city ? (
                          <p style={{ color: "red" }}>
                            {errors.city}
                          </p>
                        ) : null}
                        </div>
                                </div>
                              

                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Alternate Mobile No. (Optional)</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="number"
                                    readOnly1
                                    className="form-control"
                                    id="review"
                                    placeholder="Alternate Mobile No"
                                    required
                                  />
                                </div> */}
                              </div>
                            </div> : <div className="col-lg-6 ">
                              <div className="profile-head">
                                <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="review">City<span style={{color:'red'}}>*</span></label>
                                  <Field
                          style={{fontSize:'12px'}}
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                          readOnly
                        />
                         {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                        <div className="error">
                        {errors.city &&
                        touched.city ? (
                          <p style={{ color: "red" }}>
                            {errors.city}
                          </p>
                        ) : null}
                        </div>
                                </div>
                              

                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Alternate Mobile No. (Optional)</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="number"
                                    readOnly1
                                    className="form-control"
                                    id="review"
                                    placeholder="Alternate Mobile No"
                                    required
                                  />
                                </div> */}
                              </div>
                            </div>}
                            









                      

                            
                          
                            
                            <div className="col-lg-12 ">
                              <div className="profile-head">
                                
                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="dob">Pincode</label>
                                  <Field
                          name="pincode"
                          type="number"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Pincode"
                          value={values.pincode}
                        />
                        <div className="error">
                        {errors.pincode &&
                        touched.pincode ? (
                          <p style={{ color: "red" }}>
                            {errors.pincode}
                          </p>
                        ) : null}
                        </div>
                                </div> */}
                                {/* <div className="form-group">
                                  <label style={{ fontWeight: "500" }} htmlFor="dob">Gender</label>
                                  <input
                                    style={{ outline: 'none' }}
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    id="review"
                                    placeholder="Female"
                                    required
                                  />
                                </div> */}

                                <br />
                                {/* <br /> */}
                                <br />
                                <div className="herobtn" style={{ marginBottom: '20px',marginTop:"0px" }}>
                                <span><a href="" style={{paddingRight:'20px',fontSize:'12px',fontWeight:'600',color:'#230BB3'}}>Cancel</a></span>
                                  <input
                                    style={{ outline: 'none', padding: "5px 9px",fontSize:'12px' }}
                                    type="Submit"
                                    className="profile-edit-btn"
                                    name="btnAddMore"
                                    value="Submit"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div> : ''}





                {/* <div
                  className="row bg-white pb-4 round"
                  style={{
                    border: "1px solid #E0E0E0",
                    margin: "10px 0px",
                    borderRadius: "3px",
                    position: "relative",
                  }}
                >
                  <div className="col-md-6 px-2 pt-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label">
                          First Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-lg-8">

                        <Field
                          type="text"
                          name="first_name"
                          className="form-control"
                          placeholder="First Name"
                          value={values.first_name}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={values.last_name}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.last_name && touched.last_name ? (
                          <p style={{ color: "red" }}>
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>


                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label">
                          Mobile .No{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="mobile"
                          type="text"
                          className="form-control"
                          placeholder="Mobile. No"
                          value={values.mobile}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.mobile && touched.mobile ? (
                          <p style={{ color: "red" }}>
                            {errors.mobile}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          Address 1{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="address1"
                          type="text"
                          className="form-control"
                          placeholder="Address 1"
                          value={values.address1}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.address1 &&
                        touched.address1 ? (
                          <p style={{ color: "red" }}>
                            {errors.address1}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          Address 2{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="address2"
                          type="text"
                          className="form-control"
                          placeholder="Address 2"
                          value={values.address2}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.address2 &&
                        touched.address2 ? (
                          <p style={{ color: "red" }}>
                            {errors.address2}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          Country{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="country"
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          value={values.country}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.country &&
                        touched.country ? (
                          <p style={{ color: "red" }}>
                            {errors.country}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          State{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="state"
                          type="text"
                          className="form-control"
                          placeholder="State"
                          value={values.state}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.state &&
                        touched.state ? (
                          <p style={{ color: "red" }}>
                            {errors.state}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          City{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.city &&
                        touched.city ? (
                          <p style={{ color: "red" }}>
                            {errors.city}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-2 pt-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <label htmlFor="" className="form-label ">
                          Pincode{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <Field
                          name="pincode"
                          type="text"
                          className="form-control"
                          placeholder="Pincode"
                          value={values.pincode}
                        />
                      </div>
                      <div className="offset-lg-4 col-lg-8">
                        {errors.pincode &&
                        touched.pincode ? (
                          <p style={{ color: "red" }}>
                            {errors.pincode}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
             
                  <div
                    className="col-12 py-5 px-4 d-flex justify-content-end"
                    style={{ gap: "4px" }}
                  >
                    <button className="btn4">Cancel</button>
                    <button
                      type="submit"
                      className="btn5"
                      style={{ background: "#0e5da9" }}
                    >
                      Save
                    </button>
                  </div>
                </div> */}
              </Form>
            )}
          </Formik>
  )
}

export default AddressformComp