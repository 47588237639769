
const tokenstore = (value)=>{
  localStorage.setItem('Oneuptoken4',JSON.stringify(value))
}
const tokenvoucher = (value)=>{
  localStorage.setItem('vouchercode',JSON.stringify(value))
}
const shippingquestion = (value)=>{
  localStorage.setItem('yesorno',JSON.stringify(value))
}
const recentlystore = (value)=>{
  localStorage.setItem('recently15',JSON.stringify(value))
}
const freezrorderstore = (value)=>{
  localStorage.setItem('freeze',JSON.stringify(value))
}
const shippinfidstore = (value)=>{
  localStorage.setItem('shipping',JSON.stringify(value))
}

const getfreeze = ()=>{
  let data = localStorage.getItem('freeze')
  return JSON.parse(data)
}
const getrecetly = ()=>{
  let data = localStorage.getItem('recently15')
  return JSON.parse(data)
}
const getshippingquestion = ()=>{
  let data = localStorage.getItem('yesorno')
  return JSON.parse(data)
}
const gettoken = ()=>{
  let data = localStorage.getItem('Oneuptoken4')
  return JSON.parse(data)
}
const getshipping = ()=>{
  let data = localStorage.getItem('shipping')
  return JSON.parse(data)
}
const getvoucher = ()=>{
  let data = localStorage.getItem('vouchercode')
  return JSON.parse(data)
}

 const removeToken = ()=>{
  localStorage.removeItem('Oneuptoken4')
  localStorage.removeItem('vouchercode')
    }
 const removefreeze = ()=>{
  localStorage.removeItem('freeze')
    }
 const recentitemremove = ()=>{
  localStorage.removeItem('recently15')
    }
 const shippingidremove = ()=>{
  localStorage.removeItem('shipping')
    }
 const shippingquestionremove = ()=>{
  localStorage.removeItem('yesorno')
    }

  export {tokenstore,gettoken,removeToken,getrecetly,recentlystore,getvoucher,tokenvoucher,removefreeze,getfreeze,freezrorderstore,recentitemremove,shippinfidstore,getshipping, shippingidremove,shippingquestionremove,getshippingquestion,shippingquestion }

  