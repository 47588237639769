import React, { useState } from "react";
import Footer from '../components/Footer'
import Header from "../components/Header";
import { gettoken } from "../Localstorage/Store";
import { useFormik } from "formik";

import Loginheader from "../components/Loginheader";
import { contactvalidation } from "../Validation/Contactvalidation";
import axios from "axios";
const Contact = () => {
    const tokenvalue = gettoken();
    const [loading,setloading] = useState(false)
    const [success,setsuccess] = useState('')
    const checkoutfrom = async (values,action,setFieldError) =>{
        try {
            // const config = {
            //   headers: {
            //     Authorization: `Bearer ${tokenvalue.access}`,
            //   },
            // };
            const data = {
                first_name: values.name,
                last_name: values.last_name,
                phone_number: values.number,
                email: values.email,
                message: values.msg
            }
            let url = `${process.env.REACT_APP_API_URL}useraccount/contact/`;
            const response = await axios.post(
              url,
              data
            );
            console.log("sssssssssssssssssssss",response)
            action();
            setsuccess("Message Send Successfully")

           setloading(false)
           setTimeout(() => {
            setsuccess("")
           }, 3000);
          } catch (error) {}
    }
    const initialValues = {
        name:'',
        email:'',
        last_name:'',
        number:'',
        msg:''
     }

     const {values, errors, touched, handleBlur,handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema:contactvalidation,
        onSubmit: (values,{setFieldError,resetForm}) => {
            checkoutfrom(values,resetForm,setFieldError)     
        },

        
    });

    
    return (
        <>
       {tokenvalue ? <Header /> : <Loginheader />} 

        <div className={tokenvalue ? "breadcrumb-main marginfromtop" : "breadcrumb-main marginfromtop23"}>
        {/* <div className="breadcrumb-main marginfromtop"> */}
    <div className="container m-0">
      <div className="row">
        <div className="col">
          <div className="breadcrumb-contain">
            <div>
              <ul>
                <li><a href="/">home</a></li>
                <li>{`>`}</li>
                <li><a href="javascript:void(0)">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="contact-page section-big-py-space b-g-light">
                <div className="custom-container">
                    <div className="row section-big-pb-space">
                        <div className="col-xl-6 offset-xl-3">
                            <h3 className="text-center mb-3" style={{ fontSize: '19px' }}>GET IN TOUCH</h3>
                            <form className="theme-form" onSubmit={handleSubmit} >
                              
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" >
                                            <label className="acounttitle" htmlFor="name">First Name <span style={{color:'red'}}>*</span></label>
                                            <input type="text" className="form-control " name="name"   style={{ fontSize: '12px' }} id="name" placeholder="Enter First Name"
                                             value={values.name}
                                              onChange={handleChange} 
                                              onBlur={handleBlur} />
                                              {errors.name && touched.name ? ( <p className="form-group" style={{color:'red'}}>{errors.name}</p>): null}
                                            {/* {errors.name && <p style={{color:'red'}}>{errors.name}</p>} */}
                                        </div>
                                        {console.log("values and erroir",errors,values)}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="acounttitle" htmlFor="email">Last Name <span style={{color:'red'}}>*</span></label>
                                            <input type="text" className="form-control" name="last_name" style={{ fontSize: '12px' }} id="last-name" placeholder="Enter Last Name" 
                                             value={values.last_name}
                                             onChange={handleChange} 
                                             onBlur={handleBlur} />
                                              {errors.last_name && touched.last_name ? ( <p className="form-group" style={{color:'red'}}>{errors.last_name}</p>): null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="acounttitle" htmlFor="review">Phone number <span style={{color:'red'}}>*</span></label>
                                            <input type="text" className="form-control" name="number" style={{ fontSize: '12px' }} id="review" placeholder="Enter Your Number" 
                                             value={values.number}
                                             onChange={handleChange} 
                                             onBlur={handleBlur} />
                                              {errors.number && touched.number ? ( <p className="form-group" style={{color:'red'}}>{errors.number}</p>): null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="acounttitle">Email <span style={{color:'red'}}>*</span></label>
                                            <input type="email" className="form-control" name="email" style={{ fontSize: '12px' }} placeholder="Enter Your Email" 
                                             value={values.email}
                                             onChange={handleChange} 
                                             onBlur={handleBlur} />
                                              {errors.email && touched.email ? ( <p className="form-group" style={{color:'red'}}>{errors.email}</p>): null}
                                            {/* {errors.email && <p style={{color:'red'}}>{errors.email}</p>} */}
                                        </div>
                                    </div>
                                    <div className="col-md-12 message">
                                        <div>
                                            <label className="acounttitle">Write Your Message <span style={{color:'red'}}>*</span></label>
                                            <textarea className="form-control" name="msg" style={{ fontSize: '12px' }} placeholder="Write Your Message" rows={2} defaultValue={""} 
                                            value={values.msg}
                                            onChange={handleChange} 
                                            onBlur={handleBlur} />
                                            {errors.msg && touched.msg ? ( <p className="form-group" style={{color:'red'}}>{errors.msg}</p>): null}
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <button className="btn btn-normal" type="submit">Send Your Message</button>
                                    </div>
                                    <div className="col-md-12 pt-2" style={{display:success == "" ? "none":"block"}}>
                                    <div class="alert alert-success"  role="alert">
{success}
</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12 map">
                            <div className="theme-card">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.5193026892966!2d77.14320397495169!3d28.494019790358195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ef6576ec313%3A0xf46be40c4d72420!2sOneUp%20Brands%20(%20A%20Unit%20of%20One%20Up%20Trade%20Pvt.%20Ltd.)!5e0!3m2!1sen!2sin!4v1712723660069!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

 {/* <Footer/> */}


        </>
    );
};
export default Contact;