import * as Yup from "yup";

export const checkoutvalidation3 = Yup.object({
  first_name3: Yup.string()
    .label("First Name")
    .min(2)
    .max(19)
    .required("Please Enter First Name"),
  last_name3: Yup.string()
    .label("Last Name")
    .min(2)
    .max(19)
    .required("Please Enter Last Name"),
  email3: Yup.string().email().label("Email")
    .required("Please Enter Email"),
  address13: Yup.string()
  .label("Address 1")
    .min(2)
    .max(60)
    .required("Please Enter Address1"),
  address23: Yup.string()
  .label("Address 2")
    .min(2)
    .max(60)
    .required("Please Enter Address2"),
  country3: Yup.string()
    .label("Country")
    .required("Country is required"),
  state3: Yup.string()
    .label("State")
    .min(2)
    .required("State is required"),
  city3: Yup.string()
    .label("City")
    .min(2)
    .required("City is required"),
  pincode3: Yup.string()
    .label("PinCode")
    .min(6)
    .max(6)
    .required("Pincode is required"),
    mobile3: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Mobile Number is required"),
});
