import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { gettoken } from "../Localstorage/Store";
import { useFormik } from "formik";

import Loginheader from "../components/Loginheader";
import axios from "axios";
import { business } from "../Validation/business";
import Multiselect from "multiselect-react-dropdown";
import { vrequest } from "../Validation/vrequest";
import { useNavigate } from "react-router-dom";
import { crequest } from "../Validation/crequest";
const Creditrequest = () => {
  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState("");
  const [cityna, setcityna] = useState("");
  const [option, setoption] = useState([]);
  const [selectedcate, setselectedcate] = useState([]);
  // const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const multiselectRef = useRef(null);
  const nvg = useNavigate();
  const vrequestfrom = async (values, action, setFieldError) => {
console.log("values of fiedl",values);
setloading(true)
    try {


      let data = {
          user: tokenvalue.id,
           amount: values.amount,
           status:'Requested'
        };
     
      let url = `${process.env.REACT_APP_API_URL}credit/credit-request-user/`;
      const response = await axios.post(url, data);
      console.log("sssssssssssssssssssss", response);
      action();
      const requestId = response.data.credit_request_id;  // Extract the UUID from the response
      setsuccess(`Request sent successfully. Your request ID is: ${requestId}`);
      setloading(false);
      setTimeout(() => {
        nvg('/creditrequest')
      }, 5000);
      // if (multiselectRef.current) {
      //   multiselectRef.current.resetSelectedValues();
      // }
     
      setTimeout(() => {
        setsuccess("");
      }, 3000);
    } catch (error) {
      setloading(false)
      console.log("this ier eror msf",error)
    }
  };
  const initialValues = {
    amount: "",
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: crequest,
    onSubmit: (values, { setFieldError, resetForm }) => {
      console.log("vialdldldld", values);
        vrequestfrom(values, resetForm, setFieldError);
    },
  });


  async function fetchcategory(check1=0){
    try {
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}discounts/category_list/`;
    const response = await axios.get(url);
    // console.log("first nnn oo",response)
    if(check1 == 0){
      const appenddata = [{category_bread_crump:"All",id:"00"},...response.data.category_list];
      setoption(appenddata)
    }else{
      setoption(response.data.category_list)
    }
//   response.data.category_list.map((item,index)=>{
// setoption([...option,item.id])
//   })

    setloading(false)
  } catch (error) {
    setloading(false)
  }
  
  }

// console.log("djdjdjjcmcmcmcmcmcmcmcmc",selectedcate)
  const onSelect = (selectedList) => {
    // const allexist = selectedList.filter()
    const allexist = selectedList.some(item => item.id === '00');
    if(allexist){
      setoption([]);
    }else{
      if(selectedList[0].id !== '00'){
        fetchcategory(1)
      }
    }
    console.log('Selected values:', allexist);
    setFieldValue("categories",selectedList);
  };

  const onRemove = (selectedList) => {
    // setselectedcate(selectedList);
    // console.log('Selected values:', selectedList);
    setFieldValue("categories",selectedList);
    if(selectedList.length === 0){
    setFieldValue("categories",'');
    fetchcategory(0)
    }
  };

  useEffect(()=>{
  
    fetchcategory()
      
    },[])

  return (
    <>
      {tokenvalue ? <Header /> : <Loginheader />}

      <div
        className={
          tokenvalue
            ? "breadcrumb-main marginfromtop"
            : "breadcrumb-main marginfromtop23"
        }
      >
        {/* <div className="breadcrumb-main marginfromtop"> */}
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/">home</a>
                    </li>
                    <li>{`>`}</li>
                    <li>
                      <a href="javascript:void(0)">Credit Request</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-page section-big-py-space b-g-light">
        <div className="custom-container">
          <div className="row section-big-pb-space">
            <div className="col-xl-6 offset-xl-3">
              <h3 className="text-center mb-3" style={{ fontSize: "23px" }}>
                 CREDIT REQUEST
              </h3>
              {/* {console.log("values of value",values,"error of erro",errors)} */}
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">
             
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="name">
                       Categories <span style={{ color: "red" }}>*</span>
                      </label>
                      <Multiselect options={option} displayValue="category_bread_crump" ref={multiselectRef} onSelect={onSelect}
                        style={{ fontSize: "12px" }}
                        onRemove={onRemove} className="form-control newsize"  />
                      {errors.categories && touched.categories ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.categories}
                        </p>
                      ) : null}

                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        Voucher Type <span style={{ color: "red" }}>*</span>
                      </label>
                       <select
                                    class="form-control"
                                    value={values.vouchertype}
                                    onChange={(e) => {setFieldValue("vouchertype",e.target.value)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                    <option value="Gift Voucher - Inclusive">Gift Voucher - Inclusive</option>
                                    <option value="Gift Voucher - Exclusive">Gift Voucher - Exclusive</option>
                                    <option value="Shopping Voucher - Inclusive">Shopping Voucher - Inclusive</option>
                                    <option value="Shopping Voucher - Exclusive">Shopping Voucher - Exclusive</option>
                                  </select>
                      {errors.vouchertype && touched.vouchertype ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.vouchertype}
                        </p>
                      ) : null}
                    </div>
                  </div> */}
                                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        Amount <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        maxLength={6}
                        minLength={6}
                        name="amount"
                        style={{ fontSize: "12px" }}
                        onChange={(e)=>{setFieldValue("amount",e.target.value)}}
                        id="last-name"
                        placeholder="Enter Amount"
                        value={values.amount}
                        // onChange={(e) => {
                        //   setFieldValue(
                        //     "pincode",
                        //     e.target.value.replace(/[^0-9\ ]/gi, "")
                        //   );
                        //   fetchstateandcity(
                        //     e.target.value.replace(/[^0-9\ ]/gi, ""),
                        //     setFieldError,
                        //     setFieldValue
                        //   );
                        // }}
                        // onBlur={handleBlur}
                      />
                      {errors.amount && touched.amount ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.amount}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <button className="btn btn-normal" type="submit">
                      {" "}
                      Send Request
                    </button>
                  </div>
                  <div
                    className="col-md-12 pt-2"
                    style={{ display: success == "" ? "none" : "block" }}
                  >
                    <div class="alert alert-success" role="alert">
                      {success}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
         
        </div>
      </section>

      <Footer/>
    </>
  );
};
export default Creditrequest;