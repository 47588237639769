import * as Yup from "yup";


const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const FILE_SIZE = 5024 * 5024; // 1MB

export const gethelp = Yup.object({
  first_name: Yup.string()
    .matches(/^[a-zA-Z-]+$/, "Invalid characters")
    .min(2)
    .max(25)
    .required("Please Enter First Name"),
    last_name: Yup.string()
    .matches(/^[a-zA-Z-]+$/, "Invalid characters")
    .min(2)
    .max(25)
    .required("Please Enter Last Name"),
  msg: Yup.string()
    .matches(/^[a-zA-Z0-9!. ?]+$/, "Invalid characters")
    .min(2)
    .max(200)
    .required("Please Enter your massage"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9. @]+$/, "Invalid characters")
    .email()
    .required("Invalid Email Address")
    .max(45)
    .required("Email is required"),
    mobile: Yup.number()
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits")
    .required("Mobile Number is required"),
    image: Yup.mixed()
    .label("Image")
});
