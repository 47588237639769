import React, { useEffect, useState } from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import axios from 'axios';
import { gettoken } from '../Localstorage/Store';
import { IoIosArrowDown } from "react-icons/io";

export const Voucherrequestlist = () => {



  const nvg = useNavigate()
  const [data, setData] = useState([]);
  const [error, seterror] = useState("No orders found !");



  const [datastatus, setDatastatus] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  console.log("selectedStatus", selectedStatuses)
  const [selectedDuration, setSelectedDuration] = useState(null);
  console.log(selectedDuration, "selectedDuration")




  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false)
  const currentwdith = window.innerWidth;







  const clearFilter = () => {
    setSelectedStatuses([]); // Clear selectedStatuses array
    setSelectedDuration(null);
    fetchcategory() // Reset selectedDuration to null (or initial value)
  };

  useEffect(() => {
    async function fetchstatus() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}bannermanage/voucher-request-user/${tokenvalue.id}`;

      const response = await axios.get(url, config);
      setDatastatus(response.data);
    }
    fetchstatus()

  }, [])
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = async () => {
    if (searchTerm == '') {
      fetchcategory()
    } else {
      const searchTermLower = searchTerm.trim().toLowerCase();

      const filteredOrders = data.filter(order =>
        order.number.toLowerCase().includes(searchTermLower)
      );
      setData(filteredOrders)

      console.log('Filtered orders:', filteredOrders);
      if (data.length > 0 && selectedDuration) {
        if (selectedDuration == '360') {
          const errorMessage = "No orders found last one year "
          seterror(errorMessage);

        } if (selectedDuration == '180') {
          const errorMessage = "No orders found last six Month "
          seterror(errorMessage);

        }
        if (selectedDuration == '30') {
          const errorMessage = "No orders found last 30 Days "
          seterror(errorMessage);

        }
        if (selectedDuration == '15') {
          const errorMessage = "No orders found last 15 Days "
          seterror(errorMessage);

        }



      }
    }

  };



  async function fetchcategory() {
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}bannermanage/voucher-request-user/${tokenvalue.id}`;

    // let url = ${process.env.REACT_APP_API_URL}api/orders/;
    const response = await axios.get(url);
    console.log("order history response", response)
    console.log("order history response data", response.data)

    setData(response.data['Voucher Request Data']);
    setloading(false)
  }
  useEffect(() => {

    fetchcategory()

  }, [])

  return (
    <div>
      <Header />

      <div>
        {/* breadcrumb start */}
        <div className="breadcrumb-main marginfromtop" style={{ background: "#fff" }}>
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    <ul>
                      <li><a href="/">home</a></li>
                      <li>{`>`}</li>
                      <li><a href="javascript:void(0)">Voucher Request List</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb End */}
        <div>
          <section className="section-big-pt-space py-1 " style={{ background: "#f9f9f9" }}>
            <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-4 mt-xs-4 mb-5">

              <div className="container-fuild emp-profile">
                <div className="row pro justify-content-center">


                  {/* <div id="tab-2" style={{ display: owl == 'tab-2' ? 'block' : 'none' }} className={owl == 'tab-2' ? "tab-content active default product-block3" : "tab-content product-block3"}> */}
                  <div className="row py-xl-4 py-3">




                    <div className={"col-xl-10 col-md-12 offset-xl-1 offset-md-0 Ohistory"} style={{ padding: "0px 4px" }} >
                      <div className="input-group mb-3" style={{ gap: '10px', justifyContent: 'end' }}>
                        {/* <input
                          type="text"
                          style={{ borderRadius: "0px",letterSpacing:'1px',fontSize:'14px' }}
                          className="form-control newsizeinput"
                          placeholder="Search your orders id here"
                          aria-label="Recipient's username"
                          
                          aria-describedby="button-addon2"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)} 
                        /> */}
                        <button
                          className='Sbtn btn btn-outline-secondary'
                          style={{ background: '#230bb3', color: 'white', borderRadius: '0px', fontSize: "13px" }}
                          type="button"
                          id="button-addon2"
                          onClick={() => { nvg('/voucher-request-add') }}
                        >
                          {/* <i className="fa fa-search"></i> */}
                          <span style={{ fontSize: '18px' }}>+</span>&nbsp; Add Request
                        </button>
                      </div>
                      {/* <div className="filter-main-btn m-0" style={{display :currentwdith < 730 ? "block" : "none"}} onClick={()=>{setfilter(!filter)}}><span className="filter-btn  "><i className="fa fa-filter" aria-hidden="true" /> Filter</span></div> */}
                      <div className="filter-main-btn m-0" style={{ display: currentwdith < 730 ? "block" : "none" }} >          <button
                        type="button"
                        className="hidecontent my-2"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "white",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalfour"
                      >
                        {/* <span className="filter-btn " style={{ fontSize: '12px' }}><i className="fa fa-filter" aria-hidden="true" /> Filter</span> */}
                      </button></div>

                      <div className="table-responsive" >
                        <table className="table  orstatus" >

                          {/* <table className="table"> */}
                          <thead>
                            <tr>

                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>S.No</div></th>
                              
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Voucher Name</div></th>
 <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Request Id </div></th>
                              <th scope="col"><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Voucher Qty</div></th>






                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Voucher Amount</div></th>
                                 
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Request Date</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Voucher Status</div></th>
                            </tr>
                          </thead>
                          {/* </table> */}



                          <thead className="table-light" style={{ paddingTop: '20px' }}>
                            <tr>

                            </tr>
                          </thead>
                          <tbody >
                            {data[0] ? (data.map((item, index) => (
                              <tr key={index}>


                                <td style={{ color: '#230BB3', paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>

                                  <h5
                                    //  onClick={() => { nvg(/order-history-detail/${item.number}) }}
                                    style={{ fontSize: "12px", lineHeight: "22px", cursor: 'pointer', position: 'relative' }}> {1 + index}</h5>
                                </td>
                                <td style={{ color: '', paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>

                                  {/* <h5 */}
                                  {/* //  onClick={() => { nvg(/order-history-detail/${item.number}) }} */}
                                  {/* style={{ fontSize: "12px", lineHeight: "22px",cursor:'pointer' }}> */}
                                  {item.voucher_request_name}
                                  {/* </h5> */}
                                </td>



                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>{item.uuid}</td>


                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px', position: 'relative', left: "10px" }}>{item.no_of_vouchers} </td>
                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>₹{item.amount}</td>
                                

                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px', }}> {new Date(item.submission_date.split('Time')[0]).toLocaleDateString('en-GB', {
                                  hour: 'numeric',
                                  minute: 'numeric'
                                }


                                )}</td>


                                <td className='d-flex justify-content-center'>
                                  <p style={{ paddingTop: '15px', width: "115px", fontSize: '12px', textAlign: 'center' }}>  &nbsp;               {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                  </p>
                                </td>

                              </tr>
                            ))) : (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{ fontWeight: "500" }}
                                >
                                  {error}
                                </td>
                              </tr>
                            )}



                          </tbody>
                        </table>
                      </div>

                    </div>

                  </div>






                </div>
              </div>
            </div>
          </section>

        </div>


        <Footer />
      </div>


    </div>
  )
}
export default Voucherrequestlist;