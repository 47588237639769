import React, { useEffect, useState } from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import axios from 'axios';
import { gettoken } from '../Localstorage/Store';
import { IoIosArrowDown, IoMdArrowRoundBack } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Tracksingleorder = () => {



  const nvg = useNavigate()
  const [data, setData] = useState([]);
  const [error, seterror] = useState("No orders found !");



  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false)
  const { orderid } = useParams()
console.log("single ordr ",data)


const [showtrack, setshowtrack] = useState(false);
const [awbNumber, setawbNumber] = useState(null);
const [trackingData, setTrackingData] = useState({});

const fetchTrackingData = async (item) => {
  setawbNumber(item);
console.log("skskskkskks",item)
  try {
    const url = `${process.env.REACT_APP_API_URL}ship/track-order-now?awb=${item}`;
    const response = await axios.get(url);

    if (response.data) {
      // Assuming response.data is a JSON string
      const jsonData = JSON.parse(response.data);
      console.log("gddffddgdg dfdd",jsonData)
      setTrackingData(jsonData);
      setshowtrack(true);
    } else {
      setshowtrack(false);
    }
  } catch (error) {
    console.error('Error fetching tracking data:', error);
    setshowtrack(false);
  }
};



  useEffect(() => {
    async function orderdetail() {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${tokenvalue.access}`,
      //     'Content-Type': 'application/json',
      //   },
      // };
      // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetVouchersView`;
      let url = `${process.env.REACT_APP_API_URL}my_api/get-single_order/${orderid}`;
      try {
        setloading(false)
        const response = await axios.get(url);
        if (response.data.product_deatils) {
          const updatedProducts = response.data.product_deatils.map(product => ({
            ...product,
            calculatedPercentOfMRP: product.discount_type == "percentage" ? (product.discount / 100) * product.mrp * product.quantity : product.discount * product.quantity,
          }));
          // setData(updatedProducts);

          console.log("check latest items here",updatedProducts)

          if (updatedProducts.length > 0) {
                    const trackingDataPromises = updatedProducts.map(async item => {
                        if (item.awb) {
                            const response = await fetch(`${process.env.REACT_APP_API_URL}ship/track-order-now?awb=${item.awb}`);
                            const jsonData = await response.json();
                            const trackingData = JSON.parse(jsonData);
                            console.log("this is it",trackingData)
                            return {
                                ...item,
                                trackingStatus: trackingData.tracking_data.shipment_track[0].current_status,
                            };
                        } else {
                            // Handle items with null AWB number
                            return {
                                ...item,
                                trackingStatus: 'No AWB number',
                                estimatedDate: 'N/A',
                            };
                        }
                    });
        
                    const itemsWithTracking = await Promise.all(trackingDataPromises);
          console.log("check latest items here 34333",itemsWithTracking)

                    setData(itemsWithTracking);
             
            }

        










          
          setloading(true)
        }

      } catch (error) {
        setloading(true)
      }
    }

    orderdetail()
  }, [])




  return (
    <div>
      {/* <Header /> */}

      <div>
        {/* breadcrumb start */}
        {/* <div className="breadcrumb-main marginfromtop" style={{ background: "#fff" }}>
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    <ul>
                      <li><a href="/">home</a></li>
                      <li>{`>`}</li>
                      <li><a href="javascript:void(0)">Track Order</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* breadcrumb End */}
        <div>
          <section className="section-big-pt-space py-1 " style={{ background: "#f9f9f9" }}>

            
            <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-4 mt-xs-4 mb-5">

              <div className="container-fuild emp-profile">
                <div className="row pro">


                  {/* <div id="tab-2" style={{ display: owl == 'tab-2' ? 'block' : 'none' }} className={owl == 'tab-2' ? "tab-content active default product-block3" : "tab-content product-block3"}> */}
                  <div className="row py-xl-4 py-3">

                    
          <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 py-lg-5 py-3 d-flex justify-content-center">
          <img
            // src={`./images/logo.png`}
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth"
            style={{maxWidth:'340px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>
                <div className={ "col-xl-10 col-md-12 offset-xl-1 offset-md-0 Ohistory "} style={{ padding: "0px 4px" }} >
                     <div className="goback" style={{fontSize:'15px',color:'#230bb3',cursor:'pointer'}} onClick={()=>{nvg(-1)}}> <IoMdArrowRoundBack /> Back to Order History</div>
                      <div className="table-responsive" >
                        <table className="table  orstatus" >

                          {/* <table className="table"> */}
                          <thead>
                            <tr>

                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Product Name</div></th>

                              <th scope="col"><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Product Amount</div></th>




                              <th scope="col"><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Total Quantity</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>AWB No.</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Status</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>
                                {/* Action */}
                              </div></th>

                              {/* <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Order Status</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Track Order</div></th> */}
                            </tr>
                          </thead>
                          {/* </table> */}



                          <thead className="table-light" style={{ paddingTop: '20px' }}>
                            <tr>

                            </tr>
                          </thead>
                          <tbody >
                            {data[0] ? (data.map((item, index) => (
                              <tr  key={index}>


                                <td style={{ color: '#230BB3', paddingTop: '20px', fontSize: '12px' }}>

                                  <h5 style={{ fontSize: "12px", lineHeight: "22px",cursor:'pointer' }}> {item.product__title}</h5>
                                </td>
                                <td style={{ paddingTop: '20px', fontSize: '12px', }}> ₹{item.price_incl_tax}</td>





                                <td style={{ paddingTop: '20px', fontSize: '12px',position:'relative',left:"10px" }}>{item.quantity} Items </td>
                                <td style={{ paddingTop: '20px', fontSize: '12px' }}>{item.awb == null ? 'To be Generated' : item.awb}</td>
                                <td style={{ paddingTop: '20px', fontSize: '12px' }}>{item.trackingStatus}</td>
                                {/* <td style={{ paddingTop: '20px', fontSize: '12px' }}>₹{item.total_incl_tax}</td> */}

                                {/* <td>
                                  <p style={{ paddingTop: '12px', fontSize: '12px' }}> <span ><img src={`${process.env.REACT_APP_API_PUBLIC_URL}images/icon/success.png`} alt="404" /></span> &nbsp;               {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                  </p>
                                </td> */}
                                <td>
                                  <p style={{ paddingTop: '12px', fontSize: '12px',color:'#230bb3',fontWeight:'700',cursor:'pointer' }}    onClick={() => fetchTrackingData(item.awb)}> See More </p>
                                </td>

                              </tr>
                            ))) : (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{ fontWeight: "500" }}
                                >
                                  {error}
                                </td>
                              </tr>
                            )}



                          </tbody>
                        </table>
                      </div>

                    </div>

                  </div>






                </div>
              </div>
            </div>
          </section>
          {showtrack && (
        <div
          style={{
            position: 'fixed',
            top: 25,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}
        >


          <div style={{ maxWidth: '32rem', minWidth: '32rem', margin: '0 auto', backgroundColor: 'white', padding: '1.5rem', border: '1px solid #e5e7eb', borderRadius: '0.5rem', minHeight: '10rem', maxHeight: '30rem', overflowY: 'auto' }}>
            <div style={{
              display: 'flex', justifyContent: 'space-between',
            }}>
              <span></span>
              <span
                onClick={() => setshowtrack(false)}
                style={{
                  fontSize: '1.5rem',
                  color: 'red',
                  cursor: 'pointer'

                }}
              >
                <IoIosCloseCircleOutline />
              </span>

            </div>

            <div style={{ paddingBottom: '1rem', borderBottom: '1px solid #e5e7eb', marginBottom: '1rem' }}>
              <h2 style={{ fontSize: '1.125rem', fontWeight: '600' }}>Delivery by One Up Brands</h2>
              <p style={{ fontSize: '0.875rem', color: '#6b7280',display:'inline' }}>Tracking ID:</p> <span> {awbNumber}</span> <br />
         <p style={{ fontSize: '0.875rem', color: '#6b7280',display:'inline' }}>Estimated Delivery Date:</p> {trackingData.tracking_data.etd ? <span> {trackingData.tracking_data.etd.split(' ')[0]}</span> : <span style={{color:'#9ca3af'}}>Will be Updated After Pickup</span>}  
              <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>This is your One Up Brands tracking number.</p>
            </div>

            {trackingData && trackingData.tracking_data.shipment_track_activities ? (
              trackingData.tracking_data.shipment_track_activities.map((activity, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                    <p style={{ width: '5rem', fontSize: '0.75rem', color: '#6b7280' }}>
                      {activity.date ? new Date(activity.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '00:00'}
                    </p>
                  </div>

                  <div>
                    {activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>{activity.activity == "Data Received" ? "Pickup Awaited" : activity.activity}</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                    {!activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>Unknown Activity</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p>Yet to be shipped</p>
              </div>
            )}






          </div>
        </div>
      )}

        </div>


      

        {/* <Footer /> */}
      </div>


    </div>
  )
}
export default Tracksingleorder;
