import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { recentitemremove } from '../Localstorage/Store'

const Recentlyowl = ({elementid,pid,catid,catpage,titename}) => {
  const [Data,setData] = useState({})
const nvg = useNavigate()

  const transfer = (productid)=>{

    console.log("dkdkdkdnnvjfjjjvjvjvjv",productid,"dkddkdkdkdmmc33222222",catid,"ffkfkkskm",catpage,"fkfkksmkkdkdd",titename)
    nvg("/productdetails",{ state: { id: productid,categoryid:catid,pagename:catpage,pname:Data[0].title} });
    window.location.reload();
  }
  useEffect(()=>{
     async function fetchcategory(){
      try {
        
      let url = `${process.env.REACT_APP_API_URL}homepageapi/GetProductDetailIdWise/${elementid}`;
      const response = await axios.get(url);
      if(response.data.data.length > 0){
        setData(response.data.data);
      }else{
        recentitemremove()

        console.log("this is re view response",response)
      }
      // console.log("pod",response);
    } catch (error) {
        // recentitemremove()
        console.log("this is is recently view")
    }
    }
    fetchcategory()
    
    },[])
  return (
    <div  style={{display:elementid == pid ? "none" : "block"}}>
      <div className=" mx-2 my-2 catbox">
    <div className="product-box p-0 " onClick={()=>{transfer(elementid)}}>
      <div className="product-imgbox" style={{position: "relative", overflow: "hidden" }}  >

      {Data?.[0]?.stock_record?.num_in_stock <= 0 && (<div className="overlay" onClick={() => {
                                              transfer(Data?.[0]?.id, Data?.[0]?.title);
                                            }}></div>)}
                                        {Data?.[0]?.stock_record?.num_in_stock <= 0 && (
                                          <div className="out-of-stock">Out of Stock</div>
                                        )}
        <div className="product-front">
        <button type='button' className='btn fixedhight' style={{width:'100%'}} onClick={()=>{transfer(elementid)}}>
            <img src={`${process.env.REACT_APP_API_URL}media/${Data[0]?.image[0]?.original}`} className="img-fluid  " alt="product" />
          </button>
        </div>
        {/* <div className="product-back">
          <a href="/home">
            <img src="./images/layout-2/product/a1.jpg" className="img-fluid  " alt="product" />
          </a>
        </div> */}
       
      </div>
      <div className="product-detail detail-inline ">
        {/* <div className="detail-title">
          <div className="detail-left">
           
            <a href="/home">
              <h6 className="price-title">
               {Data?.[0]?.title}
              </h6>
            </a>
          </div>
          <div className="detail-right">
            <div className="check-price" style={{textDecoration:'none'}}>
            ₹{Data?.[0]?.price}
            </div>
            <div className="price">
              <div className="price">
              ₹{Data?.[0]?.price}
              </div>
            </div>
          </div>
        </div> */}
         <div className="detail-title">
                                        <div className="detail-left" style={{width:'100%'}}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              flexDirection:'column',
                                              alignItems:'center'
                                            }}
                                          >
                                            {" "}
                                            {/* <button
                                              type="button"
                                              className="btn"
                                              onClick={() => {
                                                transfer(item.id);
                                              }}
                                            > */}
                                            <h6
                                                className="price-title catbox2"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  color:"#230bb3",
                                                  height:'20px'
                                                
                                                }}
                                              >
                                                 {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                {Data?.[0]?.att_val.filter(item => item.attribute__name.toLowerCase() == "brand").map((item,index)=>(
                                                  item.value_option__option
                                                ))}
                                              </h6>{" "}
                                              <h6
                                                className="price-title"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  textAlign:'center',
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: "1",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                  cursor:"pointer"
                                                }}
                                              >
                                                {/* {item?.product?.[0]?.title.length > 20
                                ? `${item?.product?.[0]?.title.substr(0, 20)}...` */}
                                {/* : */}
                                 {/* {item?.product?.[0]?.title} */}
                                 {Data?.[0]?.title}
                                              </h6>{" "}
                                            {/* </button> */}
                                          </div>
                                        </div>
                                        <div
                                          className="detail-right"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="price"
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "#000",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {" "}
                                              {/* ₹{item?.product?.[0]?.price} */}
                                              {/* ₹{Data?.[0]?.price} */}
                                              ₹{parseInt(Data?.[0]?.stock_record?.price)}{" "} {Data?.[0]?.stock_record?.discount == 0 ? '' : <span style={{fontSize: '13px',color: '#c1c1c1',lineHeight: '20px',textDecoration: 'line-through',paddingLeft:'3px',fontWeight:'400'}}>₹{Data?.[0]?.stock_record?.mrp}</span>}{Data?.[0]?.stock_record?.discount == 0 ? '' : <span style={{fontSize: '12px',color: '#388e3c',lineHeight: '20px',paddingLeft:'3px',fontWeight:'400'}}>{Data?.[0]?.stock_record?.discount_type == "amount" ? `(₹${Data?.[0]?.stock_record?.discount} off)` : `(${Data?.[0]?.stock_record?.discount} %off)`}</span>}

                                            </div>
                                            {/* <div className="price text-align-center" style={{display:'flex',justifyContent:'center'}}>  ₹{item.price} </div> */}
                                          </div>
                                        </div>
                                      </div>
      </div>
    </div>
    </div>
  </div>
  )
}

export default Recentlyowl