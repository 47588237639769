import axios from 'axios'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { gettoken } from '../Localstorage/Store'

const Footer = () => {
const [email,setemail] = useState('')
const [fname,setfname] = useState('')
const tokenvalue = gettoken();

const [newsletterresponse,setnewsletterresponse] = useState('')
  const newsletter = async (e) =>{
    e.preventDefault();
    try {
      const postData = {
        full_name:fname,
        email:email
      }
      // Make a POST request using axios
      const response = await axios.post(`${process.env.REACT_APP_API_URL}MyNewsLetterApi/subscriber/`, postData);
      console.log('Response:', response.data.message);
      setnewsletterresponse(response.data.message);
      setfname('')
      setemail('')
      setTimeout(() => {
        setnewsletterresponse('')
      }, 3000);

    } catch (error) {
      // Handle errors here
      console.error('Error:', error.message);
    }
  }
  return (
<footer>
  <div className="footer1 pb-1">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer-main">
            <div className="footer-box">
              {/* <div className="footer-title mobile-title">
                <h5>about</h5>
              </div> */}
              <div className="footer-contant">
                <div className="">
                  <NavLink href="/Home">
                    <img src="https://oscarecom.stackerbee.com/images/logo.png" className="maxwidthlogo22" alt="logo" />
                    {/* <img src="http://localhost:3001/images/logo.png" className="maxwidthlogo22" alt="logo" /> */}
                  </NavLink>
                </div>
                {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.</p> */}
                
              </div>
            </div>
           
            <div className="footer-box">
              {/* <div className="footer-title">
                <h5 style={{fontSize:'16px',color:'#230bb3'}}>INFORMATION</h5>
              </div> */}
              <div className="footer-contant">
                <ul className='newgap' style={{display:'flex'}}>
                  {/* <li><NavLink className='bluehover' to="/about">About Us</NavLink></li> */}
                  <li style={{display:'flex',alignItems:'center',paddingBottom:'0px'}}><NavLink className='bluehover' to="/contact">Contact Us</NavLink></li>
                  {tokenvalue ? '' : <li style={{display:'flex',alignItems:'center',paddingBottom:'0px'}} ><NavLink className='bluehover' to="/track-order">Track Order</NavLink></li> }
                  {/* {!tokenvalue ? '' : tokenvalue.msg == "Voucher login" ? "":  <li className='hideonphone' style={{display:'flex',alignItems:'center',paddingBottom:'0px'}}><NavLink className='bluehover' to="/mycredit">My Credit</NavLink></li> }
                  {!tokenvalue ? '' : tokenvalue.msg == "Voucher login" ? "":  <li className='hideonphone' style={{display:'flex',alignItems:'center',paddingBottom:'0px'}}><NavLink className='bluehover' to="/voucher-request">Voucher Request</NavLink></li> } */}
                  <li style={{display:'flex',alignItems:'center',paddingBottom:'0px'}}><NavLink className='bluehover' to="/privacypolicy">Privacy Policy</NavLink></li>
                  <li style={{display:'flex',alignItems:'center',paddingBottom:'0px'}}><NavLink className='bluehover' to="/termsconditions">Terms & Conditions</NavLink></li>
                  <li>
                  <i className="fa fa-envelope-o" /> <span style={{textTransform: "lowercase"}}>
                    support@oneupbrands.com
                    </span>
                  </li>
                  {/* <li>Follow &amp; exchanges</li> */}
                  {/* <li><a href="javascript:void(0)">terms &amp; conditions</a></li>x
                        <li><a href="javascript:void(0)">returns &amp; exchanges</a></li>
                     <li><a href="javascript:void(0)">shipping &amp; delivery</a></li> */}
                </ul>
              </div>
            </div>
         
            {/* <div className="footer-box"> */}
              {/* <div className="footer-title">
                <h5 style={{fontSize:'16px'}}>newsletter</h5>
              </div>
              <div className="footer-contant">
                <div className="newsletter-second bottomspace">
                  <form onSubmit={newsletter} >
                  <div className="form-group">
                    <div className="input-group">
                      <input type="text" style={{fontSize:'12px'}} value={fname} onChange={(e)=>{setfname(e.target.value)}} className="form-control" placeholder="Enter Full Name" required />
                      <span className="input-group-text"><i className="fa-solid fa-user" /></span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input type="email" value={email} style={{fontSize:'12px',textTransform:'none'}} onChange={(e)=>{setemail(e.target.value)}} className="form-control" placeholder="Enter Email Address" required />
                      <span className="input-group-text"><i className="fa-solid fa-envelope" /></span>
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <a href="javascript:void(0)" style={{fontSize:'12px',borderRadius:'5px'}}  className="btn"><button type="submit" style={{border:'none',outline:'none',padding: "10px 20px",borderRadius:'3px',fontSize:'13px',background: "#4150b5", color:"white",fontWeight:'700'}}> Submit Now</button></a>
                  </div>
                  <div className="form-group mb-0 pt-1">
                   <span style={{color : newsletterresponse == "Email Already Exist." ? "red" : "green"}}>{newsletterresponse}</span>
                  </div>
                  </form>
                </div>
              </div> */}
            {/* </div> */}
          </div>
          <div style={{textAlign:'center',paddingBottom:'13px',paddingTop:'14px',color:'#919297'}}><ul className="paymant" style={{display:'flex',justifyContent:'center',gap:'9px'}}>
                  <li>
                    <a href="javascript:void(0)"><img src="https://oneupv1.stackerbee.com/images/layout-1/pay/1.png" className="img-fluid" alt="pay" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"><img src="https://oneupv1.stackerbee.com/images/layout-1/pay/2.png" className="img-fluid" alt="pay" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"><img src="https://oneupv1.stackerbee.com/images/layout-1/pay/3.png" className="img-fluid" alt="pay" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"><img src="https://oneupv1.stackerbee.com/images/layout-1/pay/4.png" className="img-fluid" alt="pay" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)"><img src="https://oneupv1.stackerbee.com/images/layout-1/pay/5.png" className="img-fluid" alt="pay" /></a>
                  </li>
                </ul></div>
          <div style={{textAlign:'center',paddingBottom:'13px',paddingTop:'14px',color:'#919297'}}><h6 >©2024 One Up Brands. All rights reserved</h6></div>
        </div>
      </div>
    </div>
  </div>
</footer>


  )
}

export default Footer