import { useLocation } from "react-router-dom";
import { gettoken } from "../../Localstorage/Store";
import { address } from "../../Validation/address";
import { Field, Formik, Form } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import countryList from 'country-list';
import { getCode,getName } from 'country-list';
import csc from 'country-state-city';
import Select from "react-select";
const EditressformComp = ({ addaddress, item, editmode,editfun,closefun }) => {
  console.log(addaddress, "firstbnmv", item);
  const currentwdith = window.innerWidth;
  const location = useLocation();
  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false);
  const [singleaddress, setsingleaddress] = useState({});
  // const [editmode,seteditmode] = useState(false)
  const [createaddressstatus, setcreateaddressstatus] = useState(false);
  const [createaddresserror, setcreateaddresserror] = useState(false);
  const [createaddressmsg, setcreateaddressmsg] = useState("");
  const countries = csc.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));





  const [cityna,setcityna] = useState("")
const [statelist,setstatelist] = useState("")
const [citylist,setcitylist] = useState("")
const updatedStates = (countryId) =>
csc.getStatesOfCountry(countryId)
.map((state) => ({ label: state.name, value: state.id, ...state }));
const updatedCities = (stateId) =>

csc.getCitiesOfState(stateId)
.map((city) => ({ label: city.name, value: city.id, ...city }));



const fetchstateandcity = async (value,setFieldError,setFieldValue) =>{
  if(value.length == 6){
try {
  let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
  const response = await axios.get(url);
  console.log(" new response vvv",response)
  // setFieldValue('city',response.data.city)
  if(response.data.city == "NA"){
    setFieldValue('city','');
    setcityna("NA");
  setFieldError('city',"Please Enter Your City")
  }else{
    setFieldValue('city',response.data.city);
    setcityna("");
  }
  setFieldValue('state',response.data.state)
  if(response.data.country){
    const countrydata = countriesdata.filter(item2=>item2.printable_name == response.data.country.trim())
    setFieldValue('country',countrydata[0].printable_name);
  }
} catch (error) {
  // console.log("error",error.response.data)
  // setFieldValue('city','')
  // setFieldValue('state','')
  setFieldError('pincode',error.response.data.message)
}
}else{
  setFieldValue('city','')
  setFieldValue('state','')
  setFieldValue('country','')
}
}
  //  edit address api start here
  // const editaddress = async (values) => {


  //   const formdata = new FormData();
  //       formdata.append("first_name", values.first_name);
  //       formdata.append("last_name", values.last_name);
  //       formdata.append("phone_number", `+91${values.mobile}`);
  //       formdata.append("line1", values.address1);
  //       formdata.append("line2", values.address2);
  //       formdata.append("country", values.country);
  //       formdata.append("state", values.state);
  //       formdata.append("city", values.city);
  //       formdata.append("pincode", values.pincode);
  //       formdata.append("user", tokenvalue.id);
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${tokenvalue.access}`,
  //     },
  //   };

  //   setloading(true);
  //   // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
  //   let url = `${process.env.REACT_APP_API_URL}homepageapi/address/${item.id}/update/`;
  //   const response = await axios.put(url, value, config);
  //   if (response.data?.id) {
  //     setcreateaddressstatus(true);
  //     setcreateaddressmsg("Successfully Update");

  //     setTimeout(() => {
  //       setcreateaddressmsg(false);
  //       setcreateaddressmsg("");
  //       window.location.reload();
  //     }, 3000);
  //   } else {
  //     createaddresserror(true);
  //   }
  // };
  //  edit address api end here
  let defaultrelste;
  let defaultste;
  let defautcity;
  let defautrealcity;

  const defaultValue = updatedCountries.find((option) => option.label === countryList.getName(item.country));
  console.log("new countrt",defaultValue);

  if(defaultValue){
    defaultste = csc.getStatesOfCountry(defaultValue.id).map((state) => ({ label: state.name, value: state.id, ...state }));
    defaultrelste = defaultste.find((option) => option.label === item.state);
   }

   if(defaultrelste){
    defautcity = csc.getCitiesOfState(defaultrelste.value).map((city) => ({ label: city.name, value: city.id, ...city }));
    defautrealcity = defautcity.find((option) => option.label === item.line4);
   }
  const [countriesdata, setcountriesdata] = useState([]);
  useEffect(() => {
    async function getcountries() {
      let url = `${process.env.REACT_APP_API_URL}api/countries/`;
      try {
        const response = await axios.get(url);
        setcountriesdata(response.data);
      } catch (error) {}
    }

    getcountries();
  }, []);
  return (
    <Formik
      initialValues={{
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.phone_number.substring(3),
        address1: item.line1,
        address2: item.line2,
        email: item.email,
        country: getName(item.country),
        state: item.state,
        city: item.line4,
        pincode: item.postcode,
        address_type:item.address_type,
      }}
      validationSchema={address}
      onSubmit={(values,{setFieldError}) => {
        

        editfun(values,item.id,setFieldError);
      }}
    >
      {({ values, errors, handleSubmit, touched, setFieldValue, setFieldError,handleChange }) => (
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {
          console.log("sdfg",errors)
          }
          {addaddress == true ? (
            <div className="container-fluid addressbox">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between">
                    <h6 className="mb-3 mt-3" style={{ color: "#230BB3" }}>
                      Edit Address
                    </h6>
                    <h6 className="mb-3 mt-3" onClick={()=>{closefun()}} style={{ color: "#230BB3",cursor:'pointer' }}>
                      Close
                    </h6>
                  </div>


                  <div className="col-lg-12 ">
                              <div className="profile-head">
                                <div className="form-group mb-0">
                                  <label style={{ fontWeight: "500",margin:'0px',fontSize:'12px' }} htmlFor="name">Address Type <span style={{color:'red'}}>*</span></label>
                                  <div className="d-flex" style={{gap:'10px'}}>
       <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="HOME"
            onChange={handleChange}
            checked={values.address_type == 'HOME'}
          />
         HOME
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="OFFICE"
            onChange={handleChange}
            checked={values.address_type == 'OFFICE'}
          />
          OFFICE
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center',gap:'2px'}}>
          <input
            type="radio"
            name="address_type"
            value="OTHER"
            onChange={handleChange}
            checked={values.address_type == 'OTHER'}
          />
          OTHER
        </label>
      </div></div>
                                </div>
                              </div>
                            </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="name"
                        >
                          First Name
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="first_name"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="First Name"
                          value={values.first_name}
                        />
                        <div className="error">
                          {errors.first_name && touched.first_name ? (
                            <p style={{ color: "red" }}>{errors.first_name}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="name"
                        >
                          Last Name
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          name="last_name"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Last Name"
                          value={values.last_name}
                        />
                        <div className="error">
                          {errors.last_name && touched.last_name ? (
                            <p style={{ color: "red" }}>{errors.last_name}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="name"
                        >
                          Mobile No.
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          name="mobile"
                          type="tel"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Mobile. No"
                          value={values.mobile}
                          onChange={(e) => {
                            setFieldValue("mobile", e.target.value.replace(/\D/g, ''));
                          }}
                        />
                        <div className="error">
                          {errors.mobile && touched.mobile ? (
                            <p style={{ color: "red" }}>{errors.mobile}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="name"
                        >
                          Email ID
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          name="email"
                          type="email"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Email ID"
                          value={values.email}
                        />
                        <div className="error">
                          {errors.email && touched.email ? (
                            <p style={{ color: "red" }}>{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          Address 1
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          name="address1"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Address 1"
                          value={values.address1}
                        />
                        <div className="error">
                          {errors.address1 && touched.address1 ? (
                            <p style={{ color: "red" }}>{errors.address1}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          Address 2
                          <span style={{color:'red'}}>*</span>
                        </label>
                        <Field
                          name="address2"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Address 2"
                          value={values.address2}
                        />
                        <div className="error">
                          {errors.address2 && touched.address2 ? (
                            <p style={{ color: "red" }}>{errors.address2}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="dob"
                        >
                          
                          Pincode
                          <span style={{color:'red'}}>*</span>
                        </label>
                        {/* <Field
                          name="pincode"
                          type="number"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Pincode"
                          value={values.pincode}
                        /> */}
                         <Field
                          name="pincode"
                          type="tel"
                          minLength={6}
                          maxLength={6}
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Pincode"
                          value={values.pincode}
                          onChange={(e)=>{setFieldValue("pincode",e.target.value.replace(/[^0-9\ ]/gi, ""));fetchstateandcity(e.target.value.replace(/[^0-9\ ]/gi, ""),setFieldError,setFieldValue)}}
                        />
                        <div className="error">
                          {errors.pincode && touched.pincode ? (
                            <p style={{ color: "red" }}>{errors.pincode}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          Country
                          <span style={{color:'red'}}>*</span>
                        </label>

                        {/* <Field
                          as="select"
                          name="country"
                          style={{fontSize:'12px'}}
                          className="form-select"
                        >
                          <option value="" disabled>
                            Select Country
                          </option>
                          {updatedCountries.map((item, index) => (
                            <option value={item.sortname}>
                              {item.label}
                            </option>
                          ))}
                        </Field> */}















                            {/* <Field as="select" name="country" style={{fontSize:'12px'}} className="form-select">
                     
                      {countriesdata.map((item, index) => (
                        item.printable_name == getName(values.country) ? <option selected  value={item.printable_name}>{item.printable_name}</option> : ''
                      ))}
                      {countriesdata.map((item, index) => (
                        item.printable_name == getName(values.country) ? "" : <option  value={item.printable_name}>{item.printable_name}</option>
                      ))}
                    </Field> */}



<Field
                          name="country"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="Country"
                          readOnly
                          value={values.country}
                        />







                         {/* <Select  onChange={(e)=>{setstatelist(e.id);setFieldValue("country",e.sortname)}} defaultValue={defaultValue} style={{fontSize:'11px'}} options={updatedCountries}  /> */}
                        <div className="error">
                          {errors.country && touched.country ? (
                            <p style={{ color: "red" }}>{errors.country}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          State
                          <span style={{color:'red'}}>*</span>
                        </label>
                        {/* <Field
                          name="state"
                          style={{fontSize:'12px'}}
                          type="text"
                          className="form-control"
                          placeholder="State"
                          value={values.state}
                        /> */}
                         <Field
                          name="state"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="State"
                          readOnly
                          value={values.state}
                        />
                                                 {/* <Select defaultValue={defaultrelste} onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? defaultValue ? updatedStates(defaultValue.id) : null : updatedStates(statelist)}  /> */}
                        <div className="error">
                          {errors.state && touched.state ? (
                            <p style={{ color: "red" }}>{errors.state}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {values.state !== '' && values.state !== null && cityna == 'NA' ?  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          City
                          <span style={{color:'red'}}>*</span>
                        </label>
                        {/* <Field
                          name="city"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                        /> */}
                          <Field
                          style={{fontSize:'12px'}}
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                        />
                                                 {/* <Select defaultValue={defautrealcity} onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? defaultrelste ? updatedCities(defaultrelste.value): [] : updatedCities(citylist)}   /> */}
                        <div className="error">
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div> :  <div className="col-lg-6 ">
                    <div className="profile-head">
                      <div className="form-group">
                        <label
                          style={{ fontWeight: "500", margin: "0px",fontSize:'12px' }}
                          htmlFor="review"
                        >
                          City
                          <span style={{color:'red'}}>*</span>
                        </label>
                        {/* <Field
                          name="city"
                          type="text"
                          style={{fontSize:'12px'}}
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                        /> */}
                          <Field
                          style={{fontSize:'12px'}}
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={values.city}
                          readOnly
                        />
                                                 {/* <Select defaultValue={defautrealcity} onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? defaultrelste ? updatedCities(defaultrelste.value): [] : updatedCities(citylist)}   /> */}
                        <div className="error">
                          {errors.city && touched.city ? (
                            <p style={{ color: "red" }}>{errors.city}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>}
                 
                
                 
                  <div className="col-lg-12 ">
                    <div className="profile-head">
                      <br />
                      <br />
                      <div
                        className="herobtn"
                        style={{ marginBottom: "20px", marginTop: "0px" }}
                      >
                        <span>
                          <a
                            href=""
                            style={{
                              paddingRight: "20px",
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "#230BB3",
                            }}
                          >
                            Cancel
                          </a>
                        </span>
                        <input
                          style={{ outline: "none", padding: "5px 9px",fontSize:'12px' }}
                          type="Submit"
                          className="profile-edit-btn"
                          name="btnAddMore"
                          value="Update"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EditressformComp;
