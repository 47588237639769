import React, { useState } from "react";
import Header from "../components/Header";
import { gettoken } from "../Localstorage/Store";
import Loginheader from "../components/Loginheader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Trackorder = () => {
  const tokenvalue = gettoken();
  const nvg = useNavigate();
  const [success, setsuccess] = useState("");
  const [mobile, setmobile] = useState("");
  const [otp, setotp] = useState("");
  const [verifyotpstatus, setverifyotpstatus] = useState(0);
  const [hidesend, sethidesend] = useState(0);
  const [showotp, setshowotp] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showotp == true) {
      const formdata = new FormData();
      formdata.append("mobile_number", mobile);
      formdata.append("otp", otp);

      try {
        let url = `${process.env.REACT_APP_API_URL}useraccount/VerifyRegistrations/`;
        const response = await axios.post(url, formdata);
        if (response.data.message == "otp verify") {
          nvg("/track-order-list", { state: { otp: otp, mobile: mobile } });
        } else {
          setverifyotpstatus(3);

          setTimeout(() => {
            setverifyotpstatus(0);
          }, 5000);
        }
      } catch (error) {}
    } else {
      const formdata = new FormData();
      formdata.append("mobile_number", mobile);

      try {
        let url = `${process.env.REACT_APP_API_URL}useraccount/track-order-sms/`;
        const response = await axios.post(url, formdata);
        console.log("otp info here", response);
        setshowotp(true);
        setverifyotpstatus(1);
        sethidesend(1);

        setTimeout(() => {
          setverifyotpstatus(0);
        }, 5000);
      } catch (error) {
        console.log("otp info here", error.response.data.error);
        setverifyotpstatus(2);

        setTimeout(() => {
          setverifyotpstatus(0);
        }, 5000);
      }
    }
  };

  return (
    <>
      {/* {tokenvalue ? <Header /> : <Loginheader />} */}

      {/* <div
        className={
          tokenvalue
            ? "breadcrumb-main marginfromtop"
            : "breadcrumb-main marginfromtop23"
        }
      >
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/">home</a>
                    </li>
                    <li>{">"}</li>
                    <li>
                      <a href="javascript:void(0)">Track Order</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <section className="contact-page section-big-py-space b-g-light">
        <div className="custom-container">
          <div className="row section-big-pb-space d-flex justify-content-center">


          <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 py-lg-5 py-3 d-flex justify-content-center">
          <img
            // src={`./images/logo.png`}
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth"
            style={{maxWidth:'340px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>
            <div className="col-xl-5 ">
              <h3 className="text-center mb-3" style={{ fontSize: "19px" }}>
                Track Order
              </h3>
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-11">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="review">
                        Phone number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="number"
                        minLength={10}
                        maxLength={10}
                        style={{ fontSize: "12px" }}
                        id="review"
                        value={mobile}
                        onChange={(e) => {
                          setmobile(e.target.value.replace(/[^0-9]/gi, ""));
                          setshowotp(false);
                        }}
                        placeholder="Enter your number"
                        required
                      />
                      {/* {errors.number && touched.number ? ( <p className="form-group" style={{color:'red'}}>{errors.number}</p>): null} */}
                    </div>
                    {verifyotpstatus == 1 ? (
                      <span style={{ display: "block", marginBottom: "20px" }}>
                        <p style={{ color: "green" }}>
                          OTP send to your Mobile Number
                        </p>
                      </span>
                    ) : (
                      ""
                    )}
                    {verifyotpstatus == 2 ? (
                      <span style={{ display: "block", marginBottom: "20px" }}>
                        <p style={{ color: "red" }}>Mobile Number Not Found</p>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {showotp == false ? (
                    ""
                  ) : (
                    <div
                      className="col-md-11"
                      style={{ display: showotp == true ? "block" : "none" }}
                    >
                      <div className="form-group">
                        <label className="acounttitle" htmlFor="review">
                          OTP <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          name="number"
                          style={{ fontSize: "12px" }}
                          id="review"
                          value={otp}
                          onChange={(e) => {
                            setotp(e.target.value.replace(/[^0-9]/gi, ""));
                          }}
                          placeholder="Enter your OTP"
                          required
                        />
                        {/* {errors.number && touched.number ? ( <p className="form-group" style={{color:'red'}}>{errors.number}</p>): null} */}
                      </div>
                      {verifyotpstatus == 3 ? (
                        <span
                          style={{ display: "block", marginBottom: "20px" }}
                        >
                          <p style={{ color: "red" }}>OTP Not Verify</p>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <div className="col-md-12 d-flex justify-content-center">
                    <button className="btn btn-normal" type="submit">
                      Submit
                    </button>
                  </div>
                  <div
                    className="col-md-12 pt-2"
                    style={{ display: success == "" ? "none" : "block" }}
                  >
                    <div class="alert alert-success" role="alert">
                      {success}
                    </div>
                  </div>
                </div>
              </form>

              <h3 className="text-center mb-3" style={{ fontSize: "13px",fontWeight:'300',position:'relative',top:'100px' }}>
                For Any Orders/Tracking Issues Write to Us at <br /><span>  <a href="mailto:support@oneupbrands.com">support@oneupbrands.com</a></span>
              </h3>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer/> */}
    </>
  );
};
export default Trackorder;
