import "./App.css";
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Contactlogin from "./pages/Contact-login";
import About from "./pages/About";
import Cart from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import Checkout from "./pages/Checkout";
import Productdetails from "./pages/Productdetails";
import Category from "./pages/Category";
import Myaccount from "./pages/Myaccount";
import Login from "./pages/Login";
import Testproduct from "./pages/Testproduct";
import Profile from "./pages/profilepage/Profile";
import Addresslist from "./pages/Addresslist";
import OrderHistoryDetails from "./pages/OrderHistoryDetails";
import Credit from "./pages/Credit";
import OrderHistorytwo from "./pages/OrderHistorytwo";
import Productdetailstwo from "./pages/Productdetailstwo";
import Thankyoupage from "./pages/Thankyoupage";
import Termsconditions from "./pages/Termsconditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Pay from "./pages/Pay";
import Categoryforbrand from "./pages/Category-for-brand";
import { gettoken } from "./Localstorage/Store";
import Verifyotp from "./pages/Verify-otp";
import Categoryforsearch from "./pages/Category-for-search";
import Trackorder from "./pages/Trackorder";
import OrderHistoryWithOutLogin from "./pages/OrderHistoryWithOutLogin";
import Thankyoupagelogin from "./pages/Thankyoupagelogin";
import Landigpage from "./pages/Landigpage";
import Clientloginpage from "./pages/Clientloginpage";
import Voucherloginpage from "./pages/Voucherloginpage";
import Registerbusiness from "./pages/Registerbusiness";
import Voucherrequest from "./pages/Voucherrequest";
import Testwizard from "./pages/Testwizard";
import Register1 from "./pages/Register1";
import Register2 from "./pages/Register2";
import Register3 from "./pages/Register3";
import Register4 from "./pages/Register4";
import Register5 from "./pages/Register5";
import Useragreement from "./pages/Useragreement";
import Voucherrequestlist from "./pages/Voucherrequestlist";
import Test from "./pages/Test";
import Thankyouonboarding from "./pages/Thankyouonboarding";
import Register1part2 from "./pages/Register1part2";
import Creditrequestlist from "./pages/Creditrequestlist";
import Creditrequest from "./pages/Creditrequest";
import Mycredit from "./pages/Mycredit";
import Tracksingleorder from "./pages/Tracksingleorder";
import Changeyourpassword from "./pages/Changeyourpassword";



function App() {
  const tokenvalue = gettoken();
  return (
    <div className="App">
      <Routes >
       {/* <Route path="/" element={<Landigpage />} />*/}
        {/* <Route path="/landingpage" element={<Landigpage />} /> */}
        {/* <Route path="/" element={<Login />} /> */}

        <Route path="/" element={<Clientloginpage />} />
        <Route path="/voucherlogin" element={<Voucherloginpage />} />
        <Route path="/home" element={tokenvalue ? <Home /> : <Clientloginpage />} />
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/track-order" element={<Trackorder />} /> 
        <Route path="/contactlogin" element={tokenvalue ? <Contactlogin /> : <Clientloginpage />} />
        {/* <Route path="/registerbusiness" element={<Register1 />} />
        <Route path="/registerbusiness2" element={<Register1part2 />} /> */}
        {/* <Route path="/registerbusiness" element={<Registerbusiness />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/mycredit" element={<Mycredit />} />
        <Route path="/creditrequest" element={<Creditrequestlist />} />
        <Route path="/addcreditrequest" element={<Creditrequest />} />
        <Route path="/cart" element={tokenvalue ? <Cart /> : <Clientloginpage />} />
        <Route path="/wishlist" element={tokenvalue ? <Wishlist/> : <Clientloginpage />} />
        <Route path="/checkout/" element={tokenvalue ? <Checkout/> : <Clientloginpage />} />
        <Route path="/pay" element={tokenvalue ? <Pay/> : <Clientloginpage />} />
        <Route path="/myaccount" element={tokenvalue ? <Myaccount/> : <Clientloginpage />} />
        <Route path="/testproduct" element={tokenvalue ? <Testproduct/> : <Clientloginpage />} />
        <Route path="/productdetails" element={tokenvalue ? <Productdetails/> : <Clientloginpage />} />
        <Route path="/productdetailstwo" element={tokenvalue ? <Productdetailstwo/> : <Clientloginpage />} />
        <Route path="/category/:id/:name/:url" element={tokenvalue ? <Category/> : <Clientloginpage />}  />
        <Route path="/categoryforbrand/:name" element={tokenvalue ? <Categoryforbrand/> : <Clientloginpage />}  />
        <Route path="/categoryforsearch/:name" element={tokenvalue ? <Categoryforsearch/> : <Clientloginpage />}  />
        <Route path="/profile" element={tokenvalue ? <Profile/> : <Clientloginpage />} />
        <Route path="/addresslist" element={tokenvalue ? <Addresslist/> : <Clientloginpage />} />
        <Route path="/order-history-detail/:orderid" element={tokenvalue ? <OrderHistoryDetails/> : <Clientloginpage />} />
        <Route path="/tracksingleorder/:orderid" element={<Tracksingleorder/>} />
        <Route path="/order-history" element={tokenvalue ? <OrderHistorytwo/> : <Clientloginpage />} />
        <Route path="/changeyourpassword" element={tokenvalue ? <Changeyourpassword/> : <Clientloginpage />} />
        <Route path="/voucher-request-add" element={tokenvalue ? <Voucherrequest/> : <Clientloginpage />} />
        <Route path="/voucher-request" element={tokenvalue ? <Voucherrequestlist/> : <Clientloginpage />} />
        <Route path="/credit" element={tokenvalue ? <Credit/> : <Clientloginpage />} />
        <Route path="/thankyoupage" element={tokenvalue ? <Thankyoupage/> : <Clientloginpage />} />
        <Route path="/useragreement" element={<Useragreement/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        {/* <Route path="/register1" element={<Register1/>} /> */}
        {/* <Route path="/register2" element={<Register2/>} />
        <Route path="/register3" element={<Register3/>} />
        <Route path="/register4" element={<Register4/>} />
        <Route path="/register5" element={<Register5/>} /> */}
        <Route path="/thankyoupagelogin" element={<Thankyoupagelogin/>} />
        <Route path="/thankyoupageforonboarding" element={<Thankyouonboarding/>} />
        <Route path="/termsconditions" element={<Termsconditions/>} />
        <Route path="/track-order-list" element={<OrderHistoryWithOutLogin/>} />
        <Route path="/verify-otp/:userId" element={<Verifyotp/>} />
        <Route path="*" element={<Clientloginpage />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </div>
  )

}

export default App;
