import React, { useEffect, useState } from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import axios from 'axios';
import { gettoken } from '../Localstorage/Store';
import { IoIosArrowDown } from "react-icons/io";
import Loginheader from '../components/Loginheader';

export const OrderHistoryWithOutLogin = () => {



  const nvg = useNavigate()
  const [data, setData] = useState([]);
  const [error, seterror] = useState("No orders found !");



  const [datastatus, setDatastatus] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  console.log("selectedStatus", selectedStatuses)
  const [selectedDuration, setSelectedDuration] = useState(null);
  console.log(selectedDuration, "selectedDuration")

  const location = useLocation();
  const clearFilter = () => {
    setSelectedStatuses([]); // Clear selectedStatuses array
    setSelectedDuration(null);
    fetchcategory() // Reset selectedDuration to null (or initial value)
};

  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false)
  const [filter1, setfilter1] = useState(false)
  const [filter2, setfilter2] = useState(false)
  const [filter3, setfilter3] = useState(false)
  const [filter4, setfilter4] = useState(false)
  const [filter5, setfilter5] = useState(false)
  const [filter6, setfilter6] = useState(false)
  const [filter7, setfilter7] = useState(false)
  const [color, setcolor] = useState(true)
  const [size, setsize] = useState(true)
  const [roderstatus, setroderstatus] = useState(true)
  const [owl, setowl] = useState('tab-1')
  const [filter, setfilter] = useState(true)
  const currentwdith = window.innerWidth;

  const clearfilter = () => {
    setfilter1(false)
    setfilter2(false)
    setfilter3(false)
    setfilter4(false)
    setfilter5(false)
    setfilter6(false)
    setfilter7(false)
  }







  useEffect(() => {
    async function fetchstatus() {

      let url = `${process.env.REACT_APP_API_URL}my_api/ordersstatus/`;

      const response = await axios.get(url);
      setDatastatus(response.data);
    }
    fetchstatus()

  }, [])

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = async () => {
    if (searchTerm == '') {
      fetchcategory()
    } else {
      const searchTermLower = searchTerm.trim().toLowerCase();

      const filteredOrders = data.filter(order =>
        order.number.toLowerCase().includes(searchTermLower)
      );
      setData(filteredOrders)

      console.log('Filtered orders:', filteredOrders);
      if (data.length > 0 && selectedDuration) {
        if (selectedDuration == '360') {
          const errorMessage = "No orders found last one year "
          seterror(errorMessage);

        } if (selectedDuration == '180') {
          const errorMessage = "No orders found last six Month "
          seterror(errorMessage);

        }
        if (selectedDuration == '30') {
          const errorMessage = "No orders found last 30 Days "
          seterror(errorMessage);

        }
        if (selectedDuration == '15') {
          const errorMessage = "No orders found last 15 Days "
          seterror(errorMessage);

        }



      }
    }

  };


  const handleStatusChange = async (status) => {
    const updatedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter(s => s !== status)
      : [...selectedStatuses, status];
    setSelectedStatuses(updatedStatuses);
    await sendDataToBackend(updatedStatuses, selectedDuration);
  };

  const handleCheckboxChange = async (duration) => {
    setSelectedDuration(duration);
    await sendDataToBackend(selectedStatuses, duration);
  };

  const sendDataToBackend = async (statuses, duration) => {
    const mobilenumber = location.state.mobile;
    console.log("mobilenumber_________", location.state.mobile)




    const url = `${process.env.REACT_APP_API_URL}my_api/orderswithoutlogin/`;
    const response = await axios.post(url, { operations: statuses, duration: duration, mobilenumber: mobilenumber });

    console.log("response from backend:", response);
    setData(response.data);
  };



  const handleStatusChangewindow = async (status) => {
    if(status == ""){
      fetchcategory()
    }else{
    const updatedStatuses = [status];
    setSelectedStatuses(updatedStatuses);
    await sendDataToBackend(updatedStatuses, selectedDuration);
  }
  };

  async function fetchcategory() {
    if (location.state.mobile && location.state.otp) {
      setloading(true)
      try {
        // let url = `${process.env.REACT_APP_API_URL}my_api/ordersstatus/`;
        let url = `${process.env.REACT_APP_API_URL}useraccount/order-list/`;
        const formdata = new FormData()
        formdata.append('mobile_number', location.state.mobile)
        formdata.append('otp', location.state.otp)
        // let url = `${process.env.REACT_APP_API_URL}api/orders/`;
        const response = await axios.post(url, formdata);
        console.log("order history response", response)
        console.log("order history response data", response.data)

        setData(response.data.data);
        console.log(response.data.data, "this new track order")
        setloading(false)
      } catch (error) {
        setloading(false)

      }
    }
  }
  useEffect(() => {

    fetchcategory()

  }, [])

  return (
    <div>
      {/* <Header /> */}
      {/* {tokenvalue ? <Header /> : <Loginheader />} */}
      <div>
        {/* breadcrumb start */}
        {/* <div
        className={
          tokenvalue
            ? "breadcrumb-main marginfromtop"
            : "breadcrumb-main marginfromtop23"
        }
      >
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/">home</a>
                    </li>
                    <li>{">"}</li>
                    <li>
                      <a href="javascript:void(0)">Order History</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* breadcrumb End */}
        <div>
          <section className="section-big-pt-space py-1 " style={{ background: "#f9f9f9" }}>
            <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-4 mt-xs-4 mb-5">

              <div className="container-fuild emp-profile">
                <div className="row pro">

                <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 py-lg-5 py-3 d-flex justify-content-center">
          <img
            // src={`./images/logo.png`}
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth"
            style={{maxWidth:'340px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>
                  {/* <div id="tab-2" style={{ display: owl == 'tab-2' ? 'block' : 'none' }} className={owl == 'tab-2' ? "tab-content active default product-block3" : "tab-content product-block3"}> */}
                  <div className="row py-xl-4 py-3">
                    {/* <div className={currentwdith < 730 ? filter == true ? "col-5 d-none px-0 align-items-center" : "col-3 d-none px-0 align-items-center" : "col-3 "} style={{ flexDirection: 'column', zIndex: 1, left: "-15px", display: filter == true ? "block" : "none" }}  >                      <div className="card" style={{ width: "92%" }}>
                      <div className="card-body">
                        <div className="collection-filter-block creative-card creative-inner category-side" style={{ padding: '0px 5px' }}>

                          <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title newarrow2" style={{ marginTop: "0px", fontSize: "12px" }} onClick={() => { setroderstatus(!roderstatus) }} >Order Status</h3>
                            <div className="collection-collapse-block-content" style={{ display: roderstatus ? "block" : "none" }}>
                              <div className="size-selector">
                                <div className="collection-brand-filter">
                                  {datastatus.map((statusData, index) => (
                                    <div key={index} className="custom-control custom-checkbox form-check collection-filter-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        id={`status-${index}`}
                                        onChange={() => handleStatusChange(statusData.status)}
                                        checked={selectedStatuses.includes(statusData.status)}
                                      />
                                      <label className="custom-control-label form-check-label" style={{ fontSize: "11px" }} htmlFor={`status-${index}`}>{statusData.status}</label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="collection-collapse-block open">
  <h3 className="collapse-block-title newarrow2" style={{ fontSize: "12px", marginBottom: "10px" }} onClick={() => { setsize(!size) }}>
    Order Time
  </h3>
  <div className="collection-collapse-block-content" style={{ display: size ? "block" : "none" }}>
    <div className="size-selector">
      <div className="collection-brand-filter" style={{ position: "relative", width: "150px" }}>
        <select
          className="form-control custom-select"
          value={selectedDuration}
          onChange={(e) => handleCheckboxChange(e.target.value)}
          style={{ width: "100%", paddingRight: "30px", borderRadius: "4px",fontSize:'13px' }}
        >
          <option value="">Select days</option>
          <option value="15">Last 15 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="180">Last 6 Months</option>
          <option value="360">Last 1 Year</option>
        </select>
        <div style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}>
     
          <IoIosArrowDown />
        </div>
      </div>
    </div>
  </div>
</div>




                        </div>
                      </div>
                    </div>
                    </div> */}



                    {/* <div className={currentwdith < 730 ? filter == false ? "col-12 Ohistory" : "col-12 Ohistory" : "col-9 Ohistory"} style={{ padding: "0px 4px" }} > */}
                    <div className={ "col-xl-10 col-md-12 offset-xl-1 offset-md-0 Ohistory "} style={{ padding: "0px 4px" }} >
                      <div className="input-group mb-3" style={{ gap: '10px',alignItems:'end' }}>
                        <div className="fiter">
                          {/* <label htmlFor="" style={{margin:'0px'}}> Order Stauts</label> */}
                          {/* {datastatus.map((statusData, index) => (
                                    <div key={index} className="custom-control custom-checkbox form-check collection-filter-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        id={`status-${index}`}
                                        onChange={() => handleStatusChange(statusData.status)}
                                        checked={selectedStatuses.includes(statusData.status)}
                                      />
                                      <label className="custom-control-label form-check-label" style={{ fontSize: "11px" }} htmlFor={`status-${index}`}>{statusData.status}</label>
                                    </div>
                                  ))} */}
                          <select
          className="form-control custom-select"
          onChange={(e) => handleStatusChangewindow(e.target.value)}
          style={{ width: "100%", paddingRight: "50px", borderRadius: "0px",fontSize:'13px' }}
        >
          <option value="">Select Order Status</option>

{datastatus.map((statusData, index) => (
                                   <option value={statusData.status}>{statusData.status}</option>    
                                  ))}
          {/* <option value="">Select days</option>
          <option value="15">Last 15 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="180">Last 6 Months</option>
          <option value="360">Last 1 Year</option> */}
        </select>
                        </div>
                        <div className="fiter">
                          {/* <label htmlFor="" style={{margin:'0px'}}> Select Days</label> */}
                          <select
          className="form-control custom-select"
          value={selectedDuration}
          onChange={(e) => handleCheckboxChange(e.target.value)}
          style={{ width: "100%", paddingRight: "50px", borderRadius: "0px",fontSize:'13px' }}
        >
          <option value="">Select days</option>
          <option value="15">Last 15 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="180">Last 6 Months</option>
          <option value="360">Last 1 Year</option>
        </select>
                        </div>
                        <input
                          type="text"
                          style={{ borderRadius: "0px",letterSpacing:'1px',height:'fit-content',fontSize:'14px' }}
                          className="form-control newsizeinput"
                          placeholder="Search your orders id here"
                          aria-label="Recipient's username"
                          
                          aria-describedby="button-addon2"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state as user types
                        />
                        <button
                          className='Sbtn btn btn-outline-secondary'
                          style={{ background: '#230bb3', color: 'white', borderRadius: '0px',height:'fit-content', fontSize: "14px" }}
                          type="button"
                          id="button-addon2"
                          onClick={handleSearch} // Call handleSearch function when the button is clicked
                        >
                          <i className="fa fa-search"></i>&nbsp; Search
                        </button>
                      </div>
                      {/* <div className="filter-main-btn m-0" style={{display :currentwdith < 730 ? "block" : "none"}} onClick={()=>{setfilter(!filter)}}><span className="filter-btn  "><i className="fa fa-filter" aria-hidden="true" /> Filter</span></div> */}
                      <div className="filter-main-btn m-0" style={{ display: currentwdith < 730 ? "block" : "none" }} >          <button
                        type="button"
                        className="hidecontent my-2"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "white",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalfour"
                      > <span className="filter-btn " style={{ fontSize: '12px' }}><i className="fa fa-filter" aria-hidden="true" /> Filter</span></button></div>

                      <div className="table-responsive" >
                        <table className="table  orstatus" >

                          {/* <table className="table"> */}
                          <thead>
                            <tr>

                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Order Id</div></th>

                              <th scope="col"><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Order Date</div></th>




                              <th scope="col"><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Total Quantity</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Payment Mode</div></th>
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Amount</div></th>

                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Order Status</div></th>
                               <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>
                                {/* Action */}
                              </div></th>
                            </tr>
                          </thead>
                          {/* </table> */}



                          <thead className="table-light" style={{ paddingTop: '20px' }}>
                            <tr>

                            </tr>
                          </thead>
                          <tbody >
                            {data[0] ? (data.map((item, index) => (
                              <tr  key={index}>


                                <td style={{ color: '#230BB3', paddingTop: '20px', fontSize: '12px' }}>

                                  <h5
                                  //  onClick={() => { nvg(`/order-history-detail/${item.number}`) }}
                                   style={{ fontSize: "12px", lineHeight: "22px",color:"",cursor:'' }}> {item.number}</h5>
                                </td>
                                <td style={{ paddingTop: '20px', fontSize: '12px', }}> {new Date(item.date_placed.split('Time')[0]).toLocaleDateString('en-GB', {
                                  hour: 'numeric',
                                  minute: 'numeric'
                                }


                                )}</td>





                                <td style={{ paddingTop: '20px', fontSize: '12px',position:'relative',left:"10px" }}>{item.product_count} Items </td>
                                <td style={{ paddingTop: '20px', fontSize: '12px' }}>
                                  {/* {item.payment_types == "razorpay" ? "Online Pay" : "COD"} */}
                                   {item.payment_types == "Razorpay" || item.payment_types == "razorpay" ? "Online Pay" : item.payment_types == "Wallet" ? "Wallet" : item.payment_types == "Custome" ? "Custom" : item.payment_types == "Credit" ? "Credit" : "COD"}</td>
                                <td style={{ paddingTop: '20px', fontSize: '12px' }}>₹{item.total_incl_tax}</td>

                                <td>
                                  <p style={{ paddingTop: '15px', fontSize: '12px' }}> <span ><img src="./images/icon/success.png" alt="404" /></span> &nbsp;               {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                  </p>
                                </td>

                                <td>
                                  <p style={{ paddingTop: '15px', fontSize: '12px',color:'#230bb3',fontWeight:'700',cursor:'pointer' }} onClick={() => { nvg(`/tracksingleorder/${item.number}`) }}> Track Order </p>
                                </td>

                              </tr>
                            ))) : (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{ fontWeight: "500" }}
                                >
                                  {error}
                                </td>
                              </tr>
                            )}



                          </tbody>
                        </table>
                      </div>

                    </div>

                  </div>






                </div>
              </div>
            </div>
          </section>

        </div>


        <div
          className="modal fade"
          id="exampleModalfour"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          style={{ zIndex: 9383838 }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl" style={{ position: 'relative', bottom: '0px', margin: "0px", alignItems: 'flex-end', height: '100vh' }}>
            <div className="modal-content" style={{ flexDirection: 'column-reverse' }}>
              <div className="modal-header mod-line">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundImage: "none", fontSize: '12px', margin: '0px', width: '44%', opacity: 1, height: "auto", border: "1px solid #b5b5b5" }}
                >Cancel</button>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ fontSize: '12px', backgroundImage: "none", margin: '0px', width: '44%', backgroundColor: "#4150b5", opacity: 1, height: "auto", color: "white" }}
                >Apply</button>
              </div>
              <div className="modal-body">
                <div className="filterheader d-flex justify-content-between">
                  <h5 style={{ fontSize: '12px' }}>Filters</h5>
                  <h5 style={{ color: "#acacac", fontSize: '12px' }} onClick={clearFilter}>Clear Filters</h5>
                </div>
                <div className="orderstatus py-3">
                  <h6 className='py-2' style={{ fontSize: '12px' }}>Order Status</h6>
                  <div className="odr-btn d-flex" style={{ flexWrap: 'wrap', gap: "12px" }}>
    {datastatus.map((statusData, index) => (
        <div key={`status-${index}`} style={{ marginBottom: '5px' }}>
            <input
                type="checkbox"
                id={`status-${index}`}
                onChange={() => handleStatusChange(statusData.status)}
                checked={selectedStatuses.includes(statusData.status)}
                style={{ display: 'none' }} 
            />
            <label
                htmlFor={`status-${index}`}
                style={{
                    border: "1px solid #b5b5b5",
                    padding: '3px 9px',
                    borderRadius: '20px',
                    backgroundColor: selectedStatuses.includes(statusData.status) ? "#4150b5" : "white",
                    color: selectedStatuses.includes(statusData.status) ? "#fff" : "#000", // Text color
                    fontSize: '11px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {statusData.status}
                {selectedStatuses.includes(statusData.status) ? (
                    <AiOutlineCheck style={{ marginLeft: '5px' }} />
                ) : (
                    <AiOutlinePlus style={{ marginLeft: '5px' }} />
                )}
            </label>
        </div>
    ))}
</div>
                </div>
                <div className="orderstatus py-3">
                  <h6 className='py-2' style={{ fontSize: '12px' }}>Order Time</h6>
                  <div className="odr-btn d-flex" style={{ flexWrap: 'wrap', gap: "12px" }}>
            <div
                onClick={() => handleCheckboxChange('15')}
                style={{
                    border: "1px solid #b5b5b5",
                    padding: '3px 9px',
                    borderRadius: '20px',
                    backgroundColor: selectedDuration === '15' ? "#4150b5" : "white",
                    color: selectedDuration === '15' ? "#fff" : "black",
                    fontSize: '13px',
                    cursor: 'pointer'
                }}
            >
                Last 15 Days {selectedDuration === '15' ? <AiOutlineCheck /> :""}
            </div>

            <div
                onClick={() =>handleCheckboxChange('30')}
                style={{
                    border: "1px solid #b5b5b5",
                    padding: '3px 9px',
                    borderRadius: '20px',
                    backgroundColor: selectedDuration === '30' ? "#4150b5" : "white",
                    color: selectedDuration === '30' ? "#fff" : "black",
                    fontSize: '13px',
                    cursor: 'pointer'
                }}
            >
                Last 30 Days {selectedDuration === '30' ? <AiOutlineCheck /> :""}
            </div>

            <div
                onClick={() => handleCheckboxChange('180')}
                style={{
                    border: "1px solid #b5b5b5",
                    padding: '3px 9px',
                    borderRadius: '20px',
                    backgroundColor: selectedDuration === '180' ? "#4150b5" : "white",
                    color: selectedDuration === '180' ? "#fff" : "black",
                    fontSize: '13px',
                    cursor: 'pointer'
                }}
            >
                Last 6 Months {selectedDuration === '180' ? <AiOutlineCheck /> :""}
            </div>

            <div
                onClick={() => handleCheckboxChange('360')}
                style={{
                    border: "1px solid #b5b5b5",
                    padding: '3px 9px',
                    borderRadius: '20px',
                    backgroundColor: selectedDuration === '360' ? "#4150b5" : "white",
                    color: selectedDuration === '360' ? "#fff" : "black",
                    fontSize: '13px',
                    cursor: 'pointer'
                }}
            >
                Last 1 Year {selectedDuration === '360' ? <AiOutlineCheck /> : ""}
            </div>
        </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>


    </div>
  )
}
export default OrderHistoryWithOutLogin;
