import * as Yup from "yup";

export const Clientlogin = Yup.object({
  email: Yup.string()
    .label("Email Address")
    // .email("Invalid Email Address")
    .max(45)
    .required("Email is required"),
  password: Yup.string()
    .label("Password")
    // .min(4,"Password must be at least 4 characters")
    .max(29)
    .required("Password is required"),
});
