import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { gettoken } from "../Localstorage/Store";
import Loginheader from "../components/Loginheader";

export const Useragreement = () => {
  const tokenvalue = gettoken();

  return (
    <div>
      {tokenvalue ? <Header /> : <Loginheader />}

      <div>
        {/* breadcrumb start */}
        <div
          className={
            tokenvalue
              ? "breadcrumb-main marginfromtop"
              : "breadcrumb-main marginfromtop23"
          }
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    <ul>
                      <li>
                        <a href="/">home</a>
                      </li>
                      <li>{">"}</li>
                      <li>
                        <a href="javascript:void(0)">User Agreement</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb End */}
        <section
          className="section-big-pt-space pb-2"
          style={{ backgroundColor: "#ececec" }}
        >
          <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4 mb-5">
            <div className="container-fuild emp-profile">
              <div className="row profile2">
                <section className="tab-product-main-tab">
                  <div className="row mt-2">
                    <h3 className="privacy">USER AGREEMENT</h3>
                    <div className="row ml-auto mr-auto mt-4 pb-2 ct_privacy_para">
                      {/* <p className="bold"> Last Updated: 20.06.2023</p> */}

                      <p>
                      By accessing and using Oneup Brands, you agree to comply with our terms and conditions. You must be at least 18 years old and provide accurate, complete, and current information when creating an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. Our services are intended for lawful purposes only, and you agree not to use our services in any manner that could damage, disable, overburden, or impair our servers or networks. Additionally, you agree not to interfere with any other party's use and enjoyment of our services or infringe upon the rights of others, including intellectual property rights.
<br /><br />
All purchases made through our website are subject to our pricing, payment, and refund policies. Prices are listed in  ₹ and may change without notice. You agree to pay all charges associated with your purchases, including applicable taxes. Payment must be made at the time of purchase through the accepted payment methods, which include Online Pay and COD. We aim to provide accurate product and pricing information; however, errors may occur. In the event that a product is listed at an incorrect price or with incorrect information due to an error, we reserve the right to refuse or cancel any orders placed for that product.
<br /><br />
We are not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses. This limitation of liability applies whether the damages arise from the use or misuse of our services, from inability to use our services, or from the interruption, suspension, or termination of our services. We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these terms, is harmful to other users of our services, or is otherwise illegal or inappropriate.
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* </div> */}

        {/* <Footer /> */}

        {/* footer start */}
      </div>
    </div>
  );
};
export default Useragreement;
