import * as Yup from 'yup';

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const FILE_SIZE = 1024 * 1024; // 1MB

export const Register5val = Yup.object({
  // bankname: Yup.string()
  //   .label("Bank Name"),
    // .required("Bank Name is required"),
  // bankaccountnumber: Yup.number()
  //   .label("Bank Account Number"),
    // .required("Bank Account Number is required"),
  // billingaddress: Yup.string()
  //   .label("Billing Address")
  //   .required("Billing Address is required"),
  // bankaddress: Yup.string()
  //   .label("Bank Address"),
    // .required("Bank Address is required"),
  // ifsc: Yup.string()
  //   .label("IFSC Code"),
    // .required("IFSC Code is required"),
  // terms: Yup.boolean()
  //   .oneOf([true], "You must accept the terms and conditions")
  //   .required("You must accept the terms and conditions"),
  pan: Yup.mixed()
    .label("PAN")
    .required("PAN Image is required"),
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
  gst: Yup.mixed(),
    // .required("GST Image is required")
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    certificateofcorporation: Yup.mixed()
    .label("Certificate of corporation"),
    // .required("Certificate of corporation Image is required")
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    msme: Yup.mixed()
    .label("Msme"),
    // .required("Msme Image is required")
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    authorizationletter: Yup.mixed()
    .label("Authorization letter"),
    // .required("Authorization letter Image is required")
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    cancelledcheque: Yup.mixed()
    .label("Cancelled Cheque"),
    // .required("Cancelled Cheque Image is required")
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   value => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format",
    //   value => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
    terms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions")
});
