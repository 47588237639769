import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import OwlCarousel from "react-owl-carousel";
import ReactImageMagnify from "react-image-magnify";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegHeart,FaHeart } from "react-icons/fa6";
import axios from "axios";
import { getrecetly, gettoken, recentlystore, removeToken } from "../Localstorage/Store";
import Cartcount from "../context/Cartcount";
import Recently from "../context/Recently";
import Recentlyowl from "../components/Recentlyowl";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addwishlist } from "../store/state/wishlist";
import { Field, Form, Formik } from "formik";
import { checkoutvalidation } from "../Validation/Checkout";
import { getCode } from "country-list";
import { addItem } from "../store/state/cart";
import Loader from "../components/Loader";

const options5 = {
  items: 1,
  loop: false,
  autoplay: true,
  nav: true,
  responsiveClass: true,
  dots: false,
  responsive: {
    1200: {
      items: 5,
      loop: false,
      // stagePadding: 50,
    },
    920: {
      items: 4,
      loop: false,
    },
    700: {
      items: 3,
      loop: false,
    },
    600: {
      items: 3,
      loop: false,
    },
    504: {
      items: 2,
      loop: false,
    },
    300: {
      items: 2,
      loop: false,
    },
    310: {
      items: 1,
      loop: false,
    },
  },
};
function Productdetails() {
  const [viewimg, setviewimg] = useState(null);
  const [qty, setqty] = useState(1);
  const gobalvariable = useSelector(state => state);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const [addressstatus, setaddressstatus] = useState(false);
  const [delto, setdelto] = useState("");
  const [Data, setData] = useState([]);
  const [fixedData, setfixedData] = useState([]);
  const [Data3, setData3] = useState([]);
  const [popstatus, setpopstatus] = useState(false);
  const [attrlist, setattrlist] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const [delresponse, setdelresponse] = useState({ status: false, msg: "" });
  const [countriesdata, setcountriesdata] = useState([]);
  const checkpin = useRef(null);
  const location = useLocation();
  const nvg = useNavigate();
  const tokenvalue = gettoken();
  const recentlydata = getrecetly();
  const searchString = `${process.env.REACT_APP_API_URL}media/`;
  // const [Cartnumber, setCartnumber] = useContext(Cartcount);
  // const [recentlyViewed, setRecentlyViewed] = useContext(Recently);
  const extractText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent.substring(0, 15) || "";
  };

 const [variantdata,setvariantdata] = useState({})
// console.log("ral data of variant nnn",fixedData)

  function getProductByAttribute2(products, attributeName, attributeValue) {
// console.log("kdkdkdkdkkd",products,attributeName,attributeValue)
    return products.find(product => {
      const attribute = product.attributes.find(attr => attr.attribute__name === attributeName);
      return attribute && attribute.value_option__option == attributeValue;
    });
    
  }
  function getProductByAttribute(products, attributeName, attributeValue) {

console.log("hello this is new msg",products, attributeName, attributeValue,Data)

const remainingAttributes = Data.attributes.filter(attr => attr.attribute__name !== attributeName);


// old code here start 
    // const variant = getProductByAttribute2(products, attributeName, attributeValue,Data);
    // setData(variant);
// old code here start 

const matchedVariant = products.find(product => {
  return remainingAttributes.every(attr => {
      // Check if the product has the same attribute and value
      const productAttr = product.attributes.find(pAttr => pAttr.attribute__name === attr.attribute__name);
      return productAttr && productAttr.value_option__option === attr.value_option__option;
  }) && product.attributes.some(attr => attr.attribute__name === attributeName && attr.value_option__option === attributeValue);
});

    
    const sortedImages = matchedVariant.image.sort((a, b) => a.display_order - b.display_order);
    setviewimg(`${process.env.REACT_APP_API_URL}media/${sortedImages?.[0]?.original}`);
    setData({sortedImages,...matchedVariant});

    // console.log("this is from variant is shre",sortedImages,"this is here section",{sortedImages,...variant})
  }



// console.log("state value is here",location.state)

  const buttonRef = useRef(null);


  const handleClick = () => {
    const buttonNode = buttonRef.current;
    if (buttonNode) {
      buttonNode.click();
    }
  };

  const createaddress = async (values,setFieldError) =>{

   try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      const formdata = new FormData();
                formdata.append("first_name", values.first_name);
                formdata.append("last_name", values.last_name);
                formdata.append("phone_number", `+91${values.mobile}`);
                formdata.append("line1", values.address1);
                formdata.append("line2", values.address2);
                formdata.append("country", getCode(values.country));
                formdata.append("state", values.state);
                formdata.append("email", values.email);
                formdata.append("line4", values.city);
                formdata.append("postcode", values.pincode);
                formdata.append("address_type", values.address_type);
                if(tokenvalue.msg == "Voucher login"){
                  formdata.append("user", tokenvalue.user_id);
                }else{
                  formdata.append("user", tokenvalue.id);
                }
      // setloading(true)
      // let url = `${process.env.REACT_APP_API_URL}api/useraddresses/`;
      let url = `${process.env.REACT_APP_API_URL}homepageapi/address/create/`;
      const response = await axios.post(url,formdata,config);
      if(response.data?.id){
        // console.log("reespnse ravi vffdddrredd",response.data)
        getaddress()
        setaddressstatus(true)
        setTimeout(() => {
          setaddressstatus(false)
          handleClick()
        }, 3000);
      }else{
        
      }
    } catch (error) {
      setFieldError('mobile',error.response.data.phone_number[0])
    }

  
   }
















const [cityna,setcityna] = useState("")
  const fetchstateandcity = async (value, setFieldError, setFieldValue) => {
    if (value.length == 6) {
      try {
        let url = `${process.env.REACT_APP_API_URL}my_api/get-postcode-information/?postal_code=${value}`;
        const response = await axios.get(url);
        // setFieldValue("city", response.data.city);
        if(response.data.city == "NA"){
          setFieldValue('city','');
          setcityna("NA");
        setFieldError('city',"Please Enter Your City")
        }else{
          setFieldValue('city',response.data.city);
          setcityna("");
        }
        setFieldValue("state", response.data.state);
        const countrydata = countriesdata.filter(
          (item2) => item2.printable_name == response.data.country.trim()
        );
        // console.log(";;;;;;", countrydata);
        setFieldValue("country", countrydata[0].printable_name);
        setFieldValue("countryurl", countrydata[0].url);
      } catch (error) {
        setFieldError("pincode", error.response.data.message);
      }
    } else {
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("country", "");
      setFieldValue("countryurl", "");
    }
  };

  const [checkloader,setcheckloader] = useState(false)
  const devto = async(itemid) => {
    if (delto == "") {
      setdelresponse({ status: true,color:"red", msg: "Deliver to field is required" });
    } else {
      setcheckloader(true)
      try {
        const formdata = new FormData();
        formdata.append("qty", qty);
        formdata.append("pin_code", delto);
        formdata.append("product_id", itemid);
        let url = `${process.env.REACT_APP_API_URL}ship/check-available/`;
        const response = await axios.post(url, formdata);
        // console.log("response of picode api",response);
        if(response.data.data == "delvery available"){

          let url2 = `${process.env.REACT_APP_API_URL}ship/get_single_product_price/?pin=${delto}&id=${itemid}&quantity=${qty}`;
      const response2 = await axios.get(url2);
          setdelresponse({
            status: true,
            msg: "Delivery available on this location",
            color: "green",
            Delivery:response2.data.rate
          });
      setcheckloader(false);
          // setdelto("");
        }else{
      setdelresponse({ status: true,color:"red", msg: "delivery not Available" });
      setcheckloader(false);
        }
        
    // setTimeout(() => {
    //   setdelresponse({ status: false, msg: "" });
    // }, 4000);
      } catch (error) {
        console.log("error", error);
      setdelresponse({ status: true,color:"red", msg: "Delivery not Available" });
    setcheckloader(false)
      }
     
    }
  };

  const redirectfun = (linkpage) => {
    nvg(linkpage);
  };

  const profilepage = (val) => {
    nvg("/profile", { state: { id: val } });
  };

  const incrementcart = () => {
    // console.log("qty of product ",qty);
    setqty(parseInt(qty) + 1);
    // console.log("qty of product add",parseInt(qty) + 1);

  };
  const decrementcart = () => {
    // console.log("qty of product delete ",qty);
    if (qty > 1) {
      setqty(parseInt(qty) - 1);
    // console.log("qty of product sub",parseInt(qty) - 1);
    }
  };


  async function Cartvalueget() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url = `${process.env.REACT_APP_API_URL}homepageapi/CartCountOfUser`;
    try {
      const response = await axios.get(url, config);
      // setCartnumber(response.data.cart_count)
      dispatch(addItem(response.data.cart_count));

    } catch (error) {
      // console.log("error unsr",error.response.status)
      if(error.response.status == 401){
        // removeToken()
        
        // window.location.reload();
    // window.location.href = "/";

      }
    }
  }


  async function Wishvalueget() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url56 = `${process.env.REACT_APP_API_URL}homepageapi/GetCountOfWishlist`;
    try {
      const response = await axios.get(url56, config);
      // console.log("first vvvbbbbbbnnnnn super response",response);
      // setWishlistnumber(response.data.wishlist_count)
      dispatch(addwishlist(response.data.wishlist_count));

    } catch (error) {
    }
  }




const [addtocarttime,setaddtocarttime] = useState(false)
  const Addtocart = async (id) => {
    setaddtocarttime(true)
    // console.log("this is real add to cart fun")
    if(popstatus == false){
    // console.log("this is real add to cart fun 2")
      const finalstock = Data.stock_record.num_in_stock - 0;
      // const finalstock = Data.stock_record.num_in_stock;

    if(finalstock > qty || finalstock == qty){
    console.log("this is real add to cart fun 4",finalstock,"thisis",qty)
    try {
      const formdata = new FormData();
      formdata.append("quantity", qty);
      formdata.append(
        "url",
        `${process.env.REACT_APP_API_URL}api/products/${id}/`
      );
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}my_api/basket/add-product/`;
      // let url = `${process.env.REACT_APP_API_URL}api/basket/add-product/`;
      const response = await axios.post(url, formdata, config);
      if (response.data?.id) {
        // nvg("/cart");
        Cartvalueget()
        toast.success(' Add to Cart Successfully', {
          position: "top-center",
          autoClose: 3000,
          containerId:'wishlist23'
          // hideProgressBar: true,
          // closeOnClick: true,
          // pauseOnHover: true,
          // draggable: true,
          // progress: undefined,
          // theme: "light",
          });

          setTimeout(() => {
            // setpopstatus(false)
            setaddtocarttime(false)
        // window.location.reload();          
          }, 2000);
        // window.location.reload();
      }
    } catch (error) {
      // console.log("error", error.response.data);
      setpopstatus(true)
      let existingToastId = toast.error(error.response.data.reason, {
         position: "top-center",
         autoClose: 2000,
         containerId:"addtocart"
         // hideProgressBar: true,
         // closeOnClick: true,
         // pauseOnHover: true,
         // draggable: true,
         // progress: undefined,
         // theme: "light",
         });
         setTimeout(() => {
           setpopstatus(false)
           toast.dismiss({ containerId: "addtocart" })
         }, 2000);
    }
  }else{
    // Quantity is too large
    setpopstatus(true)
   let existingToastId = toast.error(`Sorry! We don't have any more units for this item`, {
      position: "top-center",
      autoClose: 2000,
      containerId:"addtocart"
      // hideProgressBar: true,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: "light",
      });
      setTimeout(() => {
        setpopstatus(false)
        toast.dismiss({ containerId: "addtocart" })
      }, 2000);
  }
  }
  };


  const removewishlist = async (id) => {
    // if(popstatus == false){
      setaddtocarttime(true)
    try {
      const realone = wishlist.filter((item)=> item.product_id == id);
      let url = `${process.env.REACT_APP_API_URL}homepageapi/DeleteProductFromWishlist/${realone[0].id}/`;
      const response = await axios.delete(url);
      if (response.data.message == "success") {
        // setpopstatus(true)
        Wishvalueget()
        toast.success(' Removed from your Wishlist', {
          position: "top-center",
          autoClose: 3000,
          containerId:'wishlist23'
          // hideProgressBar: true,
          // closeOnClick: true,
          // pauseOnHover: true,
          // draggable: true,
          // progress: undefined,
          // theme: "light",
          });

          setTimeout(() => {
            // setpopstatus(false)
        fetchcategory(location.state.id,location.state.pname);
            setaddtocarttime(false)
        // window.location.reload();          
          }, 2000);


      }
    } catch (error) {
      // console.log("error", error);
      setloading(false)
    }
  // }
  };
  const Addtowishlist = async (id) => {
    // if(popstatus == false){
      setaddtocarttime(true)
    try {
      const formdata = new FormData();
      formdata.append(
        "url",
        `${process.env.REACT_APP_API_URL}api/products/${id}/`
      );
      //  formdata.append("title",title);
      //  formdata.append("name","My Wishlist");
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}homepageapi/add-wishlist-view/`;
      const response = await axios.post(url, formdata, config);
      // console.log("add to cart response", response);
      if (response.data.message == "product sucessfully added to wishlist") {
        //  }
        //   if(response.data?.id){
        // window.location.reload();
        // setpopstatus(true)

        Wishvalueget()
        toast.success('Added to your Wishlist', {
          position: "top-center",
          autoClose: 2000,
          containerId:'wishlistadd2300'
          // hideProgressBar: true,
          // closeOnClick: true,
          // pauseOnHover: true,
          // draggable: true,
          // progress: undefined,
          // theme: "light",
          });

          
          setTimeout(() => {
            fetchwishlist()
        fetchcategory(location.state.id,location.state.pname);

            setaddtocarttime(false)
            // setpopstatus(false)
        // window.location.reload();          
          }, 2000);
          
        // console.log("add to cart response", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  // }
  };

const [addressdataindex,setaddressdataindex] = useState(null)
// console.log("sfffffttttyyyuiuoioppoiiii bbbbbbb",addressdataindex);
async function getcountries() {
  let url = `${process.env.REACT_APP_API_URL}api/countries/`;
  try {
    const response = await axios.get(url);

    setcountriesdata(response.data);
  } catch (error) {}
}

const [addloader,setaddloader] = useState(true)
  async function getaddress() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        "Content-Type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_API_URL}homepageapi/address/list`;
    try {
      const response = await axios.get(url, config);
// console.log("sfffffttttyyyuiuoioppoiiii bbbbbb inside resb",response);
      if (response.data[0].id) {
        setaddressdataindex(response.data[0]);
        setdelto(response.data[0].postcode)
        // devto()
        setaddloader(false)
        // setaddressdataindex(response.data[response.data.length - 1])
      }else{
        setaddloader(false)
      }
    } catch (error) {
      setaddloader(false)
      // setloading(false)
      // console.log(error)
    }
  }

  let ispresent = false;


  const formatImages = (images) => {
    // Define the desired order of suffixes
    const order = ['-A', '-B', '-C', '-D'];
  
    // Sort the images based on the suffix order
    const sortedImages = images.sort((a, b) => {
      const suffixA = a.original.match(/-(A|B|C|D)/)[1];
      const suffixB = b.original.match(/-(A|B|C|D)/)[1];
      return order.indexOf(`-${suffixA}`) - order.indexOf(`-${suffixB}`);
    });
  
    return sortedImages;
  };





  async function fetchcategory(id,titlename) {
    setloading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}my_api/products/${id}`;
    // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetProductDetailIdWise/${id}`;
    const response = await axios.get(url,config);
    // setData(response.data);
    const existdata = response.data.arrayProd.filter((item)=> item.title == titlename);
    // console.log("first bbbbb",existdata)
    // console.log("existdataksskksssssssssssssssss",existdata[0])
    // setData(existdata[0]);
    // const formimg = formatImages(existdata[0].image);
    const sortedImages = existdata[0].image.sort((a, b) => a.display_order - b.display_order);
    // const newpohoto = [...formimg];
    // console.log("existdataksskksssssssssssssssss vvvvvvvvvvvvvvvvvvvvv",newpohoto);
    setData({sortedImages,...existdata[0]});
    // console.log("this is something is",{sortedImages,...existdata[0]})
// console.log("ddkkkdkdcc,,c,ccccccccccccccccccccccccccccccvvvvvvvvvvvvvvvv",Data)
    // setfixedData(response.data.arrayProd[0]);
    
    setfixedData(response.data);
    getcountries()
    getaddress()
    // setTimeout(() => {
      
    //   devto(existdata[0].id)
    // }, 2000);
    const uniqueKeysSet = new Set();

// Use filter to get unique products based on the key
const uniqueProducts = response.data.arrayProd.filter((product) => {
if (!uniqueKeysSet.has(product.id)) {
  uniqueKeysSet.add(product.id);
  return true;
}
return false;
});
// console.log("just do it ",uniqueProducts)

    const flattenedAttributes = uniqueProducts.flatMap(
      (child) => child.attributes
    );
    // const flattenedAttributes = response.data.children.flatMap(
    //   (child) => child.attributes
    // );
// console.log("attribute lis ",flattenedAttributes);
    const groupedAttributes = flattenedAttributes.reduce(
      (acc, attribute) => {
        const { attribute__name, value_option__option } = attribute;
        acc[attribute__name] = acc[attribute__name] || [];
        acc[attribute__name].push(value_option__option);
        return acc;
      },
      {}
    );
    // const groupedAttributes = flattenedAttributes.reduce(
    //   (acc, attribute) => {
    //     const { name, value } = attribute;
    //     acc[name] = acc[name] || [];
    //     acc[name].push(value);
    //     return acc;
    //   },
    //   {}
    // );
    // console.log("grouddd ",groupedAttributes)
    setattrlist(groupedAttributes);
 
    // addProductToRecentlyViewed(response.data, response.data.id);
    // addProductToRecentlyViewed(response.data.data[0],response.data.data[0].id)
    // console.log("new ponooo dd",newpohoto)
    setviewimg(`${process.env.REACT_APP_API_URL}media/${sortedImages?.[0]?.original}`)
    // setviewimg(`${sortedImages?.[0]?.original}`)
    // setviewimg(`${response.data?.images?.[0]?.original}`)
    // setviewimg(`${process.env.REACT_APP_API_URL}media/${response.data?.children?.[0]?.image[0]?.original}`);
    setTimeout(() => {
    setloading(false);
      
    }, 1000);
  }











  async function fetchwishlist(){
    try {
      
    
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}homepageapi/wishlist-deatail/`;
    const response = await axios.get(url,config);
    setwishlist(response.data);
  } catch (error) {
      console.log("error",error)
    setwishlist([]);
    }
  }


  useEffect(() => {
    const addProductToRecentlyViewed = (mydata, id) => {
      if (recentlydata == null) {
        recentlystore([id]);
      } else {
        if (recentlydata.some((obj) => obj === id)) {
          ispresent = true;
        } else {
        }
        if (ispresent == false) {
          // if (recentlyViewed.length < 13) {
          //   console.log("first 13 leng")
          //   // If the array size is less than the maximum, simply add the new object
          //   setRecentlyViewed([...recentlyViewed, mydata]);
          // } else {
          // If the array size is equal to the maximum, replace the oldest element

          recentlystore([...recentlydata, id]);
          // }
        }
      }
    };

    

    if (location.state.id && location.state.pname) {
      async function fetchcategory(id,titlename) {
        setloading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}my_api/products/${id}`;
        // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetProductDetailIdWise/${id}`;
        const response = await axios.get(url,config);
        // setData(response.data);
        const existdata = response.data.arrayProd.filter((item)=> item.title == titlename);
        // console.log("first bbbbb",existdata)
        // console.log("existdataksskksssssssssssssssss",existdata[0])
        // setData(existdata[0]);
        // const formimg = formatImages(existdata[0].image);
        const sortedImages = existdata[0].image.sort((a, b) => a.display_order - b.display_order);
        // const newpohoto = [...formimg];
        // console.log("existdataksskksssssssssssssssss vvvvvvvvvvvvvvvvvvvvv",newpohoto);
        setData({sortedImages,...existdata[0]});
        // console.log("this is something is",{sortedImages,...existdata[0]})
// console.log("ddkkkdkdcc,,c,ccccccccccccccccccccccccccccccvvvvvvvvvvvvvvvv",Data)
        // setfixedData(response.data.arrayProd[0]);
        
        setfixedData(response.data);
        getcountries()
        getaddress()
        // setTimeout(() => {
          
        //   devto(existdata[0].id)
        // }, 2000);
        const uniqueKeysSet = new Set();

  // Use filter to get unique products based on the key
  const uniqueProducts = response.data.arrayProd.filter((product) => {
    if (!uniqueKeysSet.has(product.id)) {
      uniqueKeysSet.add(product.id);
      return true;
    }
    return false;
  });
  // console.log("just do it ",uniqueProducts)

        const flattenedAttributes = uniqueProducts.flatMap(
          (child) => child.attributes
        );
        // const flattenedAttributes = response.data.children.flatMap(
        //   (child) => child.attributes
        // );
// console.log("attribute lis ",flattenedAttributes);
        const groupedAttributes = flattenedAttributes.reduce(
          (acc, attribute) => {
            const { attribute__name, value_option__option } = attribute;
            acc[attribute__name] = acc[attribute__name] || [];
            acc[attribute__name].push(value_option__option);
            return acc;
          },
          {}
        );
        // const groupedAttributes = flattenedAttributes.reduce(
        //   (acc, attribute) => {
        //     const { name, value } = attribute;
        //     acc[name] = acc[name] || [];
        //     acc[name].push(value);
        //     return acc;
        //   },
        //   {}
        // );
        // console.log("grouddd ",groupedAttributes)
        setattrlist(groupedAttributes);
     
        addProductToRecentlyViewed(response.data, response.data.id);
        // addProductToRecentlyViewed(response.data.data[0],response.data.data[0].id)
        // console.log("new ponooo dd",newpohoto)
        setviewimg(`${process.env.REACT_APP_API_URL}media/${sortedImages?.[0]?.original}`)
        // setviewimg(`${sortedImages?.[0]?.original}`)
        // setviewimg(`${response.data?.images?.[0]?.original}`)
        // setviewimg(`${process.env.REACT_APP_API_URL}media/${response.data?.children?.[0]?.image[0]?.original}`);
        setloading(false);
      }

      

      async function fetchcategory2(id) {
        let url = `${process.env.REACT_APP_API_URL}homepageapi/GetRecommendedProduct/${id}`;
        const response = await axios.get(url);
        setData3(response?.data?.data);
      }
      fetchwishlist()
      fetchcategory(location.state.id,location.state.pname);
      fetchcategory2(location.state.id);
    }
  }, []);

  const transfer = () => {
    nvg("/category", {
      state: {
        id: location.state?.categoryid,
        pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };

  const transfer3 = (productid) => {
    nvg("/productdetails", {
      state: {
        id: productid,
        categoryid: location.state?.categoryid,
        pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };

  const checkfun = (name)=>{
    const val = Data.attributes.find(attr => attr.attribute__name == name)?.value_option__option;
    // console.log("just checking",name,"valie ss",val)
  // const yesofsome = fixedData.children.some(item => item.attributes.some(item2 => item2.value == val)); 
  // console.log("first ..........",yesofsome,"Dddddd",val);
  return val;
  }
  return loading == true || addloader == true ? (
    <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <h2
              style={{
                textAlign: "center",
                paddingTop: "15px",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              <Loader />
            </h2>
          </div>
  ) : (
    <>
{/* {popstatus == true ? */}
<div style={{width:'100%',position:'relative',display:addtocarttime == true ? 'flex' : 'none',justifyContent:'center'}}>
{addtocarttime == true ? <ToastContainer
 style={{display:popstatus == true ? 'block' : 'none'}}
position="top-center"
autoClose={2000}
containerId={"addtocart"}

/> : ''}
 
{addtocarttime == true ? <ToastContainer
position="top-center"
autoClose={2000}
containerId={"wishlist23"}

/> : ''} 

{addtocarttime == true ? <ToastContainer
position="top-center"
autoClose={2000}
containerId={"wishlistadd2300"}

/> : ''}
 
</div>
 {/* : ''}  */}

      <Header />

      {/* breadcrumb start */}
      <div className="breadcrumb-main marginfromtop">
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div
                className="breadcrumb-contain m-0"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "5px",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  {/* <h2>product</h2> */}
                  <ul>
                    <li>
                      <a href="/">home</a>
                    </li>
                    <li style={{ fontSize: "12px" }}>&gt;</li>
                    <li>
                      <p
                        onClick={() => {
                          nvg(`/category/${fixedData.paren_category == Data?.parent__categories__name ? Data.parent__categories__id : Data.parent__categories__id}/${fixedData.paren_category == Data?.parent__categories__name ? fixedData.paren_category :`${fixedData.paren_category} > ${ Data.parent__categories__name}`}/none`)
                        }}
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        {fixedData.paren_category == Data?.parent__categories__name ? Data.parent__categories__name : ` ${fixedData.paren_category} > ` }
                        {/* {location.state?.pagename} */}
                        {Data?.parent__categories__name}
                        {/* {fixedData.categories?.[0]} */}
                      </p>
                    </li>
                    {/* <li style={{ fontSize: "12px" }}>&gt;</li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        style={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        {Data?.title}
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div
                  className="header-contain"
                  style={{ padding: "8px 0px 0px 0px" }}
                >
                  <div
                    className="icon-block  twoicon"
                    style={{ width: "100%", display: "none" }}
                  >
                    <ul
                      className="theme-color"
                      style={{
                        width: "100%",
                        background: "",
                        justifyContent: "space-around",
                        paddingRight: "10px",
                      }}
                    >
                      <li
                        className=" icon-md-block"
                        onClick={() => redirectfun("/")}
                      >
                        {/* <img
                        src="./images/mega-store/brand/home.png"
                        className="newwidthpro"
                        alt="home"
                      /> */}
                        {/* <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Home
                      </label> */}
                      </li>
                      <li></li>
                      <li
                        className="mobile-setting "
                        onClick={() => redirectfun("/order-history")}
                      ></li>
                      <li
                        className="mobile-wishlist item-count icon-desk-none"
                        onClick={() => redirectfun("/wishlist")}
                      >
                        <img
                          src="./images/mega-store/brand/heart.png"
                          className="newwidthpro hellopooja"
                          alt="heart"
                        />
                        <label
                          htmlFor=""
                          style={{ fontSize: "10px", margin: "0px" }}
                        >
                          Wishlist
                        </label>
                        <div
                          className="item-count-contain inverce"
                          style={{ top: "-4px", left: "9px",padding:'8px' }}
                        >
                         {gobalvariable.wishlist}
                        </div>
                      </li>
                      <li
                        className="mobile-cart
                      item-count"
                        onClick={() => {
                          redirectfun("/cart");
                        }}
                      >
                        <img
                          src="./images/mega-store/brand/shopping-cart.png"
                          className="newwidthpro  hellopooja"
                          alt="cart"
                        />
                        <label
                          htmlFor=""
                          style={{ fontSize: "10px", margin: "0px" }}
                        >
                          Cart
                        </label>

                        <div
                          className="item-count-contain inverce"
                          style={{ top: "-4px", left: "9px",padding:'8px' }}
                        >
                          {" "}
                          {gobalvariable.cart}{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb End */}
      {/* section start */}
      <section
        className="section-big-pt-space b-g-light"
        style={{ background: "white" }}
      >
        <div className="collection-wrapper">
          <div className="custom-container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-xs-12">
                <div className="container-fluid">
                  {/* <div className="row">
                      <div className="col-xl-12">
                      </div>
                    </div> */}
                  <div
                    className="row"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <div className="col-lg-6 makestk">
                      <div className="row">
                        <div className="col-md-3 col-3 superpadding3">
                          <div
                            class="container-fluid px-lg-4 px-md-3 px-2"
                            style={{ paddingLeft: "0px", paddingRight: "0px" }}
                          >
                            {/* https://adminoneupv1.stackerbee.com/media/ */}

                            <div
                              style={{ padding: "0px 0px 5px 0px" }}
                              className="shirt"
                            >
                              {/* {console.log("fffff img",Data)} */}
                              <img
                                src={`${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[0]?.original.replace(searchString, '')}`}
                                onClick={() => {
                                  setviewimg(`${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[0]?.original.replace(searchString, '')}`);
                                }}
                                alt
                                style={{ aspectRatio: "1/1",objectFit:'contain' }}
                                className="img-fluid  image_zoom_cls-0"
                              />
                            </div>
                            {Data?.sortedImages?.[1] ? (
                              <div
                                style={{ padding: "5px 0px" }}
                                className="shirt2"
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[1]?.original.replace(searchString, '')}`}
                                  onClick={() => {
                                    setviewimg(
                                      `${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[1]?.original.replace(searchString, '')}`
                                    );
                                  }}
                                  alt
                                  style={{ aspectRatio: "1/1",objectFit:'contain' }}
                                  className="img-fluid  image_zoom_cls-1"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {Data?.sortedImages?.[2] ? (
                              <div
                                style={{ padding: "5px 0px" }}
                                className="shirt3"
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[2]?.original.replace(searchString, '')}`}
                                  onClick={() => {
                                    setviewimg(
                                      `${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[2]?.original.replace(searchString, '')}`
                                    );
                                  }}
                                  alt
                                  style={{ aspectRatio: "1/1",objectFit:'contain' }}
                                  className="img-fluid  image_zoom_cls-2"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {Data?.sortedImages?.[3] ? (
                              <div
                                style={{ padding: "5px 0px" }}
                                className="shirt4"
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[3]?.original.replace(searchString, '')}`}
                                  onClick={() => {
                                    setviewimg(
                                      `${process.env.REACT_APP_API_URL}media/${Data?.sortedImages?.[3]?.original.replace(searchString, '')}`
                                    );
                                  }}
                                  alt
                                  style={{ aspectRatio: "1/1",objectFit:'contain' }}
                                  className="img-fluid  image_zoom_cls-3"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-9 col-9">
                          <div className="product-slick ">
                            <div>
                              <ReactImageMagnify
                                {...{
                                  smallImage: {
                                    alt: "Wristwatch by Versace",
                                    isFluidWidth: true,
                                    src: viewimg,
                                    width: 140,
                                    height: 600,
                                  },
                                  largeImage: {
                                    src: viewimg,
                                    width: 700,
                                    height: 700,
                                  },
                                  enlargedImagePosition: "over",
                                  lensStyle: {
                                    backgroundColor: "rgba(0,0,0,.6)",
                                  },
                                }}
                              />
                              {/* <img src="./images/product-sidebar/001.jpg" alt className="img-fluid  image_zoom_cls-0" /> */}
                            </div>
                            {/* <div><img src="./images/product-sidebar/002.jpg" alt className="img-fluid  image_zoom_cls-1" /></div>
                          <div><img src="./images/product-sidebar/003.jpg" alt className="img-fluid  image_zoom_cls-2" /></div>
                          <div><img src="./images/product-sidebar/004.jpg" alt className="img-fluid  image_zoom_cls-3" /></div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 rtl-text p-0">
                      <div
                        className="product-right "
                        style={{
                          boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.12)",
                          borderRadius: "10px",
                          padding: "20px 17px",
                        }}
                      >
                        <div className="pro-group">
                          {/* {console.log("current data of product is here",Data)} */}
                          <h2>{!Data?.attributes ? "" : Data?.attributes.filter(user => user.attribute__name.toUpperCase() == "BRAND").map((item,index)=>(<span style={{letterSpacing:'0px',fontWeight:"700",color:'#230bb3',cursor:'pointer'}} onClick={()=>{nvg(`/categoryforbrand/${item.value_option__option}`)}}>{item.value_option__option}</span>))}</h2>
                          <h2 style={{letterSpacing:'0px',fontWeight:"500"}}  onClick={()=>{checkfun()}} >{Data?.title} <span style={{color:"red",letterSpacing:'0.4px',fontWeight:"500"}}>{(Data?.stock_record?.num_in_stock - 0) == 0 ? "(Out of Stock)" : ""}</span></h2>
                          <div className="revieu-box">
                            <ul className="pro-price">
                              <li
                                style={{
                                  color: "#000",
                                  // color: "#230BB3",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                }}
                              >
                             {Data?.stock_record?.discount == 0 ? 'MRP:' : ''} ₹{parseInt(Data?.stock_record?.price)}{Data?.stock_record?.discount == 0 ? '' : <span style={{fontSize: '14px',color: '#c1c1c1',lineHeight: '20px',textDecoration: 'line-through',paddingLeft:'3px',margin:'0px',fontWeight:'400'}}> <span style={{fontSize: '14px',color: '#c1c1c1',lineHeight: '20px',textDecoration: 'none',paddingLeft:'3px',margin:'0px',fontWeight:'400'}}>&nbsp;MRP:</span> ₹{Data?.stock_record?.mrp}</span>}{Data?.stock_record?.discount == 0 ? '' : <span style={{fontSize: '14px',color: '#388e3c',lineHeight: '20px',paddingLeft:'2px',margin:'0px',fontWeight:'400',textDecoration:'none'}}>{Data?.stock_record?.discount_type == "amount" ? `(₹${Data?.stock_record?.discount} off)` : `(${Data?.stock_record?.discount} %off)`}</span >}
                              </li>
                              <li
                                style={{ color: "black", fontSize: "11px",position:'relative',top:'-3px',cursor:'pointer' }}
                                onClick={() => { Data?.is_wishlist?.[0]?.is_wishlist == true ? removewishlist(Data.id) : Addtowishlist(Data.id);
                                }}
                              >
                                {" "}
                                {/* <i
                                  class="fa-regular fa-heart"
                                  style={{ color: "blue", fontSize: "22px" }}
                                ></i> */}
                                {Data?.is_wishlist?.[0]?.is_wishlist == true ? <FaHeart size={19} color="#230bb3" /> : <FaRegHeart size={19} />}
                                
                                

                              </li>
                              
                            </ul>
                          </div>
                          {Data?.stock_record?.discount == 0 ? '' : <ul className="pro-price" style={{position:'relative',top:'-10px'}}>
                            <li
                                style={{
                                  color: "#000",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  textTransform:'capitalize'
                                }}
                              >You Save : {Data?.stock_record?.discount == 0 ? '' : <span style={{fontSize: '14px',color: '#388e3c',lineHeight: '20px',paddingLeft:'2px',margin:'0px',fontWeight:'400',textDecoration:'none'}}>{Data?.stock_record?.discount_type == "amount" ? `₹${parseInt(Data?.stock_record?.mrp) - parseInt(Data?.stock_record?.price)}` : `₹${parseInt(Data?.stock_record?.mrp) - parseInt(Data?.stock_record?.price)}`}</span >}</li>
                            </ul>}
                          <div>
                         
                              {(Data?.stock_record?.num_in_stock - 0) < 10 && (Data?.stock_record?.num_in_stock - 0) > 0 ? <span style={{color:'crimson',textDecoration:'none',fontWeight:'500',fontSize:'14px',position:'relative',top:'-8px'}}>Hurry, Only {Data?.stock_record?.num_in_stock - 0} Left</span> : ""}
                           
                          </div>
                        </div>
                        <div
                          id="selectSize"
                          className="pro-group addeffect-section product-description border-product d-flex"
                          style={{
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* {Data ? Data.attributes.some(obj => obj.name.includes('color')) ? <div className="productdetailcontainer customwidth">
                            <h6 className="product-title">color</h6>
                            <div className="color-selector inline">
                              <ul>
                      
                               {Data?.attributes.map((item, index) => (
                               item.name == "color" ? <li ><div className="color-4" style={{background:`${item.value}`}}></div></li> : ''
                              ))}
                               
                              </ul>
                            </div>
                          </div> : '' : ''}
                          
                          {Data?.attributes.some(obj => obj.name.includes('size')) ? <div className="productdetailcontainer customwidth" >
                            <h6 className="product-title mt-2">Available Size</h6>
                            <div className="size-box">
                              <ul>
                              {Data?.attributes.map((item, index) => (
                               item.name == "size" ? <li ><a href="javascript:void(0)">{item.value}</a></li> : ''
                              ))}
                              </ul>
                            </div>
                          </div> : ''} */}

                          {Data?.attributes
                            ? Object.entries(attrlist).map(
                                ([name, value]) => (
                                  // item.name == "color" ? (
                                  //   <div className="productdetailcontainer customwidth">
                                  //     <h6 className="product-title">color</h6>
                                  //     <div className="color-selector inline">
                                  //       <ul>
                                  //         <li>
                                  //           <div
                                  //             className="color-4"
                                  //             style={{
                                  //               background: `${item.value}`,
                                  //             }}
                                  //           ></div>
                                  //         </li>
                                  //       </ul>
                                  //     </div>
                                  //   </div>
                                  // ) : (
                                  // attrlist.map((item2,index3) =>(
                                  <div className="productdetailcontainer customwidth" style={{display:name.toUpperCase() == "BRAND" ? "none" : ""}}>
                                    <h6 className="product-title mt-2">
                                      {name}
                                    </h6>
                                    <div className="size-box">
                                      <ul style={{display:'flex',alignItems:'center'}}>
                                        {name == "Color" || name == "color" ? <>
                                         {/* {value.map((value2, index5) => (
                                          checkfun(name) == value2 ?  <li
                                              style={{
                                                background: `${value2}`,
                                                borderRadius:'50%',
                                                padding :checkfun(name) == value2 ? `19px 19px` : `15px 15px`
                                              }}
                                              
                                              onClick={()=>{getProductByAttribute(fixedData.arrayProd,name,value2);console.log("ddddddd///g/g/g/g//g")}} >
                                                <div  className="color-4" onClick={()=>{getProductByAttribute(fixedData.arrayProd,name,value2);console.log("ddldl./f;ff.le")}}  >
                                
                                                </div>
                                              </li> : undefined
                                            ))}   */}
                                            {value.filter((value, index, self) => self.indexOf(value) === index).map((value2, index5) => (
                                              <li
                                              style={{
                                                background: `${value2}`,
                                                borderRadius:'50%',
                                                padding :checkfun(name) == value2 ? `19px 19px` : `15px 15px`
                                              }}
                                              onClick={()=>{getProductByAttribute(fixedData?.arrayProd,name,value2)}} >
                                                <div  className="color-4"  >
                                        
                                                </div>
                                              </li> 
                                            ))} </>  : <>
                                             {/* {value.map((value2, index5) => (
                                              checkfun(name) == value2 ? <li
                                                className="px-2"
                                                style={{ 
                                                  width: "fit-content",
                                                  display:name == "Brand" ? index5 == 0 ? 'block' : 'none' : 'inline-block',
                                                background :checkfun(name) == value2 ? `#230bb3` : ``,
                                                color :checkfun(name) == value2 ? `#fff` : `` }}
                                                onClick={()=>{index5 == 1 ? getProductByAttribute(fixedData?.arrayProd,name,value2) : <></>}}
                                              >
                                                <a href="javascript:void(0)" style={{                                                
                                                color :checkfun(name) == value2 ? `#fff` : `` }}>
                                                  {value2}
                                                </a>
                                              </li> : undefined
                                            ))}   */}
                                            {/* {console.log("this is col msf",value)} */}
                                            {value.filter((value, index, self) => self.indexOf(value) === index).map((value2, index5) => (
                                              <li
                                                className="px-2"
                                                style={{ 
                                                  width: "fit-content",
                                                  display:name == "Brand" ? index5 != 0 ? 'none' : 'none' : 'block',
                                                  
                                                borderColor :checkfun(name) == value2 ? `#230bb3` : ``,
                                                fontWeight :checkfun(name) == value2 ? `600` : ``,
                                                color :checkfun(name) == value2 ? `#230bb3` : `` }}
                                                onClick={()=>{getProductByAttribute(fixedData?.arrayProd,name,value2)}}
                                              >
                                                {/* {console.log("brand name",name,value2)} */}
                                                <a href="javascript:void(0)" style={{                                                
                                                color :checkfun(name) == value2 ? `#230bb3` : `` }}>
                                                  {value2}
                                                </a>
                                              </li>
                                              
                                            ))}</>}
                                            {/* {value.map((value2, index5) => (
                                              checkfun(name) != value2 ? <li
                                                className="px-2"
                                                style={{ 
                                                  width: "fit-content",
                                                  display:name == "Brand" ? 'none' : 'block',
                                                background :checkfun(name) == value2 ? `#230bb3` : ``,
                                                color :checkfun(name) == value2 ? `#fff` : `` }}
                                                onClick={()=>{getProductByAttribute(fixedData?.arrayProd,name,value2)}}
                                              >
                                                <a href="javascript:void(0)" style={{                                                
                                                color :checkfun(name) == value2 ? `#fff` : `` }}>
                                                  {value2}
                                                </a>
                                              </li> : undefined
                                              
                                            ))}</>} */}
                                      </ul>
                                    </div>
                                  </div>
                                )
                                // ))

                                // )
                              )
                            : ""}
                          {/* {Data?.attributes ? Data?.attributes.map((item)=>(
                            item.name == 'color' ? <div className="productdetailcontainer customwidth">
                            <h6 className="product-title">color</h6>
                            <div className="color-selector inline">
                              <ul>
                      <li ><div className="color-4" style={{background:`${item.value}`}}></div></li>
                            
                              </ul>
                            </div>
                          </div> :
                            <div className="productdetailcontainer customwidth" >
                            <h6 className="product-title mt-2">{item.name}</h6>
                            <div className="size-box">
                              <ul>
                          
                               <li ><a href="javascript:void(0)">{item.value}</a></li> 
                            
                              </ul>
                            </div>
                          </div>
                          )) : ''}  */}

                          <div className="productdetailcontainer customwidth">
                            <h6 className="product-title mt-3">quantity</h6>
                            <div
                              className="qty-box gap"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "",
                              }}
                            >
                              <div className="input-group">
                                
                                <button
                                  type="button"
                                  onClick={() => {
                                    decrementcart();
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-minus"
                                    style={{ color: "#230bb3" }}
                                  />
                                </button>
                                <input
                                  className="qty-adj form-control"
                                  type="number"
                                  style={{ width: "44px",padding: "0.375rem 0.45rem" }}
                                  // readOnly
                                  onChange={(e)=>{ if (e.target.value === '' || parseInt(e.target.value) !== 0) {
                                    setqty(e.target.value);
                                  }}}
                                  onBlur={(e)=>{if (e.target.value === '' || parseInt(e.target.value) == 0) {
                                    setqty(1);
                                  }}}
                                  value={qty}
                                />
                               <button
                                  type="button"
                                  onClick={() => {
                                    incrementcart();
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-plus"
                                    style={{ color: "#230bb3" }}
                                  />
                                </button>
                              </div>
                              {/* <span style={{display: 'flex',}}> */}
                              {/* <h6 className="product-title"></h6> */}
                              <div className="product-buttons">
                              {!addressdataindex?.postcode ? <a
                                  href="javascript:void(0) "
                                  data-bs-toggle="modal" data-bs-target="#exampleModaladdressone"
                                  style={{
                                    background: (Data?.stock_record?.num_in_stock - 0) !== 0 ? "#230bb3" : 'rgb(207, 207, 207)',
                                    padding: "9px 9px",
                                  }}
                                  id="cartEffect"
                                  className="btn cart-btn btn-normal tooltip-top"
                                  data-tippy-content="Add to cart"
                                >
                                  <i className="fa fa-shopping-cart" />
                                  add to cart
                                </a> : <a
                                  href="javascript:void(0) "
                                  onClick={() => (Data?.stock_record?.num_in_stock - 0) == 0 ? console.log("first test 2") : Data?.is_stockrecords !== 0 ? Addtocart(Data.id) : console.log("first test 1")}
                                  style={{
                                    background: (Data?.stock_record?.num_in_stock - 0) == 0 ? '#230bb3' : Data?.is_stockrecords !== 0 ? "#230bb3" : '',
                                    // color: Data?.stock_record?.num_in_stock == 0 ? 'black' : '',
                                    padding: "9px 9px",
                                  }}
                                  id="cartEffect"
                                  className="btn cart-btn btn-normal tooltip-top"
                                  data-tippy-content="Add to cart"
                                >
                                  <i className="fa fa-shopping-cart" />
                                {(Data?.stock_record?.num_in_stock - 0) == 0 ? 'Out of Stock' : 'add to cart'}  
                                </a>}  
                              </div>
                              {/* </span> */}
                            </div>
                          </div>

                          <div
                            className="productdetailcontainer w-lg-50 w-xs-100 d-flex"
                            style={{
                              flexDirection: "column",
                              alignItems: "end",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                           <div className="pro-group">
                              <h6 className="product-title endlinetext">
                                Deliver To{" "}
                                <img src="./images/icon/place.png" alt="404" />{" "}
                              </h6>
                              <div className="delivery-detail">
                                <div className="delivery-detail-contian">
                                  <div className="input-group">
                                    <input
                                      type="phone"
                                      name="pincode"
                                      className="form-control makeitsmall"
                                      minLength={6}
                                      maxLength={6}
                                      value={delto}
                                      style={{ padding: "0px 0.75rem" }}
                                      onChange={(e) => {
                                        setdelto(e.target.value.replace(/[^0-9]/gi, ""));
                                      }}
                                      placeholder="Enter Pincode for delivery"/>
                                    {/* value={values.pincode}
                                       onChange={handleChange}
                                     onBlur={handleBlur} />
                                     {errors.pincode && touched.pincode ? (<p className="form-group" style={{ color: 'red' }}>{errors.pincode}</p>) : null}  */}

                                  </div>
                                  {/* {console.log(".mnvcxh",addressdataindex?.postcode)} */}
                                  <button
                                  ref={checkpin}
                                    type="button"
                                    style={{
                                      background: "#CFCFCF",
                                      borderRadius: "3px",
                                      color: "black",
                                      fontWeight: "700",
                                      padding: "8px 15px",
                                      fontSize: "13px",
                                    }}
                                    onClick={() => {
                                      devto(Data.id);
                                    }}
                                    className="btn btn-md"
                                  >
                                    Check
                                  </button>
                                </div>
                                {/* <p>Delivery by 17 Oct, Tuesday |  <li style={{ color: '#230BB3', fontWeight: "700", fontSize: "16px" }}><span style={{color: '#CFCFCF'}}>Free</span>  ₹40</li></p> */}
                               {checkloader == true ? <p> Loading...</p> : <p style={{ color: delresponse["color"] == "red" ? "red" : "green" }}>
                                  {delresponse["status"] == true
                                    ? <>{delresponse["msg"]}  {delresponse["Delivery"] ? <>|| ₹{delresponse["Delivery"]}</> : ''}</>
                                    : ""}
                                </p>} 
                              </div>
                            </div>
                          </div>

                          {/* <div className="productdetailcontainer w-100">
                            <h5 className="product-title ">Description</h5>
                            <p style={{ color: "#8F9091", fontSize: "12px" }}>
                              {extractText(Data?.description)}
                            </p>
                          </div> */}
                        </div>
                      </div>
   {/* product Specifications */}
   <div
                        className="product-right "
                        style={{
                          marginTop: "20px",
                          boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.12)",
                          borderRadius: "10px",
                          padding: "20px 17px",
                        }}
                      >
                        <div
                          id="selectSize"
                          className="pro-group addeffect-section product-description border-product d-flex"
                          style={{
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="productdetailcontainer w-100">
                            <h5 className="product-title ">Product Specifications</h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: Data?.specifications,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="product-right "
                        style={{
                          marginTop: "20px",
                          boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.12)",
                          borderRadius: "10px",
                          padding: "20px 17px",
                        }}
                      >
                        <div
                          id="selectSize"
                          className="pro-group addeffect-section product-description border-product d-flex"
                          style={{
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="productdetailcontainer w-100">
                            <h5 className="product-title ">Product Details</h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: fixedData?.description,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {Data3[0]?.recommended_products ? (
        <section className="section-big-py-space  ratio_asos b-g-light">
          <div className="custom-container">
            <div className="row">
              <div className="col-12 product-related">
                <h2>related products</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 product">
                <div className="product-slide-6 product-m no-arrow">
                  <OwlCarousel
                    className="owl-theme"
                    style={{ width: "100%", height: "100%" }}
                    {...options5}
                  >
                    {Data3.map((item) => (
                      <div style={{ display: "block" }}>
                        <div className="product-box">
                          <div className="product-imgbox">
                            <div className="product-front">
                              <button
                                type="button"
                                className="btn fixedhight"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  transfer3(item?.product?.[0]?.id);
                                }}
                              >
                                
                                <img
                                  src={`${process.env.REACT_APP_API_URL}media/${item?.product?.[0]?.image?.[0]?.original}`}
                                  className="img-fluid  "
                                  alt="product"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="product-detail detail-inline ">
                          <div className="detail-title">
                                        <div className="detail-left" style={{width:'100%'}}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            {/* <button
                                              type="button"
                                              className="btn"
                                              onClick={() => {
                                                transfer(item.id);
                                              }}
                                            > */}
                                              <h6
                                                className="price-title"
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  textAlign:'center',
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: "1",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                {/* {item?.product?.[0]?.title.length > 20
                                ? `${item?.product?.[0]?.title.substr(0, 20)}...` */}
                                {/* : */}
                                 {item?.product?.[0]?.title}
                                              </h6>{" "}
                                            {/* </button> */}
                                          </div>
                                        </div>
                                        <div
                                          className="detail-right"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="price"
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "#230bb3",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {" "}
                                              ₹{item?.product?.[0]?.price}
                                            </div>
                                            {/* <div className="price text-align-center" style={{display:'flex',justifyContent:'center'}}>  ₹{item.price} </div> */}
                                          </div>
                                        </div>
                                      </div>
                            {/* <div className="detail-title">
                              <div className="detail-left">
                                <h6 className="price-title">
                                
                                  {item?.product?.[0]?.title.length > 20
                                ? `${item?.product?.[0]?.title.substr(0, 20)}...`
                                : item?.product?.[0]?.title}
                                </h6>
                              </div>
                              <div className="detail-right">
                                <div className="check-price" style={{textDecoration:'none'}}>
                                  ₹{item?.product?.[0]?.price}
                                </div>
                                <div className="price">
                                  <div className="price">
                                    ₹{item?.product?.[0]?.price}
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* related products */}
      {/* Recently Viewed products */}
      {recentlydata == null ? (
        ""
      ) :
      //  recentlydata[0] == Data?.id ? (
      //   "ddqq"
      // ) :
       (
        <section
          className="section-big-py-space  ratio_asos b-g-light"
          style={{ display: recentlydata == null ? "none" : "block" }}
        >
          <div className="custom-container">
            <div className="row">
              <div className="col-12 product-related">
                <h2>Recently Viewed</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 product">
                <div
                  className="product-slide-6 product-m no-arrow"
                  style={{ display: recentlydata == null ? "none" : "block" }}
                >
                  <OwlCarousel
                    className="owl-theme"
                    style={{ width: "100%", height: "100%" }}
                    {...options5}
                  >
                    {recentlydata == null
                      ? ""
                      : recentlydata
                          .reverse()
                          .map((element, index) =>
                            element == Data?.id ? (
                              ""
                            ) : (
                              <Recentlyowl
                                elementid={element}
                                pid={Data?.id}
                                catid={location.state?.categoryid}
                                catpage={location.state?.pagename}
                                titename={Data?.title}
                              />
                            )
                          )}
                    {/* <div>
                    <div className="product-box">
                      <div className="product-imgbox">
                        <div className="product-front">
                          <a href="/home">
                            <img src="./images/layout-2/product/3.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                        <div className="product-back">
                          <a href="/home">
                            <img src="./images/layout-2/product/a3.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                       
                      </div>
                      <div className="product-detail detail-inline">
                        <div className="detail-title">
                          <div className="detail-left">
                           
                            <a href="/home">
                              <h6 className="price-title">
                                woman hande bag
                              </h6>
                            </a>
                          </div>
                          <div className="detail-right">
                            <div className="check-price">
                              ₹ 56.21
                            </div>
                            <div className="price">
                              <div className="price">
                                ₹ 24.05
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box">
                      <div className="product-imgbox">
                        <div className="product-front">
                          <a href="/home">
                            <img src="./images/layout-2/product/4.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                        <div className="product-back">
                          <a href="/home">
                            <img src="./images/layout-2/product/a4.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                       
                      </div>
                      <div className="product-detail detail-inline">
                        <div className="detail-title">
                          <div className="detail-left">
                           
                            <a href="/home">
                              <h6 className="price-title">
                                nikon camera
                              </h6>
                            </a>
                          </div>
                          <div className="detail-right">
                            <div className="check-price">
                              ₹ 60.21
                            </div>
                            <div className="price">
                              <div className="price">
                                ₹ 20.05
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box">
                      <div className="product-imgbox">
                        <div className="product-front">
                          <a href="/home">
                            <img src="./images/layout-2/product/5.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                        <div className="product-back">
                          <a href="/home">
                            <img src="./images/layout-2/product/a5.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                       
                      </div>
                      <div className="product-detail detail-inline">
                        <div className="detail-title">
                          <div className="detail-left">
                           
                            <a href="/home">
                              <h6 className="price-title">
                                lenovo laptop
                              </h6>
                            </a>
                          </div>
                          <div className="detail-right">
                            <div className="check-price">
                              ₹ 70.21
                            </div>
                            <div className="price">
                              <div className="price">
                                ₹ 30.05
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box">
                      <div className="product-imgbox">
                        <div className="product-front">
                          <a href="/home">
                            <img src="./images/layout-2/product/6.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                        <div className="product-back">
                          <a href="/home">
                            <img src="./images/layout-2/product/a6.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                       
                      </div>
                      <div className="product-detail detail-inline">
                        <div className="detail-title">
                          <div className="detail-left">
                           
                            <a href="/home">
                              <h6 className="price-title">
                                earphone Pouch Bag
                              </h6>
                            </a>
                          </div>
                          <div className="detail-right">
                            <div className="check-price">
                              ₹ 100.21
                            </div>
                            <div className="price">
                              <div className="price">
                                ₹ 80.05
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="product-box">
                      <div className="product-imgbox">
                        <div className="product-front">
                          <a href="/home">
                            <img src="./images/layout-2/product/7.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                        <div className="product-back">
                          <a href="/home">
                            <img src="./images/layout-2/product/a7.jpg" className="img-fluid  " alt="product" />
                          </a>
                        </div>
                       
                      </div>
                      <div className="product-detail detail-inline">
                        <div className="detail-title">
                          <div className="detail-left">
                           
                            <a href="/home">
                              <h6 className="price-title">
                                wooden table
                              </h6>
                            </a>
                          </div>
                          <div className="detail-right">
                            <div className="check-price">
                              ₹ 90.21
                            </div>
                            <div className="price">
                              <div className="price">
                                ₹ 28.05
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* Recently Viewed products */}
     
      <Footer />



{/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> */}

<div class="modal fade" id="exampleModaladdressone" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" style={{maxWidth:'770px'}}>
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Address</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    address1: '',
                    address2: '',
                    country: '',
                    countryurl: '',
                    state: '',
                    city: '',
                    pincode: '',
                    address_type: 'HOME',
                  }}
                  validationSchema={checkoutvalidation}
                  onSubmit={(values,{setFieldError}) => {
                    createaddress(values,setFieldError);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleSubmit,
                    touched,
                    setFieldValue,
                    setFieldError,
                    handleChange
                  }) =>(
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="row check-out ">
                    <div className="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                      <label className=" acounttitle field-label m-0">
                        Address Type
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                    <div className="d-flex" style={{gap:'10px'}}>
       <div>
        <label style={{display:'flex',alignItems:'center'}}>
          <input
            type="radio"
            name="address_type"
            value="HOME"
            onChange={handleChange}
            checked={values.address_type == 'HOME'}
          />
         HOME
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center'}}>
          <input
            type="radio"
            name="address_type"
            value="OFFICE"
            onChange={handleChange}
            checked={values.address_type == 'OFFICE'}
          />
          OFFICE
        </label>
      </div>
      <div>
        <label style={{display:'flex',alignItems:'center'}}>
          <input
            type="radio"
            name="address_type"
            value="OTHER"
            onChange={handleChange}
            checked={values.address_type == 'OTHER'}
          />
          OTHER
        </label>
      </div></div>
                      <div className="error">
                        {errors.address_type && touched.address_type ? (
                          <p style={{ color: "red" }}>
                            {errors.address_type}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                      <label className="acounttitle m-0">
                        First Name <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder='First Name' /> */}
                      
                      <Field
                        type="text"
                        name="first_name"
                        className="form-control"
                        style={{ fontSize: "12px" }}
                        placeholder="Enter Your First Name"
                        value={values?.first_name}
                      />
                      <div className="error">
                        {errors.first_name && touched.first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                      <label className="acounttitle m-0">
                        Last Name
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control"
                        style={{ fontSize: "12px" }}
                        placeholder="Enter Your Last Name"
                        value={values?.last_name}
                      />
                      <div className="error">
                        {errors.last_name && touched.last_name ? (
                          <p style={{ color: "red" }}>
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                      <label className="acounttitle field-label m-0">
                        Phone
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                      <Field
                        name="mobile"
                        type="number"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your Mobile. No"
                        value={values?.mobile}
                      />
                      <div className="error">
                        {errors.mobile && touched.mobile ? (
                          <p style={{ color: "red" }}>
                            {errors.mobile}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                      <label className="acounttitle field-label m-0">
                        Email Address
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder='Email'/> */}
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        style={{ fontSize: "12px" }}
                        placeholder="Enter Your Email Address"
                        value={values?.email}
                      />
                      <div className="error">
                        {errors.email && touched.email ? (
                          <p style={{ color: "red" }}>
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                      <label className="acounttitle field-label m-0">
                        Address
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      <Field
                        name="address1"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="House number and street name"
                        value={values.address1}
                      />
                      <div className="error">
                        {errors.address1 && touched.address1 ? (
                          <p style={{ color: "red" }}>
                            {errors.address1}
                          </p>
                        ) : null}
                      </div>
                      {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                     
                      {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                      <label className="acounttitle field-label m-0">
                        Address 2
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      <Field
                        name="address2"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control mt-2"
                        placeholder="Apartment, suite, unit etc. (optional)"
                        value={values.address2}
                      />
                      <div className="error">
                        {errors.address2 && touched.address2 ? (
                          <p style={{ color: "red" }}>
                            {errors.address2}
                          </p>
                        ) : null}
                      </div>
                      {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                     
                      {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                    </div>

                    <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12">
                      <label className=" acounttitle field-label m-0">
                        Postal Code
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder="Pincode" /> */}
                      <Field
                        name="pincode"
                        type="number"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your Pincode"
                        value={values.pincode}
                        minLength={6}
                        maxLength={6}
                        onChange={(e) => {
                          setFieldValue(
                            "pincode",
                            e.target.value.replace(/[^0-9\ ]/gi, "")
                          );
                          fetchstateandcity(
                            e.target.value.replace(/[^0-9\ ]/gi, ""),
                            setFieldError,
                            setFieldValue
                          );
                        }}
                      />
                      <div className="error">
                        {errors.pincode && touched.pincode ? (
                          <p style={{ color: "red" }}>
                            {errors.pincode}
                          </p>
                        ) : null}
                      </div>
                    </div>



                    {values.state !== '' && values.state !== null && cityna == 'NA' ? <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                      <label className="acounttitle field-label m-0">
                        City
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder="City" /> */}
                      <Field
                        name="city"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your City"
                        value={values.city}
                      />
                      {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                      <div className="error">
                        {errors.city && touched.city ? (
                          <p style={{ color: "red" }}>
                            {errors.city}
                          </p>
                        ) : null}
                      </div>
                    </div> : <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                      <label className="acounttitle field-label m-0">
                        City
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder="City" /> */}
                      <Field
                        name="city"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your City"
                        readOnly
                        value={values.city}
                      />
                      {/* <Select onChange={(e)=>{setFieldValue("city",e.label)}} style={{fontSize:'11px'}} options={citylist == "" ? [] : updatedCities(citylist)}  /> */}
                      <div className="error">
                        {errors.city && touched.city ? (
                          <p style={{ color: "red" }}>
                            {errors.city}
                          </p>
                        ) : null}
                      </div>
                    </div>}

                    
                    {/* {console.log("reeffghgttffddddcc vvvvvv nnnnnnnnnn
                    
                    nn",errors,"llkkkkkkkkjjjhhhhgggfffffdsssss",values)} */}
                    <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                      <label className="acounttitle field-label m-0">
                        State
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      {/* <input type="text" name="field-name" placeholder="State" /> */}
                      <Field
                        name="state"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Enter Your State"
                        readOnly
                        value={values.state}
                      />
                      {/* <Select onChange={(e)=>{setcitylist(e.id);setFieldValue("state",e.label)}} style={{fontSize:'11px'}} options={statelist == "" ? [] : updatedStates(statelist)}  /> */}
                      <div className="error">
                        {errors.state && touched.state ? (
                          <p style={{ color: "red" }}>
                            {errors.state}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col-xl-6 col-md-12 col-sm-12 col-xs-12 cht">
                      <label className="acounttitle field-label m-0">
                        Country
                        <span style={{color:'red',fontSize:'13px'}}> *</span>
                      </label>
                      <Field
                        name="country"
                        type="text"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Country"
                        readOnly
                        value={values.country}
                      />
                      <Field
                        name="countryurl"
                        type="hidden"
                        style={{ fontSize: "12px" }}
                        className="form-control"
                        placeholder="Country"
                        readOnly
                        value={values.countryurl}
                      />
                      <div className="error">
                        {errors.country && touched.country ? (
                          <p style={{ color: "red" }}>
                            {errors.country}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    
                    <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                     
                    <div className="payment-box">
                    <div className="text-left" style={{display:'flex',justifyContent:'end',gap:'4px'}}>
 <button
                        type="button"
                        style={{ fontSize: "12px",background:'#bdbfc0',color:'#000' }}
                        className="btn-normal btn"
                        data-bs-dismiss="modal" aria-label="Close"
                        ref={buttonRef}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        style={{ fontSize: "12px" }}
                        className="btn-normal btn"
                      >
                        Save
                      </button>
                    </div>
                     </div>
                     
                    </div>



                    <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                     
                    <div class="alert alert-success" role="alert" style={{display:addressstatus == true ? "block" : "none"}}>
  Address Created Successfully 
</div>
                     
                    </div>


                  </div>
                  </Form>
                  )}
                  </Formik>
                  
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>

    </>
  );
}

export default Productdetails;
