import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import countryList from 'country-list';
import { gettoken } from "../Localstorage/Store";
import { FaDeleteLeft } from "react-icons/fa6";
import { useDeleteCartMutation, useGetCartProductQuery } from "../store/api/cartapi";
import { addItem } from "../store/state/cart";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Testproduct = () => {
  const [showtax, setshowtax] = useState(false);
  const [loading, setloading] = useState(false);
  const [vouchervalue, setvouchervalue] = useState('');
  const [totalitemvalue, settotalitemvalue] = useState(0);
  const [discountval, setdiscountval] = useState(0);
  const [totalmrp, settotalmrp] = useState(0);
  const [data, setData] = useState([]);
  const [voucherlist, setvoucherlist] = useState([]);
  const [voucherlist2, setvoucherlist2] = useState([]);
  const [cartinfo, setcartinfo] = useState({});
  const tokenvalue = gettoken();
  const nvg = useNavigate();
  const location = useLocation();
  const gobalvariable = useSelector(state => state);
  const dispatch = useDispatch();
  const { orderid } = useParams()
  const [singleorder, setsingleorder] = useState({})
  const [isshoppingvoucher, setisshoppingvoucher] = useState([]);



  console.log("order info", singleorder);
  useEffect(() => {
    async function orderdetail() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          'Content-Type': 'application/json',
        },
      };
      // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetVouchersView`;
      let url = `${process.env.REACT_APP_API_URL}my_api/get-single_order/${orderid}`;
      try {
        setloading(false)
        const response = await axios.get(url, config);
        setsingleorder(response.data)

        let shoppingvoucher = response.data.voucher_list.filter((item) => item.voucher_type == "Shopping Voucher - Exclusive");
        setisshoppingvoucher(shoppingvoucher);
        console.log("order s", response.data)
        if (response.data.product_deatils) {
          const updatedProducts = response.data.product_deatils.map(product => ({
            ...product,
            calculatedPercentOfMRP: product.discount_type == "percentage" ? (product.discount / 100) * product.mrp * product.quantity : product.discount * product.quantity,
          }));
          const totalPrice = updatedProducts.reduce((acc, product) => acc + product.calculatedPercentOfMRP, 0);
          const totalmrp = updatedProducts.reduce((acc, product) => acc + (product.mrp * product.quantity), 0);
          const totalitem = updatedProducts.reduce((acc, product) => acc + (1 * product.quantity), 0);

          settotalitemvalue(totalitem)
          setdiscountval(totalPrice)
          settotalmrp(totalmrp)
          setData(updatedProducts);
          setcartinfo(response.data.product_deatils);
          setloading(true)
        }

      } catch (error) {
        setloading(true)
      }
    }

    orderdetail()
  }, [])



  const [trackingDatatwo, setTrackingDatatwo] = useState({
    trackingData: {
      track_status: 1,
      shipment_status: 7,
      shipment_track: [
        {
          id: 236612717,
          awb_code: "141123221084922",
          courier_company_id: 51,
          shipment_id: 236612717,
          order_id: 237157589,
          pickup_date: "2022-07-18 20:28:00",
          delivered_date: "2022-07-19 11:37:00",
          weight: "0.30",
          packages: 1,
          current_status: "Delivered",
          delivered_to: "Chittoor",
          destination: "Chittoor",
          consignee_name: "",
          origin: "Banglore",
          courier_agent_details: null,
          courier_name: "Xpressbees Surface",
          edd: null,
          pod: "Available",
          pod_status: "https://s3-ap-southeast-1.amazonaws.com/kr-shipmultichannel/courier/51/pod/141123221084922.png"
        }
      ],
      shipment_track_activities: [
        {
          date: "2022-07-19 11:37:00",
          status: "DLVD",
          activity: "Delivered",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "7",
          "sr-status-label": "DELIVERED"
        },
        {
          date: "2022-07-19 08:57:00",
          status: "OFD",
          activity: "Out for Delivery Out for delivery: 383439-Nandinayani Reddy Bhaskara Sitics Logistics  (356231) (383439)-PDS22200085719383439-FromMob , MobileNo:- 9963133564",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "17",
          "sr-status-label": "OUT FOR DELIVERY"
        },
        {
          date: "2022-07-19 07:33:00",
          status: "RAD",
          activity: "Reached at Destination Shipment BagOut From Bag : nxbg03894488",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "38",
          "sr-status-label": "REACHED AT DESTINATION HUB"
        },
        {
          date: "2022-07-18 21:02:00",
          status: "IT",
          activity: "InTransit Shipment added in Bag nxbg03894488",
          location: "BLR/FC1, BANGALORE, KARNATAKA",
          "sr-status": "18",
          "sr-status-label": "IN TRANSIT"
        },
        {
          date: "2022-07-18 20:28:00",
          status: "PKD",
          activity: "Picked Shipment InScan from Manifest",
          location: "BLR/FC1, BANGALORE, KARNATAKA",
          "sr-status": "6",
          "sr-status-label": "SHIPPED"
        },
        {
          date: "2022-07-18 13:50:00",
          status: "PUD",
          activity: "PickDone",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "42",
          "sr-status-label": "PICKED UP"
        },
        {
          date: "2022-07-18 10:04:00",
          status: "OFP",
          activity: "Out for Pickup",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "19",
          "sr-status-label": "OUT FOR PICKUP"
        },
        {
          date: "2022-07-18 09:51:00",
          status: "DRC",
          activity: "Pending Manifest Data Received",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "NA",
          "sr-status-label": "NA"
        }
      ],
      track_url: "https://shiprocket.co//tracking/141123221084922",
      etd: "2022-07-20 19:28:00",
      qc_response: {
        qc_image: "",
        qc_failed_reason: ""
      }
    }
  });


  const [showtrack, setshowtrack] = useState(false);
  console.log(showtrack, "showtrack_________===={{{{+++")
  const [awbNumber, setawbNumber] = useState(null);

  const [trackingData, setTrackingData] = useState({});

  console.log(trackingData, "filterd data")

  const fetchTrackingData = async (item) => {
    setawbNumber(item);

    try {
      const url = `${process.env.REACT_APP_API_URL}ship/track-order-now?awb=${item}`;
      const response = await axios.get(url);

      if (response.data) {
        // Assuming response.data is a JSON string
        const jsonData = JSON.parse(response.data);
        setTrackingData(jsonData);
        setshowtrack(true);
      } else {
        setshowtrack(false);
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setshowtrack(false);
    }
  };



  return (
    loading == false ? <></> : <>
      <Header />


      <div className="breadcrumb-main marginfromtop" style={{ background: "#fff" }}>
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li><a href="/">home</a></li>
                    <li>{`>`}</li>
                    <li><a href="javascript:void(0)">Order Detail</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*section start*/}
      <section className="section-big-py-space b-g-light ">
        <div className="container-fluid d-flex justify-content-center">
          <div className="row justify-content-center definewidth" >
            <div className="col-md-8">

              <div className="table-responsive hist" style={{ borderRadius: "6px" }}>
                <h5 style={{ padding: '9px 9px', fontWeight: 400, fontSize: 14, paddingBottom: '20px', cursor: 'pointer' }} onClick={() => { nvg("/order-history") }}><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/Arrow 1.png`} alt /> Back to list</h5>
                <div className=""><h4 className="Orderstatus addmedia" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0px 9px', fontWeight: 700, fontSize: "13px", paddingBottom: '20px', paddingTop: '10px' }}>Order Id: {orderid}    <span> {new Date(singleorder.order_date.split('Time')[0]).toLocaleDateString('en-GB', { hour: 'numeric', minute: 'numeric' })}</span>   <span style={{ display: 'block', color: 'black', textTransform: 'capitalize' }}>Order Status: <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icon/success.png`} alt="404" /> {singleorder.order_status}</span></h4></div>

                <table className="table">
                  <thead className="two ">
                    <tr className="three" style={{ borderBottom: '1px solid #d0cbcb' }}>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Image</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Product Name </th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>MRP</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Price</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Quantity</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Total</th>
                      {/* <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px" }} ></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                <td className="px-3"><img src="/images/layout-3/product/1.jpg" width="80px" alt="cart" className="" /></td>
                <td className="pnwidth px-3"><span style={{ color: 'black', fontSize: "14px", lineHeight: "63px" }}>Cotton shirt</span></td>
                <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px" }}>₹63.00</h6></td>
                 <td className=" px-3" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px", }}> <span>2</span></td>
                   
                <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px", color: '#230BB3' }}>₹1000.00</h6></td>
               </tr> */}
                    {singleorder.product_deatils.map((item) => (
                      <tr>
                        <td className="px-3"><img src={`${process.env.REACT_APP_API_URL}media/${item.image?.[0]?.original}`}
                          width="80px"
                          height="80px" alt="cart" className=" " /></td>
                        <td style={{ padding: '16px 0px' }} className="pnwidth px-3"><div><span style={{ color: 'black', fontSize: "14px", lineHeight: item?.product__title.length > 23 ? "24px" : "33px", textAlign: 'center', position: 'relative', top: '5px' }}>{item?.product__title} </span> </div>
                        { (singleorder.order_status !== "Delivery" && singleorder.order_status !== "Cancelled") && item.awb && (
                          <div style={{ color: 'black', fontSize: "14px", lineHeight: item.product__title.length > 23 ? "24px" : "33px", textAlign: 'start', position: 'relative', top: '5px' }}>
                            <span onClick={() => fetchTrackingData(item.awb)}>
                              Track Awb : <span style={{ padding: '16px 0px', color: 'blue', cursor: 'pointer' }}>{item.awb}</span>
                            </span>
                          </div>
                        )}



                        </td>

                        <td style={{ padding: '16px 0px' }}><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", textAlign: 'center' }}>₹{item?.mrp}</h6></td>
                        <td style={{ padding: '16px 0px' }}><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", textAlign: 'center' }}>₹{item?.price_incl_tax}</h6></td>

                        {/* <button style={{border: 'none', outline: 'none', backgroundColor: 'white', color: '#230BB3', fontSize: "17px", fontWeight: 700}}>-</button> */}
                        <td className="td-color px-3" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", padding: '16px 0px' }}> <span><div style={{ display: 'flex', justifyContent: 'center' }}>{item?.quantity}</div></span> </td>
                        {/* <button style={{border: 'none', outline: 'none', backgroundColor: 'white', color: '#230BB3', fontSize: "17px", fontWeight: 700}}>+</button> */}

                        <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", color: '#230BB3', textAlign: 'center', padding: '6px 0px' }}>₹{(item?.price_incl_tax * item?.quantity).toFixed(2)}</h6></td>
                        {/* <td>  */}
                        {/* <a href="javascript:void(0)" class="icon" style="color:#777777;padding: 0px 3px; line-height: 63px;"> <img src="/images/edit.png" alt="404"> </a> */}
                        {/* <a href="javascript:void(0)" className="icon" style={{color: '#777777', padding: '0px 3px', lineHeight: "63px"}}><img src="/images/delete.png" alt={404} /></a></td> */}
                      </tr>
                    ))}

                    {/* <tr>
                <td className="px-3"><img src="/images/layout-3/product/3.jpg" width="80px" alt="cart" className=" " /></td>
                <td className="pnwidth px-3"><span style={{ color: 'black', fontSize: "14px", lineHeight: "63px" }}>Cotton Shirt</span></td>
                <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px" }}>₹63.00</h6></td>
                 <td className=" px-3" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px" }}> <span>1</span></td>
                <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "63px", fontSize: "14px", color: '#230BB3' }}>₹63.00</h6></td>
               </tr> */}
                  </tbody>
                </table>




                <br />
                {/* <h5 style={{ padding: '9px 9px', fontWeight: 400, fontSize: 14, paddingBottom: '20px' }}><img src="/images/Arrow 1.png" alt /> Back to Shop</h5> */}
              </div>
            </div>
            <div className=" col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-sm-3 mt-xs-3">
              <div
                className="py-2 px-2"
                style={{ background: "#ffff", borderRadius: 6 }}
              >                               <div className=""><h4 style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0px 9px', fontWeight: 700, fontSize: "14px", paddingBottom: '20px', paddingTop: '10px' }}>Payment Details <span style={{ display: 'block', color: '#8f9091' }}></span></h4></div>

                <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    MRP of {totalitemvalue} {totalitemvalue > 1 ? `Item's` : `Item` }
                    {/* {data.length} Items */}
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    {console.log("jsyut checking for ", totalmrp)}
                    ₹{totalitemvalue == 0 ? 0 : totalmrp}
                    {/* ₹{cartinfo.total_incl_tax_excl_discounts} */}
                  </span>
                </div>
                <div
                  className="firstrow px-3 justify-content-between"
                  style={{ padding: "5px 0px", display: discountval == 0 ? "none" : "flex" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Discount
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    {/* ₹0./00 */}
                    {discountval == 0 ? `₹0` : `-₹${discountval}`}
                  </span>
                </div>
                <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Shipping
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{singleorder?.shiping_charge}
                  </span>
                </div>
                {singleorder.voucher_list[0]?.offer ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px", display: voucherlist2[0]?.offer ? 'block' : 'none' }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Voucher Redemption
                  </span>
                  <span
                    style={{
                      color: "#230BB3",
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    {/* {totalvoucher == 0 ? `₹0` : `-₹${totalvoucher}`} */}
                  </span>
                </div> : ''}
                {singleorder.voucher_list[0]?.offer ? singleorder.voucher_list.map((item, index) => (
                  <div className="firstrow px-2 mx-2 py-1 d-flex justify-content-between mt-1 align-items-center">
                    <div
                      className="firstcontianer d-flex align-items-start"
                      style={{ gap: 4 }}
                    >
                      <div className="containerimg">
                        <img src="/images/carticon.png" alt={404} />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '4px', alignItems: 'baseline', minWidth: '192px' }}>
                        <p
                          className="m-0"
                          style={{ fontWeight: 400, fontSize: "12px" }}
                        >
                          {item.code} applied {item.voucher_type == 'Shopping Voucher - Exclusive' ? 'excl. GST' : 'incl. GST'}
                        </p>
                        {/* <span style={{color:'',cursor:'pointer',display:'inline-block'}} onClick={()=>{removevoucher(item.code)}}>
                          <FaDeleteLeft size={15} />
                          </span> */}
                      </div>
                    </div>
                    <div className="remove" style={{ flexDirection: 'column', paddingRight: '0px', padding: '0px' }}>
                      <span
                        style={{
                          color: "#230BB3",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        ₹{item.offer}
                      </span>
                      {/* <span style={{
                        color: "#D83043",
                        fontWeight: 500,
                        fontSize: "10px",
                      }}>
                  <span style={{paddingRight:'15px'}}> Save :</span>- ₹{voucherlist2[index]?.original_value}
                    </span>
                 {voucherlist2[index]?.voucher_gst_amount || voucherlist2[index]?.voucher_gst_amount == 0 ?<span style={{
                        color: "#D83043",
                        fontWeight: 500,
                        fontSize: "10px",
                      }}>
                  <span style={{paddingRight:'15px'}}> gst :</span>+ ₹{voucherlist2[index]?.voucher_gst_amount}
                    </span> : ''} */}
                    </div>
                  </div>
                )) : ''
                }
                {singleorder.voucher_list[0]?.offer ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Basic Amount(Redeemable)
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{singleorder?.basic_amount_radeemable}
                  </span>
                </div> : ''}
                {isshoppingvoucher[0]?.code ? singleorder.total_product_gst_value > 0 ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    GST Amount(Payable)
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{singleorder.total_product_gst_value}
                  </span>
                </div> : '' : ''}
                {/* <div
                  className="firstrow px-3 justify-content-between"
                  style={{ padding: "5px 0px",display : tokenvalue.msg == "Voucher login" ? "none":"flex" }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      paddingLeft: "0px",
                    }}
                  >
                    Credit Utilize
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹0{" "}
                    <hr
                      color="red"
                      style={{
                        margin: 0,
                        position: "relative",
                        top: 4,
                        opacity: 1,
                        color: "#2B2A29",
                        background: "#2B2A29",
                      }}
                    />
                  </span>
                </div> */}
                <div className="firstrow px-3 pt-1 d-flex justify-content-between">
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      paddingLeft: "0px",
                    }}
                  >
                    Order Total{" "}
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#8F9091",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setshowtax(!showtax);
                      }}
                    >
                      (incl. taxes) <span style={{ fontSize: '13px' }}>{showtax == true ? '-' : '+'}</span>  {" "}
                      <span
                        style={{
                          fontSize: 10,
                          color: "#8F9091",
                          textAlign: 'end',
                          display: showtax == true ? "inline-block" : "none",
                        }}
                        id="span2"
                      >
                        {/* % GST on ₹0 */}₹{singleorder.total_product_gst_value ? singleorder.total_product_gst_value : 0}
                      </span>
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{singleorder.order_total_amount ? singleorder.order_total_amount : 0}
                  </span>
                </div>
                <br />
                <hr />

                <div className="firstrow px-3 d-flex justify-content-between" style={{ padding: "5px 0px" }}>
                  <span className="family" style={{ fontWeight: 600, fontSize: "12px" }}>Payment Mode</span>
                  <span className="family" style={{ fontWeight: 500, color: '#8F9091', fontSize: '12px' }}>
                    {/* {singleorder.payment_mode == "cash_on_delivery" ? "Cash On Delivery" : "Online Pay"} */}
                     {singleorder.payment_mode == "Razorpay" ? "Online Pay" : singleorder.payment_mode == "Wallet" ? "Wallet" : singleorder.payment_mode == "Custome" ? "Custome" : "COD"}</span>
                </div>
              </div>
              <div
                className="py-2"
                style={{ background: "#ffff", borderRadius: 6, marginTop: 10 }}
              >

                {/* <div
                  className="firstrow px-3 justify-content-between"
                  style={{ padding: "5px 0px",display : tokenvalue.msg == "Voucher login" ? "none":"flex" }}
                >
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    Apply Credits
                  </span>
                  <div style={{ display: "flex", gap: 4 }}>
                    <span style={{ fontWeight: 700, color: "#230BB3" }}>
                      <input
                        type="text"
                        className="form-control applypay"
                        placeholder="Enter your credit"
                      />
                    </span>
                    <button
                      className="btn"
                      style={{
                        fontWeight: 500,
                        // backgroundColor: "#2b2a29",
                        backgroundColor: "#230BB3",
                        color: "white",
                        fontSize: 12,
                        height: "fit-content",
                        padding: "7px 8px",
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div> */}
                <div className="px-4" style={{}}>

                  <h4 style={{ fontSize: '14px' }}>Billing Address</h4>
                  <p style={{ color: '#2B2A29', fontSize: '12px' }}>{singleorder.billing_address.first_name} {singleorder.billing_address.last_name}</p>
                  <p style={{ fontSize: '12px' }}> {singleorder.billing_address.line1}, {singleorder.billing_address.line2},  {countryList.getName(singleorder.billing_address.country)},{singleorder.billing_address.state}, {singleorder.billing_address.line4}, {singleorder.billing_address.postcode} <br />
                  </p>
                  {/* <p className="" style={{ fontSize: '12px' }}>
                    Mobile:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }}>
                      {singleorder.shipping_address.phone_number}
                    </span>
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Email ID:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }} >
                      {singleorder.shipping_address.email}

                    </span>
                  </p> */}


<br />
                  <h4 style={{ fontSize: '14px' }}>Delivery Address</h4>
                  <p style={{ color: '#2B2A29', fontSize: '12px' }}>{singleorder.shipping_address.first_name} {singleorder.shipping_address.last_name}</p>
                  <p style={{ fontSize: '12px' }}> {singleorder.shipping_address.line1}, {singleorder.shipping_address.line2},  {countryList.getName(singleorder.shipping_address.country)},{singleorder.shipping_address.state}, {singleorder.shipping_address.line4}, {singleorder.shipping_address.postcode} <br />
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Mobile:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }}>
                      {singleorder.shipping_address.phone_number}
                    </span>
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Email ID:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }} >
                      {singleorder.shipping_address.email}

                    </span>
                  </p>

                  {/* <span style={{fontWeight: 600, fontSize: 12}}>Apply Credits</span>
<div style={{display: 'flex', gap: 4}}>
  <span style={{fontWeight: 700, color: '#230BB3'}}><input type="text" className="form-control applypay" placeholder="Enter your credit" /></span>
  <button className="btn" style={{fontWeight: 500, backgroundColor: '#230BB3', color: 'white', fontSize: 12, height: 'fit-content', padding: '7px 8px'}}>Apply</button>
</div> */}
                </div>
                {/* <div style={{display : tokenvalue.msg == "Voucher login" ? "none":"flex" }} className="firstrow px-3 pb-2 justify-content-between">
                  <span style={{ fontWeight: 600, fontSize: "17px" }} />
                  <span style={{ fontWeight: 500, fontSize: 11 }}>
                    Available Credit - ₹0
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {showtrack && (
        <div
          style={{
            position: 'fixed',
            top: 25,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}
        >


          <div style={{ maxWidth: '32rem', minWidth: '32rem', margin: '0 auto', backgroundColor: 'white', padding: '1.5rem', border: '1px solid #e5e7eb', borderRadius: '0.5rem', minHeight: '10rem', maxHeight: '30rem', overflowY: 'auto' }}>
            <div style={{
              display: 'flex', justifyContent: 'space-between',
            }}>
              <span></span>
              <span
                onClick={() => setshowtrack(false)}
                style={{
                  fontSize: '1.5rem',
                  color: 'red',
                  cursor: 'pointer'

                }}
              >
                <IoIosCloseCircleOutline />
              </span>

            </div>

            <div style={{ paddingBottom: '1rem', borderBottom: '1px solid #e5e7eb', marginBottom: '1rem' }}>
              <h2 style={{ fontSize: '1.125rem', fontWeight: '600' }}>Delivery by Oneup</h2>
              <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Tracking ID:</p> <span> {awbNumber}</span>
              <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>This is your Oneup tracking number.</p>
            </div>

            {trackingData && trackingDatatwo.trackingData.shipment_track_activities ? (
              trackingDatatwo.trackingData.shipment_track_activities.map((activity, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                    <p style={{ width: '5rem', fontSize: '0.75rem', color: '#6b7280' }}>
                      {activity.date ? new Date(activity.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '00:00'}
                    </p>
                  </div>

                  <div>
                    {activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>{activity.activity}</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                    {!activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>Unknown Activity</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p>In Progress</p>
              </div>
            )}






          </div>
        </div>
      )}

      {/*section end*/}

      {/* <Footer /> */}
    </>
  );
};
export default Testproduct;
