import * as Yup from "yup";

export const Register4val = Yup.object({
  fullname: Yup.string()
    .label("Full Name"),
    // .required("Full Name is required"),
    designation: Yup.string()
    .label("Designation"),
    // .required("Designation is required"),
    email: Yup.string()
    .label("Email ID")
    .email(),
    // .required("Email ID is required"),
    mobile: Yup.number()
    .label("Mobile No")
    .min(1000000000, "Mobile number must be at least 10 digits")
    .max(9999999999, "Mobile number cannot be more than 10 digits"),
    // .required("Please Enter Your Mobile Number"),
    address: Yup.string()
    .label("Address")
    .max(90)
    .required("Address is required"),
    address2: Yup.string()
    .label("Address 2")
    .max(90)
    .required("Address 2 is required"),
    postalcode: Yup.number()
    .label("Postal Code")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Postal Code is required"),
    city: Yup.string()
    .required("City is required"),
    state: Yup.string()
    .required(" State is required"),
    country: Yup.string()
    .required("Country is required"),
});
