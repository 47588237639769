import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import Loginheader from "../components/Loginheader";


const Thankyouonboarding = () => {
  const nvg = useNavigate();

    return(
  <>
<div>
 
  {/*header start*/}
{/* <Header /> */}
{/* <Loginheader /> */}
{/* {tokenvalue.msg !== "Voucher login" ? <Header /> : <Loginheader />}  */}
  {/*header end*/}
{/* <Disable /> */}


<section className=" ">
          <div className="col-lg-12 col-sm-12 mt-xs-4 mb-2">
            {/* <div className='container-fluid bigdiv'> */}
            <div className="container-fluid">
              <div className="row Thankpage">
            
            
              <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 py-lg-5 py-3 d-flex justify-content-center">
          <img
            // src={`./images/logo.png`}
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth"
            style={{maxWidth:'340px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>




                <div className="col-lg-12 col-sm-12 col-md-12">
                <div className="TextTankyou">
               <div className="Imgverify"> <img src="./images/thankyou.png" alt="img-fluid" style={{width:'12%'}} className="boyimg"/></div>
                    <h2 className="Fontthank" style={{fontWeight:'600'}}> <a href="Thank you" style={{color:'#230BB3'}}>Thank you !</a> </h2>
                </div>
                <div>
                    <p className="py-2" style={{fontSize:'17px',textAlign:'center',fontWeight:'600'}} > Thank you for completing your onboarding details, you will received an email message shortly.
                        {/* <p className="text-center" style={{color:'#8F9091'}} >With order details.</p> */}
                    </p>
                </div>
                <div className="backTohome" style={{paddingTop:'14px',marginBottom:'150px'}}>
                <NavLink to="/" style={{lineHeight:'20px',color:'#8F9091',fontSize:'14px' }}>Back To Home Page</NavLink> 
                </div>
               


                </div>

                
              </div>
            </div>
          </div>
        </section>

  {/* footer start */}
  {/* <Footer /> */}
  {/* footer end */}

 
</div>
        </>
    )
}
export default Thankyouonboarding;