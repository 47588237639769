import * as Yup from "yup";

export const Register2val = Yup.object({
  gstno: Yup.string()
    .label("GST No"),
    // .required("GST No is required"),
    businessname: Yup.string()
    .label("businessname"),
    address: Yup.string()
    .label("Address")
    .max(100)
    .required("Address is required"),
    address2: Yup.string()
    .label("Address 2")
    .max(60)
    .required("Address 2 is required"),
    postalcode: Yup.number()
    .label("Postal Code")
    .min(100000, "Pincode must be at least 6 digits")
    .max(999999, "Pincode cannot be more than 6 digits")
    .required("Postal Code is required"),
    city: Yup.string()
    .required("City is required"),
    state: Yup.string()
    .required(" State is required"),
    country: Yup.string()
    .required("Country is required"),
    pan: Yup.string()
    .required("Pan Card is required"),
    // .min(1000000000, "PAN No must be at least 10 digits")
    // .max(9999999999, "PAN No cannot be more than 10 digits"),
    // businesslegaltype: Yup.string()
    // .label("Business Legal Type")
    // .required(" Business Legal Type is required"),
    registrationdate: Yup.date()
    .label("Date of Establishment")
    .required("Registration Date is required")
});
