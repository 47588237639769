import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Subsubcategory from "./Subsubcategory.jsx";

const MobileSubcategorynew = ({ value,setwdith }) => {
  const [showfullmenu, setshowfullmenu] = useState(false);
  const nvg = useNavigate();
  // const urlParts = value.url.split("/");
  const categoryid = value.category_id;

  const transfer2 = (value, title,maincategory) => {
    // let urlParts = value.split("/");
    // let id = urlParts[urlParts.length - 2];
    // nvg("/category",{ state: { id: id,pagename:title } });
    nvg(`/category/${value}/${maincategory} > ${title}/none`);
    // window.location.reload();
  };

  const transfer = (id,value) => {
    // nvg("/category",{ state: { id: id,pagename:value.breadcrumbs} });
    nvg(`/category/${id}/${value}/none`);
    // window.location.reload();
  };
  const [categories, setCategories] = useState([]);
  // useEffect(() => {
  //   async function fetchcategory() {
  //     const response = await axios.get(value.children);
  //     setCategories(response.data);
  //   }

  //   fetchcategory();
  // }, []);
  return (
    <>
    <li className={value.child.length == 0 ? "" : "mega"}>
      {value.child.length == 0 ? (
        <button
          className="btn dark-menu-item desgin1"
          style={{ padding: "6px 18px 0px 18px" }}
          //  to="#"
          onClick={() => {
            transfer(value.category_id,value.category_name);
          }}
          type="button"
        >
          {" "}
          {/* <img
     src={value.image}
     style={{ width: "25px" }}
     alt={404}
     className="hideonlaptop"
   />{" "} */}
          &nbsp; <span onClick={() => {
            transfer(value.category_id,value.category_name);
          }} className="largefont"> {value.category_name} </span>
        </button>
      ) : (
        <button
          type="button"
          className="btn dark-menu-item desgin1 justshow"
          style={{ padding: "6px 18px 0px 18px" }}
          onClick={() => {
            setshowfullmenu(!showfullmenu);
          }}
          // href="javascript:void(0)"
        >
          {/* <img
                              src={value.image}
                              style={{ width: "25px" }}
                              alt={404}
                              className="hideonlaptop"
                            />{" "} */}
          &nbsp; <span className="largefont"> {value.category_name}</span>
        </button>
      )}

      {showfullmenu == true ? (
        value.child.length < 0 ? (
          ""
        ) : (
          <ul
            className="mega-menu full-mega-menu "
            style={{ display: "block" }}
          >
            <div>
              <div className="container">
                <div className="row">
                  {value.child.map((item, index) => (
                    <div className="col mega-box">
                      <div className="link-section">
                        <div className="menu-title">
                          <h5
                            onClick={() => {
                              transfer2(item.id, item.name,value.category_name);
                            }}
                            style={{ fontWeight: "400" }}
                          >
                            {item.name}{" "}
                          </h5>
                        </div>
                        {/* <Subsubcategory subvalue={item.children} /> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </ul>
        )
      ) : (
        ""
      )}
    </li>
    
    </>
  );
};

export default MobileSubcategorynew;
