import React, { useEffect, useRef, useState } from 'react'
import { gettoken } from '../Localstorage/Store'
import { useLocation, useNavigate } from 'react-router-dom'
import Subcategory from './Subcategorylogin'
import axios from 'axios'
import MobileSubcategory from './MobileSubcategory'

const Loginheader = ({loginchange,voucherchange,focusemail}) => {
const nvg = useNavigate()
const [categories, setCategories] = useState([]);
const tokenvalue = gettoken()
const location = useLocation()
const pagename = location.pathname;
const ctl = useRef(null)
const vtl = useRef(null)

const [searchdata, setsearchdata] = useState([]);
const [loader, setloader] = useState(true);

  const searchresult = async (value) => {

    if(value == undefined || value == null || value == ''){     
    setsearchdata([])
    }else{
    let url = `${process.env.REACT_APP_API_URL}homepageapi/search/?q=${value}`;
    const response = await axios.get(url);
    setsearchdata(response.data.data)

  }
   }

   const sendlogin = (id)=>{
    if(pagename == "/"){
    if(id == 1){
      loginchange()
    }else{
      voucherchange()
    }
  }else{
    if(id !== 1){
      nvg('/',{ state: { id: 1} })
    }else{
      nvg('/')
    }
    
  }
   }
  useEffect(()=>{
    if(tokenvalue){
      if(pagename !== "/contactlogin"){
        nvg('/home')
      }
    }
    
    // async function fetchcategory(){
    //   setloader(true)
    //   let url = `${process.env.REACT_APP_API_URL}api/categories/`;
    //   const response = await axios.get(url);
    //   setCategories(response.data)
    //   setTimeout(() => {
    //     setloader(false)
    //   }, 600);
    //  }
    //  fetchcategory();
   },[])

   useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
    const [showsidebar, setshowsidebar] = useState(false);
    const [subcategory, setsubcategory] = useState(false);
    const [acdropdown, setacdropdown] = useState(false);
  return (
    <header className="fixed-top" style={{position: 'fixed'}}>
  <div className="mobile-fix-option" />
  <div className="header7">
    <div className="custom-container">
      <div className="row">
        <div className="col-12">
          <div className="header-contain ">
            <div className="logo-block logowidth">
              <div className="mobilecat-toggle" onClick={() => setshowsidebar(true)}> <i className="fa fa-bars sidebar-bar" /></div>
              <div className="brand-logo logo-sm-center">
                <a href="/">
                  <img src="https://oneupv1.stackerbee.com/images/logo.png" className="img-fluid" alt="logo" />
                </a>
              </div>
            </div>
            
            {/* <div className="header-search ajax-search the-basics dflex" style={{backgroundColor: 'white', border: '1px solid #cbc7c7', padding: '0px 3px'}}>
              <div className="input-group" style={{border: '1px solid white'}}>
                <input type="search" className="form-control newsizeinput" style={{border: '1px solid white', background: 'white', padding: 0, borderRadius: 0}} placeholder="Search for Product" />
              </div>
              <div className="input-group-text btn " style={{backgroundColor: 'white', padding: '0px 17px', borderRadius: 0, display: 'flex', border: 'none'}}>
                <span className="newfontsize"> <i className="fa fa-search " style={{color: 'rgba(35, 11, 179, 1)'}} /></span>
              </div>
            </div> */}
            {/* <div style={{position:'relative'}}>
                <div
                  className="header-search ajax-search the-basics dflex"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #cbc7c7",
                    padding: "0px 3px",
                  }}
                >
                  <div
                    className="input-group"
                    style={{ border: "1px solid white" }}
                    onClick={focusemail}
                  >
                    <input
                      type="text"
                      className="form-control newsizeinput"
                      style={{
                        border: "1px solid white",
                        background: "white",
                        padding: 0,
                        borderRadius: 0,
                        letterSpacing: 0,
                        fontSize:'14px'
                      }}
                      // onChange={(e)=>{searchresult(e.target.value)}}
                      placeholder="Search For Product"
                    />
                  </div>
                  <div
                    className="input-group-text btn "
                    style={{
                      backgroundColor: "white",
                      padding: "0px 17px",
                      borderRadius: 0,
                      display: "flex",
                      border: "none",
                    }}
                  >
                    <span className="newfontsize">
                      {" "}
                      <i
                        className="fa fa-search "
                        style={{ color: "rgba(35, 11, 179, 1)" }}
                      />
                    </span>
                  </div>
                </div>
                  <ul className="serachlisting" >
                  {searchdata[0]?.title ? searchdata.map((item, index) => ( 
                    <li>
                      <div className="p-1 d-flex" style={{gap:"10px",cursor:'pointer'}} >
                        <div className="imagecontain">
                        <img src={`${process.env.REACT_APP_API_URL}media/${item.image[0]?.original}`} style={{width:'70px'}} className="img-fluid  " alt="product" />
                        </div>
                        <div className="cartinfo">
                         <h6 style={{fontSize:'12px',color:'#230bb3',fontWeight:'500'}}>{item.title}</h6>
                         <h6 style={{fontSize:'12px',color:'#230bb3'}}>₹{item.price}</h6>
                         {/* <p style={{fontSize:'12px',color:'#230bb3'}} dangerouslySetInnerHTML={{__html:item.description}}></p> */}
                        {/* </div>
                      </div>
                    </li>
                        )) : ''}
                  </ul>
                  </div> */}
                            <div className="icon-block  hiddemeu" style={{gap: '5px'}}>
                              <li><p onClick={()=>{nvg('/about')}} style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'13px 8px',color:'#230bb3'}}>About Us</p></li>
                              <li><p onClick={()=>{nvg('/contact')}} style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}>Contact Us</p></li>
                              {/* <li><p onClick={()=>{nvg('/privacypolicy')}} style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}>Privacy Policy</p></li>
                              <li><p onClick={()=>{nvg('/termsconditions')}} style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}>Terms & Conditions</p></li> */}
                              <li><p onClick={()=>{nvg('/track-order')}} style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}>Track Order</p></li>
                              <li><p onClick={()=>{nvg('/clientlogin')}} className="custom-btn btn-1 px-2"
                              style={{width:'fit-content',margin:'0px 5px'}}
                              //  style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}
                               >Client Login</p></li>
                              {/* <button id="btn2" className="custom-btn btn-1" onClick={()=>{nvg('/registerbusiness')}}   type="button">Register Business</button> */}

                              <li><p onClick={()=>{nvg('/voucherlogin',{ state: { id: 1} })}} 
                              // style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}
                              className="custom-btn btn-1 px-2"
                              style={{width:'fit-content',margin:'0px 5px'}}
                              >Voucher Login</p></li>
                              <li><p onClick={()=>{nvg('/registerbusiness')}}
                              //  style={{fontWeight:'bold',cursor:'pointer',fontSize:'16px',padding:'0px 8px',color:'#230bb3'}}
                              className="custom-btn btn-1 px-2"
                              style={{width:'fit-content',margin:'0px 5px'}}
                               >Register Business</p></li>
              {/* <button id="btn1" className="custom-btn btn-1 m-1" onClick={()=>{sendlogin(1)}}   type="button">Client Login</button> */}
              {/* <button id="btn2" className="custom-btn btn-1" onClick={()=>{sendlogin(2)}} type="button">Voucher Redemption</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="searchbar-input">
      <div className="input-group">
        <div className="input-group-append">
          <span className="input-group-text">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28.931px" height="28.932px" viewBox="0 0 28.931 28.932" style={{enableBackground: 'new 0 0 28.931 28.932'}} xmlSpace="preserve">
              <g>
                <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z">
                </path>
              </g>
            </svg>
          </span>
        </div>
        <input type="text" className="form-control" placeholder="search your product" />
        <div className="input-group-append">
          <span className="input-group-text close-searchbar">
            <svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg">
              <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0">
              </path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div className="category-header7">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="category-contain">
            <div className="category-left showandhide ">
              <div className="header-category">
                <a className="category-toggle"><i className="fa fa-bars" />pages</a>
                <ul id="main-menu" 
                    onClick={focusemail}
                    className={
                        showsidebar == true
                          ? "collapse-category show sm pixelstrap sm-horizontal open"
                          : "collapse-category show sm pixelstrap sm-horizontal"
                      } >
                  <li className="back-btn" onClick={() => setshowsidebar(false)}><i className="fa fa-angle-left" /> back</li>
            




                  <li>
        <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/about')}} 
  type='button'
 >
   {" "}
   {/* <img
     src={value.image}
     style={{ width: "25px" }}
     alt={404}
     className="hideonlaptop"
   />{" "} */}
   &nbsp;{" "}
   <span className="largefont"> About Us </span>


 </button>

 
</li>
                  <li>
        <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/contact')}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont"> Contct Us </span>
 </button>
</li>
                  {/* <li>
        <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont"> Privacy Policy </span>
 </button>
</li>
                  <li>
        <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont"> Terms & Conditions</span>
 </button>
</li> */}
                  <li>
        <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/track-order')}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont"> Track Order</span>
 </button>
</li>

<li>
  
  
  
   <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/clientlogin')}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont">Client Login</span>
 </button></li>
                              <li> <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/voucherlogin',{ state: { id: 1} })}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont">Voucher Login</span>
 </button></li>
                              <li> <button
   className="btn dark-menu-item desgin1"
   style={{padding:"6px 18px 0px 18px"}}
  //  to="#"
  // onClick={()=>{transfer(categoryid)}}
  onClick={()=>{nvg('/registerbusiness')}}
  type='button'
 >
   &nbsp;{" "}
   <span className="largefont">Register Business</span>
 </button></li>

                </ul>
              </div>
              <div className="logo-block">
                <div className="brand-logo logo-sm-center">
                  <a href="/">
                    <img src="https://oneupv1.stackerbee.com/images/logo.png" className="img-fluid" alt="logo" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="category-right ">
              <div className="menu-block">
                <nav id="main-nav">
                  <div className="toggle-nav">
                    <i className="fa fa-bars sidebar-bar" />
                  </div>
                  <ul 
                    onClick={focusemail}
                    id="main-menu" className="sm pixelstrap sm-horizontal">
                    <li>
                      <div className="mobile-back text-right">
                        Back<i className="fa fa-angle-right ps-2" aria-hidden="true" />
                      </div>
                    </li>
                    {categories.map((item, index) => (
                          item.name == "Electronics" ? <Subcategory value={item} setwdith={0}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Appliances" ? <Subcategory value={item}  setwdith={1} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Apparels" ? <Subcategory value={item} setwdith={1}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Accessories" ? <Subcategory value={item} setwdith={1} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Kitchenware" ? <Subcategory value={item} setwdith={1} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Sanitaryware" ? <Subcategory value={item} setwdith={0}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name !== "Appliances" && item.name !== "Electronics" && item.name !== "Apparels" && item.name !== "Accessories" && item.name !== "Kitchenware" && item.name !== "Sanitaryware" ? <Subcategory value={item} setwdith={item.name == "Furnishing" || item.name == "Interior" ? 0 : 0}  /> : ''
                        ))}
                  </ul>
                </nav>
              </div>
            </div> */}
            <div className="store-locator">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="searchbar-input ajax-search the-basics">
      <div className="input-group">
        <span className="input-group-text">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28.931px" height="28.932px" viewBox="0 0 28.931 28.932" style={{enableBackground: 'new 0 0 28.931 28.932'}} xmlSpace="preserve">
            <g>
              <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z">
              </path>
            </g>
          </svg>
        </span>
        <input type="search" className="form-control typeahead" placeholder="Search a Product" />
        <span className="input-group-text close-searchbar">
          <svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg">
            <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0">
            </path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</header>
  )
}

export default Loginheader