import * as Yup from "yup";

export const vrequest = Yup.object({
    vouchername: Yup.string()
    .label("Voucher Name")
    .required("Please Enter Voucher Name"),
    categories: Yup.array()
    .label("Categories")
    .required("Please Enter at least one Category"),
    vouchertype: Yup.string()
    .label("Voucher Type")
    // .oneOf(["Gift Voucher - Inclusive", "Shopping Voucher - Exclusive"], "Invalid Voucher selection")
    .required("Voucher Type is required"),
    quantity: Yup.number()
    .label("Quantity")
    .required("Please Enter Quantity"),
    amount: Yup.number()
    .label("Amount")
    .required("Please Enter Amount"),
});
