import * as Yup from "yup";

export const Register3val = Yup.object({
  companytype: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    companytype2: Yup.string()
    .label("Company Type")
    .required("Company Type is required"),
    industrytype: Yup.string()
    .label("Intustry Type")
    .required("Intustry Type is required"),
    weblink: Yup.string()
});
